import * as React from 'react';
import classNames from 'classnames';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Zoom from '@mui/material/Zoom';
import CircularProgress from '@mui/material/CircularProgress';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import ArchiveIcon from '@mui/icons-material/Archive';

import { GatherPhoto, PhotoStatusEnum, StoreState } from '../../../types';
import { GatherCasePublic, GatherCaseUX, isAlbumEntry } from '../../../shared/types';

import { getFullNameFromCase, PhotoOrientationType, PhotoSizeType } from '../../../services';
import ConfirmationDialog from '../../common/ConfirmationDialog';
import PhotoPopper from '../../family/photoSlideshow/PhotoPopper';
import { wrappedStyles, StyledProps } from './styles';
import VirtualMasonryPhotoList from './VirtualMasonryPhotoList';
import withGStyles from '../../../styles/WithGStyles';
import withState from '../../common/utilHOC/WithState';
import { AppDispatch } from '../../../store';
import { downloadFromURL } from '../../../services/doc.service';

function mapStateToProps({
    casesState,
}: StoreState) {
    return {
        casePhotos: casesState.casePhotos,
        activeCase: casesState.selectedCase || casesState.publicCase
    };
}

export interface SharedProps {
    photoList: GatherPhoto[];
    photoOptions?: {
        orientation?: PhotoOrientationType;
        size?: PhotoSizeType;
    };
    allowMultiSelection: boolean;
    selectedPhotoIds?: number[]; // if provided switch to multi=photo mode
    isLargePhotoView?: boolean;
    hideUploadButton?: boolean;
    hideDownloadButton?: boolean;
    hideEditButton?: boolean;
    enablePhotoSwipe?: boolean;
    acceptMultiple?: boolean;  // allow multiple uploads
    isPhotoSelecting?: boolean;
    isPhotoDeselecting?: boolean;
    selectedPhotoId?: number | null;
    resetPhotoSelectDeselect?: () => void;
    scrollElement?: HTMLElement;
    hideFlagInappropriateButtonOnPopper?: boolean;
    editPhotoButtonClass?: string;
}

export interface Props extends SharedProps, ReturnType<typeof mapStateToProps> {
    dispatch: AppDispatch;
    isUploading: boolean;
    isLoading: boolean;
    zIndex: number;
    showLoader?: boolean;
    activePhotoId?: number;
    uploadNewButtonClass?: string;
    onPhotoClick?: (chosenImage: GatherPhoto) => void;
    onFileUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onPhotoDelete?: (gatherPhoto: GatherPhoto) => void;
    downloadCasePhotos?: (gatherCase: GatherCaseUX | GatherCasePublic) => Promise<string | null>;
    loaderClass?: string;
}

interface State {
    isDeleteConfirmationDialogOpen: boolean;
    photoToDelete: GatherPhoto | null;
    downloadUrl: string | null;
    isDownloadConfirmationDialogOpen: boolean;
    popperEl: HTMLElement | null;
    isOpenPhotoPopper: boolean;
    activePhotoId: string | null;
}

class MasonryPhotoList extends React.Component<Props & StyledProps, State> {
    protected fileUploadInput: HTMLInputElement | null = null;

    constructor(props: Props & StyledProps) {
        super(props);

        this.state = {
            // if we have any images already selected, then initialize those now
            isDeleteConfirmationDialogOpen: false,
            photoToDelete: null,
            downloadUrl: null,
            isDownloadConfirmationDialogOpen: false,
            popperEl: null,
            isOpenPhotoPopper: false,
            activePhotoId: null,
        };
    }

    async componentDidMount() {
        const { downloadCasePhotos, activeCase, hideDownloadButton } = this.props;
        if (!hideDownloadButton && downloadCasePhotos && activeCase) {
            this.setState({
                downloadUrl: await downloadCasePhotos(activeCase),
            });
        }
    }

    componentDidUpdate(prevProps: Readonly<Props & StyledProps>) {
        const { photoList: prevList } = prevProps;
        const { photoList, selectedPhotoIds } = this.props;

        const safeSelectedIdList = selectedPhotoIds ? selectedPhotoIds : [];
        // extract any images that were uploading
        const uploading = prevList.filter(({ status }) => status === PhotoStatusEnum.uploading);
        // now see if they have finished loading
        uploading.forEach((entry) => {
            const newEntry = photoList.find((e) => e.gatherId === entry.gatherId);
            if (newEntry && newEntry.status === PhotoStatusEnum.uploaded && newEntry.photo) {
                const alreadySelected = safeSelectedIdList
                    .find((id) => isAlbumEntry(newEntry.photo) && id === newEntry.photo.photo_id);
                // if image finished loading and is not already selected add to list
                if (!alreadySelected) {
                    this.handlePhotoClick(newEntry);  // add to list
                }
            }
        });
    }

    findActivePhoto = () => {
        const { casePhotos } = this.props;
        const { activePhotoId } = this.state;
        if (!casePhotos) {
            return null;
        }
        return casePhotos.find(e => e.gatherId === activePhotoId) || null;
    };

    closePopper = () => {
        this.setState({
            popperEl: null,
            isOpenPhotoPopper: false,
        });
    };

    togglePhotoPopper = (e: React.MouseEvent<HTMLElement>, photoId: string | null) => {
        e.preventDefault();
        e.stopPropagation();

        this.setState({
            popperEl: e.currentTarget,
            activePhotoId: photoId,
        });

        this.setState((prevState) => ({
            isOpenPhotoPopper: !prevState.isOpenPhotoPopper
        }));
    };

    renderUploadNewPhoto = () => {
        const { classes, isUploading, uploadNewButtonClass } = this.props;

        return (
            <Zoom in timeout={1200}>
                <Button
                    className={classes.uploadNewPhotoContainer}
                    onClick={() => this.fileUploadInput && this.fileUploadInput.click()}
                    disabled={isUploading}
                >
                    <div className={classNames(classes.buttonTextAbsolute, uploadNewButtonClass)}>
                        <AddAPhotoIcon color="inherit" className={classes.addPhotoIcon} />
                        <Typography
                            color="inherit"
                            paragraph
                            className={classes.squareButtonText}
                        >
                            Upload New
                        </Typography>
                    </div>
                </Button>
            </Zoom>
        );
    };

    openDownloadAllPhotosConfirmationDialog = () => {
        this.setState({ isDownloadConfirmationDialogOpen: true });
    };

    closeDownloadAllPhotosConfirmationDialog = () => {
        this.setState({ isDownloadConfirmationDialogOpen: false });
    };

    renderDownloadPhotosConfirmationDialog = () => {
        const { photoList, zIndex, activeCase } = this.props;
        const count = photoList ? photoList.length : 0;
        const { isDownloadConfirmationDialogOpen, downloadUrl } = this.state;
        const downloadDialogHeader = `Download the ${count} photos in this album`;
        const dialogSubHeader = `This will download these ${count} photos into a zipped folder onto your 
        device so that you can use them as needed. This action will maintain the order of the photos listed on this 
        page. This may take a few moments to complete. Feel free to navigate away from this page while your photos 
        are being prepared and they will be waiting for you when you come back!`;
        const deleteDialogConfirmationButton = `Download ${count} Photos`;
        const cancelButtonText = 'Nevermind';

        return (
            <ConfirmationDialog
                header={downloadDialogHeader}
                subHeader={dialogSubHeader}
                confirmationButtonText={deleteDialogConfirmationButton}
                cancelButtonText={cancelButtonText}
                onClose={this.closeDownloadAllPhotosConfirmationDialog}
                open={isDownloadConfirmationDialogOpen}
                onConfirm={() => {
                    if (activeCase && downloadUrl) {
                        downloadFromURL(downloadUrl, `${getFullNameFromCase(activeCase)} Photos`);
                    }
                    this.closeDownloadAllPhotosConfirmationDialog();
                }}
                zIndex={zIndex + 1}
            />
        );
    };

    renderDownloadPhotosButton = () => {
        const { downloadUrl } = this.state;
        const { classes, photoList } = this.props;
        const disabled = !photoList.length || !downloadUrl;

        return (
            <Zoom in timeout={1200}>
                <Button
                    variant="outlined"
                    onClick={() => this.openDownloadAllPhotosConfirmationDialog()}
                    className={classNames(
                        classes.downloadButton,
                        disabled ? classes.disabledDownloadButton : ''
                    )}
                    disabled={disabled}
                >
                    Download {photoList.length ? photoList.length : ''} Photos&nbsp;
                    <ArchiveIcon color="primary" />
                </Button>
            </Zoom>
        );
    };

    renderShowLoader = () => {
        const { classes } = this.props;

        return (
            <Grid container justifyContent="center" alignContent="center" className={classes.root}>
                <Grid item xs={12} className={classes.textCenter}>
                    <CircularProgress
                        color="primary"
                        className={classes.loader}
                        size={80}
                        thickness={3}
                    />
                </Grid>
            </Grid>
        );
    };

    renderMasonList = () => {
        const { activePhotoId, isOpenPhotoPopper } = this.state;
        const {
            isUploading,
            photoList,
            photoOptions,
            allowMultiSelection,
            selectedPhotoId,
            selectedPhotoIds,
            resetPhotoSelectDeselect,
            isPhotoSelecting,
            isPhotoDeselecting,
            hideUploadButton,
            hideDownloadButton,
            isLargePhotoView,
            showLoader,
            scrollElement,
            enablePhotoSwipe,
            hideEditButton,
            editPhotoButtonClass,
        } = this.props;
        const filteredPhotos = photoList
            .filter((photo) => photo.status !== PhotoStatusEnum.failed)
            .reverse();

        if (showLoader) {
            return this.renderShowLoader();
        }

        return (
            <VirtualMasonryPhotoList
                photoList={filteredPhotos}
                photoOptions={photoOptions}
                enablePhotoSwipe={enablePhotoSwipe}
                allowMultiSelection={allowMultiSelection}
                imageIsSelected={this.imageIsSelected}
                selectedPhotoId={selectedPhotoId}
                selectedPhotoIds={selectedPhotoIds}
                resetPhotoSelectDeselect={resetPhotoSelectDeselect}
                isPhotoDeselecting={isPhotoDeselecting}
                isPhotoSelecting={isPhotoSelecting}
                activePhotoId={activePhotoId}
                openPopper={this.togglePhotoPopper}
                onPhotoClick={this.handlePhotoClick}
                isPopperOpen={isOpenPhotoPopper}
                hideUploadButton={hideUploadButton}
                hideDownloadButton={hideDownloadButton}
                scrollElement={scrollElement}
                isLargePhotoView={isLargePhotoView}
                renderUploadNewPhoto={this.renderUploadNewPhoto}
                renderDownloadPhotosButton={this.renderDownloadPhotosButton}
                hideEditButton={hideEditButton}
                isUploadingPhoto={isUploading}
                editPhotoButtonClass={editPhotoButtonClass}
            />
        );
    };

    imageIsSelected = (photo: GatherPhoto): boolean => {
        const { selectedPhotoIds } = this.props;
        if (photo.photo && photo.photo && selectedPhotoIds !== undefined) {
            const photoId = 'photo_id' in photo.photo ? photo.photo.photo_id : photo.photo.id as number;
            return selectedPhotoIds.indexOf(photoId) !== -1;
        }
        return false;
    };

    handlePhotoClick = (chosenImage: GatherPhoto) => {
        if (this.props.onPhotoClick) {
            this.props.onPhotoClick(chosenImage);
        }
    };

    openDeleteConfirmationDialog = (photo: GatherPhoto) => {
        this.setState({
            isDeleteConfirmationDialogOpen: true,
            photoToDelete: photo,
        });
    };

    closeDeleteConfirmationDialog = () => {
        this.setState({
            isDeleteConfirmationDialogOpen: false,
            photoToDelete: null,
        });
    };

    handleDeletePhoto = () => {
        const { onPhotoDelete } = this.props;
        const { photoToDelete } = this.state;
        if (photoToDelete && onPhotoDelete) {
            onPhotoDelete(photoToDelete);
        }
        this.closeDeleteConfirmationDialog();
    };

    renderLoader = (text: string = 'Loading...') => {
        const { classes, loaderClass } = this.props;

        return (
            <div className={classNames(classes.loaderContainer, loaderClass)}>
                <CircularProgress
                    size={86}
                    thickness={3}
                />
                <Typography
                    align="center"
                    noWrap
                    className={classes.loadingText}
                >
                    {text}
                </Typography>
            </div>
        );
    };

    renderConfirmationDialog = () => {
        const { zIndex } = this.props;
        const { isDeleteConfirmationDialogOpen } = this.state;
        const deleteDialogHeader = 'Are you sure you want to delete this photo?';
        const deleteDialogConfirmationButton = 'Delete Photo';

        return (
            <ConfirmationDialog
                header={deleteDialogHeader}
                confirmationButtonText={deleteDialogConfirmationButton}
                onClose={this.closeDeleteConfirmationDialog}
                open={isDeleteConfirmationDialogOpen}
                onConfirm={this.handleDeletePhoto}
                zIndex={zIndex + 1}
            />
        );
    };

    render() {
        const { popperEl, isOpenPhotoPopper } = this.state;
        const {
            zIndex,
            classes,
            isLoading,
            acceptMultiple,
            onFileUpload,
            scrollElement,
            photoList,
            activeCase,
            hideFlagInappropriateButtonOnPopper
        } = this.props;
        const activePhoto = this.findActivePhoto();
        const timeSinceUploaded = activePhoto && isAlbumEntry(activePhoto.photo) &&
            activePhoto.photo.timeSinceUploaded || undefined;

        return (
            <div>
                <input
                    type="file"
                    accept="image/gif, image/jpeg, image/png"
                    multiple={acceptMultiple || false}
                    className={classes.displayNone}
                    ref={ref => {
                        if (ref) {
                            this.fileUploadInput = ref;
                        }
                    }}
                    onClick={e => {
                        const element = e.target as HTMLInputElement;
                        // clear this value to so that same photo can be chosen each time
                        element.value = '';
                    }}
                    onChange={onFileUpload}
                />
                <Grid item xs={12} className={classes.masonList}>
                    {(isLoading || !scrollElement) ? this.renderLoader() : this.renderMasonList()}
                </Grid>
                {this.renderConfirmationDialog()}
                {this.renderDownloadPhotosConfirmationDialog()}
                {isOpenPhotoPopper && activeCase &&
                    <PhotoPopper
                        key={activePhoto?.gatherId}
                        activePhoto={activePhoto}
                        photoList={photoList}
                        activeCase={activeCase}
                        zIndex={zIndex + 1}
                        popperEl={popperEl}
                        hideRemoveAlbum
                        timeSinceUploaded={timeSinceUploaded}
                        isOpenPhotoPopper={isOpenPhotoPopper}
                        closePopper={this.closePopper}
                        hideFlagInappropriateButton={hideFlagInappropriateButtonOnPopper}
                    />
                }
            </div>
        );
    }
}

export default withState(mapStateToProps)(withGStyles(wrappedStyles<Props>())(MasonryPhotoList));
