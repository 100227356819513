import { getFullNameFromCase, getRememberURLForSharing, isFeatureEnabled } from '../../../services';
import { GatherCaseUX, GatherCasePublic, GuestListUser, GuestListUserMap, FeatureKey } from '../../../shared/types';
import { GatherPhoto } from '../../../types';
import { log } from '../../../logger';
import { ShareOptionType } from './widgets/ShareLinkSection';

export const shareOptionRenderOrder: ShareOptionType[] = [
    'QRCODE',
    'FACEBOOK',
    'MESSENGER',
    'TWITTER',
    'WHATSAPP',
    'LINKEDIN',
    'EMAIL',
    'SMS',
    'SHARE',
    'LINK',
];

export type ShareOptionSettings = Record<ShareOptionType, { enabled: boolean; color: string }>;

export interface RememberSettingsType {
    label: string;
    description: string;
    enabled: boolean;
    canFamilyAdjust: boolean;
    canFuneralHomeAdjust: boolean;
    canGOMPeonAdjust: boolean;
    isToggleDisabled?: boolean;
}

export enum RememberSettingsKey {
    page = 'page',
    cover_photo = 'cover_photo',
    life_span = 'life_span',
    inscription = 'inscription',
    hanging_photos = 'hanging_photos',
    obituary = 'obituary',
    go_fund_me = 'go_fund_me',
    memories_section = 'memories_section',
    guest_book = 'guest_book',
    casket_bearers = 'casket_bearers',
    service_details = 'service_details',
    events = 'events',
    embed_memorial_video = 'embed_memorial_video',
    flowers_and_cards = 'flowers_and_cards',
    show_photos = 'show_photos',
    guest_payment = 'guest_payment',
    sell_flowers = 'sell_flowers',
    plant_trees = 'plant_trees',
}

export type RememberSettings = Record<RememberSettingsKey, RememberSettingsType>;

export const getRememberSettings = (c: GatherCaseUX | GatherCasePublic | null): RememberSettings => {
    const name = c ? c.display_fname : 'loved one'; // Why would this ever be null?
    const rememberSettings: RememberSettings = {
        'page': {
            label: '',
            description: '',
            enabled: c ? c.options.remember_page : true,
            canFamilyAdjust: true,
            canFuneralHomeAdjust: true,
            canGOMPeonAdjust: true,
        },
        'cover_photo': {
            label: 'Show Cover Photo',
            description: 'This option lets you hide an uploaded cover photo.',
            enabled: c ? c.options.cover_photo : true,
            canFamilyAdjust: true,
            canFuneralHomeAdjust: true,
            canGOMPeonAdjust: true,
        },
        'life_span': {
            label: 'Show Birth and Death Year',
            description: `
                This option lets you hide the birth and death year that appears under ${name}'s name.
                Dates are pulled from ${name}'s vitals.`,
            enabled: c ? c.options.life_span : true,
            canFamilyAdjust: true,
            canFuneralHomeAdjust: true,
            canGOMPeonAdjust: true,
        },
        'inscription': {
            label: 'Show Inscription',
            description: `
                This option lets you hide the inscription shown at the top of the page.
                Dates are pulled from ${name}'s vitals.`,
            enabled: c ? c.options.inscription : true,
            canFamilyAdjust: true,
            canFuneralHomeAdjust: true,
            canGOMPeonAdjust: true,
        },
        'hanging_photos': {
            label: 'Show Gallery Photos',
            description: 'This option allows you to hide the row of gallery photos.',
            enabled: c ? c.options.hanging_photos : true,
            canFamilyAdjust: true,
            canFuneralHomeAdjust: true,
            canGOMPeonAdjust: true,
        },
        'obituary': {
            label: 'Show Obituary',
            description: `
                The obituary will only display once the obituary task is complete.
                If the task is skipped, this section won't appear.`,
            enabled: c ? c.options.obituary : true,
            canFamilyAdjust: true,
            canFuneralHomeAdjust: true,
            canGOMPeonAdjust: true,
        },
        'go_fund_me': {
            label: 'Add a GoFundMe link',
            description: 'This is a free option to place a link for an existing GoFundMe page.',
            enabled: c ? c.options.go_fund_me : false,
            canFamilyAdjust: true,
            canFuneralHomeAdjust: true,
            canGOMPeonAdjust: true,
        },
        'memories_section': {
            label: 'Show Memories Section',
            description: 'This option lets you hide the ability to share Condolences, Tributes, and Memories',
            enabled: c ? c.options.memories_section : true,
            canFamilyAdjust: true,
            canFuneralHomeAdjust: true,
            canGOMPeonAdjust: true,
        },
        'guest_book': {
            label: 'Show Guest List',
            description: 'The Guest List shows a list of admins, guests, and visitors who want to contribute to ' +
                `${c ? `${c.display_fname}'s` : ''} page`,
            enabled: c ? c.options.guest_book : true,
            canFamilyAdjust: true,
            canFuneralHomeAdjust: true,
            canGOMPeonAdjust: true,
        },
        'casket_bearers': {
            label: 'Show Casket Bearers',
            description: `
                Casket Bearers will only display once the Casket Bearers task is complete.
                If the task is skipped, this section won't appear.
            `,
            enabled: c ? c.options.casket_bearers : true,
            canFamilyAdjust: true,
            canFuneralHomeAdjust: true,
            canGOMPeonAdjust: true,
        },
        'service_details': {
            label: 'Show Service Details',
            description: `
                Service Details will only display once the Service Details task is complete.
                If the task is skipped, this section won't appear.`,
            enabled: c ? c.options.service_details : true,
            canFamilyAdjust: true,
            canFuneralHomeAdjust: true,
            canGOMPeonAdjust: true,
        },
        'events': {
            label: 'Show Events',
            description: `
                This option allows you to hide any of ${name}'s scheduled events.
                The arrangement conference will never be displayed here.`,
            enabled: c ? c.options.events : true,
            canFamilyAdjust: false,
            canFuneralHomeAdjust: true,
            canGOMPeonAdjust: true,
        },
        'embed_memorial_video': {
            label: 'Embed a memorial video',
            description: 'This option allows you to embed a YouTube and/or a Tukios Memorial video.',
            enabled: c ? c.options.embed_memorial_video : true,
            canFamilyAdjust: true,
            canFuneralHomeAdjust: true,
            canGOMPeonAdjust: true,
        },
        'flowers_and_cards': {
            label: 'Show Flowers & Cards',
            description: 'This section displays photos of the actual flowers and cards received for ' +
                `${name}'s services.`,
            enabled: c ? c.options.flowers_and_cards : true,
            canFamilyAdjust: false,
            canFuneralHomeAdjust: true,
            canGOMPeonAdjust: true,
        },
        'show_photos': {
            label: 'Show 5 feature photos',
            description: `This option allows you to hide uploaded photos of ${name} from the Remember page. 
            This is not recommended.`,
            enabled: c ? c.options.show_photos : true,
            canFamilyAdjust: false,
            canFuneralHomeAdjust: true,
            canGOMPeonAdjust: true,
        },
        'guest_payment': {
            label: 'Allow Payments from Website',
            description: `This option allows you to disable payments from your website for this case.`,
            enabled: c ? c.options.guest_payment : true,
            canFamilyAdjust: false,
            canFuneralHomeAdjust: false,
            canGOMPeonAdjust: true,
            isToggleDisabled: c ?
                !isFeatureEnabled(FeatureKey.PAYMENTS_GUEST_PAYMENTS, c.funeral_home.features)
                : false,
        },
        'sell_flowers': {
            label: 'Sell Flowers',
            description: `This option allows you to disable flower sales from your website for this case.`,
            enabled: c ?  c.options.sell_flowers : true,
            canFamilyAdjust: false,
            canFuneralHomeAdjust: false,
            canGOMPeonAdjust: false,
            isToggleDisabled: c ?
                !isFeatureEnabled(FeatureKey.REMEMBER_FLOWER_LINK, c.funeral_home.features)
                : false,
        },
        'plant_trees': {
            label: 'Plant Trees',
            description: `This option allows you to disable tree planting from your website for this case.`,
            enabled: c ?  c.options.plant_trees : true,
            canFamilyAdjust: false,
            canFuneralHomeAdjust: false,
            canGOMPeonAdjust: false,
            isToggleDisabled: c ?
                !isFeatureEnabled(FeatureKey.PLANT_TREES, c.funeral_home.features)
                : false,
        },
    };
    return rememberSettings;
};

export const CASE_DETAILS_TEXT_SHADOW = '0px 2px 4px rgba(0, 0, 0, 0.15)';

// Share Options Colors
export const FACEBOOK_COLOR = '#3b5998';
export const MESSANGER_COLOR = '#0084FF';
export const TWITTER_COLOR = '#00acee';
export const WHATSAPP_COLOR = '#25d366';
export const LINKEDIN_COLOR = '#0e76a8';
export const EMAIL_COLOR = '#c71610';
export const SMS_COLOR = '#27c848';
export const SHARE_COLOR = '#3e80f5';
export const LINK_COLOR = '#757575';
export const QRCODE_COLOR = '#757575';

export const getShareOptions = (gatherCase: GatherCaseUX | GatherCasePublic | null): ShareOptionSettings => {
    return {
        'FACEBOOK': { enabled: gatherCase ? gatherCase.options.share_FACEBOOK : true, color: FACEBOOK_COLOR },
        'MESSENGER': { enabled: gatherCase ? gatherCase.options.share_MESSENGER : true, color: MESSANGER_COLOR },
        'TWITTER': { enabled: gatherCase ? gatherCase.options.share_TWITTER : true, color: TWITTER_COLOR },
        'WHATSAPP': { enabled: gatherCase ? gatherCase.options.share_WHATSAPP : true, color: WHATSAPP_COLOR },
        'LINKEDIN': { enabled: gatherCase ? gatherCase.options.share_LINKEDIN : true, color: LINKEDIN_COLOR },
        'EMAIL': { enabled: gatherCase ? gatherCase.options.share_EMAIL : true, color: EMAIL_COLOR },
        'SMS': { enabled: gatherCase ? gatherCase.options.share_SMS : true, color: SMS_COLOR },
        'SHARE': { enabled: gatherCase ? gatherCase.options.share_SHARE : true, color: SHARE_COLOR },
        'LINK': { enabled: gatherCase ? gatherCase.options.share_LINK : true, color: LINK_COLOR },
        'QRCODE': { enabled: gatherCase ? gatherCase.options.share_QRCODE : true, color: QRCODE_COLOR }
    };
};

export const getPhotoUploader = (
    photo: GatherPhoto | null | undefined,
    guestListMap: GuestListUserMap,
): GuestListUser | null => {
    const uploaderUserId = Number(photo && photo.photo && photo.photo.uploaded_by);
    return !Number.isNaN(uploaderUserId) && guestListMap[uploaderUserId] || null;
};

export const isNavigatorShareSupported = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const nav = (navigator as any);
    return Boolean(nav.share);
};

const isRecord = (value: unknown): value is Record<string, unknown> => (
    typeof value === 'object' && value !== null
);

export const handleShareOptionAction = async (publicCase: GatherCasePublic) => {
    const fullName = getFullNameFromCase(publicCase);

    const shareData = {
        title: fullName,
        url: getRememberURLForSharing(publicCase.name),
    };

    if (isNavigatorShareSupported() && navigator.share) {
        try {
            await navigator.share(shareData);
        } catch (err) {
            if (isRecord(err) || err === undefined) {
                log.warn('Could not share!', err);
            }
        }
    }
};