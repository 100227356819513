import * as React from 'react';
import classNames from 'classnames';

import {
    getPhotoUrl,
    DEFAULT_WIDTH,
    DEFAULT_HEIGHT,
    PhotoSizeType,
    isTouchDevice,
} from '../../../../services';

import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUnchecked from '@mui/icons-material/RadioButtonUnchecked';

import { GatherPhoto, PhotoStatusEnum } from '../../../../types';

import { _getPhotoId } from '../../../family/photoSlideshow';
import { CloudinaryTransformationsType, isAlbumEntry } from '../../../../shared/types';
import { openPhotoSwipeDialog } from '../../../../actions/PhotoSwipe.action';
import { useStyles } from './styles';
import { AppDispatch } from '../../../../store';
import { useDispatch } from 'react-redux';
import { useForkRef } from '@mui/material/utils';

interface Props {
    imageId?: string;
    photo: GatherPhoto;
    multiSelect?: Boolean;
    isSelected?: Boolean;
    photoOptions?: {
        size?: PhotoSizeType;
    };
    imgStyle?: React.CSSProperties;
    imgContainerStyle?: React.CSSProperties;
    onPhotoClick?: (target?: HTMLElement) => void;
    isPhotoSelecting?: boolean;
    isPhotoDeselecting?: boolean;
    selectedPhotoId?: number | null;
    resetPhotoSelectDeselect?: () => void;
    isOpenPhotoPopper: boolean;
    activePhotoId: string | null;
    enablePhotoSwipe?: boolean;
    photos: GatherPhoto[];
    openPopper: (event: React.MouseEvent<HTMLElement>, photoId: string) => void;
    hideEditButton?: boolean;
    columnWidth: number;
    editPhotoButtonClass?: string;
}

const MasonryPhoto = React.forwardRef(
    function MasonryPhoto(props: Props, ref: React.Ref<HTMLDivElement>) {
        const {
            photo,
            photoOptions,
            imgContainerStyle,
            imgStyle,
            imageId,
            multiSelect,
            isSelected,
            onPhotoClick,
            selectedPhotoId,
            isPhotoDeselecting,
            resetPhotoSelectDeselect,
            isOpenPhotoPopper,
            activePhotoId,
            openPopper,
            photos,
            enablePhotoSwipe,
            hideEditButton,
            columnWidth,
            editPhotoButtonClass,
        } = props;

        const dispatch = useDispatch<AppDispatch>();
        const classes = useStyles();
        const divRef = React.useRef<HTMLDivElement>(null);
        const nodeRef = useForkRef<HTMLDivElement>(divRef, ref);

        const renderLoader = () => {
            return (
                <div
                    className={classes.loaderContainer}
                    style={{
                        width: DEFAULT_WIDTH,
                        height: DEFAULT_HEIGHT
                    }}
                >
                    <CircularProgress
                        color="primary"
                        size={48}
                        thickness={3}
                    />
                    <Typography
                        align="center"
                        noWrap
                        color="primary"
                        className={classes.loadingText}
                    >
                        Uploading...
                    </Typography>
                </div>
            );
        };

        const renderActionButtons = () => {
            return (
                <>
                    {multiSelect &&
                        <IconButton
                            className={classNames(
                                classes.colorWhite,
                                classes.selectIcon,
                                isSelected && classes.selectedGreenIcon,
                            )}
                            onClick={e => {
                                if (onPhotoClick) {
                                    onPhotoClick(e.currentTarget);
                                }
                            }}
                            size="large">
                            {(isSelected) ?
                                <CheckCircleIcon className={classNames(
                                    classes.colorGreen, isSelected && classes.fontSize28
                                )} /> :
                                <RadioButtonUnchecked />
                            }
                        </IconButton>
                    }
                </>
            );
        };

        const width = columnWidth;  // to account for max column size
        let height = isAlbumEntry(photo.photo) && photo.photo.width && photo.photo.height
            ? Math.ceil((width / photo.photo.width) * photo.photo.height)
            : 'auto';
        let cloudinaryTrans;

        // is rotated
        if (isAlbumEntry(photo.photo) &&
            photo.photo.transformations && photo.photo.transformations.cloudinary) {
            cloudinaryTrans = photo.photo.transformations.cloudinary;
            const rotate = cloudinaryTrans.angle;
            if (rotate && (Math.abs(rotate) / 90) % 2 === 1 && photo.photo.width && photo.photo.height) {
                height = Math.ceil((width / photo.photo.height) * photo.photo.width);
            }
        }

        const transformations: CloudinaryTransformationsType[] = [{
            width: Math.ceil(width),
            quality: 'auto',
            fetch_format: 'auto',
            crop: 'limit',
        }];
        if (cloudinaryTrans) {
            transformations.unshift(cloudinaryTrans);
        }

        let sizeStyle = {
            width: (photoOptions && photoOptions.size === 'large' && width * 2)
                || (photoOptions && photoOptions.size === 'small' && width / 2)
                || width,
            height,
        };

        let photoClass = classes.photo;

        const photoId = _getPhotoId(photo);

        return (
            <div
                ref={nodeRef}
                className={classNames(
                    classes.imageBox,
                    photo.status === PhotoStatusEnum.uploading ? undefined : classes.transformMe,
                    (photo.status === PhotoStatusEnum.uploading && classes.backgroundWhite || undefined),
                    photoId === selectedPhotoId && isPhotoDeselecting && classes.deselectedPhoto,
                    enablePhotoSwipe && classes.cursorPointer
                )}
                onMouseLeave={e => resetPhotoSelectDeselect && resetPhotoSelectDeselect()}
                style={{ ...sizeStyle, ...imgContainerStyle }}
            >
                {photo.status === PhotoStatusEnum.uploading ? renderLoader()
                    : !photo.photo ? null :
                        <>
                            <div
                                className={classNames(classes.photoContainer, isSelected && classes.selected)}
                                onClick={e => {
                                    if (onPhotoClick) {
                                        onPhotoClick();
                                    }

                                    if (photos && enablePhotoSwipe) {
                                        dispatch(openPhotoSwipeDialog(
                                            'GATHER_PHOTO',
                                            photo.photo && photo.photo.public_id || null,
                                            photos
                                        ));
                                    }
                                }}
                            >
                                <img
                                    src={getPhotoUrl(photo.photo.public_id, transformations)}
                                    className={photoClass}
                                    style={{ ...sizeStyle, ...imgStyle }}
                                    id={imageId}

                                />
                                <div>
                                    <div className={classes.photoWrapper} />
                                    {!hideEditButton &&
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            className={classNames(
                                                classes.editPhotoButton,
                                                (!isTouchDevice()
                                                    && isOpenPhotoPopper && activePhotoId === photo.gatherId)
                                                && classes.buttonPosition,
                                                isTouchDevice() && classes.buttonPosition,
                                                isSelected && classes.selected,
                                                hideEditButton && classes.hideButton,
                                                editPhotoButtonClass,
                                            )}
                                            onClick={(e) => openPopper(e, photo.gatherId)}
                                        >
                                            <EditIcon color="primary" />&nbsp;Edit Photo
                                        </Button>
                                    }
                                </div>
                            </div>
                            {renderActionButtons()}
                        </>
                }
            </div>
        );
    }
);

export default MasonryPhoto;
