import * as t from 'io-ts';
import { DateFromISOString } from 'io-ts-types/lib/DateFromISOString';
import {
    AddressRecord,
    AutoObitParams,
    CaseEntityRecord,
    CaseLabelUX,
    CaseTaskRecord,
    CaseTypeDefinition,
    DeathCertificateConfigRecord,
    DeathCertificateRestingPlaceType,
    EntityRecord,
    EntitySummary,
    FuneralHomeCaseRecord,
    FuneralHomeRecord,
    GatherCaseRecord,
    GatherCaseReportAssignee,
    GatherEventRecord,
    getValidator,
    Homesteaders,
    InsuranceCarrierRecord,
    InsurancePolicyRecord,
    InvoiceRecord,
    KeeptrackCardRecord,
    LongAddress,
    Nullable,
    PaymentRecord,
    PolicyCaseStatus,
    ProductCategoryGroup,
    ProductContractItemRecord,
    ProductContractRecord,
    ProductContractRevisionRecord,
    ProductManufacturerRecord,
    ProductPackageRecord,
    ProductRecord,
    ProductTaxRateRecord,
    TaskLocationRecord,
    TaskRecord,
    UserProfileRecord,
    WorkflowRecord,
} from '.';
import moment from 'moment-timezone';

export enum ReportDataSource {
    case_non_financial = 'case_non_financial',
    case_financial = 'case_financial',
    case_vitals = 'case_vitals',
    invoice_line_items = 'invoice_line_items',
    events = 'events',
    helpers = 'helpers',
    notes = 'notes',
    task_and_step_activity = 'task_and_step_activity',
    payments = 'payments',
    remember_page = 'remember_page',
    insurance_policies = 'insurance_policies',
}

export const DataSourceDisplayLookup: Record<
    ReportDataSource,
    {
        label: string;
        definition: string;
    }
> = {
    [ReportDataSource.case_non_financial]: {
        label: 'Case Non-Financial',
        definition:
            `Get a high-level overview of important non-financial case information, including summary-level data` +
            ` on various items.`,
    },
    [ReportDataSource.case_financial]: {
        label: 'Case Financial',
        definition:
            `Get a high-level overview of important financial case information, including summary-level data on` +
            ` various items.`,
    },
    [ReportDataSource.case_vitals]: {
        label: 'Case Vitals',
        definition:
            `Understand the demographic details of all cases by viewing and filtering any value that was entered` +
            ` via the Death Certificate task and the A.I. Obituary Builder task.`,
    },
    [ReportDataSource.invoice_line_items]: {
        label: 'Invoice Line Items',
        definition:
            `Understand the details of everything you sell across the following 7 Line Item Types: Statement` +
            ` Products, Statement Discounts & Premiums, Outside Products, Convenience Fees, Package ` +
            `Discounts & Premiums.`,
    },
    [ReportDataSource.events]: {
        label: 'Events',
        definition:
            `Understand your team's workload by viewing all calendar events and event details` + ` across all cases.`,
    },
    [ReportDataSource.helpers]: {
        label: 'Helpers',
        definition:
            `Gain valuable insight into each family helper, including admins, guests, uninvited` +
            ` helpers, and deceased family members.`,
    },
    [ReportDataSource.notes]: {
        label: 'Notes',
        definition: `Keep everyone on the same page by viewing all Case Notes, Task Notes, and Step Notes.`,
    },
    [ReportDataSource.task_and_step_activity]: {
        label: 'Task and Step Activity',
        definition:
            `Get a high-level overview of all tasks across all cases, including Tracking Steps.` +
            ` This includes both internal and external tasks, their status, type, details, and more.`,
    },
    [ReportDataSource.payments]: {
        label: 'Payments',
        definition:
            `View details for each payment entered on a case, including: Cash, Check, Card Reader,` +
            ` Enter Card, Other Method, Insurance, Remote Credit, Remote ACH Transfer, Web Payment.  `,
    },
    [ReportDataSource.remember_page]: {
        label: 'Remember Page',
        definition:
            `Understand how your community is interacting with your brand, including memories, ` +
            `photos, flowers, trees, and more.`,
    },
    [ReportDataSource.insurance_policies]: {
        label: 'Insurance Policies',
        definition:
            `Understand your pre-need performance by viewing all of your policies directly in ` +
            `Gather via the Homesteader Policy Integration.`,
    },
};

const REPORT_DATA_SOURCE = t.union([
    t.literal(ReportDataSource.case_non_financial),
    t.literal(ReportDataSource.case_financial),
    t.literal(ReportDataSource.case_vitals),
    t.literal(ReportDataSource.invoice_line_items),
    t.literal(ReportDataSource.events),
    t.literal(ReportDataSource.helpers),
    t.literal(ReportDataSource.notes),
    t.literal(ReportDataSource.task_and_step_activity),
    t.literal(ReportDataSource.payments),
    t.literal(ReportDataSource.remember_page),
    t.literal(ReportDataSource.insurance_policies),
]);

/* DataSource Date Types */

export enum DataSourceDateType {
    CaseCreatedOn = 'CaseCreatedOn',
    CaseCreatedOnSimple = 'CaseCreatedOnSimple',
    CaseDeathDate = 'CaseDeathDate',
    CaseDeathDateSimple = 'CaseDeathDateSimple',
    DCLastUpdateDate = 'DCLastUpdateDate',
    TaskResolvedOn = 'TaskResolvedOn',
    PaymentDate = 'PaymentDate',
    PaymentCreatedDate = 'PaymentCreatedDate',
    LineItemCreatedOn = 'LineItemCreatedOn',
    LineItemUpdatedOn = 'LineItemUpdatedOn',
    StatementCreatedOn = 'StatementCreatedOn',
    InvoiceOpenDate = 'InvoiceOpenDate',
    InvoiceCloseDate = 'InvoiceCloseDate',
    EventDate = 'EventDate',
    HelperInvitedOn = 'HelperInvitedOn',
    PolicyIssueDate = 'PolicyIssueDate',
}

const DATA_SOURCE_DATE_TYPE = t.union([
    t.literal(DataSourceDateType.CaseCreatedOn),
    t.literal(DataSourceDateType.CaseCreatedOnSimple),
    t.literal(DataSourceDateType.CaseDeathDate),
    t.literal(DataSourceDateType.CaseDeathDateSimple),
    t.literal(DataSourceDateType.DCLastUpdateDate),
    t.literal(DataSourceDateType.TaskResolvedOn),
    t.literal(DataSourceDateType.PaymentDate),
    t.literal(DataSourceDateType.PaymentCreatedDate),
    t.literal(DataSourceDateType.LineItemCreatedOn),
    t.literal(DataSourceDateType.LineItemUpdatedOn),
    t.literal(DataSourceDateType.StatementCreatedOn),
    t.literal(DataSourceDateType.InvoiceOpenDate),
    t.literal(DataSourceDateType.InvoiceCloseDate),
    t.literal(DataSourceDateType.EventDate),
    t.literal(DataSourceDateType.HelperInvitedOn),
    t.literal(DataSourceDateType.PolicyIssueDate),
]);

export const isDataSourceDateType = (value: unknown): value is DataSourceDateType =>
    typeof value === 'string' && Object.values(DataSourceDateType).includes(value as DataSourceDateType);

export const DataSourceDateTypeDisplayLookup: Record<
    DataSourceDateType,
    {
        label: string;
        definition: string;
    }
> = {
    [DataSourceDateType.CaseCreatedOn]: {
        label: 'Case Created On',
        definition: `The date the case was first created in Gather UNLESS the Case Type has been converted 
        to a new Case Type (e.g., Pre-Need to At-Need) then the most recent conversion date is used instead.
         This field includes the time if available, which is visible on hover and in the download. 
         Timezone is driven by the funeral home location timezone.`,
    },
    [DataSourceDateType.CaseCreatedOnSimple]: {
        label: 'Case Created On (Simple)',
        definition: `The date the case was first created in Gather. IMPORTANT: If a case was 
        converted to a different Case Type (e.g., Pre-Need to At-Need) this will show the 
        date the case was first created and NOT the date of the conversion, which could cause incompleted 
        data for converted pre-needs. Consider using the more robust Case Created On field instead.`,
    },
    [DataSourceDateType.CaseDeathDate]: {
        label: 'Case Death Date',
        definition: `Safest way to filter by date of death. If no death date, the date the case was first 
        created in Gather is used. If no death date AND the Case Type has been 
        converted to a new Case Type (e.g., Pre-Need to At-Need) then the most recent conversion 
        date is used instead. NOTE: If a range is used for an unknown death date, this field will 
        use the earliest date of the range.`,
    },
    [DataSourceDateType.CaseDeathDateSimple]: {
        label: 'Case Death Date (Simple)',
        definition: `The date of death as specified in the case vitals. IMPORTANT: This field 
        does NOT account for missing death dates or converted pre-needs, which could 
        cause incompleted data. Consider using the more robust Case Death Date field instead. NOTE: 
        If a range is used for an unknown death date, this field will use the earliest date of the range.`,
    },
    [DataSourceDateType.DCLastUpdateDate]: {
        label: 'Death Certificate Last Update Date',
        definition: `The date and time the DC task was last updated. This can be iether by a funeral home team member
         or by an invited family member that has access to the DC task. See DC Last Updated By to see who 
         made the change.`,
    },
    [DataSourceDateType.TaskResolvedOn]: {
        label: 'Task Resolved On',
        definition: `The date the Task was most recently resolved, meaning it was moved to "Completed" or "Skipped".
         If a task moves to this status and then back to "Uncompleted" this field will be blank.`,
    },
    [DataSourceDateType.PaymentDate]: {
        label: 'Payment Date',
        definition: `This is an adjustable date to reflect when the actual payment was made by the customer. 
        For example, a customer may have paid prior to when the payment was entered into Gather, 
        this field allows the funeral home to enter the correct date for the actual receipt of the 
        payment even if it was in the past.`,
    },
    [DataSourceDateType.PaymentCreatedDate]: {
        label: 'Payment Created Date',
        definition: `This is the date that the payment was created in Gather. This date and time cannot be changed.
         To adjust when the payment was actually made by the customer, use the Payment Date.`,
    },
    [DataSourceDateType.LineItemCreatedOn]: {
        label: 'Line Item Created On',
        definition: `The date the line item was first added.`,
    },
    [DataSourceDateType.LineItemUpdatedOn]: {
        label: 'Line Item Updated On',
        definition: `The date and time the line item was most recently updated on the case. This will be the same 
        as the Line Item Created On field if nothing has been changed. Actions that will update this value include: 
        adding or editing a product note, adjusting the Line Item Quantity, changing the price, adding or editing a 
        line item discount or premium. If a line item is removed from an open statement, it will no longer show 
        in this report. `,
    },
    [DataSourceDateType.StatementCreatedOn]: {
        label: 'Statement Created On',
        definition: `The date and time the Statement of Funeral Goods and Services was created. This happens when 
        the Statement was first viewed by someone and not when the first line item was added nor when the 
        case was first created.`,
    },
    [DataSourceDateType.InvoiceOpenDate]: {
        label: 'Invoice Open Date',
        definition: `The date the Invoice was first opened. For "Statement Invoice 1", this Invoice Open Date 
        will be equal to the Statement Created On value. For each subsequent Statement Invoice, 
        it will be the date that the previous Invoice was frozen.`,
    },
    [DataSourceDateType.InvoiceCloseDate]: {
        label: 'Invoice Close Date',
        definition: `The date the invoice moved to an Invoice Status of locked, which occurs when the 
        invoice was frozen. `,
    },
    [DataSourceDateType.EventDate]: {
        label: 'Event Date',
        definition: `The date of the event. This field includes the Event Start Time, which is visible on hover 
        and in the download. Timezone is driven by the funeral home location timezone. `,
    },
    [DataSourceDateType.HelperInvitedOn]: {
        label: 'Helper Invited On',
        definition: `The date the Helper was invited to this particular case. Field will be blank if they were 
        not invited.`,
    },
    [DataSourceDateType.PolicyIssueDate]: {
        label: 'Issue Date',
        definition: `The date the policy was issued.`,
    },
};

export const DataSourceDateSelectorOptions: Record<ReportDataSource, DataSourceDateType[]> = {
    [ReportDataSource.case_vitals]: [
        DataSourceDateType.CaseDeathDate,
        DataSourceDateType.CaseDeathDateSimple,
        DataSourceDateType.CaseCreatedOn,
        DataSourceDateType.CaseCreatedOnSimple,
        DataSourceDateType.DCLastUpdateDate,
    ],
    [ReportDataSource.payments]: [
        DataSourceDateType.CaseDeathDate,
        DataSourceDateType.CaseDeathDateSimple,
        DataSourceDateType.CaseCreatedOn,
        DataSourceDateType.CaseCreatedOnSimple,
        DataSourceDateType.PaymentDate,
        DataSourceDateType.PaymentCreatedDate,
    ],
    [ReportDataSource.invoice_line_items]: [
        DataSourceDateType.CaseDeathDate,
        DataSourceDateType.CaseDeathDateSimple,
        DataSourceDateType.CaseCreatedOn,
        DataSourceDateType.CaseCreatedOnSimple,
        DataSourceDateType.LineItemCreatedOn,
        DataSourceDateType.LineItemUpdatedOn,
        DataSourceDateType.StatementCreatedOn,
        DataSourceDateType.InvoiceOpenDate,
        DataSourceDateType.InvoiceCloseDate,
    ],
    [ReportDataSource.task_and_step_activity]: [DataSourceDateType.CaseCreatedOn, DataSourceDateType.TaskResolvedOn],
    [ReportDataSource.events]: [
        DataSourceDateType.CaseDeathDate,
        DataSourceDateType.CaseCreatedOn,
        DataSourceDateType.EventDate,
    ],
    [ReportDataSource.helpers]: [
        DataSourceDateType.CaseDeathDate,
        DataSourceDateType.CaseCreatedOn,
        DataSourceDateType.HelperInvitedOn,
    ],
    [ReportDataSource.notes]: [DataSourceDateType.CaseDeathDate, DataSourceDateType.CaseCreatedOn],
    [ReportDataSource.case_non_financial]: [DataSourceDateType.CaseDeathDate, DataSourceDateType.CaseCreatedOn],
    [ReportDataSource.case_financial]: [DataSourceDateType.CaseDeathDate, DataSourceDateType.CaseCreatedOn],
    [ReportDataSource.remember_page]: [DataSourceDateType.CaseDeathDate, DataSourceDateType.CaseCreatedOn],
    [ReportDataSource.insurance_policies]: [DataSourceDateType.PolicyIssueDate],
};

/* ReportDateRange */

export enum ReportDateRange {
    today = 'today',
    yesterday = 'yesterday',
    last_7_days = 'last_7_days',
    last_14_days = 'last_14_days',
    last_28_days = 'last_28_days',
    last_30_days = 'last_30_days',
    last_60_days = 'last_60_days',
    last_90_days = 'last_90_days',
    current_week = 'current_week',
    prev_week = 'prev_week',
    current_month = 'current_month',
    prev_month = 'prev_month',
    current_quarter = 'current_quarter',
    prev_quarter = 'prev_quarter',
    current_year = 'current_year',
    prev_year = 'prev_year',
    custom = 'custom',
}

export const REPORT_DATE_RANGE = t.union([
    t.literal(ReportDateRange.today),
    t.literal(ReportDateRange.yesterday),
    t.literal(ReportDateRange.last_7_days),
    t.literal(ReportDateRange.last_14_days),
    t.literal(ReportDateRange.last_28_days),
    t.literal(ReportDateRange.last_30_days),
    t.literal(ReportDateRange.last_60_days),
    t.literal(ReportDateRange.last_90_days),
    t.literal(ReportDateRange.current_week),
    t.literal(ReportDateRange.prev_week),
    t.literal(ReportDateRange.current_month),
    t.literal(ReportDateRange.prev_month),
    t.literal(ReportDateRange.current_quarter),
    t.literal(ReportDateRange.prev_quarter),
    t.literal(ReportDateRange.current_year),
    t.literal(ReportDateRange.prev_year),
    t.literal(ReportDateRange.custom),
]);

export const isReportDateRange = (value: unknown): value is ReportDateRange =>
    typeof value === 'string' && Object.values(ReportDateRange).includes(value as ReportDateRange);

export const ReportDateRangeDisplayLookup = (
    timezone: string,
    range: ReportDateRange
): {
    text: string;
    tooltip: string;
} => {
    const obj = {
        [ReportDateRange.today]: {
            text: 'Today',
            tooltip: `The current day from 12:00 am to 11:59:59 pm ${timezone}.`,
        },
        [ReportDateRange.yesterday]: {
            text: 'Yesterday',
            tooltip: `Yesterday from 12:00 am to 11:59:59 pm ${timezone}.`,
        },
        [ReportDateRange.last_7_days]: {
            text: 'Last 7 Days',
            tooltip: `The last 7 full days (12:00 am to 11:59:59 pm ${timezone}), including today.`,
        },
        [ReportDateRange.last_14_days]: {
            text: 'Last 14 Days',
            tooltip: `The last 14 full days (12:00 am to 11:59:59 pm ${timezone}), including today.`,
        },
        [ReportDateRange.last_28_days]: {
            text: 'Last 28 Days',
            tooltip: `The last 28 full days (12:00 am to 11:59:59 pm ${timezone}), including today.`,
        },
        [ReportDateRange.last_30_days]: {
            text: 'Last 30 Days',
            tooltip: `The last 30 full days (12:00 am to 11:59:59 pm ${timezone}), including today.`,
        },
        [ReportDateRange.last_60_days]: {
            text: 'Last 60 Days',
            tooltip: `The last 60 full days (12:00 am to 11:59:59 pm ${timezone}), including today.`,
        },
        [ReportDateRange.last_90_days]: {
            text: 'Last 90 Days',
            tooltip: `The last 90 full days (12:00 am to 11:59:59 pm ${timezone}), including today.`,
        },
        [ReportDateRange.current_week]: {
            text: 'Current Week',
            tooltip: `Only the time that has elapsed since this week started on Monday at 12:00 am 
            ${timezone}.`,
        },
        [ReportDateRange.prev_week]: {
            text: 'Previous Week',
            tooltip: `The full week immediately before the current week. Weeks start on Monday at 12:00 am 
            ${timezone}.`,
        },
        [ReportDateRange.current_month]: {
            text: 'Current Month',
            tooltip: `Only the time that has elapsed since this month started on the 1st at 12:00 am 
            ${timezone}.`,
        },
        [ReportDateRange.prev_month]: {
            text: 'Previous Month',
            tooltip: `The full monthly immediately before the current month. Months start on the 1st at 
            12:00 am ${timezone}.`,
        },
        [ReportDateRange.current_quarter]: {
            text: 'Current Quarter',
            tooltip: `Only the time that has elapsed since this quarter started. 
            Quarters start on the following 4 dates at 12:00 am ${timezone}: 01 JAN, 01 MAR, 01 JUL, 01 OCT.`,
        },
        [ReportDateRange.prev_quarter]: {
            text: 'Previous Quarter',
            tooltip: `The full quarter immediately before the current quarter. 
            Quarters start on the following 4 dates at 12:00 am ${timezone}: 01 JAN, 01 MAR, 01 JUL, 01 OCT.`,
        },
        [ReportDateRange.current_year]: {
            text: 'Current Year',
            tooltip: `Only the time that has elapsed since this calendar year started. 
            Years start on 01 JAN at 12:00 am ${timezone}.`,
        },
        [ReportDateRange.prev_year]: {
            text: 'Previous Year',
            tooltip: `The full year immediately before the current year. Years start on 01 JAN at 12:00 am 
            ${timezone}.`,
        },
        [ReportDateRange.custom]: {
            text: 'Fixed Date Range',
            tooltip: `Please note, that for saved and sent reports, 
            the date selected here will not adjust dynamically but will always show the exact dates you select. `,
        },
    };
    return obj[range];
};

/* ReportCreateRequest */
const ReportCreateRequestDefinition = {
    name: t.string,
    description: t.string,
    data_source: REPORT_DATA_SOURCE,
    custom_from_date: t.union([DateFromISOString, t.null]),
    custom_to_date: t.union([DateFromISOString, t.null]),
    date_type: t.string,
    date_range: REPORT_DATE_RANGE,
    funeral_home_ids_with_access: t.union([t.literal('ALL'), t.array(t.number)]),
    user_ids_with_access: t.array(t.number),
};

// only GOM users can change these fields
const ReportCreateRestricted = {
    is_gather_report: t.boolean,
};

const ReportCreateRequestType = t.intersection([
    t.type(ReportCreateRequestDefinition),
    t.partial(ReportCreateRestricted),
]);
export interface ReportCreateRequest extends t.TypeOf<typeof ReportCreateRequestType> { }

export class ReportCreateRequest {
    public static fromRequest = getValidator<ReportCreateRequest>(ReportCreateRequestType);
}

/* ReportUpdateRequest */
// WARNING: Any changes to this MUI config type MUST be accompanied by a DB migration script

const PinnedColumnsDefinition = t.partial({
    left: t.array(t.string),
    right: t.array(t.string),
});

const Dimensions = t.partial({
    maxWidth: t.number,
    minWidth: t.number,
    width: t.number,
    flex: t.number,
});

const ColumnsDefinition = t.partial({
    columnVisibilityModel: t.record(t.union([t.string, t.number]), t.boolean),
    orderedFields: t.array(t.string),
    dimensions: t.record(t.string, Dimensions),
});

const OptionalFilterItemDefinition = t.partial({
    field: t.string,
    id: t.union([t.number, t.string]),
    value: t.unknown,
});

const FilterItemDefinition = t.intersection([
    t.type({
        field: t.string,
        operator: t.string,
    }),
    OptionalFilterItemDefinition,
]);

const DefaultFilterModelDefinition = t.partial({
    quickFilterValues: t.array(t.unknown),
});

const FilterModelDefinition = t.intersection([
    t.type({
        items: t.array(FilterItemDefinition),
    }),
    DefaultFilterModelDefinition,
]);

const FilterDefinition = t.partial({
    filterModel: FilterModelDefinition,
});

const SortingItemDefinition = t.array(
    t.type({
        field: t.string,
        sort: t.union([t.literal('asc'), t.literal('desc'), t.null, t.undefined]),
    }),
);

const SortingModelDefinition = t.partial({
    sortModel: SortingItemDefinition,
});

const PaginationModelDefinition = t.partial({
    paginationModel: t.partial({
        pageSize: t.number,
        page: t.number,
    }),
});

const RowGroupingModelDefinition = t.partial({
    model: t.array(t.string),
});

const PreferencePanelDefinition = t.type({
    open: t.boolean,
});

const AggregationModelDefinition = t.partial({
    model: t.record(t.string, t.string),
});

// WARNING: Any changes to this MUI config type MUST be accompanied by a DB migration script
const MUIGridStateType = t.partial({
    aggregation: AggregationModelDefinition,
    pinnedColumns: PinnedColumnsDefinition,
    columns: ColumnsDefinition,
    preferencePanel: PreferencePanelDefinition,
    filter: FilterDefinition,
    sorting: SortingModelDefinition,
    pagination: PaginationModelDefinition,
    rowGrouping: RowGroupingModelDefinition,
});
export interface MUIGridState extends t.TypeOf<typeof MUIGridStateType> { }

const ReportUpdateRequestFields = t.partial({
    name: t.string,
    description: t.string,
    date_type: t.string,
    date_range: REPORT_DATE_RANGE,
    custom_from_date: t.union([DateFromISOString, t.null]),
    custom_to_date: t.union([DateFromISOString, t.null]),
    mui_config: MUIGridStateType,
    owned_by_user_id: t.number,
    is_featured_default: t.boolean,
    funeral_home_ids_with_access: t.union([t.literal('ALL'), t.array(t.number)]),
    user_ids_with_access: t.array(t.number),
});

export interface ReportUpdateRequest extends t.TypeOf<typeof ReportUpdateRequestFields> { }

export class ReportUpdateRequest {
    public static fromRequest = getValidator<ReportUpdateRequest>(ReportUpdateRequestFields);
}

/* ReportRecord */
export interface ReportRecord {
    id: number;
    uuid: string;
    name: string;
    description: string | null;
    is_gather_report: boolean;
    date_type: string;
    date_range: ReportDateRange;
    custom_from_date: Date | string | null; // frontend will become a string, backend will be Date
    custom_to_date: Date | string | null; // frontend will become a string, backend will be Date
    data_source: ReportDataSource;
    created_by: number;
    created_time: Date;
    updated_by: number;
    updated_time: Date;
    deleted_by: number | null;
    deleted_time: Date | null;
    owned_by: number;
    mui_config: MUIGridState | null;
    is_featured_default: boolean;
    all_fh_can_access: boolean;
}

export interface ReportInsertRecord
    extends Pick<
        ReportRecord,
        | 'name'
        | 'description'
        | 'data_source'
        | 'date_range'
        | 'custom_from_date'
        | 'custom_to_date'
        | 'created_by'
        | 'updated_by'
        | 'owned_by'
        | 'is_gather_report'
        | 'all_fh_can_access'
    > { }

export interface ReportUpdateRecord
    extends Pick<ReportRecord, 'updated_by' | 'updated_time'>,
    Partial<
        Pick<
            ReportRecord,
            | 'name'
            | 'description'
            | 'date_type'
            | 'date_range'
            | 'custom_from_date'
            | 'custom_to_date'
            | 'deleted_by'
            | 'deleted_time'
            | 'owned_by'
            | 'mui_config'
            | 'is_featured_default'
            | 'all_fh_can_access'
        >
    > { }

export interface FuneralHomeWithReportAccessRecord {
    report_id: number;
    funeral_home_id: number;
}

export interface UserWithReportAccessRecord {
    report_id: number;
    user_profile_id: number;
}

/* ReportUX */
export interface ReportUXRecord
    extends Pick<
        ReportRecord,
        | 'id'
        | 'uuid'
        | 'name'
        | 'description'
        | 'date_type'
        | 'date_range'
        | 'custom_from_date'
        | 'custom_to_date'
        | 'data_source'
        | 'created_by'
        | 'created_time'
        | 'updated_by'
        | 'updated_time'
        | 'deleted_by'
        | 'deleted_time'
        | 'owned_by'
        | 'is_gather_report'
        | 'mui_config'
        | 'all_fh_can_access'
    > {
    owned_by_entity_id: number;
    owned_by_full_name: string;
    funeral_home_ids_with_access: number[];
    entity_ids_with_access: number[];
    schedule_ids: number[];
}

export interface ReportUX extends ReportUXRecord {
    users_with_access: EntitySummary[];
    owner: EntitySummary | null;
}

/* ReportSummary */
export interface ReportSummary
    extends Pick<ReportRecord, 'uuid' | 'name' | 'data_source' | 'created_time' | 'updated_time' | 'description'> {
    is_owned_by_me: boolean;
    is_gather_report: boolean;
    is_fh_shared_report: boolean;
    is_user_shared_report: boolean;
    owned_by_full_name: string;
    created_by_full_name: string;
}

const BaseDatasourceRequest = {
    datasource: REPORT_DATA_SOURCE,
    funeralHomeIds: t.array(t.number),
    fromDateInclusive: t.string,
    toDateInclusive: t.string,
    dateType: DATA_SOURCE_DATE_TYPE,
    showTestCases: t.boolean,
    timezone: t.string,
};

export enum DataSourceCaseStatus {
    ACTIVE = 'ACTIVE',
    ARCHIVED = 'ARCHIVED',
    TEST = 'TEST',
}

/* Case Non Financial */
// TODO: JJT this is a copy from the old case report. Needs to be audited
export interface CaseNonFinancialDataSourceUXRecord
    extends Pick<
        GatherCaseRecord,
        | 'id'
        | 'fname'
        | 'lname'
        | 'name'
        | 'dob_date'
        | 'dod_start_date'
        | 'created_time'
        | 'deleted_time'
        | 'is_test'
    >,
    Pick<
        FuneralHomeCaseRecord,
        'uuid' | 'funeral_home_id' | 'case_number' | 'case_type' | 'archived_time' | 'updated_time' | 'workflow_id'
    > {
    full_name: string;
    converted_to_at_need_date: FuneralHomeCaseRecord['case_type_change_time'];
    age: number | null;
    funeral_home_case_id: FuneralHomeCaseRecord['id'];
    workflow_name: Nullable<WorkflowRecord>['name'];
    funeral_home_name: FuneralHomeRecord['name'];
    funeral_home_key: FuneralHomeRecord['key'];
    owner_funeral_home_id: GatherCaseRecord['funeral_home_id'];
    case_creator: string;
    assignee_fname: EntityRecord['fname'];
    case_assignee: string;
    assignee: GatherCaseReportAssignee;
    filter_date: Date;
    informant_name: string;
    informant_display_relationship: Text;
    informant_phone: Text;
    informant_email: Text;
    location_name: string;
    labels: CaseLabelUX[];
}

export interface CaseNonFinancialDataSourceUX extends CaseNonFinancialDataSourceUXRecord { }

const CaseNonFinancialDSRequestType = t.type({
    ...BaseDatasourceRequest,
    datasource: t.literal(ReportDataSource.case_non_financial),
    caseTypes: t.array(CaseTypeDefinition),
});
export interface CaseNonFinancialDSRequest extends t.TypeOf<typeof CaseNonFinancialDSRequestType> { }

/* Case Financial */
export interface CaseFinancialDataSourceUXRecord { }

export interface CaseFinancialDataSourceUX extends CaseFinancialDataSourceUXRecord { }

const CaseFinancialDSRequestType = t.type({
    ...BaseDatasourceRequest,
    datasource: t.literal(ReportDataSource.case_financial),
    caseTypes: t.array(CaseTypeDefinition),
});
export interface CaseFinancialDSRequest extends t.TypeOf<typeof CaseFinancialDSRequestType> { }

/* Case Vitals */
export interface CaseVitalsDataSourceUXRecord {
    row_id: GatherCaseRecord['id'];
    datasource: ReportDataSource.case_vitals;
    // Common Fields across ALL data sources
    case_id: GatherCaseRecord['id'];
    case_uuid: FuneralHomeCaseRecord['uuid'];
    funeral_home_name: FuneralHomeRecord['name'];
    case_type: FuneralHomeCaseRecord['case_type'];
    funeral_home_id: FuneralHomeRecord['id'];
    funeral_home_key: FuneralHomeRecord['key'];
    case_number: FuneralHomeCaseRecord['case_number'];
    case_keeptrack_key: KeeptrackCardRecord['tracker_id'] | null;
    case_full_name: string;
    case_fname: GatherCaseRecord['fname'];
    case_mname: GatherCaseRecord['mname'];
    case_lname: GatherCaseRecord['lname'];
    case_name: GatherCaseRecord['name'];
    case_workflow_name: WorkflowRecord['name'] | null;
    case_workflow_id: WorkflowRecord['id'] | null;
    case_assignee_name: string;
    case_assignee_entity_id: EntityRecord['id'];
    case_created_time: FuneralHomeCaseRecord['case_type_change_time'];
    case_simple_created_time: GatherCaseRecord['created_time'];
    case_death_date: Date;
    case_simple_death_date: Date | null;
    case_labels: CaseLabelUX[];
    case_status: DataSourceCaseStatus;

    // Shared Fields w/ other data sources
    case_dc_percentage: GatherCaseRecord['death_certificate_percentage'];
    case_decedent_age: number | null;
    case_dc_disposition_method: DeathCertificateRestingPlaceType['options'] | null;
    case_informant_name: string;
    case_informant_relationship: string;
    case_informant_phone: EntityRecord['phone'] | null;
    case_informant_email: EntityRecord['email'] | null;
    case_informant_address: AddressRecord['long_address'] | null;
    case_birth_date: GatherCaseRecord['dob_date'];
    case_suffix: GatherCaseRecord['suffix'];
    case_display_fname: GatherCaseRecord['display_fname'];
    case_display_full_name: GatherCaseRecord['display_full_name'];

    // Case Vitals-specific Fields
    case_dc_locked: 'Locked' | 'Unlocked';
    case_dc_updated_time: GatherCaseRecord['dc_updated_time'];
    case_dc_updated_by_name: string;
    case_dc_config_name: DeathCertificateConfigRecord['name'];
    case_dc: GatherCaseRecord['death_certificate'];
    case_death_end_date: Date | null;
    case_pronouncement_date: Date | null;
    case_father_name: string;
    case_mother_name: string;
    case_spouse_name: string;
    case_spouse_relationship: string;
    case_spouse_phone: EntityRecord['phone'] | null;
    case_spouse_address: AddressRecord['long_address'] | null;
    case_ai_obit: AutoObitParams | null;
}

export interface CaseVitalsDataSourceUX extends CaseVitalsDataSourceUXRecord { }

const CaseVitalsDSRequestType = t.type({
    ...BaseDatasourceRequest,
    datasource: t.literal(ReportDataSource.case_vitals),
});
export interface CaseVitalsDSRequest extends t.TypeOf<typeof CaseVitalsDSRequestType> { }

export enum DataSourceInvoiceStatus {
    Locked = 'Locked',
    Open = 'Open',
    Perpetual = 'Perpetual',
}

export enum DataSourceStatementStatus {
    Frozen = 'Frozen',
    Unfrozen = 'Unfrozen',
}

/* Invoice Line Items */
// this datasource is made up of a union between the contract_item table and the invoice table
// Below are two interfaces that represent the two different types of records that get UNIONed together
// Followed by a 3rd interface that includes the common & shared fields
interface ContractItemRecordLineItem {
    row_id: `ci${number}`;
    line_item_id: `ci${number}`;
    line_item_name: string;
    line_item_name_original: ProductContractItemRecord['name'];
    line_item_type: ProductContractItemRecord['type'];
    line_item_is_removed: boolean;
    line_item_subtotal: ProductContractItemRecord['list_price'];
    line_item_adjustments: ProductContractItemRecord['price_adjustment'];
    line_item_tax_amount: ProductContractItemRecord['tax_total'];
    line_item_total: number;
    line_item_quantity: ProductContractItemRecord['quantity'];
    line_item_units: ProductRecord['var_increment_units'] | null;
    line_item_created_on: ProductContractItemRecord['created_time'];
    line_item_updated_on: ProductContractItemRecord['updated_time'];
    statement_created_on: ProductContractRecord['created_time'];
    line_item_category: ProductContractItemRecord['category'];
    line_item_grouping: ProductCategoryGroup;
    statement_taxation_method: ProductContractRecord['taxation_method'];
    line_item_is_taxable: boolean;
    // TODO line_item_package_tax_adjustments
    // TODO line_item_taxable_amount
    line_item_tax_rate: ProductTaxRateRecord['description'] | null;
    line_item_package: ProductPackageRecord['name'] | null;
    line_item_subpackage: ProductPackageRecord['name'] | null;
    invoice_name: 'Statement';
    invoice_id: `cr${number}`;
    invoice_status: DataSourceInvoiceStatus.Locked | DataSourceInvoiceStatus.Open;
    invoice_open_date: ProductContractRevisionRecord['created_time'];
    invoice_close_date: ProductContractRevisionRecord['frozen_time'];
    invoice_closed_by_name: string;
    // TODO invoice_total
    statement_status: DataSourceStatementStatus;
    line_item_pricing_model: ProductRecord['pricing_model'] | null;
    line_item_sku: ProductContractItemRecord['sku'];
    line_item_note: ProductContractItemRecord['note'];
    line_item_product_id: ProductRecord['id'] | null;
    line_item_tags: ProductRecord['tags'] | null;
    line_item_cost: ProductRecord['cost'] | null;
    line_item_model_number: ProductRecord['model_number'] | null;
    line_item_manufacturer: ProductManufacturerRecord['name'] | null;
    line_item_is_hidden: ProductRecord['is_hidden'] | null;
}

export enum InvoiceLineItemType {
    outside_product = 'Outside Product',
    convenience_fee = 'Convenience Fee',
}

interface InvoiceRecordLineItem {
    row_id: `i${number}`;
    line_item_id: `i${number}`;
    line_item_name: InvoiceRecord['description'];
    line_item_name_original: InvoiceRecord['description'];
    line_item_type: InvoiceLineItemType;
    line_item_is_removed: null;
    line_item_subtotal: InvoiceRecord['amount_due'];
    line_item_adjustments: null;
    line_item_tax_amount: InvoiceRecord['sales_tax'];
    line_item_total: number;
    line_item_quantity: null;
    line_item_units: ProductRecord['var_increment_units'] | null;
    line_item_created_on: InvoiceRecord['issue_date'];
    line_item_updated_on: null;
    statement_created_on: null;
    line_item_category: InvoiceRecord['product_category'];
    line_item_grouping: ProductCategoryGroup;
    statement_taxation_method: null;
    line_item_is_taxable: boolean;
    // line_item_package_tax_adjustments: null;
    // line_item_taxable_amount: InvoiceRecord['amount_due'];
    line_item_tax_rate: ProductTaxRateRecord['description'] | null;
    line_item_package: null;
    line_item_subpackage: null;
    invoice_name: 'Outside of Statement Invoice';
    invoice_id: `fhc${number}`;
    invoice_status: DataSourceInvoiceStatus.Perpetual;
    invoice_open_date: InvoiceRecord['issue_date'];
    invoice_close_date: null;
    invoice_closed_by_name: null;
    // TODO invoice_total
    statement_status: null;
    line_item_pricing_model: ProductRecord['pricing_model'] | null;
    line_item_sku: ProductRecord['sku'] | null;
    line_item_note: InvoiceRecord['memo'];
    line_item_product_id: ProductRecord['id'] | null;
    line_item_tags: ProductRecord['tags'] | null;
    line_item_cost: ProductRecord['cost'] | null;
    line_item_model_number: ProductRecord['model_number'] | null;
    line_item_manufacturer: ProductManufacturerRecord['name'] | null;
    line_item_is_hidden: ProductRecord['is_hidden'] | null;
}

interface InvoiceLineItemsCommonFields {
    datasource: ReportDataSource.invoice_line_items;
    // Common Fields across ALL data sources
    case_id: GatherCaseRecord['id'];
    case_uuid: FuneralHomeCaseRecord['uuid'];
    funeral_home_name: FuneralHomeRecord['name'];
    case_type: FuneralHomeCaseRecord['case_type'];
    funeral_home_id: FuneralHomeRecord['id'];
    funeral_home_key: FuneralHomeRecord['key'];
    case_number: FuneralHomeCaseRecord['case_number'];
    case_keeptrack_key: KeeptrackCardRecord['tracker_id'] | null;
    case_full_name: string;
    case_fname: GatherCaseRecord['fname'];
    case_mname: GatherCaseRecord['mname'];
    case_lname: GatherCaseRecord['lname'];
    case_name: GatherCaseRecord['name'];
    case_workflow_name: WorkflowRecord['name'] | null;
    case_workflow_id: WorkflowRecord['id'] | null;
    case_assignee_name: string;
    case_assignee_entity_id: EntityRecord['id'];
    case_created_time: FuneralHomeCaseRecord['case_type_change_time'];
    case_simple_created_time: GatherCaseRecord['created_time'];
    case_death_date: Date;
    case_simple_death_date: Date | null;
    case_labels: CaseLabelUX[];
    case_status: DataSourceCaseStatus;

    // Shared Fields w/ other data sources
    case_dc_percentage: GatherCaseRecord['death_certificate_percentage'];
    case_decedent_age: number | null;
    case_dc_disposition_method: DeathCertificateRestingPlaceType['options'] | null;
    case_informant_name: string;
    case_informant_relationship: string;
    case_informant_phone: EntityRecord['phone'] | null;
    case_informant_email: EntityRecord['email'] | null;
    case_informant_address: AddressRecord['long_address'] | null;
    fh_address: AddressRecord['long_address'];
}

export type InvoiceLineItemsDataSourceUXRecord = InvoiceLineItemsCommonFields &
    (ContractItemRecordLineItem | InvoiceRecordLineItem);

export type InvoiceLineItemsDataSourceUX = InvoiceLineItemsDataSourceUXRecord;

const InvoiceLineItemsDSRequestType = t.type({
    ...BaseDatasourceRequest,
    datasource: t.literal(ReportDataSource.invoice_line_items),
});
export interface InvoiceLineItemsDSRequest extends t.TypeOf<typeof InvoiceLineItemsDSRequestType> { }

/* Events */
export interface EventsDataSourceUXRecord {
    row_id: GatherEventRecord['id'];
    datasource: ReportDataSource.events;
    // Common Fields across ALL data sources
    case_id: GatherCaseRecord['id'];
    case_uuid: FuneralHomeCaseRecord['uuid'];
    funeral_home_name: FuneralHomeRecord['name'];
    case_type: FuneralHomeCaseRecord['case_type'];
    funeral_home_id: FuneralHomeRecord['id'];
    funeral_home_key: FuneralHomeRecord['key'];
    case_number: FuneralHomeCaseRecord['case_number'];
    case_keeptrack_key: KeeptrackCardRecord['tracker_id'] | null;
    case_full_name: string;
    case_fname: GatherCaseRecord['fname'];
    case_mname: GatherCaseRecord['mname'];
    case_lname: GatherCaseRecord['lname'];
    case_name: GatherCaseRecord['name'];
    case_workflow_name: WorkflowRecord['name'] | null;
    case_workflow_id: WorkflowRecord['id'] | null;
    case_assignee_name: string;
    case_assignee_entity_id: EntityRecord['id'];
    case_created_time: FuneralHomeCaseRecord['case_type_change_time'];
    case_simple_created_time: GatherCaseRecord['created_time'];
    case_death_date: Date;
    case_simple_death_date: Date | null;
    case_labels: CaseLabelUX[];
    case_status: DataSourceCaseStatus;

    // Shared Fields w/ other data sources
    case_current_location_name: TaskLocationRecord['name'] | null;
    case_current_location_id: CaseTaskRecord['task_location_id'] | null;

    // Specific to Events
    event_name: GatherEventRecord['name'];
    event_start_time: GatherEventRecord['start_time'];
    event_end_time: GatherEventRecord['end_time'];
    event_duration_minutes: number;
    event_id: GatherEventRecord['id'];
    event_full_address: AddressRecord['long_address'] | null;
    event_address1: AddressRecord['address1'] | null;
    event_address2: AddressRecord['address2'] | null;
    event_city: AddressRecord['city'] | null;
    event_state: AddressRecord['state'] | null;
    event_postal_code: AddressRecord['postal_code'] | null;
    event_is_private: GatherEventRecord['is_private'];
    event_is_streamable: GatherEventRecord['is_streamable'];
    event_is_arrangement: boolean;
    event_has_passed: boolean;
}

export interface EventsDataSourceUX extends EventsDataSourceUXRecord { }

const EventsDSRequestType = t.type({
    ...BaseDatasourceRequest,
    datasource: t.literal(ReportDataSource.events),
});
export interface EventsDSRequest extends t.TypeOf<typeof EventsDSRequestType> { }

export enum DataSourceHelperType {
    Admin = 'Admin',
    Guest = 'Guest',
    Deceased = 'Deceased',
}

/* Helpers */
export interface HelpersDataSourceUXRecord {
    row_id: CaseEntityRecord['id'];
    datasource: ReportDataSource.helpers;
    // Common Fields across ALL data sources
    case_id: GatherCaseRecord['id'];
    case_uuid: FuneralHomeCaseRecord['uuid'];
    funeral_home_name: FuneralHomeRecord['name'];
    case_type: FuneralHomeCaseRecord['case_type'];
    funeral_home_id: FuneralHomeRecord['id'];
    funeral_home_key: FuneralHomeRecord['key'];
    case_number: FuneralHomeCaseRecord['case_number'];
    case_keeptrack_key: KeeptrackCardRecord['tracker_id'] | null;
    case_full_name: string;
    case_fname: GatherCaseRecord['fname'];
    case_mname: GatherCaseRecord['mname'];
    case_lname: GatherCaseRecord['lname'];
    case_name: GatherCaseRecord['name'];
    case_workflow_name: WorkflowRecord['name'] | null;
    case_workflow_id: WorkflowRecord['id'] | null;
    case_assignee_name: string;
    case_assignee_entity_id: EntityRecord['id'];
    case_created_time: FuneralHomeCaseRecord['case_type_change_time'];
    case_simple_created_time: GatherCaseRecord['created_time'];
    case_death_date: Date;
    case_simple_death_date: Date | null;
    case_labels: CaseLabelUX[];
    case_status: DataSourceCaseStatus;

    // Shared Fields w/ other data sources
    case_birth_date: GatherCaseRecord['dob_date'];
    case_suffix: GatherCaseRecord['suffix'];
    case_display_fname: GatherCaseRecord['display_fname'];
    case_display_full_name: GatherCaseRecord['display_full_name'];

    // Specific to Helpers
    helper_full_name: string;
    helper_fname: EntityRecord['fname'];
    helper_mname: EntityRecord['mname'];
    helper_lname: EntityRecord['lname'];
    helper_relationship: string;
    helper_type: DataSourceHelperType;
    helper_invited_on: UserProfileRecord['invited_time'] | null;
    helper_phone: EntityRecord['phone'];
    helper_home_phone: EntityRecord['home_phone'];
    helper_work_phone: EntityRecord['work_phone'];
    helper_email: EntityRecord['email'];
    helper_full_address: AddressRecord['long_address'] | null;
    helper_address1: AddressRecord['address1'] | null;
    helper_address2: AddressRecord['address2'] | null;
    helper_city: AddressRecord['city'] | null;
    helper_state: AddressRecord['state'] | null;
    helper_postal_code: AddressRecord['postal_code'] | null;
    helper_is_invited: boolean;
    helper_has_created_account: boolean | null;
    helper_is_informant: boolean;
}

export interface HelpersDataSourceUX extends HelpersDataSourceUXRecord { }

const HelpersDSRequestType = t.type({
    ...BaseDatasourceRequest,
    datasource: t.literal(ReportDataSource.helpers),
});
export interface HelpersDSRequest extends t.TypeOf<typeof HelpersDSRequestType> { }

/* Notes */
export interface NotesDataSourceUXRecord { }

export interface NotesDataSourceUX extends NotesDataSourceUXRecord { }

const NotesDSRequestType = t.type({
    ...BaseDatasourceRequest,
    datasource: t.literal(ReportDataSource.notes),
});
export interface NotesDSRequest extends t.TypeOf<typeof NotesDSRequestType> { }

/* Task and Step Activity */
interface TaskStepActivityTaskAssignee {
    user_id: UserProfileRecord['id'];
    entity_id: UserProfileRecord['entity_id'];
    fname: EntityRecord['fname'];
    lname: EntityRecord['lname'];
}

export enum TaskStepActivityTaskStatus {
    Completed = 'Completed',
    Skipped = 'Skipped',
    Uncompleted = 'Uncompleted',
}

export enum TaskStepActivityTaskType {
    trackingStep = 'Tracking Step',
    standardTask = 'Standard Task',
    aftercareTask = 'After-care Task',
}

export interface TaskStepActivityDataSourceUXRecord {
    row_id: TaskRecord['id'];
    datasource: ReportDataSource.task_and_step_activity;

    // Common Fields across ALL data sources
    case_id: GatherCaseRecord['id'];
    case_uuid: FuneralHomeCaseRecord['uuid'];
    funeral_home_name: FuneralHomeRecord['name'];
    case_type: FuneralHomeCaseRecord['case_type'];
    funeral_home_id: FuneralHomeRecord['id'];
    funeral_home_key: FuneralHomeRecord['key'];
    case_number: FuneralHomeCaseRecord['case_number'];
    case_keeptrack_key: KeeptrackCardRecord['tracker_id'] | null;
    case_full_name: string;
    case_fname: GatherCaseRecord['fname'];
    case_mname: GatherCaseRecord['mname'];
    case_lname: GatherCaseRecord['lname'];
    case_name: GatherCaseRecord['name'];
    case_workflow_name: WorkflowRecord['name'] | null;
    case_workflow_id: WorkflowRecord['id'] | null;
    case_assignee_name: string;
    case_assignee_entity_id: EntityRecord['id'];
    case_created_time: FuneralHomeCaseRecord['case_type_change_time'];
    case_simple_created_time: GatherCaseRecord['created_time'];
    case_death_date: Date;
    case_simple_death_date: Date | null;
    case_labels: CaseLabelUX[];
    case_status: DataSourceCaseStatus;

    // Shared Fields w/ other data sources
    case_current_location_name: TaskLocationRecord['name'] | null;
    case_current_location_id: CaseTaskRecord['task_location_id'] | null;

    // Specific to Task and Step Activity
    task_id: TaskRecord['id'];
    task_type: TaskStepActivityTaskType;
    task_name: string;
    task_status: TaskStepActivityTaskStatus;
    task_assignees: TaskStepActivityTaskAssignee[];
    task_assigned_to_all: boolean;
    task_resolved_on: Date | null;
    task_resolved_by_name: string;
    task_resolved_by_entity_id: EntityRecord['id'] | null;
    task_days_outstanding: number;
    task_note: CaseTaskRecord['note'];
    task_subtitle: TaskRecord['subtitle'];
    task_description: TaskRecord['description'];
    task_visible_to_family: TaskRecord['visible_to_family'];
    task_is_after_care: TaskRecord['is_after_care'];
    step_location_name: TaskLocationRecord['name'] | null;
    step_location_id: TaskLocationRecord['id'] | null;
    task_performed_by_name: string;
    task_performed_by_entity_id: EntityRecord['id'] | null;
    task_completion_perc: number;
    step_completion_perc: number;
    case_current_location_moved_time: CaseTaskRecord['marked_complete_time'] | null;
    case_pickup_address: LongAddress | null;
    case_dropoff_address: LongAddress | null;
    task_due_time: CaseTaskRecord['complete_by_time'];
    take_is_overdue: boolean | null;
    // TODO: step_time_in_location
}

export interface TaskStepActivityDataSourceUX extends TaskStepActivityDataSourceUXRecord { }

// Task/Step Activity Data Source Request
const TaskStepActivityDSRequestType = t.type({
    ...BaseDatasourceRequest,
    datasource: t.literal(ReportDataSource.task_and_step_activity),
});
export interface TaskStepActivityDSRequest extends t.TypeOf<typeof TaskStepActivityDSRequestType> { }

/* Payments */
export interface PaymentsDataSourceUXRecord {
    row_id: PaymentRecord['id'];
    datasource: ReportDataSource.payments;
    // Common Fields across ALL data sources
    case_id: GatherCaseRecord['id'];
    case_uuid: FuneralHomeCaseRecord['uuid'];
    funeral_home_name: FuneralHomeRecord['name'];
    case_type: FuneralHomeCaseRecord['case_type'];
    funeral_home_id: FuneralHomeRecord['id'];
    funeral_home_key: FuneralHomeRecord['key'];
    case_number: FuneralHomeCaseRecord['case_number'];
    case_keeptrack_key: KeeptrackCardRecord['tracker_id'] | null;
    case_full_name: string;
    case_fname: GatherCaseRecord['fname'];
    case_mname: GatherCaseRecord['mname'];
    case_lname: GatherCaseRecord['lname'];
    case_name: GatherCaseRecord['name'];
    case_workflow_name: WorkflowRecord['name'] | null;
    case_workflow_id: WorkflowRecord['id'] | null;
    case_assignee_name: string;
    case_assignee_entity_id: EntityRecord['id'];
    case_created_time: FuneralHomeCaseRecord['case_type_change_time'];
    case_simple_created_time: GatherCaseRecord['created_time'];
    case_death_date: Date;
    case_simple_death_date: Date | null;
    case_labels: CaseLabelUX[];
    case_status: DataSourceCaseStatus;

    // Shared Fields w/ other data sources
    case_dc_percentage: GatherCaseRecord['death_certificate_percentage'];
    case_decedent_age: number | null;
    case_dc_disposition_method: DeathCertificateRestingPlaceType['options'] | null;
    case_informant_name: string;
    case_informant_relationship: string;
    case_informant_phone: EntityRecord['phone'] | null;
    case_informant_email: EntityRecord['email'] | null;
    case_informant_address: AddressRecord['long_address'] | null;
    fh_address: AddressRecord['long_address'];

    // Payment-specific Fields
    payment_payer_name: string;
    payment_payer_relationship: string;
    payment_method: PaymentRecord['method'];
    payment_status: PaymentRecord['status'];
    payment_subtotal: PaymentRecord['amount'];
    payment_convenience_fee: PaymentRecord['merch_fee'];
    payment_total: string;
    payment_processing_expense: string;
    payment_payout_total: string;
    payment_date: PaymentRecord['payment_date'];
    payment_created_by_name: string;
    payment_created_time: PaymentRecord['created_time'];
    payment_memo: PaymentRecord['memo'];
    payment_payout_date: Date | null;
    payment_payer_phone: EntityRecord['phone'] | null;
    payment_payer_email: EntityRecord['email'] | null;
    payment_payer_address: AddressRecord['long_address'] | null;
    payment_payer_address1: AddressRecord['address1'] | null;
    payment_payer_address2: AddressRecord['address2'] | null;
    payment_payer_city: AddressRecord['city'] | null;
    payment_payer_state: AddressRecord['state'] | null;
    payment_payer_postal_code: AddressRecord['postal_code'] | null;
    case_value_total: FuneralHomeCaseRecord['expense_total'];
    case_value_collected: FuneralHomeCaseRecord['collected_total'];
    case_value_outstanding: string;
    case_value_collected_perc: number | null;
    payment_reconciled_time: PaymentRecord['reconciled_time'];
    payment_insurance_policy_number: PaymentRecord['external_id'] | null;
    payment_check_number: PaymentRecord['external_id'] | null;
    case_statement_status: 'Frozen' | 'Unfrozen' | null;
    payment_id: PaymentRecord['id'];
}

export interface PaymentsDataSourceUX extends PaymentsDataSourceUXRecord { }

const PaymentsDSRequestType = t.type({
    ...BaseDatasourceRequest,
    datasource: t.literal(ReportDataSource.payments),
});
export interface PaymentsDSRequest extends t.TypeOf<typeof PaymentsDSRequestType> { }

/* Remember Page */
export interface RememberPageDataSourceUXRecord { }

export interface RememberPageDataSourceUX extends RememberPageDataSourceUXRecord { }

const RememberPageDSRequestType = t.type({
    ...BaseDatasourceRequest,
    datasource: t.literal(ReportDataSource.remember_page),
});
export interface RememberPageDSRequest extends t.TypeOf<typeof RememberPageDSRequestType> { }

/* Insurance Policies */
export interface InsurancePoliciesDataSourceUXRecord {
    row_id: InsurancePolicyRecord['id'];
    datasource: ReportDataSource.insurance_policies;
    // Common Fields across ALL data sources -- EXCEPT case-related fields are NULLABLE
    case_id: Nullable<GatherCaseRecord>['id'];
    case_uuid: Nullable<FuneralHomeCaseRecord>['uuid'];
    funeral_home_name: FuneralHomeRecord['name'];
    case_type: Nullable<FuneralHomeCaseRecord>['case_type'];
    funeral_home_id: FuneralHomeRecord['id'];
    funeral_home_key: FuneralHomeRecord['key'];
    case_number: Nullable<FuneralHomeCaseRecord>['case_number'];
    case_keeptrack_key: KeeptrackCardRecord['tracker_id'] | null;
    case_full_name: string | null;
    case_fname: Nullable<GatherCaseRecord>['fname'];
    case_mname: Nullable<GatherCaseRecord>['mname'];
    case_lname: Nullable<GatherCaseRecord>['lname'];
    case_name: Nullable<GatherCaseRecord>['name'];
    case_workflow_name: WorkflowRecord['name'] | null;
    case_workflow_id: WorkflowRecord['id'] | null;
    case_assignee_name: string | null;
    case_assignee_entity_id: Nullable<EntityRecord>['id'];
    case_created_time: Nullable<FuneralHomeCaseRecord>['case_type_change_time'];
    case_simple_created_time: Nullable<GatherCaseRecord>['created_time'];
    case_death_date: Date | null;
    case_simple_death_date: Date | null;
    case_status: DataSourceCaseStatus | null;

    // Insurance Policies-specific Fields
    policy_id: InsurancePolicyRecord['id'];
    status: PolicyCaseStatus;
    insured_full_name: string;
    policy_number: InsurancePolicyRecord['policy_number'];
    insurance_company_name: InsuranceCarrierRecord['name'];
    insured_dob: string;
    insured_age: string;
    insured_gender: string;
    insured_email: string;
    insured_phone: string;
    insured_address: string;
    policy_status: Homesteaders.PolicyStatus;
    product_type: string;
    face_value: string;
    issue_date: string;
    pay_frequency: string;
    arrangement_total: string;
    policy_owner_full_name: string;
    policy_owner_dob: string;
    policy_owner_age: string;
    policy_owner_gender: string;
    policy_owner_email: string;
    policy_owner_phone: string;
    policy_owner_address: string;
    writing_agent: string;
    potential_case_uuid: number | null;
    funeral_home_theme_color: string;
}

export interface InsurancePoliciesDataSourceUX extends InsurancePoliciesDataSourceUXRecord { }

const InsurancePoliciesDSRequestType = t.type({
    ...BaseDatasourceRequest,
    datasource: t.literal(ReportDataSource.insurance_policies),
});
export interface InsurancePoliciesDSRequest extends t.TypeOf<typeof InsurancePoliciesDSRequestType> { }

/* Data Source Request */
const DataSourceRequestType = t.union([
    CaseNonFinancialDSRequestType,
    CaseFinancialDSRequestType,
    CaseVitalsDSRequestType,
    HelpersDSRequestType,
    PaymentsDSRequestType,
    InvoiceLineItemsDSRequestType,
    EventsDSRequestType,
    TaskStepActivityDSRequestType,
    RememberPageDSRequestType,
    NotesDSRequestType,
    InsurancePoliciesDSRequestType,
]);

export type DataSourceRequest = t.TypeOf<typeof DataSourceRequestType>;
const iotsRequestTypeLookup: Record<ReportDataSource, t.HasProps> = {
    [ReportDataSource.case_non_financial]: CaseNonFinancialDSRequestType,
    [ReportDataSource.task_and_step_activity]: TaskStepActivityDSRequestType,
    [ReportDataSource.case_financial]: CaseFinancialDSRequestType,
    [ReportDataSource.case_vitals]: CaseVitalsDSRequestType,
    [ReportDataSource.invoice_line_items]: InvoiceLineItemsDSRequestType,
    [ReportDataSource.events]: EventsDSRequestType,
    [ReportDataSource.helpers]: HelpersDSRequestType,
    [ReportDataSource.notes]: NotesDSRequestType,
    [ReportDataSource.payments]: PaymentsDSRequestType,
    [ReportDataSource.remember_page]: RememberPageDSRequestType,
    [ReportDataSource.insurance_policies]: InsurancePoliciesDSRequestType,
};

export const dataSourceRequestFromRequest = getValidator<DataSourceRequest>(
    ({ datasource }) => iotsRequestTypeLookup[datasource],
);

// this is only required for the InsurancePolicies Legacy report
export const insurancePoliciesDSFromRequest = getValidator<InsurancePoliciesDSRequest>(InsurancePoliciesDSRequestType);

export type DataSourceRowData =
    // | CaseNonFinancialDataSourceUX
    | TaskStepActivityDataSourceUX
    // | CaseFinancialDataSourceUX
    | CaseVitalsDataSourceUX
    | InvoiceLineItemsDataSourceUX
    | EventsDataSourceUX
    | HelpersDataSourceUX
    // | NotesDataSourceUX
    // | RememberPageDataSourceUX
    | InsurancePoliciesDataSourceUX
    | PaymentsDataSourceUX;

export type DataSourceData =
    // | CaseNonFinancialDataSourceUX[]
    | TaskStepActivityDataSourceUX[]
    // | CaseFinancialDataSourceUX[]
    | CaseVitalsDataSourceUX[]
    | InvoiceLineItemsDataSourceUX[]
    | EventsDataSourceUX[]
    | HelpersDataSourceUX[]
    // | NotesDataSourceUX[]
    // | RememberPageDataSourceUX[];
    | InsurancePoliciesDataSourceUX[]
    | PaymentsDataSourceUX[];
export interface DataSourceResponse {
    data: DataSourceData;
}

export enum ReportURLSearchParams {
    funeralHomeIds = 'fhs',
    dateType = 'dt',
    dateRange = 'dr',
    timezone = 'tz',
    customFromDate = 'cfd',
    customToDate = 'ctd',
}

export const NoFHSelectedValue = 'none';

export const generateFuneralHomeIdsURLParamValue = (funeralHomeIds: number[]): string => {
    return funeralHomeIds.join(',') || NoFHSelectedValue;
};

const CustomDateFormat = 'YYYY-MM-DD';

export const generateDateURLParamValue = (date: moment.Moment) => {
    return date.format(CustomDateFormat);
};

export type ReportUrlState = {
    [ReportURLSearchParams.funeralHomeIds]: string;
    [ReportURLSearchParams.dateType]: string;
    [ReportURLSearchParams.dateRange]: string;
    [ReportURLSearchParams.timezone]: string;
    [ReportURLSearchParams.customFromDate]?: string;
    [ReportURLSearchParams.customToDate]?: string;
};

export interface ReportState {
    funeralHomeIds: number[];
    dateType: DataSourceDateType;
    dateRange: ReportDateRange;
    timezone: string;
    customFromDate: moment.Moment | null;
    customToDate: moment.Moment | null;
}

export const parseReportURLSearchParams = (params: {
    funeralHomeIdsRaw: string | null;
    dateTypeRaw: string | null;
    dateRangeRaw: string | null;
    timezoneRaw: string | null;
    customFromDateRaw: string | null;
    customToDateRaw: string | null;
}): Nullable<ReportState> => {
    const { funeralHomeIdsRaw, dateTypeRaw, dateRangeRaw } = params;
    // a comma-separated list of FH IDs OR the string 'none' if no FHs are explicitly selected
    // 'none' gets converted to an empty array due to the map & filter
    const funeralHomeIds: number[] | null = funeralHomeIdsRaw?.split(',').map(Number).filter(Boolean) ?? null;
    const dateType: DataSourceDateType | null = isDataSourceDateType(dateTypeRaw) ? dateTypeRaw : null;
    const dateRange: ReportDateRange | null = isReportDateRange(dateRangeRaw) ? dateRangeRaw : null;
    const customFromDate: moment.Moment | null = params.customFromDateRaw
        ? moment(params.customFromDateRaw, CustomDateFormat, true).startOf('day')
        : null;
    const customToDate: moment.Moment | null = params.customToDateRaw
        ? moment(params.customToDateRaw, CustomDateFormat, true).endOf('day')
        : null;
    return {
        funeralHomeIds,
        dateType,
        dateRange,
        customFromDate,
        customToDate,
        timezone: params.timezoneRaw,
    };
};

export const encodeReportURLSearchParams = (params: ReportState): ReportUrlState => {
    const input: ReportUrlState = {
        [ReportURLSearchParams.dateRange]: params.dateRange,
        [ReportURLSearchParams.dateType]: params.dateType,
        [ReportURLSearchParams.funeralHomeIds]: generateFuneralHomeIdsURLParamValue(params.funeralHomeIds),
        [ReportURLSearchParams.timezone]: params.timezone,
    };
    if (params.customFromDate) {
        input[ReportURLSearchParams.customFromDate] = generateDateURLParamValue(params.customFromDate);
    }
    if (params.customToDate) {
        input[ReportURLSearchParams.customToDate] = generateDateURLParamValue(params.customToDate);
    }
    return input;
};
