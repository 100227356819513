import AddIcon from '@mui/icons-material/Add';
import Chip from '@mui/material/Chip';
import { Theme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { getIntercomTargetProp } from '../../../services';
import { CaseLabelUX } from '../../../shared/types';
import { GStyles } from '../../../styles/GStyles';

const useStyles = makeStyles(
    (theme: Theme) => ({
        root: {
            textAlign: 'center',
            padding: '4px 8px',
            '& $chip': {
                fontSize: 13,
                textTransform: 'capitalize',
                margin: 2,
                color: theme.palette.common.white,
            },
            '& $labelChip, $chip': {
                height: 24,
                backgroundColor: '#fff',
                margin: 2,
                '& svg': {
                    marginRight: 2,
                    fontSize: 14,
                },
            },
        },
        labelChip: {
            border: `2px dashed`,
            background: theme.palette.common.white,
            color: theme.palette.secondary.main,
            '&$invertedColor': {
                background: 'unset',
                border: '1px dashed #fff',
                color: '#fff',
            },
        },
        label: {
            '& span': {
                padding: '0 10px',
            },
        },
        numberLabelChip: {
            height: 20,
            marginLeft: 2,
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.common.white,
            border: `1px solid ${theme.palette.primary.main}`,
            '&:hover': {
                backgroundColor: theme.palette.common.white,
            },
        },
        fixedWidth: {
            width: 90,
        },
        invertedColor: {},
        chip: {},
    }),
    { name: 'CaseLabelChips' },
);

export interface CaseLabelChipsProps {
    labels: CaseLabelUX[];
    className?: string;
    chipClass?: string;
    color?: 'invert';
    isAddLabelButtonVisible?: boolean | null;
    disableTooltip?: string;
    addLabelClass?: string;
    intercomTargetProp?: string;
    maxLabelsToShow?: number;
    labelsCountChipClass?: string;
    onClick: (label?: CaseLabelUX) => void;
}
const CaseLabelChips = (props: CaseLabelChipsProps) => {
    const {
        labels,
        className,
        chipClass,
        isAddLabelButtonVisible,
        color,
        disableTooltip,
        addLabelClass,
        intercomTargetProp,
        maxLabelsToShow,
        labelsCountChipClass,
        onClick,
    } = props;
    const classes = useStyles();

    const maxLabels = maxLabelsToShow ?? labels.length;
    const countLabelsNotShown = labels.length - maxLabels;
    const showAddLabelButton = isAddLabelButtonVisible && labels.length > 0;
    const invertedColor = color === 'invert';

    return (
        <div className={classNames(className, classes.root)}>
            {labels.slice(0, maxLabels).map((label, idx) => (
                <Tooltip key={label.id} title={disableTooltip ?? label.name} placement="top">
                    <Chip
                        key={label.id}
                        label={label.name}
                        className={classNames(
                            classes.chip,
                            classes.label,
                            chipClass,
                            props.maxLabelsToShow && props.maxLabelsToShow > 3 && classes.fixedWidth,
                        )}
                        style={{
                            backgroundColor: label.color,
                            border: invertedColor ? '1px solid #fff' : undefined,
                        }}
                        onClick={disableTooltip ? undefined : () => onClick(label)}
                    />
                </Tooltip>
            ))}

            {countLabelsNotShown > 0 && (
                <Tooltip
                    placement="top"
                    title={`${countLabelsNotShown} additional label${countLabelsNotShown === 1 ? '' : 's'} has been 
                    applied that isn't showing. Click to view or edit.`}
                >
                    <Chip
                        label={`+${countLabelsNotShown}`}
                        className={classNames(classes.label, classes.numberLabelChip, labelsCountChipClass)}
                        classes={{ label: GStyles.flexCentred }}
                        onClick={() => onClick()}
                    />
                </Tooltip>
            )}

            {!showAddLabelButton && (
                <Tooltip title={disableTooltip ?? ''} placement="top">
                    <Chip
                        label={
                            <>
                                <AddIcon />
                                Label
                            </>
                        }
                        className={classNames(
                            classes.labelChip,
                            invertedColor && classes.invertedColor,
                            chipClass,
                            addLabelClass,
                        )}
                        classes={{ label: GStyles.flexCentred }}
                        onClick={disableTooltip ? undefined : () => onClick()}
                        {...getIntercomTargetProp(intercomTargetProp)}
                    />
                </Tooltip>
            )}
        </div>
    );
};

export default CaseLabelChips;
