import React from 'react';
import { NavigateOptions, useNavigate } from 'react-router-dom';
import { log } from '../../../logger';
import { AppRoute, buildPathFromRoute, buildURLString, PathType } from "../../../services";

export interface GNavigateOptions extends NavigateOptions {
    openInNewTab?: boolean;
}

export interface GNavigateFunction {
    (to: AppRoute, options?: GNavigateOptions): void;
    (delta: number): void;
}

export function useGNavigate() {
    const navigate = useNavigate();
    const gNavigate: GNavigateFunction = React.useCallback((to: AppRoute | number, options?: GNavigateOptions) => {
        if (typeof to === "number") {
            navigate(to);
            return;
        }

        const path = buildPathFromRoute(to);
        switch (path.type) {
            case PathType.DISABLED:
                break;
            case PathType.ABSOLUTE:
                const urlString = buildURLString(path.urlParts);
                if (options?.openInNewTab) {
                    window.open(urlString, '_blank');
                } else {
                    window.location.href = urlString;
                }
                break;
            case PathType.RELATIVE:
                navigate(buildURLString(path.urlParts), options);
                break;
            default:
                log.warn('Invalid PathType', { path, to });
        }
    }, [navigate]);
    return gNavigate;
}
