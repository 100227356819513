import { Component } from 'react';
import classNames from 'classnames';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import AddIcon from '@mui/icons-material/Add';
import { ProductCategory } from '../../../shared/types';
import { StoreState } from '../../../types';
import { cloneGlobalProduct } from '../../../actions/product/FHProduct.action';
import { loadGlobalProducts } from '../../../actions/product/GlobalProduct.action';
import withGStyles, { WithGStyles } from '../../../styles/WithGStyles';
import { StyleRulesCallback } from '@mui/styles/withStyles';
import { Theme } from '@mui/material';
import withState from '../../common/utilHOC/WithState';
import { AppDispatch } from '../../../store';
import AllProductCategoriesInterimDialog from '../../allProducts/AllProductCategoryinterimDialog';

function mapStateToProps({
    productState,
    userSession,
    productSupplierState,
    funeralHomeState,
}: StoreState) {
    const { globalProducts } = productState;
    return {
        globalProducts: globalProducts.data,
        hasMoreGlobalProducts: globalProducts.hasMoreData,
        isGlobalProductsLoading: globalProducts.isLoading,
        isLoading: productState.isFHProductsLoading,
        categoryProducts: productState.funeralHomeProducts,
        user: userSession.userData,
        funeralHomeSuppliers: productSupplierState.funeralHomeSuppliers,
        activeFuneralHome: funeralHomeState.activeFuneralHome,
    };
}

interface Props extends ReturnType<typeof mapStateToProps> {
    dispatch: AppDispatch;
    category: ProductCategory;
    handleCreateNewProductClick: () => void;
    footerClass?: string;
    zIndex: number;
}

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {},
    footer: {
        justifyContent: 'center',
        flex: '0 0 auto',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '@media (min-width: 600px)': {
            flexDirection: 'row',
        },
    },

});

type CombinedProps = Props & WithGStyles<'root' | 'footer'>;

interface State {
    isAllProductCategoryDialogOpen: boolean;
    selectedCategory: ProductCategory | null;
}

class AddProductButtons extends Component<CombinedProps, State> {

    state: State = {
        isAllProductCategoryDialogOpen: false,
        selectedCategory: null,
    };

    renderAllProductCategoriesInterimDialog = () => {
        const { isAllProductCategoryDialogOpen, selectedCategory } = this.state;
        const { globalProducts, categoryProducts } = this.props;
        return (
            <AllProductCategoriesInterimDialog
                selectedCategory={selectedCategory}
                isDialogOpen={isAllProductCategoryDialogOpen}
                zIndex={1320}
                closeDialog={this.closeAllProductCateoriesInterimDialog}
                availableProducts={globalProducts}
                selectedProducts={categoryProducts}
                onCreateNewProductClick={this.handleCreateNewProductClick}
                onLoadProducts={this.handleLoadGlobalProducts}
                onProductAdd={this.handleAddGlobalProduct}
                onSelectedCategoryChange={this.handleSelectedCategory}
            />
        );
    };

    render() {
        const {
            classes,
            handleCreateNewProductClick,
            footerClass,
        } = this.props;

        return (
            <>
                <Grid item className={classNames(classes.footer, footerClass)}>
                    <Button
                        size="large"
                        variant="contained"
                        color="primary"
                        className={classes.margin_8}
                        onClick={e => handleCreateNewProductClick()}
                    >
                        <AddIcon />&nbsp;CREATE NEW
                    </Button>
                    <Button
                        size="large"
                        variant="contained"
                        color="primary"
                        className={classes.margin_8}
                        onClick={e => this.openAllProductCateoriesInterimDialog()}
                    >
                        <PlaylistAddIcon />&nbsp;SELECT FROM GLOBAL CATALOG
                    </Button>
                </Grid>

                {this.renderAllProductCategoriesInterimDialog()}
            </>
        );
    }

    handleSelectedCategory = (c: ProductCategory) => {
        this.setState({
            selectedCategory: c
        });
    };

    handleCreateNewProductClick = () => {
        const { handleCreateNewProductClick } = this.props;
        handleCreateNewProductClick();
    };

    openAllProductCateoriesInterimDialog = async () => {
        this.setState({
            isAllProductCategoryDialogOpen: true,
        });
    };

    closeAllProductCateoriesInterimDialog = () => {
        this.setState({
            selectedCategory: null,
            isAllProductCategoryDialogOpen: false,
        });
    };

    handleAddGlobalProduct = async (productId: number) => {
        const { dispatch, activeFuneralHome } = this.props;
        if (!activeFuneralHome) {
            return;
        }
        await dispatch(cloneGlobalProduct(productId, activeFuneralHome.id));
    };

    handleLoadGlobalProducts = async (offset: number, searchCategory: string) => {
        const { dispatch, category } = this.props;
        const { selectedCategory } = this.state;

        const _category = selectedCategory !== null ? selectedCategory : category;
        await dispatch(loadGlobalProducts(_category, offset, searchCategory, 'name', 'asc'));
    };
}

export default withState(mapStateToProps)(withGStyles(styles)(AddProductButtons));