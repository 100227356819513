import { StoreState, AllowedAcceptTypes } from '../types';
import * as constants from '../constants';
import {
    GatherUserAction,
    getAuthToken,
    logoutUserSession,
    switchUserSession,
} from './UserSession.action';
import { FuneralHomeAction } from './FuneralHome.action';
import { CasePreviewAction, CaseTableAction, GatherCaseAction } from './GatherCase.action';
import { DeepLinkUX } from '../shared/types';
import { TaskAction } from './task/Task.action';
import {
    AppError,
    UnauthorizedError,
    AccessRestrictedError,
    NotFoundError,
    ConflictError,
    isErrorType,
    handleException,
} from './errors';
import { internetStatusChanged, AppAction } from './App';
import moment from 'moment';
import { downloadFromURL } from '../services/doc.service';
import { AlbumAction } from './Album.action';
import { PhotoAction } from './Photo.action';
import { TeamAction } from './Team.action';
import { LiveStreamAction } from './LiveStream.action';
import { FHProductAction } from './product/FHProduct.action';
import { ContractAction } from './product/Contract.action';
import { GlobalProductAction } from './product/GlobalProduct.action';
import { TaskTemplateAction } from './task/TaskTemplate.action';
import { WorkflowAction } from './Workflow.action';
import { VideoAction } from './video.action';
import { DialogAction, openAppOutdatedDialog } from './Dialog.action';
import { DocPacketTableAction } from './DocPacketTable.action';
import { InviteHelperAction } from './CaseHelper.action';
import { FinanceAction } from './Finance.action';
import { DocPacketAction } from './DocPacket.action';
import { VisitorAction } from './visitor.action';
import { DeathCertificateConfigAction } from './DeathCertificateConfig.action';
import { isValidISODateString } from '../shared/utils';
import { ObituaryLinkAction } from './ObituaryLink.action';
import { ModerationAction } from './Moderation.action';
import { MemoryAction } from './Memory.action';
import { AppDispatch } from '../store';
import { RolodexAction } from './Rolodex.action';
import { AppSnackbarAction } from './AppSnackbar.action';
import { AccessRestrictedAction } from './AccessRestricted.action';
import { DocAction } from './Doc.action';
import { FlowerSalesAction } from './FlowerSales.action';
import { GatherEventAction } from './GatherEvent.action';
import { LocationAction } from './Location.action';
import { NoteAction } from './Note.action';
import { PersonTableAction } from './Person.action';
import { PhotoSwipeAction } from './PhotoSwipe.action';
import { ProductSupplierAction } from './product/ProductSupplier.action';
import { ServiceDetailAction } from './ServiceDetail.action';
import { ConfettiAction } from './confetti.action';
import { CaseLabelAction } from './CaseLabel.action';
import { FingerprintAction } from './Fingerprints.action';
import { BelongingAction } from './Belongings.action';
import { TaskLocationAction } from './TaskLocation.action';
import { WhiteboardAction } from './Whiteboard.action';
import { CaseS3Action } from './CaseIdPhotos.action';
import { TaskComponentAction } from './task/TaskComponent.action';
import { KeepTrackAction } from './KeepTrack.action';
import { isRememberPage } from '../services';
import { WebsiteAction } from './Website.action';
import { KeepTrackReportAction } from "./KeepTrackReport.action";
import { InsurancePolicyAction } from './Insurance.action';

const apiBasePath = process.env.REACT_APP_API_BASE_PATH || 'https://api.gatherqa.app';
const authHeader = 'X-Api-Key';

type FetchMethod = 'PUT' | 'POST' | 'PATCH' | 'GET' | 'DELETE' | 'DOWNLOAD';

async function initiateDownload(response: Response, fileName?: string) {
    const url = window.URL.createObjectURL(await response.blob());
    let defaultFileName = 'download.csv';
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const disposition = response.headers.get('content-disposition') || '';
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
        defaultFileName = matches[1].replace(/['"]/g, '');
    }
    downloadFromURL(url, fileName ?? defaultFileName);
    window.URL.revokeObjectURL(url);
}

function _jsonDateParser(this: unknown, key: string, value: unknown): unknown {
    if (typeof value === 'string' && key.endsWith('_time') && value) {
        const isValidDateString = isValidISODateString(value);
        if (isValidDateString) {
            return new Date(value);
        }
        return value;
    }
    return value;
}

export async function parseJSON(response: Response) {
    let body: string = '';
    try {
        body = await response.text();
        // Trying to parse as json may fail...
        return await JSON.parse(body, _jsonDateParser);
    } catch (ex) {
        if (typeof ex === 'string') {
            throw new AppError('Unable to parse response body as JSON', response.status, ex, {
                response: {
                    body,
                    status: response.status,
                    statusText: response.statusText,
                    ok: response.ok,
                    redirected: response.redirected,
                    type: response.type,
                    headers: response.headers,
                },
            });
        }
    }
}

async function parseFetchResponse(response: Response, method: FetchMethod, fileName?: string) {
    if (!response.ok) {
        const error: AppError = await parseJSON(response);
        switch (response.status) {
            case 401:
                throw new UnauthorizedError(error.message, { responseBody: error });
            case 403:
                throw new AccessRestrictedError(error.message, { responseBody: error });
            case 404:
                throw new NotFoundError(error.message, { responseBody: error });
            case 409:
                throw new ConflictError(error.message, { responseBody: error });
            default:
                throw new AppError(error.message, response.status, response.statusText, { responseBody: error });
        }
    } else {
        if (method === 'DOWNLOAD') {
            initiateDownload(response, fileName);
        } else {
            return await parseJSON(response);
        }
    }
}

let apiBuildTime: Date | undefined;
export function getApiBuildTime() {
    return apiBuildTime;
}
export function getReactBuildTime() {
    const { REACT_APP_BUILD_TIME } = process.env;
    // For Testing: to replicate what a real value would be
    // const REACT_APP_BUILD_TIME = Math.floor(new Date('2012.08.10').getTime() / 1000).toString();

    if (REACT_APP_BUILD_TIME) {
        return moment(parseInt(REACT_APP_BUILD_TIME, 10) * 1000);
    } else {
        return null;
    }
}

function checkResponse(
    response: Response,
    dispatch: AppDispatch,
): void {
    // check 503
    if (response.status === 503) {
        window.location.href = `https://downtime.gather.app`;
    }

    // check if app version is outdated

    const reactBuildTime = getReactBuildTime();
    if (reactBuildTime) {
        const apiTimestamp = response.headers.get('X-GATHER-API-TIMESTAMP');
        // For Testing: to replicate what a real value would be
        // const apiTimestamp: string | undefined = 'Wed, 21 Jun 2022 22:00:07 GMT';

        const apiTimestampDate = apiTimestamp ? new Date(apiTimestamp) : undefined;
        apiBuildTime = apiTimestampDate;

        if (apiTimestampDate) {
            // if timestamps are within a grace period of each other then consider them equal
            const apiTimeWithGracePeriod = moment(apiTimestampDate).subtract(30, 'minutes');
            const isUIOutdated = apiTimeWithGracePeriod.isAfter(reactBuildTime);
            if (isUIOutdated) {
                const isNextDay = moment().isAfter(apiTimeWithGracePeriod, 'day');
                // only show the AppOutdated dialog if:
                // 1. The user isn't on a remember page OR
                // 2. It is now the next day (local time) after a release
                if (!isRememberPage() || isNextDay) {
                    dispatch(openAppOutdatedDialog());
                }
            }
        }
    }

    // check authentication
    if (response.status === 401) {
        // WARNING: never pass globalLogout = true (and never leave it out) when calling logoutUserSession from a 401
        //  Or you'll get into an endless cylcle (trying to log out when unauthenticated returns another 401...)
        dispatch(logoutUserSession(false));
    }
}

function buildRequest(
    resource: string,
    method: FetchMethod,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: Record<string, any> | null,
    headersOverride?: Record<string, string>,
    rawBody?: FormData,
): RequestInit {

    // body-parser tries to interpret no Content-Type as json, so send text/plain if there is none
    const defaultHeaders: HeadersInit = {
        Accept: AllowedAcceptTypes.application_json,
        'Content-Type': data ? 'application/json' : 'text/plain',
    };

    // If rawBody is provided, do not set 'Content-Type' manually, let the browser do it
    if (rawBody) {
        delete defaultHeaders['Content-Type'];
    }

    defaultHeaders[authHeader] = getAuthToken();

    const headers: HeadersInit = {
        ...defaultHeaders,
        ...headersOverride,
    };

    // Set up the request with headers and method
    const request: RequestInit = {
        headers,
        method: method === 'DOWNLOAD'
            ? data === null ? 'GET' : 'POST'
            : method
    };

    if (rawBody) {
        request.body = rawBody;
    } else if (data) {
        // Send JSON data as body if set
        request.body = JSON.stringify(data);
    }

    return request;
}

async function sendRequest(
    resource: string,
    request: RequestInit,
    dispatch: AppDispatch,
): Promise<Response | null> {

    const startTime = Date.now();
    try {
        const response: Response = await fetch(`${apiBasePath}/${resource}`, request);
        checkResponse(response, dispatch);
        dispatch(internetStatusChanged({ hasInternet: true }));
        return response;
    } catch (ex) {
        const failTime = Date.now();
        const requestTime = Math.floor((failTime - startTime) / 1000);
        const metaData = {
            request: {
                ...request,
                resource,
            },
        };
        const isTimeout = requestTime >= 6;
        const prefix = isTimeout ? 'Fetch Error (timeout)' : 'Fetch Error';

        // if request timed out don't assume "No Internet"
        if (!isTimeout) {
            dispatch(internetStatusChanged({ hasInternet: false }));
        }
        dispatch(
            handleException({
                ex,
                prefix,
                metadata: metaData,
                showSnackbar: false,
                sendToSentry: isTimeout,
            })
        );
        return null;
    }
}

async function sendToAPI<T>(
    resource: string,
    method: FetchMethod,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: Record<string, any> | null,
    dispatch: AppDispatch,
    headersOverride?: Record<string, string>,
    rethrowExceptions?: boolean,
    fileName?: string,
): Promise<T | null> {

    const request: RequestInit = buildRequest(resource, method, data, headersOverride);

    const response: Response | null = await sendRequest(resource, request, dispatch);
    if (!response) {
        return null;
    }

    try {
        return await parseFetchResponse(response, method, fileName);
    } catch (ex) {

        const metadata = {
            ...(isErrorType(ex) ? ex.metaData : {}),
            request: {
                ...request,
                resource,
            },
        };
        dispatch(
            handleException({
                ex,
                prefix: 'API Error',
                showSnackbar: false,
                sendToSentry: isErrorType(ex) && ex.sendToSentry !== undefined ? ex.sendToSentry : true,
                metadata,
            })
        );
        if (rethrowExceptions) {
            throw ex;
        }
        return null;
    }
}

export async function advancedAPIRequest(
    resource: string,
    method: FetchMethod,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: Record<string, any>,
    dispatch: AppDispatch,
    headersOverride?: Record<string, string>,
    rawBody?: FormData,
): Promise<Response | null> {

    const request: RequestInit = buildRequest(resource, method, data, headersOverride, rawBody);

    return await sendRequest(resource, request, dispatch);
}

export async function getFromAPI<T>(
    resource: string = '',
    dispatch: AppDispatch,
): Promise<T | null> {
    return await sendToAPI<T>(resource, 'GET', null, dispatch);
}

export async function downloadFromAPI<T>(
    resource: string = '',
    dispatch: AppDispatch,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: Record<string, any> | null = null,
    acceptType: AllowedAcceptTypes = AllowedAcceptTypes.text_csv,
    rethrowExceptions: boolean = false,
    fileName?: string,
): Promise<T | null> {
    return await sendToAPI<T>(
        resource,
        'DOWNLOAD',
        data,
        dispatch,
        { Accept: acceptType },
        rethrowExceptions,
        fileName
    );
}

export async function deleteFromAPI<T>(
    resource: string = '',
    dispatch: AppDispatch,
): Promise<T | null> {
    return await sendToAPI<T>(resource, 'DELETE', null, dispatch);
}

export async function postToAPI<T>(
    resource: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: Record<string, any>,
    dispatch: AppDispatch
): Promise<T | null> {
    return await sendToAPI<T>(resource, 'POST', data, dispatch);
}

export async function putToAPI<T>(
    resource: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: Record<string, any>,
    dispatch: AppDispatch
): Promise<T | null> {
    return await sendToAPI<T>(resource, 'PUT', data, dispatch);
}

export async function patchAPI<T>(
    resource: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: Record<string, any>,
    dispatch: AppDispatch
): Promise<T | null> {
    return await sendToAPI<T>(resource, 'PATCH', data, dispatch);
}

interface SetDeepLinkLoading {
    type: constants.SET_DEEP_LINK_LOADING;
    isLoading: boolean;
}

function setDeepLinkLoading(isLoading: boolean): SetDeepLinkLoading {
    return {
        type: constants.SET_DEEP_LINK_LOADING,
        isLoading,
    };
}

interface SetDeepLinkInviationStatus {
    type: constants.SET_DEEP_LINK_INVITATION_STATUS;
    isInvitationAccepted: boolean;
}

export function setDeepLinkInviationStatus(isInvitationAccepted: boolean): SetDeepLinkInviationStatus {
    return {
        type: constants.SET_DEEP_LINK_INVITATION_STATUS,
        isInvitationAccepted,
    };
}

// Need to export this so the user session reducer can reference it
export interface SetDeepLinkValue {
    type: constants.SET_DEEP_LINK_VALUE;
    deepLink: DeepLinkUX | null;
}

function setDeepLinkValue(deepLink: DeepLinkUX | null): SetDeepLinkValue {
    return {
        type: constants.SET_DEEP_LINK_VALUE,
        deepLink,
    };
}

export function evaluateDeepLink(text: string) {
    return async (dispatch: AppDispatch, getState: () => StoreState) => {
        dispatch(setDeepLinkLoading(true));
        const link = await getFromAPI<DeepLinkUX>(`link/${text}`, dispatch);
        if (link) {
            const { userSession } = getState();
            const { userData } = userSession;
            if (userData && link.userId !== null && userData.user_id !== link.userId) {
                // if the logged in user is different from the deep link user then switch to the new user
                await dispatch(switchUserSession());
            }
        }
        dispatch(setDeepLinkValue(link)); // resets deeplink loading state
    };
}

export type DeepLinkAction = SetDeepLinkLoading | SetDeepLinkValue
    | SetDeepLinkInviationStatus;

// TODO - move the template stuff into its own action file
interface ClearTemplate {
    type: constants.CLEAR_TEMPLATE;
}

export function clearTemplate(): ClearTemplate {
    return {
        type: constants.CLEAR_TEMPLATE
    };
}

export enum TopLevelRoute {
    gatherAdmin = 'gatherAdmin',
    fhDashboard = 'fhDashboard',
    family = 'family',
    remember = 'remember',
}

export enum TopLevelSubRoute {
    familyObituary = 'familyObituary'
}

export const REFRESH_APP_THEME = 'REFRESH_APP_THEME';
interface RefreshAppTheme {
    type: typeof REFRESH_APP_THEME;
    topLevelRoute: TopLevelRoute | TopLevelSubRoute;
}

export function refreshAppTheme(topLevelRoute: TopLevelRoute | TopLevelSubRoute): RefreshAppTheme {
    return {
        type: REFRESH_APP_THEME,
        topLevelRoute,
    };
}

export function shareAppData(params: {
    dataType: string;
    data: object[] | null;
}) {
    const { dataType, data } = params;
    if (data && data.length) {
        // Only dispatch this event if the local storage GATHER-DATA-XFER is set to REVENUEREPORT
        // This the way that the puppeteer hook indicates that it wants the event to be dispatched
        if (localStorage.getItem('GATHER-DATA-XFER') === dataType) {
            // console.log('Dispatching gatherDataReady event', revenueReport.length);
            window.dispatchEvent(new CustomEvent('gatherDataReady', {
                detail: {
                    dataType,
                    rowCount: data.length,
                    data,
                }
            }));
        }
    }
}

export type GatherTemplateAction = ClearTemplate;

export type GatherAction =
    | GatherUserAction
    | GatherTemplateAction
    | FuneralHomeAction
    | GatherCaseAction
    | DeepLinkAction
    | TaskAction
    | TeamAction
    | AlbumAction
    | PhotoAction
    | LiveStreamAction
    | VideoAction
    | GlobalProductAction
    | FHProductAction
    | ContractAction
    | TaskTemplateAction
    | WorkflowAction
    | DialogAction
    | DocPacketTableAction
    | InviteHelperAction
    | FinanceAction
    | DocPacketAction
    | VisitorAction
    | DeathCertificateConfigAction
    | ObituaryLinkAction
    | MemoryAction
    | ModerationAction
    | RolodexAction
    | AppAction
    | AppSnackbarAction
    | AccessRestrictedAction
    | DocAction
    | FlowerSalesAction
    | CaseTableAction
    | CasePreviewAction
    | GatherEventAction
    | LocationAction
    | NoteAction
    | PersonTableAction
    | PhotoSwipeAction
    | ProductSupplierAction
    | ServiceDetailAction
    | ConfettiAction
    | CaseLabelAction
    | FingerprintAction
    | BelongingAction
    | TaskLocationAction
    | WhiteboardAction
    | CaseS3Action
    | TaskComponentAction
    | RefreshAppTheme
    | KeepTrackAction
    | KeepTrackReportAction
    | WebsiteAction
    | InsurancePolicyAction
    ;
