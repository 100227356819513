import { GridRowId } from '@mui/x-data-grid-premium';
import { ProductCategory, ProductSummary, ProductUX } from '../../shared/types';
import { useEffect, useState } from 'react';
import { setSnackbarSuccess } from '../../actions/AppSnackbar.action';
import { useGDispatch } from '../../types';
import { GDataGridTableColumn } from '../common/GDataGrid/types';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import BaseProductSummaryGrid from './BaseProductSummaryGrid';

interface Props {
    category: ProductCategory;
    availableProducts: ProductSummary[];
    selectedProducts: ProductUX[];
    onCreateNewProductClick: (productName?: string) => void;
    onLoadProducts: (offset: number, searchText: string) => Promise<void>;
    onProductAdd: (id: number) => Promise<void>;
}

const GlobalCatalogProductContents = (props: Props) => {
    const { onLoadProducts, category, availableProducts, onProductAdd, selectedProducts } = props;

    const [productIdsSaving, setProductIdsSaving] = useState<number[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    const dispatch = useGDispatch();

    useEffect(() => {
        const loadProducts = async () => {
            setIsLoading(true);
            await onLoadProducts(0, '');
            setIsLoading(false);
        };
        loadProducts();
    }, [category, onLoadProducts]);

    const columns: GDataGridTableColumn<ProductSummary & { row_id: number }>[] = [
        {
            field: 'Actions',
            headerName: '',
            renderCell: ({ row, id }) => {
                const alreadyAdded = !!selectedProducts.find((selected) =>
                    selected.cloned_from === id
                );
                return (
                    alreadyAdded ?
                        <CheckIcon
                            sx={{
                                margin: 'auto',
                                color: theme => theme.palette.primary.main
                            }}
                        />
                        :
                        <AddIcon
                            sx={{
                                cursor: 'pointer',
                                margin: 'auto',
                                color: theme => theme.palette.primary.main
                            }}
                            onClick={() => handleImportSelectedRow(id)}
                        />
                );
            },
            disableReorder: true,
            filterable: false,
            disableColumnMenu: true,
            sortable: false,
            minWidth: 50,
            width: 50,
        },
    ];

    const handleAddClick = async (e: React.MouseEvent<HTMLElement> | undefined, product: ProductSummary) => {
        if (e) {
            e.stopPropagation();
            e.preventDefault();
        }

        setProductIdsSaving((prevState) => [...prevState, product.id]);

        await onProductAdd(product.id);

        setProductIdsSaving((prevState) => [...prevState, ...productIdsSaving.filter((id) => id !== product.id)]);
    };

    const handleImportSelectedRow = async (row: GridRowId) => {
        const product = availableProducts.find((p) => p.id === row);
        if (!product) {
            return;
        }
        await handleAddClick(undefined, product);

        dispatch(setSnackbarSuccess(`1 product has been imported.`, undefined, 2000));
    };

    return (
        <BaseProductSummaryGrid
            actionColumn={columns}
            availableProducts={availableProducts}
            isLoading={isLoading}
        />
    );
};

export default GlobalCatalogProductContents;
