import { style } from 'typestyle';
import { createTheme, darken, lighten, PaletteColorOptions, Theme } from '@mui/material/styles';
import { APP_SECONDARY_COLOR } from '../constants/colorVariables';

import { convertHexToRGBA } from '../services/app.service';
import { HELPER_STYLED_PROPS } from './Helper.styles';
import { TYPOGRAPHY_STYLED_PROPS } from './Typography.styles';
import { APP_STYLED_PROPS } from './App.styles';
import { MARGIN_STYLED_PROPS } from './Margin.styles';
import { PADDING_STYLED_PROPS } from './Padding.styles';
import { ThemeUX } from '../shared/types';

export type GLOBAL_STYLED_PROPS = HELPER_STYLED_PROPS &
    TYPOGRAPHY_STYLED_PROPS &
    APP_STYLED_PROPS &
    MARGIN_STYLED_PROPS &
    PADDING_STYLED_PROPS;

declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        rememberPrimary: true;
        rememberSecondary: true;
    }
}

declare module '@mui/material/SvgIcon' {
    interface SvgIconPropsColorOverrides {
        rememberPrimary: true;
        rememberSecondary: true;
    }
}

declare module '@mui/material/IconButton' {
    interface IconButtonPropsColorOverrides {
        rememberPrimary: true;
        rememberSecondary: true;
    }
}

declare module '@mui/material/TextField' {
    interface TextFieldPropsColorOverrides {
        rememberPrimary: true;
        rememberSecondary: true;
    }
}

declare module '@mui/material/ToggleButtonGroup' {
    interface ToggleButtonGroupPropsColorOverrides {
        rememberPrimary: true;
        rememberSecondary: true;
    }
}

declare module '@mui/material/Slider' {
    interface SliderPropsColorOverrides {
        rememberPrimary: true;
        rememberSecondary: true;
    }
}

declare module '@mui/material/Chip' {
    interface ChipPropsColorOverrides {
        rememberPrimary: true;
        rememberSecondary: true;
    }
}

declare module '@mui/material/Checkbox' {
    interface CheckboxPropsColorOverrides {
        rememberPrimary: true;
        rememberSecondary: true;
    }
}
declare module '@mui/material/styles' {
    interface Palette {
        rememberPrimary: Palette['primary'];
        rememberSecondary: Palette['primary'];
    }

    interface PaletteOptions {
        rememberPrimary: PaletteColorOptions;
        rememberSecondary: PaletteColorOptions;
    }
}
const memoizedCreateTheme = (themeColor: string, rememberTheme: ThemeUX | null) => createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1200,
            xl: 1536,
        },
    },
    components: {
        MuiAvatar: {
            styleOverrides: {
                root: {
                    WebkitBorderRadius: '50%',
                    MozBorderRadius: '50%',
                    borderRadius: '50%',
                    '& img': {
                        WebkitBorderRadius: '50%',
                        MozBorderRadius: '50%',
                        borderRadius: '50%',
                    },
                },
                fallback: {
                    display: 'none',
                },
            },
        },
        MuiBadge: {
            styleOverrides: {
                badge: {
                    lineHeight: 'unset',
                    padding: '0px 4px',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                sizeLarge: {
                    minHeight: 40,
                    minWidth: 112,
                },
                sizeSmall: {
                    padding: '7px 8px',
                    minHeight: 30,
                },
                root: {
                    minHeight: 36,
                    lineHeight: '1.2em',
                    letterSpacing: 'normal',
                },
                containedPrimary: {
                    color: '#fff',
                },
                outlined: {
                    padding: '8px 16px',
                },
                text: {
                    padding: '8px 16px',
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                container: {
                    '&:after': {
                        content: 'unset',
                    },
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    fontWeight: 400,
                    lineHeight: '1.16667em',
                },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    backgroundColor: 'rgba(0, 0, 0, 0.12)',
                },
            },
        },
        MuiFab: {
            styleOverrides: {
                root: {
                    color: '#fff !important',
                },

                primary: {
                    color: '#fff !important',
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                focused: {},
                root: {
                    '&$focused': {
                        color: themeColor,
                    },
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    padding: 0,
                    width: 48,
                    height: 48,
                },
                colorInherit: {
                    color: '#fff',
                },
            },
        },
        MuiInput: {
            styleOverrides: {
                focused: {},
                disabled: {},
                error: {},
                underline: {
                    '&$focused:after': {
                        borderBottomColor: themeColor,
                    },
                    '&:hover:not($disabled):not($focused):not($error):before': {
                        borderBottomColor: themeColor,
                    },
                },
            },
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    paddingTop: 11,
                    paddingBottom: 11,
                },
                gutters: {
                    '@media (min-width: 600px)': {
                        paddingLeft: 24,
                        paddingRight: 24,
                    },
                },
            },
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    minWidth: 'unset',
                    marginRight: '16px',
                },
            },
        },
        MuiListItemSecondaryAction: {
            styleOverrides: {
                root: {
                    right: 4,
                },
            },
        },
        MuiListItemText: {
            styleOverrides: {
                root: {
                    padding: '0 16px',
                    marginTop: 'unset',
                    marginBottom: 'unset',
                    '&:first-of-type': {
                        paddingLeft: 0,
                    }
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    paddingTop: 11,
                    paddingBottom: 11,
                },
            },
        },
        MuiSelect: {
            defaultProps: {
                variant: 'standard',
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    lineHeight: 1,
                    '@media (min-width: 960px)': {
                        fontSize: '0.8125rem',
                    },
                    '&:hover': {
                        color: themeColor,
                        opacity: 1,
                    },
                    "&:disabled": {
                        color: 'rgba(0, 0, 0, 0.38) !important',
                    },
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                scrollableX: {
                    scrollbarWidth: 'none',
                    msOverflowStyle: 'none',
                    '&::-webkit-scrollbar': {
                        width: '0 !important',
                    },
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    lineHeight: 'unset',
                    fontSize: '0.8125rem',
                    padding: '4px 56px 4px 24px',
                },
                head: {
                    fontSize: '0.75rem',
                },
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    height: 48,
                },
                head: {
                    height: 56,
                },
            },
        },
        MuiTextField: {
            defaultProps: {
                variant: 'standard',
            },
        },
        MuiTypography: {
            defaultProps: {
                variant: 'body2',
            },
            styleOverrides: {
                root: {
                    letterSpacing: 'normal',
                    color: 'rgba(0, 0, 0, 0.87)',
                },
                caption: {
                    lineHeight: '1.375em',
                    fontWeight: 200,
                    letterSpacing: 'normal',
                },
                body1: {
                    letterSpacing: 'normal',
                },
                body2: {
                    lineHeight: '1.46429em',
                    letterSpacing: 'normal',
                },
                subtitle1: {
                    lineHeight: '1.5em',
                    letterSpacing: 'normal',
                },
                h6: {
                    lineHeight: '1.5em',
                    letterSpacing: 'normal',
                },
            },
        },
        MuiTooltip: {
            defaultProps: {
                enterDelay: 400,
                enterNextDelay: 400,
            },
        },
    },
    palette: {
        secondary: {
            main: APP_SECONDARY_COLOR,
        },
        primary: {
            main: themeColor,
        },
        rememberPrimary: {
            main: rememberTheme?.primary_color ?? themeColor,
            contrastText: '#fff',
            light: lighten(rememberTheme?.primary_color ?? themeColor, 0.2),
            dark: darken(rememberTheme?.primary_color ?? themeColor, 0.2)
        },
        rememberSecondary: {
            contrastText: '#fff',
            main: rememberTheme?.secondary_color ?? themeColor,
            light: lighten(rememberTheme?.secondary_color ?? themeColor, 0.2),
            dark: darken(rememberTheme?.secondary_color ?? themeColor, 0.2)
        }
    },
});

export class AppTheme {
    public static getMuiTheme = (themeColor: string, rememberTheme: ThemeUX | null): Theme => {
        return memoizedCreateTheme(themeColor, rememberTheme);
    };

    public static getThemeColor(theme: Theme): string {
        return theme.palette.primary.main;
    }

    // TODO remove
    public static buttonOutline(themeColor: string, color?: string): Object {
        return style({
            color: color ? color : themeColor,
            background: '#fff',
            border: '1px solid',
            borderColor: themeColor,
            boxShadow: 'none',
            $nest: {
                '&:hover': {
                    backgroundColor: convertHexToRGBA(themeColor, 0.08),
                },
            },
        });
    }
}
