import { isEqual, uniq } from 'lodash';
import {
    ProductState,
    ProductSupplierState,
} from '../types';
import {
    ProductContractUX,
    ContractOptionsUX,
    ProductTaxRateUX,
    ProductCategoryEnum,
} from '../shared/types';
import {
    UPDATE_FUNERAL_HOME_FEATURED_PRODUCTS,
    FUNERAL_HOME_PRODUCTS_LOADING,
    FUNERAL_HOME_PRODUCTS_LOADED,
    FUNERAL_HOME_PRODUCT_SUMMARIES_LOADING,
    FUNERAL_HOME_PRODUCT_SUMMARIES_LOADED,
    SET_FUNERAL_HOME_PRODUCT,
    UPDATE_FUNERAL_HOME_PRODUCT,
    REMOVE_FUNERAL_HOME_PRODUCT,
    SET_PRODUCT_MANUFACTURERS,
    SET_PRODUCT_MANUFACTURER,
    SET_PRODUCT_TAGS,
    ADD_PRODUCT_TAG,
    REMOVE_PRODUCT_TAG,
    SET_PRODUCT_TAX_RATES,
    SET_PRODUCT_TAX_RATE,
    SET_PRODUCT_PACKAGES,
    REMOVE_PRODUCT_PACKAGE,
    SET_CONTRACT_OPTIONS,
    SET_CONTRACT_DISCLAIMERS,
    ADD_FUNERAL_HOME_PRODUCT,
    UPDATED_FUNERAL_HOME_CATEGORY_PRODUCTS,
} from '../actions/product/FHProduct.action';
import {
    ADD_PACKAGE_TO_CONTRACT,
    REMOVE_PACKAGE_FROM_CONTRACT,
    SET_PRODUCT_CONTRACTS,
    SET_PRODUCT_ACTIVE_CONTRACT,
    SET_PRODUCT_ACTIVE_CONTRACT_SUMMARY,
    SET_PRODUCT_CONTRACT_LOADING,
    ADD_PRODUCT_CONTRACT_ITEM,
    ADD_PRODUCT_CUSTOM_CONTRACT_ITEM,
    UPDATE_PRODUCT_CONTRACT_ITEM,
    REMOVE_PRODUCT_CONTRACT_ITEM,
    SET_PRODUCT_CONTRACT_VIEWERS,
    ADD_PRODUCT_CONTRACT_DISCOUNT_ITEM,
    SET_PRODUCT_ACTIVE_CONTRACT_FROZEN_STATE,
    UPDATE_CONTRACT_DISCLAIMER_REASON,
    UPDATE_PRODUCT_ACTIVE_CONTRACT,
} from '../actions/product/Contract.action';
import {
    GLOBAL_PRODUCTS_LOADING,
    GLOBAL_PRODUCTS_LOADED,
    GLOBAL_PRODUCTS_FAILED_TO_LOAD,
    ADD_GLOBAL_PRODUCT,
    UPDATE_GLOBAL_PRODUCT,
    REMOVE_GLOBAL_PRODUCT,
} from '../actions/product/GlobalProduct.action';
import * as constants from '../constants';

import {
    isContractRevisionCurrent,
    getActionsForContractItemUpdate,
    getActionsForContractItemDelete,
    getActionsForContractItemCreate,
    getActionsForCustomContractItemCreate,
    getActionsForAddingPackage,
    getActionsForRemovingPackage,
    updateItemRevisionsList,
    ContractItemActions,
    getActionsForContractDiscountItemCreate,
} from '../shared/goods_and_services/contractActions';
import { getLatestItems, getContractCurrentRevision } from '../shared/goods_and_services/utils';
import {
    SET_PRODUCT_SUPPLIERS,
    SET_PRODUCT_SUPPLIER,
    ADD_PRODUCT_SUPPLIER,
    REMOVE_PRODUCT_SUPPLIER,
    ProductSupplierAction,
    SET_FUNERALHOME_PRODUCT_SUPPLIERS,
    ADD_FUNERALHOME_PRODUCT_SUPPLIER,
    REMOVE_FUNERALHOME_PRODUCT_SUPPLIER,
} from '../actions/product/ProductSupplier.action';
import { LOADED_PRIVATE_CASE } from '../actions/GatherCase.action';
import { CLEAR_ACTIVE_FUNERALHOME, LOADED_PUBLIC_GPL } from '../actions/FuneralHome.action';
import { GatherAction } from '../actions';

export const initData: ProductState = {
    globalProducts: {
        data: [],
        hasMoreData: false,
        isLoading: false,
        category: ProductCategoryEnum.casket,
        searchText: '',
        sortBy: 'id',
        sortDirection: 'desc',
    },
    funeralHomeProducts: [],
    funeralHomeProductSummaries: [],
    funeralHomeCategories: [],
    manufacturers: [],
    tags: [],
    taxRates: [],
    packages: [],
    activeContract: null,
    activeContractSummary: null,
    isContractLoading: false,
    isFHProductsLoading: false,
    contracts: [],
    contractOptions: ContractOptionsUX.generateDefaults(),
    contractDisclaimers: [],
};

export const initSupplierData: ProductSupplierState = {
    globalSuppliers: [],
    funeralHomeSuppliers: [],
};

const getOrCreateDraftContractRevision = (contract: ProductContractUX) => {
    const currentRevision = getContractCurrentRevision(contract);
    return isContractRevisionCurrent(contract) ? currentRevision : currentRevision + 1;
};

const updateActiveContractState = (
    state: ProductState,
    actions: ContractItemActions | null,
    draftRevision: number,
): ProductState => {
    const { activeContract } = state;

    if (!activeContract || !actions) {
        return state;
    }

    const { itemsToAdd, itemUpdates, itemIdsToRemove } = actions;

    const updatedItemRevisionsList = updateItemRevisionsList(
        activeContract.items,
        itemsToAdd,
        itemUpdates,
        itemIdsToRemove,
    );

    const oldContractCurrentRevision = getContractCurrentRevision(activeContract);
    const isNewRevision = draftRevision !== oldContractCurrentRevision;
    const isFrozen = isNewRevision ? false : activeContract.is_frozen;
    const updatedRevisions = isNewRevision ? [...activeContract.revisions, draftRevision] : activeContract.revisions;
    return {
        ...state,
        activeContract: {
            ...activeContract,
            items: updatedItemRevisionsList,
            revisions: updatedRevisions,
            is_frozen: isFrozen,
        },
    };
};

export const productState = (
    state: ProductState = initData,
    action: GatherAction,
): ProductState => {
    switch (action.type) {
        case CLEAR_ACTIVE_FUNERALHOME:
        case constants.USER_LOGGED_OUT:
            return initData;
        case LOADED_PRIVATE_CASE:
            return {
                ...state,
                activeContract: null,
            };
        case UPDATE_FUNERAL_HOME_FEATURED_PRODUCTS: {
            const updatedProducts = state.funeralHomeProducts.map(fhProduct =>
                action.products.find(product => product.id === fhProduct.id)
                ?? fhProduct
            );
            const updatedProductSummaries = state.funeralHomeProductSummaries.map(fhProduct =>
                action.products.find(product => product.id === fhProduct.id)
                ?? fhProduct
            );
            return {
                ...state,
                funeralHomeProducts: updatedProducts,
                funeralHomeProductSummaries: updatedProductSummaries,
            };
        }
        case FUNERAL_HOME_PRODUCTS_LOADING: {
            return {
                ...state,
                funeralHomeProducts: [],
                isFHProductsLoading: true,
            };
        }
        case FUNERAL_HOME_PRODUCTS_LOADED: {
            return {
                ...state,
                funeralHomeProducts: action.products,
                isFHProductsLoading: false,
            };
        }
        case FUNERAL_HOME_PRODUCT_SUMMARIES_LOADING: {
            return {
                ...state,
                funeralHomeProductSummaries: [],
                isFHProductsLoading: true,
            };
        }
        case FUNERAL_HOME_PRODUCT_SUMMARIES_LOADED: {
            const funeralHomeCategories = uniq(action.summaries.map((sum) => sum.category));
            return {
                ...state,
                funeralHomeProductSummaries: action.summaries,
                funeralHomeCategories,
                isFHProductsLoading: false,
            };
        }
        case LOADED_PUBLIC_GPL: {
            return {
                ...state,
                funeralHomeCategories: action.categories,
                packages: action.packages,
            };
        }
        case ADD_FUNERAL_HOME_PRODUCT: {
            return {
                ...state,
                funeralHomeProductSummaries: [...state.funeralHomeProductSummaries, action.product],
                funeralHomeProducts: [...state.funeralHomeProducts, action.product],
            };
        }
        case SET_FUNERAL_HOME_PRODUCT: {
            return {
                ...state,
                funeralHomeProductSummaries: state.funeralHomeProductSummaries.map((existing) => {
                    if (existing.id === action.productId) {
                        return action.product;
                    }
                    return existing;
                }),
                funeralHomeProducts: state.funeralHomeProducts.map((existing) => {
                    if (existing.id === action.productId) {
                        return action.product;
                    }
                    return existing;
                }),
            };
        }
        case UPDATE_FUNERAL_HOME_PRODUCT: {
            return {
                ...state,
                funeralHomeProductSummaries: state.funeralHomeProductSummaries.map((existing) => {
                    if (existing.id === action.productId) {
                        return {
                            ...existing,
                            ...action.changes,
                        };
                    }
                    return existing;
                }),
                funeralHomeProducts: state.funeralHomeProducts.map((existing) => {
                    if (existing.id === action.productId) {
                        return {
                            ...existing,
                            ...action.changes,
                        };
                    }
                    return existing;
                }),
            };
        }
        case UPDATED_FUNERAL_HOME_CATEGORY_PRODUCTS: {
            return {
                ...state,
                funeralHomeProductSummaries: state.funeralHomeProductSummaries.map((p) => {
                    if (p.category === action.category) {
                        return {
                            ...p,
                            ...action.changes,
                        };
                    } else {
                        return p;
                    }
                }),
                funeralHomeProducts: state.funeralHomeProducts.map((p) => {
                    if (p.category === action.category) {
                        return {
                            ...p,
                            ...action.changes,
                        };
                    } else {
                        return p;
                    }
                }),
            };
        }
        case REMOVE_FUNERAL_HOME_PRODUCT: {
            return {
                ...state,
                funeralHomeProductSummaries: state.funeralHomeProductSummaries.filter((product) => {
                    return product.id !== action.productId;
                }),
                funeralHomeProducts: state.funeralHomeProducts.filter((product) => {
                    return product.id !== action.productId;
                }),
            };
        }
        case SET_PRODUCT_MANUFACTURERS: {
            return {
                ...state,
                manufacturers: action.manufacturers,
            };
        }
        case SET_PRODUCT_MANUFACTURER: {
            return {
                ...state,
                manufacturers: state.manufacturers.map((existing) => {
                    if (existing.id === action.manufacturerId) {
                        return {
                            ...action.manufacturer,
                            id: action.manufacturerId,
                        };
                    }
                    return existing;
                }),
            };
        }
        case SET_PRODUCT_TAGS: {
            return {
                ...state,
                tags: action.tags,
            };
        }
        case ADD_PRODUCT_TAG: {
            return {
                ...state,
                tags: [...state.tags, action.tag],
            };
        }
        case REMOVE_PRODUCT_TAG: {
            return {
                ...state,
                tags: state.tags.filter((tag) => !isEqual(tag, action.tag)),
            };
        }
        case SET_PRODUCT_TAX_RATES: {
            return {
                ...state,
                taxRates: action.taxRates,
            };
        }
        case SET_PRODUCT_TAX_RATE: {
            return {
                ...state,
                taxRates: state.taxRates.map((taxRate): ProductTaxRateUX => {
                    if (taxRate.id === action.taxRateId) {
                        return {
                            ...taxRate,
                            ...action.taxRate,
                        };
                    } else {
                        return taxRate;
                    }
                }),
            };
        }
        case SET_CONTRACT_OPTIONS: {
            return {
                ...state,
                contractOptions: action.options,
            };
        }
        case SET_CONTRACT_DISCLAIMERS: {
            return {
                ...state,
                contractDisclaimers: action.disclaimers,
            };
        }
        case UPDATE_CONTRACT_DISCLAIMER_REASON: {
            const activeContract = state.activeContract;
            if (!activeContract) {
                return state;
            }
            return {
                ...state,
                activeContract: {
                    ...activeContract,
                    contract_disclaimers: activeContract.contract_disclaimers.map((disclaimer) => {
                        if (disclaimer.id === action.disclaimerId) {
                            return {
                                ...disclaimer,
                                reason: action.reason || undefined,
                            };
                        } else {
                            return disclaimer;
                        }
                    }),
                },
            };
        }
        case SET_PRODUCT_PACKAGES: {
            return {
                ...state,
                packages: action.packages,
            };
        }
        case REMOVE_PRODUCT_PACKAGE: {
            return {
                ...state,
                packages: state.packages.filter((p) => p.id !== action.packageId),
            };
        }
        /* ---- Global Products Reducer ---- */
        case GLOBAL_PRODUCTS_LOADING: {
            return {
                ...state,
                globalProducts: {
                    ...state.globalProducts,
                    data: action.offset === 0 ? [] : state.globalProducts.data,
                    isLoading: true,
                    category: action.category,
                    searchText: action.searchText,
                    sortBy: action.sortBy,
                    sortDirection: action.sortDirection,
                },
            };
        }
        case GLOBAL_PRODUCTS_LOADED: {
            // overwrite data if offset === 0
            const updatedData = action.offset === 0
                ? action.products
                : [...state.globalProducts.data, ...action.products];
            return {
                ...state,
                globalProducts: {
                    ...state.globalProducts,
                    data: updatedData,
                    hasMoreData: action.hasMoreData,
                    isLoading: false,
                },
            };
        }
        case GLOBAL_PRODUCTS_FAILED_TO_LOAD: {
            return {
                ...state,
                globalProducts: {
                    ...state.globalProducts,
                    isLoading: false,
                },
            };
        }
        case ADD_GLOBAL_PRODUCT: {
            return {
                ...state,
                globalProducts: {
                    ...state.globalProducts,
                    data: [action.product, ...state.globalProducts.data],
                }
            };
        }
        case UPDATE_GLOBAL_PRODUCT: {
            return {
                ...state,
                globalProducts: {
                    ...state.globalProducts,
                    data: state.globalProducts.data.map((product) => {
                        if (product.id === action.product.id) {
                            return action.product;
                        } else {
                            return product;
                        }
                    }),
                },
            };
        }
        case REMOVE_GLOBAL_PRODUCT: {
            return {
                ...state,
                globalProducts: {
                    ...state.globalProducts,
                    data: state.globalProducts.data.filter((product) => product.id !== action.productId),
                },
            };
        }
        case SET_PRODUCT_CONTRACTS: {
            return {
                ...state,
                contracts: action.contracts,
            };
        }
        case SET_PRODUCT_ACTIVE_CONTRACT: {
            return {
                ...state,
                activeContract: action.contract,
            };
        }
        case UPDATE_PRODUCT_ACTIVE_CONTRACT: {
            if (!state.activeContract) {
                return state;
            }
            return {
                ...state,
                activeContract: {
                    ...state.activeContract,
                    ...action.contract,
                },
            };
        }
        case SET_PRODUCT_ACTIVE_CONTRACT_SUMMARY: {
            return {
                ...state,
                activeContractSummary: action.contractSummary,
            };
        }
        case SET_PRODUCT_ACTIVE_CONTRACT_FROZEN_STATE: {

            if (!state.activeContract) {
                return state;
            }
            return {
                ...state,
                activeContract: {
                    ...state.activeContract,
                    is_frozen: action.isFrozen,
                },
            };
        }
        case SET_PRODUCT_CONTRACT_LOADING: {
            return {
                ...state,
                isContractLoading: action.isLoading,
            };
        }
        case SET_PRODUCT_CONTRACT_VIEWERS: {

            if (!state.activeContract) {
                return state;
            }
            return {
                ...state,
                activeContract: {
                    ...state.activeContract,
                    viewers: action.viewers,
                },
            };
        }
        case ADD_PRODUCT_CONTRACT_ITEM: {

            const { activeContract, packages, funeralHomeProducts, taxRates } = state;
            if (!activeContract) {
                return state;
            }

            const draftRevision = getOrCreateDraftContractRevision(activeContract);

            const actions = getActionsForContractItemCreate(
                action.itemId,
                action.item,
                activeContract,
                packages,
                funeralHomeProducts,
                taxRates,
                draftRevision,
            );

            return updateActiveContractState(state, actions, draftRevision);
        }
        case ADD_PRODUCT_CUSTOM_CONTRACT_ITEM: {

            const { activeContract, packages, taxRates } = state;
            if (!activeContract) {
                return state;
            }

            const draftRevision = getOrCreateDraftContractRevision(activeContract);

            const actions = getActionsForCustomContractItemCreate(
                action.itemId,
                action.customItem,
                activeContract,
                packages,
                taxRates,
                draftRevision,
            );

            return updateActiveContractState(state, actions, draftRevision);
        }
        case ADD_PRODUCT_CONTRACT_DISCOUNT_ITEM: {

            const { activeContract, packages, taxRates } = state;
            if (!activeContract) {
                return state;
            }

            const draftRevision = getOrCreateDraftContractRevision(activeContract);

            const actions = getActionsForContractDiscountItemCreate(
                action.itemId,
                action.amount,
                action.itemName,
                activeContract,
                packages,
                taxRates,
                draftRevision,
            );

            return updateActiveContractState(state, actions, draftRevision);
        }
        case UPDATE_PRODUCT_CONTRACT_ITEM: {

            const { activeContract, packages, taxRates } = state;
            if (!activeContract) {
                return state;
            }

            const contractItems = getLatestItems(activeContract.items);
            const existingItem = contractItems.find((item) => item.id === action.itemId);
            if (!existingItem) {
                return state;
            }

            const draftRevision = getOrCreateDraftContractRevision(activeContract);

            const actions = getActionsForContractItemUpdate(
                action.item,
                existingItem,
                activeContract,
                packages,
                taxRates,
                draftRevision,
            );

            return updateActiveContractState(state, actions, draftRevision);
        }
        case REMOVE_PRODUCT_CONTRACT_ITEM: {

            const { activeContract, packages, taxRates } = state;
            if (!activeContract) {
                return state;
            }

            const contractItems = getLatestItems(activeContract.items);
            const existingItem = contractItems.find((item) => item.id === action.itemId);
            if (!existingItem) {
                return state;
            }

            const draftRevision = getOrCreateDraftContractRevision(activeContract);

            const actions = getActionsForContractItemDelete(
                existingItem,
                activeContract,
                packages,
                taxRates,
                draftRevision,
            );

            return updateActiveContractState(state, actions, draftRevision);
        }
        case ADD_PACKAGE_TO_CONTRACT: {

            const { activeContract, packages, taxRates } = state;
            if (!activeContract) {
                return state;
            }

            const draftRevision = getOrCreateDraftContractRevision(activeContract);

            const pkg = packages.find((p) => p.id === action.packageId);
            if (!pkg) {
                return state;
            }

            const actions = getActionsForAddingPackage(
                pkg,
                activeContract,
                packages,
                taxRates,
                draftRevision,
            );

            return updateActiveContractState(state, actions, draftRevision);
        }
        case REMOVE_PACKAGE_FROM_CONTRACT: {

            const { activeContract, packages, taxRates } = state;
            if (!activeContract) {
                return state;
            }

            const draftRevision = getOrCreateDraftContractRevision(activeContract);

            const actions = getActionsForRemovingPackage(
                action.packageId,
                activeContract,
                packages,
                taxRates,
                draftRevision,
            );

            return updateActiveContractState(state, actions, draftRevision);
        }
        default:
            return state;
    }
};

export const productSupplierState = (
    state: ProductSupplierState = initSupplierData,
    action: ProductSupplierAction | GatherAction,
): ProductSupplierState => {
    switch (action.type) {
        case constants.USER_LOGGED_OUT:
            return initSupplierData;
        /**
         * Product Supplier State Actions
         */
        case SET_PRODUCT_SUPPLIERS: {
            return {
                ...state,
                globalSuppliers: action.productSuppliers,
            };

        }
        case SET_PRODUCT_SUPPLIER: {
            return {
                ...state,
                globalSuppliers: state.globalSuppliers.map((existing) => {
                    if (existing.id === action.supplierId) {
                        return action.productSupplier;
                    }
                    return existing;
                }),
            };
        }
        case ADD_PRODUCT_SUPPLIER: {
            return {
                ...state,
                globalSuppliers: [...state.globalSuppliers, action.productSupplier],
            };
        }
        case REMOVE_PRODUCT_SUPPLIER: {
            return {
                ...state,
                globalSuppliers: state.globalSuppliers.filter((supplier) => !isEqual(supplier.id, action.supplierId)),
            };
        }
        case LOADED_PUBLIC_GPL:
        case SET_FUNERALHOME_PRODUCT_SUPPLIERS: {
            return {
                ...state,
                funeralHomeSuppliers: action.suppliers,
            };
        }
        case ADD_FUNERALHOME_PRODUCT_SUPPLIER: {
            return {
                ...state,
                funeralHomeSuppliers: [...state.funeralHomeSuppliers, action.funeralHomeProductSupplier],
            };

        }
        case REMOVE_FUNERALHOME_PRODUCT_SUPPLIER: {
            return {
                ...state,
                funeralHomeSuppliers: state.funeralHomeSuppliers.filter((supplier) =>
                    !isEqual(supplier.id, action.funeralHomeProductSupplier.id)),
            };
        }
        default:
            return state;
    }
};
