import * as t from 'io-ts';
import { DocUX, EntitySummary, GatherCaseRecord, getValidator, PdfFieldData, SignatureRequest } from '.';
import { AvatarUser, EntityUX } from './user';

// Autoform Formfill Types
const GatherAutoFillFieldsDefinition = {
    'id': t.string,
    'Current_Timestamp': t.array(t.string),
    'Date_Today': t.string,
    'Funeral_Home_Case_Number': t.string,
    'Decedent_Name': t.array(t.string),
    'Decedent_Display_Name': t.string,
    'Decendent_Birth_Last_Name': t.string,
    'Decedent_Birth_Last_Name': t.string,
    'Decedent_Gender': t.string,
    'Decedent_Gender_is_Male': t.string,
    'Decedent_Gender_is_Female': t.string,
    'Decedent_Gender_is_Other': t.string,
    'Decedent_Gender_is_Unknown': t.string,
    'Decedent_Pronouns': t.string,
    'Decedent_Age_at_Time_of_Passing': t.string,
    'Decedent_Age_at_Today': t.string,
    'Decedent_Also_Known_As': t.string,
    'Decedent_SSN': t.string,
    'Decedent_SSN_is_Unknown': t.string,
    'Decedent_SSN_is_None': t.string,
    'Decedent_Date_of_Birth': t.string,
    'Decedent_Photo_Id': t.string,
    'Decedent_Photo': t.string,
    'Decedent_Photo_Transformations': t.string,

    'Decedent_Pick_Up_Full_Address': t.string,
    'Decedent_Pick_Up_Full_Address_With_Postal_Code': t.string,
    'Decedent_Pick_Up_Street_Address': t.string,
    'Decedent_Pick_Up_City_State': t.string,
    'Decedent_Pick_Up_Location_Name': t.string,
    'Decedent_Pick_Up_City': t.string,
    'Decedent_Pick_Up_County': t.string,
    'Decedent_Pick_Up_Postal_Code': t.string,
    'Decedent_Pick_Up_State': t.string,
    'Decedent_Pick_Up_Country': t.string,

    'Decedent_Drop_Off_Full_Address': t.string,
    'Decedent_Drop_Off_Full_Address_With_Postal_Code': t.string,
    'Decedent_Drop_Off_Street_Address': t.string,
    'Decedent_Drop_Off_City_State': t.string,
    'Decedent_Drop_Off_Location_Name': t.string,
    'Decedent_Drop_Off_City': t.string,
    'Decedent_Drop_Off_County': t.string,
    'Decedent_Drop_Off_Postal_Code': t.string,
    'Decedent_Drop_Off_State': t.string,
    'Decedent_Drop_Off_Country': t.string,

    'Decedent_Facility_Has_Stairs': t.string,
    'Decedent_Facility_Has_Stairs_Unknown': t.string,
    'Decedent_Minister_Officiating': t.string,
    'Decedent_Number_of_Certified_Death_Certificates': t.string,
    'Decedent_Casket': t.string,
    'Decedent_Insurance_Company_Name': t.string,
    'Decedent_Hair': t.string,
    'Decedent_Facial_Hair': t.string,
    'Decedent_Nails': t.string,
    'Decedent_Lipstick_Color': t.string,
    'Decedent_Medical_Examiner_Release_Number': t.string,
    'Decedent_Clothing_Due_Date': t.string,
    'Decedent_Referred_To_Us_By': t.string,
    'Decedent_Hobbies': t.string,
    'Decedent_Lodges': t.string,
    'Decedent_Clubs': t.string,
    'Decedent_Offices': t.string,
    'Decedent_Previous_Places_Lived': t.string,
    'Decedent_Casket_Panel': t.string,
    'Decedent_Chapel': t.string,
    'Decedent_Insurance_Policy_Number': t.string,
    'Decedent_Weight': t.string,
    'Decedent_Other': t.string,
    'Decedent_Has_Children_Under_18': t.string,

    'DOD_START_DATE': t.array(t.string),
    'DOD_END_DATE': t.array(t.string),
    'Decedent_Date_of_Death': t.array(t.string),
    'Decedent_Time_of_Death': t.array(t.string),
    'Death_Certificate_Birth_Date': t.string,
    'Decedent_Education_Level': t.string,
    'Decedent_Education_History': t.string,
    'Funeral_Home_Id': t.string,
    'Funeral_Home_Address_Id': t.string,
    'Funeral_Home_Name': t.string,
    'Funeral_Home_Location': t.string,
    'Funeral_Home_Address': t.string,
    'Funeral_Home_City': t.string,
    'Funeral_Home_State': t.string,
    'Funeral_Home_City_State': t.string,
    'Funeral_Home_City_State_Postal_Code': t.string,
    'Funeral_Home_Postal_Code': t.string,
    'Funeral_Home_Country': t.string,
    'Funeral_Home_Time_Zone': t.string,
    'Funeral_Home_Phone': t.string,
    'Informant_Name': t.array(t.string),
    'Informant_Phone': t.string,
    'Informant_Email': t.string,
    'Informant_Relationship': t.string,
    'Informant_Full_Address': t.string,
    'Informant_Full_Address_With_Postal_Code': t.string,
    'Informant_Street_Address': t.string,
    'Informant_City_State': t.string,
    'Informant_City_State_Postal_Code': t.string,
    'Informant_Address2': t.string,
    'Informant_Location_Name': t.string,
    'Informant_City': t.string,
    'Informant_County': t.string,
    'Informant_Postal_Code': t.string,
    'Informant_State': t.string,
    'Informant_Country': t.string,
    'Assignee_Name': t.array(t.string),
    'Assignee_Email': t.string,
    'Assignee_Phone': t.string,
    'Assignee_Role': t.string,
    'Assignee_State_License_Number': t.string,
    'Birth_Place': t.string,
    'Birth_Place_City': t.string,
    'Birth_Place_State': t.string,
    'Birth_Place_Country': t.string,
    'Death_Place_Full_Address': t.string,
    'Death_Place_Full_Address_With_Postal_Code': t.string,
    'Death_Place_Street_Address': t.string,
    'Death_Place_City_State': t.string,
    'Death_Place_City_State_Postal_Code': t.string,
    'Death_Place_Address2': t.string,
    'Death_Place_Location_Name': t.string,
    'Death_Place_City': t.string,
    'Death_Place_County': t.string,
    'Death_Place_Postal_Code': t.string,
    'Death_Place_State': t.string,
    'Death_Place_Country': t.string,
    'Where_did_decedent_die': t.string,
    'Physician_Full_Name': t.string,
    'Physician_Phone_Number': t.string,
    'Physician_Fax_Number': t.string,
    'Physician_Address_Full_Address': t.string,
    'Physician_Address_Full_Address_With_Postal_Code': t.string,
    'Physician_Address_Street_Address': t.string,
    'Physician_Address_City_State': t.string,
    'Physician_Address_Location_Name': t.string,
    'Physician_Address_City': t.string,
    'Physician_Address_County': t.string,
    'Physician_Address_Postal_Code': t.string,
    'Physician_Address_State': t.string,
    'Physician_Address_Country': t.string,
    'Residence_Full_Address': t.string,
    'Residence_Full_Address_With_Postal_Code': t.string,
    'Residence_Street_Address': t.string,
    'Residence_City_State': t.string,
    'Residence_City_State_Postal_Code': t.string,
    'Residence_Address2': t.string,
    'Residence_Location_Name': t.string,
    'Residence_City': t.string,
    'Residence_County': t.string,
    'Residence_Postal_Code': t.string,
    'Residence_State': t.string,
    'Residence_Country': t.string,
    'Inside_City_Limits': t.string,
    'Years_lived_in_current_county': t.string,
    'Death_Certificate_Filing_State': t.string,
    'Cause_Of_Death': t.string,
    'Decedent_Hebrew_Date_Of_Birth': t.string,
    'Decedent_Hebrew_Date_Of_Death': t.string,

    'Marital_Status': t.string,
    'Marriage_Date': t.string,
    'Marriage_Place': t.string,
    'Marriage_Place_City': t.string,
    'Marriage_Place_State': t.string,
    'Marriage_Place_Country': t.string,
    'Spouse_Name': t.array(t.string),
    'Spouse_First_Name': t.string,
    'Spouse_Middle_Name': t.string,
    'Spouse_Last_Name': t.string,
    'Spouse_Maiden_Name': t.string,
    'Surviving_Spouse_Name': t.array(t.string),
    'Spouse_Telephone_Number': t.string,
    'Spouse_Full_Address': t.string,
    'Spouse_Full_Address_With_Postal_Code': t.string,
    'Spouse_Street_Address': t.string,
    'Spouse_City_State': t.string,
    'Spouse_Location_Name': t.string,
    'Spouse_City': t.string,
    'Spouse_County': t.string,
    'Spouse_Postal_Code': t.string,
    'Spouse_State': t.string,
    'Spouse_Country': t.string,
    'Spouse_Date_Of_Passing': t.string,

    'Served_in_Military': t.string,
    'Military_Branch': t.array(t.string),
    'Years_in_Military': t.string,
    'Highest_Military_Rank': t.string,
    'Military_Service_Number': t.string,
    'Military_Honors': t.array(t.string),
    'Military_Service_Start_Date': t.string,
    'Military_Service_End_Date': t.string,
    'Military_Disability_Contributed_to_Death': t.string,
    'Is_Military': t.string,
    'Father_Name': t.array(t.string),
    'Father_First_Name': t.string,
    'Father_Middle_Name': t.string,
    'Father_Last_Name': t.string,
    'Father_Birth_Place': t.string,
    'Father_Birth_Place_City': t.string,
    'Father_Birth_Place_State': t.string,
    'Father_Birth_Place_Country': t.string,
    'Mother_Name': t.array(t.string),
    'Mother_First_Name': t.string,
    'Mother_Middle_Name': t.string,
    'Mother_Maiden_Name': t.string,
    'Mother_Birth_Place': t.string,
    'Mother_Birth_Place_City': t.string,
    'Mother_Birth_Place_State': t.string,
    'Mother_Birth_Place_Country': t.string,

    'Ancestry': t.array(t.string),
    'Is_of_Hispanic_Origin': t.array(t.string),
    'Race': t.array(t.string),
    'Is_US_Citizen': t.string,
    'Type_of_Disposition': t.string,
    'Disposition_Crematory_Name': t.string,
    'Disposition_Full_Address': t.string,
    'Disposition_Full_Address_With_Postal_Code': t.string,
    'Disposition_Street_Address': t.string,
    'Disposition_City_State': t.string,
    'Disposition_Location_Name': t.string,
    'Disposition_City': t.string,
    'Disposition_County': t.string,
    'Disposition_Postal_Code': t.string,
    'Disposition_State': t.string,
    'Disposition_Country': t.string,
    'Date_of_Disposition': t.string,
    'Second_Disposition_Full_Address': t.string,
    'Second_Disposition_Full_Address_With_Postal_Code': t.string,
    'Second_Disposition_Street_Address': t.string,
    'Second_Disposition_City_State': t.string,
    'Second_Disposition_Location_Name': t.string,
    'Second_Disposition_City': t.string,
    'Second_Disposition_County': t.string,
    'Second_Disposition_Postal_Code': t.string,
    'Second_Disposition_State': t.string,
    'Second_Disposition_Country': t.string,
    'Cemetery_Property_Details': t.string,
    'Employment_Status': t.string,
    'Normal_Occupation': t.string,
    'Kind_of_Business': t.string,
    'Name_of_Company': t.string,
    'Years_in_Occupation': t.string,
    'Retirement_Year': t.string,
    'Last_Employer': t.string,
    'Education_Level': t.string,
    'Contract_Total': t.string,
    'Obituary_Text': t.string,
    'URL_Key': t.string,
    'Remember_Page': t.string,
    'Link_YouTube': t.string,
    'Link_Tukios': t.string,
    'Link_GoFundMe': t.string,
    'No_Memorial_Service': t.string,

    'Event1_Rank': t.string,
    'Event1_Name': t.string,
    'Event1_Start_Time': t.array(t.string),
    'Event1_End_Time': t.array(t.string),
    'Event1_Timezone': t.string,
    'Event1_Is_Private': t.string,
    'Event1_Location_Name': t.string,
    'Event1_Full_Address': t.string,
    'Event1_Full_Address_With_Postal_Code': t.string,

    'Event2_Rank': t.string,
    'Event2_Name': t.string,
    'Event2_Start_Time': t.array(t.string),
    'Event2_End_Time': t.array(t.string),
    'Event2_Timezone': t.string,
    'Event2_Is_Private': t.string,
    'Event2_Location_Name': t.string,
    'Event2_Full_Address': t.string,
    'Event2_Full_Address_With_Postal_Code': t.string,

    'Event3_Rank': t.string,
    'Event3_Name': t.string,
    'Event3_Start_Time': t.array(t.string),
    'Event3_End_Time': t.array(t.string),
    'Event3_Timezone': t.string,
    'Event3_Is_Private': t.string,
    'Event3_Location_Name': t.string,
    'Event3_Full_Address': t.string,
    'Event3_Full_Address_With_Postal_Code': t.string,

    'Event4_Rank': t.string,
    'Event4_Name': t.string,
    'Event4_Start_Time': t.array(t.string),
    'Event4_End_Time': t.array(t.string),
    'Event4_Timezone': t.string,
    'Event4_Is_Private': t.string,
    'Event4_Location_Name': t.string,
    'Event4_Full_Address': t.string,
    'Event4_Full_Address_With_Postal_Code': t.string,

    'Event5_Rank': t.string,
    'Event5_Name': t.string,
    'Event5_Start_Time': t.array(t.string),
    'Event5_End_Time': t.array(t.string),
    'Event5_Timezone': t.string,
    'Event5_Is_Private': t.string,
    'Event5_Location_Name': t.string,
    'Event5_Full_Address': t.string,
    'Event5_Full_Address_With_Postal_Code': t.string,
    'KeepTrack_Tracker_Key': t.string,
};

const GatherAutoFillFieldsType = t.type(GatherAutoFillFieldsDefinition);

export interface GatherAutoFillFields extends t.TypeOf<typeof GatherAutoFillFieldsType> {
}

export class GatherAutoFillFields {
    public static isGatherAutoFillField(field: string): field is keyof GatherAutoFillFields {
        return GatherAutoFillFieldsDefinition.hasOwnProperty(field);
    }
    public static fromDatabase = getValidator<GatherAutoFillFields>(GatherAutoFillFieldsType);
}

// ------> DocPacket <------
export enum CombinedDocPacketStatus {
    Completed = 'Completed',
    Pending = 'Pending',
    Not_Started = 'Not Started'
}

export type DocPacketType = 'fill_esign' | 'fill_print';
export type DocPacketStatus = 'started' | 'printed' | 'sent' | 'signed';

export interface HelloSignSignerRequest {
    name: string;
    email_address: string;
}

export interface DocPacketRecord {
    id: number;
    gather_case_id: number;
    status: DocPacketStatus;
    type: DocPacketType;
    created_time: Date;
    created_by: number;
    data_updated_time: Date;
    data_updated_by: number;
    pdf_created_time: Date | null;
    pdf_created_by: number | null;
    pdf_final_file: string | null;
    hellosign_id: string | null; // This ID is the signature request id from hellosign
    deleted_time: Date | null;
    deleted_by: number | null;
    notification_sent_time: Date | null;
}

export interface DocPacketReportRecord {
    case_key: GatherCaseRecord['name'];
    case_display_name: GatherCaseRecord['display_full_name'];
    case_photo: AvatarUser;
    id: number;
    status: DocPacketStatus;
    type: DocPacketType;
    signatures: string | null;
    documents: string;
    created_time: Date;
    created_by_user_profile_id: number;
    created_by_entity_id: number;
    created_by_fname: string;
    created_by_mname: string | null;
    created_by_lname: string;
    created_by: string;
    completed_time: Date | null;
}
export interface DocPacketReportUX extends DocPacketReportRecord {
    id: number;
    created_display_time: string;
    packet_display_type: string;
    packet_display_status: string;
    completed_display_time: string;
    packet_display_signatures: string | null;
}

export interface DocPacketDocRecord {
    id: number;
    doc_id: number;
    doc_packet_id: number;
    field_data: PdfFieldData[];
}

export interface DocPacketDocUX {
    doc: DocUX;
    doc_packet_id: number;
    field_groups: DocPacketFieldGroup[];
    signers: DocPacketDocSigners;
    pdf_template_s3_url: string;
}

export interface DocPacketContractRecord {
    id: number;
    contract_id: number;
    doc_packet_id: number;
}

export interface DocPacketContractUX extends DocPacketContractRecord {
    signers: DocPacketContractSignerRecord[];
}

export type HelloSignSignerStatus =
    'awaiting_signature' |
    'viewed' |
    'signed' |
    'declined'
    ;

export enum HelloSignSignerStatusEnum {
    'awaiting_signature' = 'awaiting_signature',
    'viewed' = 'viewed',
    'signed' = 'signed',
    'declined' = 'declined',
}

export enum SignerHistoryType {
    'send_signature_request' = 'send_signature_request',
    'send_signature_reminder' = 'send_signature_reminder',
    'update_signature' = 'update_signature',
}

export interface DocPacketSignerHistoryRecord {
    id: number;
    doc_packet_id: number;
    person_id: number;
    email: string;
    history_type: SignerHistoryType;
    sent_time: Date;
}

export interface DocPacketSignerHistoryInsert extends Omit<DocPacketSignerHistoryRecord, 'id'> {

}

export interface DocPacketSignerHistory extends Omit<DocPacketSignerHistoryRecord, 'doc_packet_id' | 'person_id'> {

}

export interface DocPacketSignerRecord {
    id: number;
    doc_packet_id: number;
    person_id: number;
    hellosign_id: string | null; // This is the signer id from hellosign
    hellosign_status: HelloSignSignerStatus | null;
    hellosign_status_time: Date | null;
    last_reminder_sent_time: Date | null;
}

export interface DocPacketSignerUX extends DocPacketSignerRecord {
    person: EntitySummary;
    signing_docs: Record<number, string[]>;  // { [doc_packet_doc_id]: signer_group[] }
    signing_contracts: Record<number, 'team' | 'helpers'>;  // { [doc_packet_contract_id]: signer_group }
    history: DocPacketSignerHistoryRecord[] | null;
}

export interface DocPacketSignerReminderResponse {
    signatureRequest: SignatureRequest | null;
    historyEvent: DocPacketSignerHistoryRecord | null;
}
export interface DocPacketSignerEmailResponse {
    docPacketSigner: DocPacketSignerRecord;
    signer: EntityUX;
    historyEvent: DocPacketSignerHistoryRecord | null;
}
export interface DocPacketContractSignerRecord {
    doc_packet_contract_id: number;
    doc_packet_signer_id: number;
    signer_group: 'team' | 'helpers';
}

export interface DocPacketDocSignerRecord {
    doc_packet_doc_id: number;
    doc_packet_signer_id: number;
    signer_group: string;
}

export interface DocPacketFieldGroup {
    group: string;
    fields: PdfFieldData[];
}

export interface DocPacketSignedDocRecord {
    doc_id: number;
    doc_packet_id: number;
}

export interface DocPacketDocSigners {
    [group: string]: number; // { [group]: person ID }
}

export interface DocPacketUX extends DocPacketRecord {
    created_by_fname: string | null;
    created_by_lname: string | null;
    docs: DocPacketDocUX[];
    contracts: DocPacketContractUX[];
    signedDocs: DocUX[];
    signers: DocPacketSignerUX[];
    pdf_final_s3_url: string | null;
}

// ---> DocPacketRequest <---
const DocPacketRequestTypeDefinition = {
    type: t.string,
    docIds: t.array(t.number),
    contractIds: t.array(t.number),
};

const DocPacketRequestType = t.type(DocPacketRequestTypeDefinition);

export interface DocPacketRequest extends t.TypeOf<typeof DocPacketRequestType> {
    type: DocPacketType;
}

export class DocPacketRequest {
    public static fromRequest = getValidator<DocPacketRequest>(DocPacketRequestType);
    public static fromPatchRequest = getValidator<Partial<DocPacketRequest>>(t.partial(DocPacketRequestType.props));
}

// ---> DocPacketSignerRequest <---
const DocPacketSignerRequestType = t.type({
    person_id: t.number,
    signer_group: t.string,
});

export interface DocPacketSignerRequest extends t.TypeOf<typeof DocPacketSignerRequestType> {
}

export class DocPacketSignerRequest {
    public static fromRequest = getValidator<DocPacketSignerRequest>(DocPacketSignerRequestType);
}

// ---> DocPacketDocRequest <---
const DocPacketDocRequestTypeDefinition = {
    field_data: t.dictionary(t.string, t.string),
};

const DocPacketDocRequestType = t.type(DocPacketDocRequestTypeDefinition);

export interface DocPacketDocRequest extends t.TypeOf<typeof DocPacketDocRequestType> {
    field_data: { [fieldId: string]: string };
}

export class DocPacketDocRequest {
    public static fromRequest = getValidator<DocPacketDocRequest>(DocPacketDocRequestType);
}

// ---> DocPacketFinalizeRequest <---
const DocPacketFinalizeRequestType = t.intersection([
    t.type({
        caseUuid: t.string,
    }),
    t.partial({
        contract_html: t.string,
        packet_signers: t.array(t.number),
    })
]);

export interface DocPacketFinalizeRequest extends t.TypeOf<typeof DocPacketFinalizeRequestType> {
}

export class DocPacketFinalizeRequest {
    public static fromRequest = getValidator<DocPacketFinalizeRequest>(DocPacketFinalizeRequestType);
}