import * as React from 'react';
import classNames from 'classnames';
import moment from 'moment';
import Dinero from 'dinero.js';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';

import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import ReceiptIcon from '@mui/icons-material/Receipt';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { Theme } from '@mui/material/styles';

import { StyleRulesCallback } from '@mui/styles';

import {
    Transaction,
    PaymentTransaction,
    PaymentGrouping,
    PaymentRequest,
    PaymentMode,
    FeeSchedule,
    PaymentType,
    getDisplayRelationshipForPerson, GatherCaseUX, EntitySummary, getCaseEntity, PaymentMethod
} from '../../../../../shared/types';

import UserAvatar from '../../../../../components/common/UserAvatar';
import { PaymentCard } from './PaymentCard';
import { Payer, PaymentFieldToolTip, PaymentMethodIcon } from '..';
import { proposePayment, PatchPaymentParameters } from '../../../../../actions/Finance.action';
import CardHeader from './CardHeader';
import PolicyCard from './PolicyCard';
import { RestrictedPaymentFeature } from './RestrictedPaymentFeaturePopper';
import { generateNameInitials, getPaymentMethodLabel } from '../../../../../services';
import withGStyles, { WithGStyles } from '../../../../../styles/WithGStyles';
import DisabledPermissionTooltip from '../../../../common/DisabledPermissionTooltip';
import { Permission } from '../../../../../shared/types/permissions';
import { typographyStyle } from '../PaymentReceipt.dialog';
import { formatPrice } from '../../../../../shared/goods_and_services/pricing';

const styles = <Props extends object>(): StyleRulesCallback<Theme, Props> => (theme) => ({
    root: {
        position: 'relative',
        margin: '36px 0 18px'
    },
    card: {
        borderRadius: 8,
        width: 300,
        boxShadow: 'none',
        height: 'auto',
        color: theme.palette.primary.main,
        border: '1px solid',
    },
    avatar: {
        position: 'absolute !important' as 'absolute',
        top: -36,
        left: '50%',
        transform: 'translateX(-50%)',
        height: 72,
        width: 72,
        border: `2px solid ${theme.palette.background.paper}`,
        zIndex: 1,
        padding: 0,
        boxSizing: 'border-box',
        fontSize: 32,
        fontWeight: 200,
        textTransform: 'uppercase',
        '-webkit-font-smoothing': 'antialiased',
    },
    content: {
        padding: '12px 8px 20px 8px',
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    },
    cardContent: {
        padding: '0 !important',
    },
    amount: {},
    paymentReceived: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& svg': {
            marginRight: 4,
            width: 24,
        },
        '& p': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '&:hover': {
                cursor: 'pointer',
                textDecoration: 'underline',
            },
        },
        '& span': {
            display: 'flex',
        },
    },
    status: {
        fontSize: 12,
        margin: '4px auto 16px',
        wordBreak: 'break-word',
        '& p:not(:last-of-type)': {
            marginBottom: '8px !important'
        },
        '& $description': {
            display: 'block',
            marginTop: 8,
            fontStyle: 'italic',
            color: theme.palette.secondary.main
        }
    },
    footer: {
        borderTop: '1px solid',
        padding: 8,
        textAlign: 'center',
        '& button': {
            textTransform: 'none',
            minHeight: 32,
            fontWeight: 300,
            padding: '4px 16px',
        }
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& button': {
            padding: 4,
            fontSize: 12,
            minWidth: 'calc(50% - 4px)'
        }
    },
    noBorder: {
        border: 'none !important'
    },
    isRestricted: {
        opacity: 0,
        visibility: 'hidden',
        pointerEvents: 'none'
    },
    top18: {
        top: -18
    },
    cardHeaderFontWeight: {
        '& p': {
            fontWeight: 300
        }
    },
    downloadInvoice: {
        width: 'fit-content',
        margin: '0px auto',
    },
    downloadInvoiceBtn: {
        borderRadius: '0px 0px 6px 6px',
        fontSize: '0.8125rem',
        minHeight: 28,
        '& svg': {
            width: 22,
            height: 22,
        }
    },
    description: {}
});

type StyleProps = WithGStyles<'root' | 'card' | 'avatar' | 'content' | 'cardContent' | 'amount' | 'paymentReceived'
    | 'status' | 'footer' | 'buttonContainer' | 'noBorder' | 'isRestricted' | 'top18' | 'cardHeaderFontWeight'
    | 'downloadInvoice' | 'downloadInvoiceBtn' | 'description'>;

function getPaymentTypeDescriptor(payment: Transaction, isRestricted: boolean) {
    const amount = Transaction.dollarFormatUnsigned(payment, true);
    const totalAmount = Transaction.dollarFormatUnsigned(payment);
    const createdDate = moment(payment.date).format('dddd, D MMM YYYY');
    const paymentDate = moment(payment.payment_date).format('dddd, D MMM YYYY');
    const isCashOrCheck = payment.method === PaymentMethod.cash || payment.method === PaymentMethod.check;

    return (
        <>
            {payment.method === PaymentMethod.insurance &&
                <>
                    <Typography color="primary" style={typographyStyle}>
                        Insurance Company:&nbsp;
                        <span>{payment.payer_name}</span>
                    </Typography>
                    <Typography color="primary" style={typographyStyle}>
                        Policy Number:&nbsp;
                        <span>{payment.external_id}</span>
                    </Typography>
                </>
            }
            {!isCashOrCheck &&
                <Tooltip
                    enterDelay={400}
                    placement="top"
                    title={!isRestricted && PaymentFieldToolTip.paymentSubtotal}
                >
                    <Typography color="primary" style={typographyStyle}>
                        Payment Subtotal:&nbsp;
                        <span>{amount}</span>
                    </Typography>
                </Tooltip>
            }
            {!isCashOrCheck && !!payment.merch_fee &&
                <Tooltip
                    enterDelay={400}
                    placement="top"
                    title={!isRestricted && PaymentFieldToolTip.paymentFee}
                >
                    <Typography color="primary" style={typographyStyle}>
                        Convenience Fee:&nbsp;
                        <span>{formatPrice(payment.merch_fee, 'USD')}</span>
                    </Typography>
                </Tooltip>
            }
            <Tooltip
                enterDelay={400}
                placement="top"
                title={!isRestricted && PaymentFieldToolTip.paymentTotal}
            >
                <Typography color="primary" style={typographyStyle}>
                    Payment Total:&nbsp;
                    <span>{totalAmount}</span>
                </Typography>
            </Tooltip>
            {payment.method === PaymentMethod.check &&
                <Typography color="primary" style={typographyStyle}>
                    Check Number:&nbsp;
                    <span>{payment.external_id}</span>
                </Typography>
            }
            <Tooltip
                enterDelay={400}
                placement="top"
                title={!isRestricted && PaymentFieldToolTip.paymentDate}
            >
                <Typography color="primary" style={typographyStyle}>
                    Payment Date:&nbsp;
                    <span>{paymentDate}</span>
                </Typography>
            </Tooltip>
            <Tooltip
                enterDelay={400}
                placement="top"
                title={!isRestricted && PaymentFieldToolTip.paymentCreatedBy}
            >
                <Typography color="primary" style={typographyStyle}>
                    Payment Created by:&nbsp;
                    <span>{payment.creator_fname} {payment.creator_lname}</span>
                </Typography>
            </Tooltip>
            <Tooltip
                enterDelay={400}
                placement="top"
                title={!isRestricted && PaymentFieldToolTip.paymentCreatedDate}
            >
                <Typography color="primary" style={typographyStyle}>
                    Payment Created Date:&nbsp;
                    <span>{createdDate}</span>
                </Typography>
            </Tooltip>
        </>
    );
}

interface PaymentProps {
    payer: Payer;
    isFirstEle: boolean;
    payment: PaymentTransaction;
    isRestricted: boolean;
    funeralHomeCaseId: number;
    showReceipt: (paymentId: number) => void;
    showRefundDialog: (paymentTransaction: PaymentTransaction) => void;
    showAdjustPaymentDialog: (paymentInfo: PaymentRequest, payer: Payer) => void;
}

type StyledPaymentProps = PaymentProps & StyleProps;

const PaymentReceivedCard = (props: StyledPaymentProps) => {
    const {
        payment,
        payer,
        classes,
        isFirstEle,
        isRestricted,
        funeralHomeCaseId,
        showReceipt,
        showRefundDialog,
    } = props;

    const paymentMemo = (payment.description === 'Processing Fee')
        ? 'Convenience Fee' : payment.description || '';

    const showAdjustPaymentDialog = () => {
        if (payment) {
            props.showAdjustPaymentDialog(
                {
                    payment_id: payment.payment_id,
                    payer_id: payment.payer_id,
                    payer_name: '',
                    method: payment.method,
                    mode: payment.mode,
                    type: PaymentType.CASE,
                    amount: Dinero({ amount: payment.credit, currency: 'USD' }),
                    merch_fee: Dinero({ amount: 0, currency: 'USD' }),
                    memo: paymentMemo,
                    funeralHomeCaseId,
                    is_anon: false,
                    external_id: payment.external_id || '',
                    payment_date: moment(payment.payment_date),
                },
                payer);
        }
    };

    const showOfferRefundButton = !isRestricted &&
        (payment.method === PaymentMethod.card
            || payment.method === PaymentMethod.online
            || payment.method === PaymentMethod.plaid
        );
    const showAdjustPaymentButton = !isRestricted && (payment.method === PaymentMethod.cash
        || payment.method === PaymentMethod.check
        || payment.method === PaymentMethod.other
        || payment.method === PaymentMethod.insurance);

    return (
        <div
            className={classNames(
                classes.content,
                isFirstEle && classes.noBorder
            )}
        >
            <div className={classes.paymentReceived}>
                <Typography color="secondary" onClick={e => showReceipt(payment.payment_id)}>
                    <Tooltip
                        placement="top"
                        title={getPaymentMethodLabel(payment.method, payment.mode)}
                        enterDelay={500}
                        leaveDelay={200}
                    >
                        <span>
                            <PaymentMethodIcon
                                paymentMethod={payment.method}
                                classFillPrimary={classes.fillPrimary}
                            />
                        </span>
                    </Tooltip>
                    {getPaymentMethodLabel(payment.method, payment.mode)}
                </Typography>

                <Typography
                    color="secondary"
                    className={classes.amount}
                    onClick={e => showReceipt(payment.payment_id)}
                >
                    {Transaction.dollarFormatUnsigned(payment)}
                </Typography>
            </div>

            <Typography component="div" className={classes.status} color="primary">
                {getPaymentTypeDescriptor(payment, isRestricted)}
                {payment.description &&
                    <span className={classes.description}>
                        {paymentMemo}
                    </span>
                }
            </Typography>

            <div
                className={classNames(
                    classes.buttonContainer,
                    classes.flexCentred,
                    (showAdjustPaymentButton || showOfferRefundButton) && classes.spaceBetween
                )}
            >
                <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    onClick={() => showReceipt(payment.payment_id)}
                >
                    <ReceiptIcon />&nbsp;View Receipt
                </Button>
                {showAdjustPaymentButton &&
                    <DisabledPermissionTooltip permission={Permission.EDIT_PAYMENTS}>
                        {disabled =>
                            <Button
                                disabled={disabled}
                                variant="outlined"
                                color="primary"
                                size="small"
                                onClick={e => showAdjustPaymentDialog()}
                            >
                                <SwapVertIcon />&nbsp;Adjust Payment
                            </Button>
                        }
                    </DisabledPermissionTooltip>
                }
                {showOfferRefundButton &&
                    <DisabledPermissionTooltip permission={Permission.EDIT_PAYMENTS}>
                        {disabled =>
                            <Button
                                variant="outlined"
                                color="primary"
                                size="small"
                                disabled={disabled}
                                onClick={e => showRefundDialog(payment)}
                            >
                                <MoneyOffIcon />&nbsp;Offer Refund
                            </Button>
                        }
                    </DisabledPermissionTooltip>
                }
            </div>
        </div>
    );
};

type StyledPayerCardProps = PayerCardProps & StyleProps;

interface PayerCardProps {
    isFamilyUser: boolean | null;
    totalDebits: number;
    helpers: EntitySummary[];
    selectedCase: GatherCaseUX;
    paymentGroup: PaymentGrouping;
    patchPayment: (...params: PatchPaymentParameters) => void;
    isRestricted: boolean;
    feeSchedule: FeeSchedule | null;
    isHelperText?: boolean;
    zIndex: number;
    removePayer: (payerId: number, payerName: string) => void;
    deletePayment: (paymentId: number, payerName?: string) => void;
    showPaymentDialog: (paymentInfo: PaymentRequest, payer: Payer, isRestricted: boolean) => void;
    showReceipt: (paymentId: number) => void;
    proposePayment: (...params: Parameters<typeof proposePayment>) => void;
    openRestrictedPaymentFeaturePopper: (restrictedFeature: RestrictedPaymentFeature) => void;
    showRefundDialog: (paymentTransaction: PaymentTransaction) => void;
    showAdjustPaymentDialog: (paymentInfo: PaymentRequest, payer: Payer) => void;
    patchPaymentMode: (paymentId: number, mode: PaymentMode) => void;
    onAddNonUserContactInfoClick: (nonUserId: number) => void;
    handleClickEventOnAvatar: (
        event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>,
        entityId: number,
    ) => void;
    setDownloadInvoice: (downloadInvoice: boolean, payerId: number, callback?: () => void) => void;
}

const PayerCard = (props: StyledPayerCardProps) => {
    const {
        classes,
        helpers,
        paymentGroup,
        totalDebits,
        isFamilyUser,
        isRestricted,
        feeSchedule,
        isHelperText,
        selectedCase,
        zIndex,
        showPaymentDialog,
        openRestrictedPaymentFeaturePopper,
        showRefundDialog,
        showAdjustPaymentDialog,
        handleClickEventOnAvatar,
        setDownloadInvoice,
        showReceipt,
        deletePayment,
        patchPayment,
        patchPaymentMode,
        onAddNonUserContactInfoClick
    } = props;

    const downloadInvoice = (payerId: number) => {
        setDownloadInvoice(true, payerId, () => {
            printInvoice();
        });
    };

    const printInvoice = () => {
        const printContents = document.getElementById('print-content');
        const printContainer = document.getElementById('print-container');

        if (!printContents || !printContainer) {
            return;
        }

        const invoiceStyles = `
        <style>
            @page {
                margin: 24px;
            }
        </style>`;

        printContainer.innerHTML = printContents.innerHTML + invoiceStyles;
        window.print();

        setDownloadInvoice(false, -1);
    };

    const proposePaymentLocal = () => {

        props.proposePayment({
            payer_id: paymentGroup.payer_id,
            payer_name: '',
            method: PaymentMethod.unknown,
            mode: PaymentMode.IN_PERSON,
            type: PaymentType.CASE,
            amount: Dinero({ amount: 0, currency: 'USD' }),
            merch_fee: Dinero({ amount: 0, currency: 'USD' }),
            memo: '',
            funeralHomeCaseId: selectedCase.funeral_home_case_id,
            is_anon: false
        });
    };

    const payments = paymentGroup.transactions;
    const firstPayment = payments[0];
    const helper = helpers.find(h => Boolean(h.entity_id === firstPayment.payer_id));
    const payer: Payer = PaymentTransaction.constructPayer(firstPayment, helper);
    const canRemove = firstPayment.status !== 'succeeded' && firstPayment.method === PaymentMethod.insurance;
    const removeButtonLabel = firstPayment.method === PaymentMethod.insurance
        ? 'Remove this policy'
        : 'Remove this payer';

    const caseEntity = getCaseEntity(helper || null, selectedCase.id);
    const displayRelationship = helper && caseEntity && getDisplayRelationshipForPerson(caseEntity);

    return (
        <div className={classes.root}>
            <Card className={classes.card}>
                <CardContent className={classes.cardContent}>
                    {firstPayment.method === PaymentMethod.insurance &&
                        <Avatar className={classes.avatar}>
                            <InsertDriveFileIcon className={classes.fontSize36} />
                        </Avatar>
                        || helper &&
                        <UserAvatar
                            user={helper}
                            photoClassName={classNames(classes.avatar, classes.cursorPointer, classes.top18)}
                            initialsClassName={classNames(classes.avatar, classes.cursorPointer, classes.top18)}
                            size={68}
                            onClick={e => handleClickEventOnAvatar(e, helper.entity_id)}
                        />
                        || <Avatar
                            className={classNames(classes.avatar, helper && classes.cursorPointer)}
                            onClick={e => helper && handleClickEventOnAvatar(e, helper.entity_id)}
                        >
                            {generateNameInitials(`${payer.payerEntity.fname} ${payer.payerEntity.lname}`)}
                        </Avatar>}
                    <CardHeader
                        heading={firstPayment.payer_name || ''}
                        subheading={displayRelationship
                            ? `${selectedCase.fname}'s ${displayRelationship}`
                            : undefined}
                        received={'$T,ODO'}
                        expected={Transaction.dollarFormatNumberAbs(paymentGroup.total)}
                        collected={100}
                        paymentCount={1}
                        rootClass={classes.cardHeaderFontWeight}
                    />

                    {payments.map((payment, idx) => (
                        <div key={payment.key}>
                            {payment.is_proposed && payment.method !== PaymentMethod.insurance && <PaymentCard
                                feeSchedule={feeSchedule}
                                funeralHomeCaseId={selectedCase.funeral_home_case_id}
                                caseUuid={selectedCase.uuid}
                                patchPaymentMode={patchPaymentMode}
                                onAddNonUserContactInfoClick={onAddNonUserContactInfoClick}
                                payment={payment}
                                isHelperText={isHelperText}
                                showPaymentDialog={showPaymentDialog}
                                payer={payer}
                                patchPayment={patchPayment}
                                deletePayment={(paymentId) => {
                                    if (payments.length === 1) {
                                        deletePayment(paymentId, firstPayment.payer_name || '');
                                        return; // toggle the confirmation dialog
                                    }
                                    deletePayment(paymentId);
                                }}
                                isInPersonMode={payment.status === 'proposed'}
                                isFirstEle={idx === 0}
                                totalDebits={totalDebits}
                                openRestrictedPaymentFeaturePopper={openRestrictedPaymentFeaturePopper}
                                isFamilyUser={isFamilyUser}
                                zIndex={zIndex}
                            />}
                            {payment.is_proposed && payment.method === PaymentMethod.insurance &&
                                <PolicyCard
                                    key={payment.key}
                                    payment={payment}
                                    payer={payer}
                                    funeralHomeCaseId={selectedCase.funeral_home_case_id}
                                    showPaymentDialog={showPaymentDialog}
                                />}
                            {!payment.is_proposed &&
                                <PaymentReceivedCard
                                    showReceipt={showReceipt}
                                    classes={classes}
                                    payment={payment}
                                    payer={payer}
                                    funeralHomeCaseId={selectedCase.funeral_home_case_id}
                                    isFirstEle={idx === 0}
                                    isRestricted={isRestricted}
                                    showRefundDialog={showRefundDialog}
                                    showAdjustPaymentDialog={showAdjustPaymentDialog}
                                />}
                        </div>
                    ))}

                    {firstPayment.method !== PaymentMethod.insurance &&
                        <div className={classes.footer}>
                            <DisabledPermissionTooltip permission={Permission.ADD_PAYMENTS}>
                                {disabled =>
                                    <Button
                                        color="secondary"
                                        disabled={disabled}
                                        onClick={proposePaymentLocal}
                                        className={isFamilyUser && classes.isRestricted || undefined}
                                    >
                                        + Add New Payment for {firstPayment.payer_name}
                                    </Button>
                                }
                            </DisabledPermissionTooltip>
                        </div>}

                    {canRemove && <div className={classes.footer}>
                        <DisabledPermissionTooltip permission={Permission.REMOVE_PAYMENTS}>
                            {disabled =>
                                <Button
                                    disabled={disabled}
                                    className={classNames(classes.colorRed, classes.fontSize16)}
                                    onClick={() => props.removePayer(
                                        payer.payerEntity.id,
                                        firstPayment.payer_name || ''
                                    )}
                                >
                                    <RemoveCircleOutlineIcon />&nbsp;
                                    {removeButtonLabel}
                                </Button>
                            }
                        </DisabledPermissionTooltip>
                    </div>}
                </CardContent>
            </Card>
            <div className={classes.downloadInvoice}>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => downloadInvoice(paymentGroup.payer_id)}
                    className={classes.downloadInvoiceBtn || undefined}
                >
                    <CloudDownloadIcon /> &nbsp; View Payer Invoice
                </Button>
            </div>
        </div>
    );
};

export default withGStyles(styles<PayerCardProps>())(PayerCard);
