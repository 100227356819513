import * as React from 'react';
import classNames from 'classnames';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import AddIcon from '@mui/icons-material/Add';

import {
    ProductCategoryEnum,
    ProductContractItemUX,
    DocPacketContractSignerRecord,
    DocPacketSignerUX,
    ContractOptionsUX,
    ContractDisclaimerUX,
    ContractPackageView,
    ContractCategoryGrouping,
    getContractGrouping,
    ContractCategoryGroup,
    ProductPackageUX,
    ProductSubPackage,
    ContractPackageGroup,
    isSubPackage,
    LegalLocation,
    ContractCategoryGroupConfig,
    CaseTransactions,
} from '../../../../shared/types';

import { StyledProps, styleWrapper, typographyStyle } from './styles';
import { DisclaimersSection } from './widgets';
import {
    calculateTotalForItems,
    formatDinero,
    formatPrice,
} from '../../../../shared/goods_and_services/pricing';
import {
    isPackageDiscount,
    isAllowanceCredit,
    isContractDiscount,
} from '../../../../shared/goods_and_services/utils';

import { PayerDetails, PrintModeType } from '..';

import Header, { FuneralHomeDetails, CaseDetails } from './widgets/Header';
import Totals from './widgets/Totals';
import Signatures from './widgets/Signatures';
import { getLegalContractContent } from '../../../funeralHomes/goodsAndServices/AddLegalContract';
import Tooltip from '@mui/material/Tooltip';
import CategoryGroupContent from './widgets/CategoryGroupContent';
import { getSubPackages } from '../../../../shared/goods_and_services/packages';
import ContractPackages from './contractPackages';
import withGStyles from '../../../../styles/WithGStyles';
import { StyledProps as ItemStyles } from '../payment/widgets/Details';
import Item from '../payment/widgets/Item';
import { GStyles } from '../../../../styles/GStyles';

type Props = {
    children?: JSX.Element;
    isPrintMode: boolean;
    printModeType?: PrintModeType;
    isCondensedView: boolean;
    isContractFrozen: boolean;
    canUserEditContract: boolean;
    canUserEditCase: boolean;
    printElementId?: string;
    teamContractSigners: DocPacketContractSignerRecord[];
    helperContractSigners: DocPacketContractSignerRecord[];
    docPacketSigners: DocPacketSignerUX[];
    funeralHomeDetails: FuneralHomeDetails;
    caseDetails: CaseDetails;
    contractPackages: (ProductPackageUX | ProductSubPackage)[];
    contractDisclaimers: ContractDisclaimerUX[];
    contractOptions: ContractOptionsUX;
    contractItems: ProductContractItemUX[];
    contractSubTotal: Dinero.Dinero;
    contractTaxTotal: Dinero.Dinero;
    contractGrandTotal: Dinero.Dinero;
    legalUnavailableText: string;
    legalTextStyle?: React.CSSProperties;
    containerStyle?: React.CSSProperties;
    onDisclaimerReasonClick?: (disclaimerId: number) => void;
    onConfigureDisclaimerClick?: () => void;
    onAdjustContractTotalClick?: (contractItemId?: string) => void;
    onTaxManagementClick: () => void;
    onLegalTextClick?: (loactionType: LegalLocation) => void;
    onCaseNumberClick?: () => void;
    renderCategoryContent: (
        category: ProductCategoryEnum | 'package',
        categoryItemIds: string[],
    ) => JSX.Element | null;
    overridePackagesRender?: () => JSX.Element | null;
    onAddAdditionalItemClick?: () => void;
    isFHorGOMUser: boolean;
    isItemizedStatement: boolean;
    transactions: CaseTransactions;
    isFamilyUser: boolean | null;
    loggedInUserId: number | null;
    isDownloadingInvoice: boolean;
    payerDetails?: PayerDetails;
    isShowPayments: boolean;
};

const ContractContent = (props: Props & ItemStyles & StyledProps) => {

    const {
        classes,
        isPrintMode,
        printModeType,
        printElementId,
        isCondensedView,
        isContractFrozen,
        canUserEditContract,
        canUserEditCase,
        teamContractSigners,
        helperContractSigners,
        docPacketSigners,
        funeralHomeDetails,
        caseDetails,
        contractPackages,
        contractDisclaimers,
        contractOptions,
        contractItems,
        contractSubTotal,
        contractTaxTotal,
        contractGrandTotal,
        renderCategoryContent,
        overridePackagesRender,
        legalUnavailableText,
        legalTextStyle,
        containerStyle,
        onLegalTextClick,
        onCaseNumberClick,
        onDisclaimerReasonClick,
        onConfigureDisclaimerClick,
        onAdjustContractTotalClick,
        onTaxManagementClick,
        onAddAdditionalItemClick,
        isFHorGOMUser,
        isItemizedStatement,
        isFamilyUser,
        transactions,
        loggedInUserId,
        isDownloadingInvoice,
        payerDetails,
        isShowPayments
    } = props;

    const isItemizedOrInvoice = isItemizedStatement || isDownloadingInvoice;

    const { package_view, hide_package_item_prices, equal_contract_spacing } = contractOptions;

    let categoryGrouping = ContractCategoryGrouping.Default;
    if (isCondensedView) {
        if (contractOptions.use_five_category_groups) {
            categoryGrouping = ContractCategoryGrouping.NewJersey;
        } else if (contractOptions.use_two_category_groups) {
            categoryGrouping = ContractCategoryGrouping.NewYork;
        }
    }

    const splitOutPackageItems = package_view === ContractPackageView.package_only
        || package_view === ContractPackageView.grouped;

    const itemsForCategories = splitOutPackageItems
        ? contractItems.filter((item) => item.package_id === null)
        : contractItems;

    const [leftGrouping, rightGrouping] = getContractGrouping(categoryGrouping, false);

    // Package Groups
    let packageGroups: ContractPackageGroup[] = [];
    for (const pkg of contractPackages) {
        if (isSubPackage(pkg)) {
            continue;
        }
        const subPkgs = getSubPackages(pkg);
        let items = contractItems.filter(item => item.package_id === pkg.id);
        const subTotal = calculateTotalForItems(items);

        if (hide_package_item_prices && splitOutPackageItems) {
            items = items.filter(item => !isPackageDiscount(item));
        }

        const group: ContractPackageGroup = {
            pkgId: pkg.id,
            subTotalLabel: pkg.name,
            legalTextLocation: null,
            categories: [],
            subTotal,
            items,
            title: pkg.name,
            subPackages: subPkgs.map((sub) => sub.name),
            pkgPrice: pkg.price ? formatPrice(pkg.price, pkg.asset_type, '$0,0') : null,
        };
        packageGroups.push(group);
    }

    // Product Groups
    const getProductGroups = (contractGrouping: ContractCategoryGroupConfig[]) =>
        contractGrouping.map((config) => {

            const items: ProductContractItemUX[] = itemsForCategories.filter((i) =>
                config.categories.some((cat) => cat === i.category)
            );
            const subTotal = calculateTotalForItems(items);
            const group: ContractCategoryGroup = {
                ...config,
                items,
                subTotal,
            };
            return group;
        });

    const productGroupsLeft: ContractCategoryGroup[] = getProductGroups(leftGrouping);
    const productGroupsRight: ContractCategoryGroup[] = getProductGroups(rightGrouping);

    const productGroups: ContractCategoryGroup[] = [...productGroupsLeft, ...productGroupsRight];

    const groupsForTotal: ContractCategoryGroup[] = [...productGroups];
    if (splitOutPackageItems) {
        groupsForTotal.unshift(...packageGroups);
    }

    const packageDiscounts = itemsForCategories.filter((item) => isPackageDiscount(item) && item.list_price !== 0);
    const allowanceCredits = itemsForCategories.filter((item) => isAllowanceCredit(item) && item.list_price !== 0);
    const contractDiscounts = itemsForCategories.filter((item) => isContractDiscount(item) && item.list_price !== 0);

    const visibleDisclaimers = contractDisclaimers.filter((disclaimer) => !isPrintMode || disclaimer.reason);

    const renderLegalText = (location: LegalLocation) => {
        const isTopOrBottom = location === 'top' || location === 'bottom';
        const content = getLegalContractContent(contractOptions, location);
        const isTop = location === 'top';
        const isInvoiceLanguage = location === LegalLocation.invoice_language;

        if (isTop && !content.legalText && !legalUnavailableText) {
            return null;
        }

        return (
            <Typography
                color="secondary"
                className={classNames(
                    classes.typographySmall,
                    isTop && content.legalText && isCondensedView && classes.borderTopPrimary
                )}
                style={{
                    ...typographyStyle,
                    ...legalTextStyle,
                    fontSize: isCondensedView ? isTop ? 11 : 10 : 12,
                    lineHeight: (isTop || isInvoiceLanguage) ? '1.125em' : '1em',
                    fontWeight: 300,
                    whiteSpace: 'pre-line',
                    padding: isTopOrBottom && isCondensedView && '2px 8px'
                        || isTopOrBottom && '2px 8px 8px'
                        || isInvoiceLanguage && '2px 0px'
                        || '4px 0',
                    display: isCondensedView && isTopOrBottom && 'flex'
                        || isCondensedView && 'block'
                        || 'inherit',
                    margin: isInvoiceLanguage && '10px 4px 0px 4px'
                        || isTopOrBottom && 0
                        || 'auto',
                    width: isCondensedView && 'calc(100% - 16px)'
                        || 'auto',
                    cursor: onLegalTextClick !== undefined ? 'pointer' : undefined,
                    textTransform: 'none',
                    justifyContent: isTopOrBottom && 'flex-start' || 'center',
                    color: isTopOrBottom && legalTextStyle && legalTextStyle.color
                        || !content.legalText && legalTextStyle && legalTextStyle.color
                        || isInvoiceLanguage && '#000'
                        || undefined,
                    borderTop: printModeType === 'official' && isTop && '1px solid' || undefined,
                    textAlign: 'left'
                }}
                onClick={onLegalTextClick !== undefined ? () => onLegalTextClick(location) : undefined}
            >
                {content.legalText || legalUnavailableText}
            </Typography>
        );
    };

    const renderProductGroups = (ProductsGroup: ContractCategoryGroup[]) => {
        return (
            ProductsGroup.map((group) => (
                <CategoryGroupContent
                    key={group.title}
                    classes={classes}
                    isCondensedView={isCondensedView}
                    isPrintMode={isPrintMode}
                    isItemizedStatement={isItemizedStatement}
                    subtotalLabel={group.subTotalLabel}
                    legalText={group.legalTextLocation && renderLegalText(group.legalTextLocation)}
                    title={group.title}
                    subtotalAmount={formatDinero(group.subTotal)}
                    categoryContent={group.categories.map((cat) => renderCategoryContent(
                        cat,
                        group.items.filter((i) => i.category === cat).map((i) => i.id),
                    ))}
                />
            ))
        );
    };

    const renderPackages = () => {
        return overridePackagesRender
            ? overridePackagesRender()
            : <ContractPackages
                packageView={package_view}
                packageGroups={packageGroups}
                isCondensedView={isCondensedView}
                isPrintMode={isPrintMode}
                printModeType={printModeType}
                isItemizedStatement={isItemizedStatement}
                renderCategoryContent={renderCategoryContent}
            />;
    };

    const contractLeftSide = () => {
        return (
            <>
                {categoryGrouping !== ContractCategoryGrouping.NewJersey
                    && renderPackages()
                }

                {renderProductGroups(productGroupsLeft)}

                {categoryGrouping === ContractCategoryGrouping.NewJersey
                    && renderPackages()
                }
            </>
        );
    };

    const getChargesOutsideStatement = () => {

        const Items = transactions.transactions.filter(item => (
            item.description !== `${caseDetails.caseFirstName}'s Contract`
            && item.type !== `PAYMENT`
        ));

        const grandTotal = transactions.transactions.reduce(
            (acc, currentValue) => {
                return (acc + currentValue.debit + currentValue.sales_tax);
            },
            0);

        return (
            <Grid style={{ marginLeft: 8, borderTop: '1px solid' }}>
                <Typography className={classes.outsideChargesHeading}>CHARGES OUTSIDE OF STATEMENT</Typography>
                <div className={classes.marginBottom5}>
                    {Items.map((v, i) => (
                        <Item
                            key={v.key}
                            transaction={v}
                            openDetailMenu={() => {
                                return;
                            }}
                            showReceipt={() => {
                                return;
                            }}
                            editInvoice={() => {
                                return;
                            }}
                            isContractFrozen={isContractFrozen}
                            caseFirstName={caseDetails.caseFirstName}
                            navigateToContract={() => {
                                return;
                            }}
                            isFamilyUser={isFamilyUser}
                            loggedInUserId={loggedInUserId}
                            isPrintMode={isPrintMode}
                            outsideCharges
                            isShowPayments={isItemizedStatement || isDownloadingInvoice}
                        />
                    ))}
                </div>

                <div
                    className={classes.payments}
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        borderTop: '1px solid'
                    }}
                >
                    <Typography className={classes.grandTotal}>
                        GRAND TOTAL
                    </Typography>
                    <Typography className={classes.grandTotal}>
                        {(grandTotal / 100.0).toLocaleString('en-US', {
                            style: 'currency',
                            currency: 'USD',
                        })}
                    </Typography>
                </div>
            </Grid>
        );
    };

    const getPayments = (balance: string) => {
        const collectedTotal = (transactions.totals.collected_total / 100.0).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
        });

        const proposedTotal = (transactions.totals.proposed_total / 100.0).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
        });

        const balanceOwedByPayer = payerDetails && (payerDetails.balanceOwedByPayer / 100.0).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
        });

        return [
            { label: 'Total Payments', amount: collectedTotal, isSubItem: false },
            { label: 'Total Expected Payments', amount: proposedTotal, isSubItem: true },
            {
                label: 'Balance owed by you today', amount: balanceOwedByPayer, isSubItem: false,
                class: classes.balance
            },
            { label: 'Remaining balance after all expected payments', amount: balance, isSubItem: true },
        ];
    };

    const renderPayments = () => {
        const { totals } = transactions;
        const balance = (
            (
                totals.expense_total
                - totals.collected_total
                - totals.proposed_total)
            / 100.0
        ).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
        });

        const Items = transactions.transactions.filter((item) =>
            item.description !== `${caseDetails.caseFirstName}'s Contract`
        );

        const paymentDetails = getPayments(balance);

        // If the case transactions are still loading, show a loading message
        if (transactions.isLoading) {
            return (
                <Grid style={{ marginLeft: 8 }}>
                    <div style={{ borderBottom: '1px solid' }}>
                        <Typography
                            style={{
                                ...typographyStyle,
                                fontSize: 18,
                                textAlign: 'center',
                                fontWeight: 500,
                            }}
                        >
                            Calculating...
                        </Typography>
                    </div>
                </Grid>
            );
        }

        return (
            <Grid style={{ marginLeft: 8 }}>
                <div style={{ borderBottom: '1px solid' }}>
                    <Typography
                        style={{
                            ...typographyStyle,
                            fontSize: 18,
                            textAlign: 'center',
                            fontWeight: 500,
                        }}
                    >
                        Payments
                    </Typography>
                </div>

                {isDownloadingInvoice
                    ?
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {paymentDetails.map((payment, i) => {
                            return (
                                <div
                                    className={classes.payments}
                                    key={payment.label}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <Typography
                                        className={classNames(
                                            classes.paymentsText,
                                            payment.isSubItem && classes.opacity60,
                                            payment.isSubItem && classes.fontSize14,
                                            payment.class
                                        )}
                                    >
                                        {payment.label}
                                    </Typography>
                                    <Typography
                                        className={classNames(
                                            classes.paymentsText,
                                            payment.isSubItem && classes.opacity60,
                                            payment.isSubItem && classes.fontSize14,
                                            payment.class
                                        )}
                                    >
                                        {payment.amount}
                                    </Typography>
                                </div>
                            );
                        })}
                    </div>
                    :
                    <>
                        <div style={{ margin: '4px 0px' }}>
                            {Items.map((v, i) => (
                                <Item
                                    fullWidth={!isCondensedView}
                                    key={v.key}
                                    transaction={v}
                                    openDetailMenu={() => {
                                        return;
                                    }}
                                    showReceipt={() => {
                                        return;
                                    }}
                                    editInvoice={() => {
                                        return;
                                    }}
                                    isContractFrozen={isContractFrozen}
                                    caseFirstName={caseDetails.caseFirstName}
                                    navigateToContract={() => {
                                        return;
                                    }}
                                    isFamilyUser={isFamilyUser}
                                    loggedInUserId={loggedInUserId}
                                    isPrintMode={isPrintMode}
                                    isShowPayments={isShowPayments || isItemizedStatement || isDownloadingInvoice}
                                />
                            ))}
                        </div>

                        <div
                            className={classes.payments}
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <Typography style={{ ...typographyStyle, fontSize: 18, fontWeight: 500 }}>
                                Remaining Balance
                            </Typography>
                            <Typography style={{ ...typographyStyle, fontSize: 18, fontWeight: 500 }}>
                                {balance}
                            </Typography>
                        </div>
                    </>
                }
            </Grid>
        );
    };

    const contractRightSide = () => {
        return (
            <>
                {renderProductGroups(productGroupsRight)}
                <div style={isPrintMode ? { marginRight: 8 } : undefined}>
                    <Totals
                        isPrintMode={isPrintMode}
                        isCondensedView={isCondensedView}
                        isContractFrozen={isContractFrozen}
                        canUserEditContract={canUserEditContract}
                        allGroups={groupsForTotal}
                        contractSubTotal={formatDinero(contractSubTotal)}
                        contractTaxTotal={formatDinero(contractTaxTotal)}
                        contractGrandTotal={contractGrandTotal}
                        packageDiscounts={packageDiscounts}
                        allowanceCredits={allowanceCredits}
                        contractDiscounts={contractDiscounts}
                        onAdjustContractTotalClick={onAdjustContractTotalClick}
                        onTaxManagementClick={onTaxManagementClick}
                        isNewYorkContract={categoryGrouping === ContractCategoryGrouping.NewYork}
                        isDownloadingInvoice={isDownloadingInvoice}
                    />

                    {isDownloadingInvoice && getChargesOutsideStatement()}
                    {(isItemizedOrInvoice || isShowPayments) && renderPayments()}

                    {!isItemizedOrInvoice &&
                        <>
                            <DisclaimersSection
                                classes={classes}
                                disclaimers={visibleDisclaimers}
                                legalText={contractOptions.legal_contract_disclaimer}
                                openDisclaimerReasonDialog={onDisclaimerReasonClick}
                                openConfigureDisclaimerDialog={onConfigureDisclaimerClick}
                                canUserEditContract={canUserEditContract}
                            />

                            {renderLegalText(LegalLocation.bottom)}

                            {printModeType === 'official' &&
                                <Signatures
                                    isPrintMode={isPrintMode || false}
                                    isCondensedView={isCondensedView}
                                    teamSigners={teamContractSigners}
                                    helperSigners={helperContractSigners}
                                    docPacketSigners={docPacketSigners}
                                    contractOptions={contractOptions}
                                />
                            }
                        </>
                    }
                </div>
            </>
        );
    };

    return (
        <Grid
            item
            xs={12}
            sm={11}
            id={printElementId}
            className={classNames(
                classes.printableSection,
                isContractFrozen && classes.noBorder
            )}
            style={{
                maxWidth: 820,
                padding: '0 24px',
                ...containerStyle,
            }}
        >
            <Header
                isPrintMode={isPrintMode || false}
                isCondensedView={isCondensedView}
                funeralHomeDetails={funeralHomeDetails}
                caseDetails={caseDetails}
                contractGrandTotal={formatDinero(contractGrandTotal)}
                onCaseNumberClick={onCaseNumberClick}
                isNewJerseyContract={categoryGrouping === ContractCategoryGrouping.NewJersey}
                canUserEditCase={canUserEditCase}
                isItemizedStatement={isItemizedStatement}
                isDownloadingInvoice={isDownloadingInvoice}
                payer={payerDetails?.payer}
                renderInvoiceLanguage={renderLegalText(LegalLocation.invoice_language)}
            />

            <Grid
                item
                className={classes.content}
                xs={12}
                sm={10}
                md={8}
                style={{ margin: '0px auto' }}
            >
                {!isItemizedOrInvoice && renderLegalText(LegalLocation.top)}
                <div style={isCondensedView ?
                    {
                        borderTop: '1px solid',
                        paddingTop: 8,
                        width: '100%'
                    }
                    : undefined} />
                <div
                    style={
                        isCondensedView ? {
                            display: 'flex',
                            flexDirection: 'column',
                            boxSizing: 'border-box'
                        } : undefined
                    }
                    className={isCondensedView ? classes.compactView : undefined}
                >
                    {isFHorGOMUser && !isPrintMode && onAddAdditionalItemClick &&
                        <div
                            style={{ margin: '0 auto 8px' }}
                            className={classNames(
                                GStyles.widthFitContent,
                                GStyles.positionRelative,
                                GStyles.flexCentred
                            )}
                        >
                            <Tooltip
                                title="Click to search all products across all categories."
                                placement="top"
                            >
                                <Button
                                    variant="contained"
                                    className={classes.addProductButton}
                                    onClick={e => onAddAdditionalItemClick()}
                                >
                                    <AddIcon />&nbsp;Add products to {caseDetails.caseFirstName}'s statement
                                </Button>
                            </Tooltip>
                        </div>
                    }

                    {(equal_contract_spacing || isItemizedOrInvoice) ?
                        <Grid
                            item
                            xs={12}
                            style={
                                isCondensedView
                                    ? {
                                        columnCount: 2,
                                        WebkitColumnCount: 2,
                                        MozColumnCount: 2,
                                        columnGap: '1px',
                                        WebkitColumnGap: '1px',
                                        MozColumnGap: '1px',
                                        columnRule: 'solid 1px',
                                        WebkitColumnRule: 'solid 1px',
                                        MozColumnRule: 'solid 1px',
                                    } : undefined
                            }
                            className={isCondensedView && !isPrintMode && classes.equalSpacingContainer || undefined}
                        >
                            <div
                                className={
                                    isCondensedView && !isPrintMode && classes.compactViewContent || undefined
                                }
                                style={
                                    isCondensedView ? {
                                        width: '100%',
                                        padding: '0 0 8px'
                                    } : undefined
                                }
                            >
                                {contractLeftSide()}
                                {contractRightSide()}
                            </div>
                        </Grid>
                        :
                        <Grid
                            item
                            xs={12}
                            className={isCondensedView && classes.compactViewContainer || undefined}
                        >
                            <div
                                className={
                                    isCondensedView && !isPrintMode && classes.compactViewContent || undefined
                                }
                                style={
                                    isCondensedView ? {
                                        width: 'calc(50% - 1px)',
                                        maxWidth: isPrintMode && 'calc(50% - 1px)' || undefined,
                                        display: 'inline-block',
                                        borderRight: '1px solid',
                                        float: 'left',
                                        padding: '0 0 8px'
                                    } : undefined
                                }
                            >
                                {contractLeftSide()}
                            </div>
                            <div
                                style={isCondensedView ? {
                                    width: '50%',
                                    maxWidth: isPrintMode && '50%' || undefined,
                                    display: 'inline-block',
                                    padding: '0 0 4px',
                                    float: 'right'
                                } : undefined}
                                className={isCondensedView ? classes.compactViewContent : undefined}
                            >
                                {contractRightSide()}
                            </div>
                        </Grid>
                    }
                </div>
            </Grid>
        </Grid>
    );
};

const componentStyles = styleWrapper<Props>();
export default withGStyles(componentStyles)(ContractContent);
