import { MouseEventHandler } from 'react';
import classNames from 'classnames';
import moment from 'moment-timezone';

import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';

import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import CloseIcon from '@mui/icons-material/Close';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import { useQRStepperCommonStyles } from '.';
import { TrackingItem, TrackingItemStatus, TrackingItemType } from '../BodyTrackingContent';
import UserAvatar from '../../../common/UserAvatar';
import useWidth, { isWidthDown } from '../../../common/hooks/useWidth';
import {
    DateTimeFormat,
    getDatesDiffDurations,
    getDurationsForDuration,
    getIntercomTargetProp,
    joinNameParts,
    MomentDurations,
} from '../../../../services';
import { log } from '../../../../logger';
import makeGStyles from '../../../../styles/makeGStyles';
import { SUCCESS_COLOR } from '../../../../constants/colorVariables';
import IconWithStepDetailPopper from '../../tasks/simpleLayout/completedStepsStepper/IconWithStepDetailPopper';
import SeeMoreText from './SeeMoreText';
import ComponentAnswers from './ComponentAnswers';
import { TaskComponentType, TaskComponentUX } from '../../../../shared/types';

enum TimeAbbreviations {
    years = 'y',
    months = 'mo',
    weeks = 'w',
    days = 'd',
    hours = 'h',
    minutes = 'm',
    seconds = 's',
};

const getCorrectPlurality = (text: string, value: number) => {
    return value > 1
        ? text
        : text.slice(0, -1);
};

// always show two forms of duration
// EXCEPTION: durations <1hr will show just minutes
const getVisibleDurations = (durations: MomentDurations): { key: string; value: number }[] => {
    let breakOnNext = false;
    const visibleDurations = [];
    for (const key of Object.keys(durations)) {
        if (durations[key] || breakOnNext) {
            visibleDurations.push({
                key,
                value: durations[key] || 0,
            });

            // break after we have two forms of duration (i.e. years & months OR hours and minutes)
            if (breakOnNext) {
                break;
            } else {
                breakOnNext = true;
            }
        }
    }
    return visibleDurations;
};

export const getLocationDurationTooltip = (params: {
    durations: MomentDurations;
    caseFName: string;
    isCurrent: boolean;
    movedTime?: moment.Moment;
}) => {
    const { durations, caseFName, movedTime, isCurrent } = params;

    const longDuration = getLongKeepTrackDuration({
        durations,
    });
    let tooltip: string;
    if (longDuration === 'Just Now') {
        if (isCurrent) {
            tooltip = `${caseFName} was just moved to this location`;
        } else {
            tooltip = `${caseFName} was in this location for only a moment`;
        }
    } else {
        const tense = isCurrent ? 'has been' : 'was';
        tooltip = `${caseFName} ${tense} in this location for ${longDuration || ''}`;
    }
    if (movedTime) {
        tooltip += `. Moved here on ${movedTime.format(DateTimeFormat.ShortDateWithTimeAndTZ)}`;
    }
    return tooltip;
};

export const getLongKeepTrackDuration = (params: {
    durations: MomentDurations | null;
    withAgo?: boolean;
}) => {
    const { durations, withAgo } = params;
    if (!durations) {
        return null;
    }

    const visibleDurations = getVisibleDurations(durations);
    let formattedDate = visibleDurations.map(
        ({ key, value }) => `${value} ${getCorrectPlurality(key, value)}`
    ).join(' and ');
    if (formattedDate && withAgo) {
        formattedDate += ' ago';
    }

    if (!formattedDate) {
        formattedDate = 'Just Now';
    }

    return formattedDate;
};

export const getCompactKeepTrackDuration = (params: {
    durations: MomentDurations | null;
    withEllipses?: boolean;
    noJustNow?: boolean;
    withMinusPrefix?: boolean;
}) => {
    const { durations, withEllipses, withMinusPrefix, noJustNow } = params;
    if (!durations) {
        return null;
    }

    const visibleDurations = getVisibleDurations(durations);

    let formattedDate = visibleDurations.map(({ key, value }) => {
        const timeAbbreviation: string = TimeAbbreviations[key] || key.charAt(0);
        return `${value}${timeAbbreviation}`;
    }).join(' ');

    if (!formattedDate) {
        formattedDate = noJustNow ? '0m' : 'Just Now';
    }

    if (withEllipses) {
        formattedDate += '...';
    }
    if (withMinusPrefix && formattedDate !== 'Just Now') {
        formattedDate = `-${formattedDate}`;
    }
    return formattedDate;
};

const useStyles = makeGStyles(theme => ({
    marginBottomContainer: {
        marginBottom: -12
    },
    completedText: {
        border: 'none !important',
        '&:not($keepTrack)': {
            color: SUCCESS_COLOR,
        },
        '& svg': {
            color: `${theme.palette.common.white} !important`
        }
    },
    lastStep: {
        marginTop: 0,
    },
    iconMargin: {
        '& svg': {
            fontSize: '16px !important',
            marginTop: 3
        }
    },
    backgroundSuccess: {
        background: `${SUCCESS_COLOR} !important`
    },
    backgroundColorSecondary: {
        background: `${theme.palette.secondary.main} !important`,
    },
    crossIcon: {
        top: -4,
        left: -4,
        position: 'absolute',
        border: `1px solid`,
        borderRadius: '50%',
        background: '#fff',
        color: theme.palette.secondary.main,
        padding: '2px',
        '& svg': {
            color: `${theme.palette.secondary.main} !important`,
            fontSize: '14px !important'
        }
    },
    Subheading: {
        fontWeight: 500,
        display: 'flex',
        alignItems: 'center',
        justifyItems: 'center',
        lineHeight: 1,
        '& span': {
            fontSize: 14,
        }
    },
    avatar: {
        width: 16,
        height: 16,
        fontSize: 10,
    },
    secondaryText: {
        fontStyle: 'italic',
        paddingRight: 8,
        lineHeight: 1.25,
    },
    seeMore: {
        color: SUCCESS_COLOR,
    },
    date: {
        lineHeight: 1.125
    },
    timeAgoText: {
        color: theme.palette.secondary.main,
        fontStyle: 'italic',
        marginLeft: 10,
        textAlign: 'right',
    },
    width_100: {
        width: '100% !important'
    },
    marginBottom100: {
        marginBottom: '100px !important',
    },
    visibilityIcon: {
        marginLeft: 6,
        '& svg': {
            fontSize: 18,
        },
    },
    opacity1Important: {
        '& svg': {
            opacity: '1 !important'
        },
    },
    answerContainer: {
        marginTop: 4,
        flexDirection: 'column',
    },
    keepTrack: {},
    borderSuccess: {},
    loctionIcon: {},
    isSkipped: {},
}), { name: 'CompletedStep' });

interface Props {
    step: TrackingItem;
    isLastStep: boolean;
    caseFName: string;
    keepTrackKey: string;
    openNotesDialog: VoidFunction;
    openMenu: MouseEventHandler<HTMLButtonElement>;
    caseUuid: string;
}

const CompletedStep = (props: Props) => {
    const { step, caseFName, keepTrackKey, isLastStep, openMenu, openNotesDialog, caseUuid } = props;
    const { finishedTime } = step;

    const commonStyles = useQRStepperCommonStyles();
    const classes = useStyles();

    const screenWidth = useWidth();
    const isSmDown = isWidthDown('xs', screenWidth);

    const dateFormat = `${isSmDown ? 'ddd' : 'dddd'}, D MMM YYYY`;

    if (!finishedTime) {
        log.warn('No finished time for completed step', { step });
        return null;
    }

    const completedOn = finishedTime && `${finishedTime.format(dateFormat)} `
        + `at ${finishedTime.format('h:mm a z')}`;

    const isInitializeOrFinalize = step.itemType === TrackingItemType.initialize_step
        || step.itemType === TrackingItemType.finalize_step;
    const isNote = step.itemType === TrackingItemType.note;

    const isSkipped = step.status === TrackingItemStatus.skipped;
    const isWorkflowUpdate = step.itemType === TrackingItemType.workflow_update;

    const atLocationDurations = step.durationInLocation
        ? getDurationsForDuration(step.durationInLocation)
        : null;

    const locationDurationTooltip = !atLocationDurations ? '' : getLocationDurationTooltip({
        durations: atLocationDurations,
        caseFName,
        isCurrent: step.inLocation ?? false,
        movedTime: step.finishedTime || undefined,
    });

    const locationDurationCompact = getCompactKeepTrackDuration({
        durations: atLocationDurations,
        withEllipses: step.inLocation,
        noJustNow: true,
    });

    const completedDurations = getDatesDiffDurations({
        older: finishedTime,
        newer: moment(),
    });
    const completedDurationLong = getLongKeepTrackDuration({
        durations: completedDurations,
        withAgo: true,
    });
    const completedDurationCompact = getCompactKeepTrackDuration({
        durations: completedDurations,
        withMinusPrefix: true,
    });

    const onClick = step.onClick ?? (isNote ? openNotesDialog : undefined);

    const stepAnswers: TaskComponentUX[] | undefined = step.answers ?? undefined;

    return (
        <Grid container wrap="nowrap" className={commonStyles.stepWrapper} key={step.key}>
            <Grid
                className={classNames(
                    commonStyles.stepLeftContainer,
                    isInitializeOrFinalize && classes.marginBottomContainer,
                    isNote && commonStyles.noteContainer,
                    isInitializeOrFinalize && commonStyles.keepTrack
                )}
            >
                {step.icon &&
                    <div
                        className={classNames(
                            classes.completedText,
                            !isWorkflowUpdate && classes.cursorPointer,
                            isLastStep && classes.lastStep,
                            isNote && classes.iconMargin,
                            classes.positionRelative,
                            !isNote && classNames(
                                commonStyles.iconContainer,
                                commonStyles.borderRadius50,
                                classes.colorWhite,
                                classes.flexCentred,
                                classes.backgroundSuccess,
                                classes.opacity1Important

                            ),
                            isSkipped && classes.backgroundColorSecondary,
                            isWorkflowUpdate && commonStyles.workflowUpdated
                        )}
                        onClick={onClick}
                    >
                        {isSkipped &&
                            <Tooltip
                                title={`This task is skipped by ${step.finishedBy?.fname || ''}`}
                                enterDelay={100}
                            >
                                <Box className={classNames(classes.flexCentred, classes.crossIcon)}>
                                    <CloseIcon color="secondary" />
                                </Box>
                            </Tooltip>
                        }

                        {step.icon}
                    </div>
                }

                {step.iconImage &&
                    <div
                        className={classNames(
                            commonStyles.stepIconImage,
                            !isWorkflowUpdate && classes.cursorPointer,
                            commonStyles.stepImageBorderRadius,
                            commonStyles.iconContainer,
                            commonStyles.borderSuccess

                        )}
                        onClick={onClick}
                    >
                        {step.iconImage}
                    </div>
                }

                {step.itemType === TrackingItemType.move_step && !step.icon && !step.iconImage &&
                    <div
                        className={classNames(
                            commonStyles.stepIconImage,
                            !isWorkflowUpdate && classes.cursorPointer,
                            commonStyles.iconContainer,
                            commonStyles.borderSuccess,
                            classes.flexCentred,
                        )}
                        onClick={onClick}
                    >
                        <LocationOnIcon className={commonStyles.locationIcon} />
                    </div>
                }

                <div
                    className={classNames(
                        commonStyles.stepConnector,
                        !isLastStep && commonStyles.completedStepBorder,
                    )}
                />
            </Grid>

            <Grid className={classNames(
                commonStyles.completedStepContainer,
                isLastStep && classNames(classes.lastStep, classes.marginBottom100),
                (isNote || isInitializeOrFinalize) && classes.width_100
            )}>
                <Typography
                    component="div"
                    className={classNames(
                        commonStyles.labelContainer,
                        isInitializeOrFinalize && commonStyles.keepTrack
                    )}
                >
                    <span
                        className={classNames(
                            commonStyles.stepLabel,
                            !isWorkflowUpdate && classes.cursorPointer,
                            classes.completedText,
                            isInitializeOrFinalize && classNames(commonStyles.labelContent, classes.keepTrack),
                            isSkipped && commonStyles.isSkipped,
                            isWorkflowUpdate && commonStyles.workflowUpdated,
                            isNote && classes.clickable

                        )}
                        onClick={onClick}
                        {...getIntercomTargetProp(`TrackingPage-TrackTab-CompletedStepLabel`)}
                    >
                        {step.label}
                    </span>

                    <Grid container item xs={5} md={3} justifyContent="flex-end" wrap="nowrap">
                        {finishedTime &&
                            <Tooltip
                                placement="top"
                                title={completedDurationLong || ''}
                                enterDelay={600}
                            >
                                <span
                                    className={classes.timeAgoText}
                                    {...getIntercomTargetProp(`TrackingPage-TrackTab-TimeInStep`)}
                                >
                                    {completedDurationCompact}
                                </span>
                            </Tooltip>
                        }
                        {(!step.isVisibleToFamily && step.stepId) &&
                            <IconWithStepDetailPopper
                                Icon={VisibilityOffIcon}
                                caseUuid={caseUuid}
                                activeStepId={step.stepId}
                                iconButtonClass={classes.visibilityIcon}
                                iconTooltip={'Step not visible to the family'}
                                placement="top"
                                intercomTargetProp={`TrackingPage-TrackTab-Visibility`}
                            />
                        }
                        {(isWorkflowUpdate || isNote) &&
                            <Tooltip
                                title={isWorkflowUpdate
                                    ? 'WorkFlow adjustments are never visible to family'
                                    : 'Step notes are never visible to family'}
                                placement="top"
                            >
                                <VisibilityOffIcon
                                    color="primary"
                                    fontSize="small"
                                    sx={{ mt: '1px' }}
                                    className={classNames(classes.fontSize18, classes.visibilityIcon)}
                                />
                            </Tooltip>
                        }

                        {(isWorkflowUpdate || isNote)
                            ? <div
                                className={classNames(classes.pointerEventsNone, commonStyles.moreVertIcon)}
                                aria-hidden={true}
                            />
                            : <IconButton
                                className={commonStyles.moreVertIcon}
                                onClick={openMenu}
                                {...getIntercomTargetProp(`TrackingPage-TrackTab-StepMenu`)}
                            >
                                <MoreVertIcon color="secondary" />
                            </IconButton>
                        }
                    </Grid>
                </Typography>

                {!isNote &&
                    <Typography
                        color="secondary"
                        className={classNames(classes.fontStyleItalic, classes.date)}
                        {...getIntercomTargetProp(`TrackingPage-TrackTab-CompletedTime`)}
                    >
                        {completedOn}
                    </Typography>
                }

                {step.finishedBy && !isNote &&
                    <Grid container alignItems="center">
                        <Typography
                            color="secondary"
                            className={classes.Subheading}
                            component="div"
                            {...getIntercomTargetProp(`TrackingPage-TrackTab-CompletedBy`)}
                        >
                            <UserAvatar
                                size={20}
                                user={step.finishedBy}
                                className={classes.avatar}
                            />
                            &nbsp;
                            <span className={classes.displayFlex}>
                                {isSkipped && 'Skipped by '}
                                {joinNameParts(step.finishedBy)}
                            </span>
                        </Typography>

                        {isInitializeOrFinalize &&
                            <Typography
                                color="secondary"
                                className={classNames(
                                    classes.flexCentred,
                                    classes.fontWeight500,
                                    classes.marginLeft10
                                )}
                                {...getIntercomTargetProp(`TrackingPage-TrackTab-TrackerNumber`)}
                            >
                                <QrCode2Icon className={classNames(classes.fontSize16, commonStyles.marginRight2)} />
                                {keepTrackKey}
                            </Typography>
                        }

                        {locationDurationCompact &&
                            <Tooltip placement="top" enterDelay={600} title={locationDurationTooltip || ''}>
                                <Typography
                                    color="secondary"
                                    className={classNames(
                                        classes.marginLeft10,
                                        classes.flexCentred,
                                        commonStyles.lineHeight1
                                    )}
                                    {...getIntercomTargetProp(`TrackingPage-TrackTab-TimeInLocation`)}
                                >
                                    <AccessTimeIcon
                                        className={classNames(classes.fontSize16, commonStyles.marginRight2)}
                                    />
                                    <span className={classes.marginTop2}>{locationDurationCompact}</span>
                                </Typography>
                            </Tooltip>
                        }
                    </Grid>
                }

                {step.secondaryText?.map((txt) =>
                    <SeeMoreText
                        key={txt}
                        minLines={2}
                        text={txt}
                        seeMoreTextClass={classNames(classes.seeMore, classes.clickable)}
                        containerClass={commonStyles.marginTop4}
                        textClass={classNames(
                            classes.secondaryText,
                            isNote && classes.clickable
                        )}
                        onTextClick={isNote ? openNotesDialog : undefined}
                    />
                )}
                {stepAnswers &&
                    <Grid container className={classes.answerContainer}>
                        <Grid item>
                            <ComponentAnswers
                                componentType={TaskComponentType.checklist_questions}
                                questions={stepAnswers}
                            />
                        </Grid>
                        <Grid item>
                            <ComponentAnswers
                                componentType={TaskComponentType.text_questions}
                                questions={stepAnswers}
                            />
                        </Grid>
                        <Grid item>
                            <ComponentAnswers
                                componentType={TaskComponentType.multiple_choice_question}
                                questions={stepAnswers}
                            />
                        </Grid>
                    </Grid>
                }
            </Grid>
        </Grid>
    );
};

export default CompletedStep;
