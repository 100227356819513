import * as React from 'react';
import classNames from 'classnames';
import { TransitionGroup } from 'react-transition-group';
import { isEqual } from 'lodash';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Fade from '@mui/material/Fade';

import Clear from '@mui/icons-material/Clear';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import UserAvatar from '../../../common/UserAvatar';
import { lifeSpan } from '../../../../services';
import {
    EntityRelationship,
    EntityRelationshipType,
    FeatureKey,
    GatherCasePublic,
    getCaseEntity,
    getFuneralHomeId,
    UserProfile,
    UserRoles,
    VisitorCaseRequest,
    VisitorCreateRequest
} from '../../../../shared/types';
import SelfLoginSectionAnimation from './SelfLoginSectionAnimation';
import { registerNewVisitor, registerVisitorToCase } from '../../../../actions/visitor.action';
import ConfirmCloseDialog from './ConfirmCloseDialog';
import { attemptUserLogin, sendPasswordResetRequest, setLoginError } from '../../../../actions/UserSession.action';
import {
    isValidEmail,
    isValidPassword,
    isValidPhoneNumber,
    PasswordCheckResult,
    splitFullName
} from '../../../../shared/utils';
import { registerAppError } from '../../../../actions/errors';
import SelfLoginAccountCreationSuccess from './SelfLoginAccountCreationSuccess';
import { closeSelfLoginDialog } from '../../../../actions/Dialog.action';
import { canCreateMemory } from '../../../../shared/authority/can';
import { SelfLoginStyledProps, selfLoginStyles } from './SelfLogin.styles';
import ShareOptionsDialog from '../memories/postMemories/ShareOptions.dialog';
import withGStyles from '../../../../styles/WithGStyles';
import { AppDispatch } from '../../../../store';
import { StoreState } from '../../../../types';
import FuneralHomeLogo from '../../../common/FuneralHomeLogo';
import withState from '../../../common/utilHOC/WithState';
import { compose } from 'redux';
import { SlideTransition } from '../../../common/Transitions';
import withFullScreen from '../../../common/utilHOC/WithFullScreen';
import NameStep from './steps/NameStep';
import RelationshipStep from './steps/RelationshipStep';
import LoginStep from './steps/LoginStep';
import ResetPasswordStep from './steps/ResetPasswordStep';
import LoginCredentialsStep from './steps/LoginCredentialsStep';
import PostCommentsStep from './steps/PostCommentStep';
import { log } from '../../../../logger';
import PostPhotosStep from './steps/PostPhotosStep';
import SendFlowersStep from './steps/SendFlowersStep';
import { isEnabled } from '../../../common/AppFeature';
import { getOptionContent, ShareOptionType } from '../widgets/ShareLinkSection';
import SocialIconPopper from '../widgets/SocialIconPopper';
import { getShareOptions } from '../utils';

export enum SelfLoginSrcAction {
    sharePhoto = 'sharePhoto',
    shareText = 'shareText',
    signGuestbook = 'signGuestbook',
    flowerSale = 'flowerSale',
    treeSale = 'treeSale',
    postMemories = 'postMemories',
    other = 'other',
}

const mapStateToProps = ({
    casesState,
    userSession,
    dialogState,
    memoryState,
    funeralHomeState,
    whiteLabel,
}: StoreState) => {
    return {
        userData: userSession.userData,
        loginError: userSession.loginError,
        passwordResetMessage: userSession.passwordResetMessage,
        passwordResetPending: userSession.passwordResetPending,
        isOpen: dialogState.selfLogin.isOpen,
        srcAction: dialogState.selfLogin.srcAction || SelfLoginSrcAction.other,
        zIndex: dialogState.selfLogin.zIndex,
        isUserLoggedIn: !!userSession.userData,
        customerDetails: dialogState.selfLogin.customerDetails,
        onLoginSuccessCallback: dialogState.selfLogin.onLoginSuccessCallback,
        onAbortCallback: dialogState.selfLogin.onAbortCallback,
        onCloseCallback: dialogState.selfLogin.onCloseCallback,
        activeTheme: whiteLabel.rememberTheme,
        photosToUploadCount: dialogState.selfLogin.photosToUploadCount,
        memoryList: memoryState.memoryList,
        funeralHomeId: getFuneralHomeId(
            funeralHomeState.activeFuneralHome,
            casesState.selectedCase,
            casesState.publicCase),
        isFlowersLinkEnabled: isEnabled(FeatureKey.REMEMBER_FLOWER_LINK, funeralHomeState, casesState, userSession),
        isTreesLinkEnabled: isEnabled(FeatureKey.PLANT_TREES, funeralHomeState, casesState, userSession)
    };
};

interface DialogProps {
    fullScreen: boolean;
}

interface InjectedProps extends ReturnType<typeof mapStateToProps> {
    dispatch: AppDispatch;
}

interface Props {
    publicCase: GatherCasePublic;
}

type CombinedProps = SelfLoginStyledProps & DialogProps & Props & InjectedProps;

export enum LoginDialogStep {
    unknown = 'unknown',
    name = 'name',
    relationship = 'relationship',
    loginCredentials = 'loginCredentials',
    postComments = 'postComments',
    postPhotos = 'postPhotos',
    sendFlowers = 'sendFlowers',
    existingUser = 'existingUser',
    loginCreationSuccess = 'loginCreationSuccess',
}
export enum ExistingUserStep {
    login = 'login',
    passwordReset = 'passwordReset'
}

export enum LoginType {
    email = 'email',
    phone = 'phone',
}
interface LoginDialogStepState {
    active: LoginDialogStep;
    subStep: ExistingUserStep | null;
}
interface State {
    firstName: string;
    lastName: string;
    relationshipType: EntityRelationshipType | null;
    relationship: EntityRelationship | null;
    relationshipAlias: string;
    email: string;
    phone: string;
    loginType: LoginType;
    existingUserLoginEmailPhone: string;
    loginErrorMessage: string;
    password: string;
    localPasswordResetMessage: string;
    stepState: LoginDialogStepState;
    isPreviousStepRendering: boolean;
    hasLoginDetailsError: boolean;
    isConfirmCloseDialogOpen: boolean;
    invalidCredentials: boolean;
    hasEmailPhoneError: boolean;
    hasPasswordError: boolean;
    passwordErrorMessage: string[];
    isLoading: boolean;
    isPasswordResetInProgress: boolean;
    isShareOptionsDialogOpen: boolean;
    popperState: 'open' | 'close';
    socialIconPopperAnchor: HTMLElement | null;
    shareOptionType: ShareOptionType;
}

const INITIAL_STATE: State = {
    firstName: '',
    lastName: '',
    relationship: null,
    relationshipAlias: '',
    relationshipType: null,
    email: '',
    phone: '',
    loginType: LoginType.phone,
    existingUserLoginEmailPhone: '',
    loginErrorMessage: '',
    password: '',
    localPasswordResetMessage: '',
    stepState: {
        active: LoginDialogStep.name,
        subStep: ExistingUserStep.login
    },
    isPreviousStepRendering: false,
    hasLoginDetailsError: false,
    isConfirmCloseDialogOpen: false,
    invalidCredentials: false,
    hasEmailPhoneError: false,
    hasPasswordError: false,
    passwordErrorMessage: [],
    isLoading: false,
    isPasswordResetInProgress: false,
    isShareOptionsDialogOpen: false,
    socialIconPopperAnchor: null,
    popperState: 'close',
    shareOptionType: 'FACEBOOK'
};

const initializeState = (props: CombinedProps): State => {
    const { isUserLoggedIn, userData, publicCase, srcAction, customerDetails } = props;
    let initialState: Partial<State> & { stepState: LoginDialogStepState } = {
        stepState: { ...INITIAL_STATE.stepState }
    };
    if (isUserLoggedIn) {
        if (userData) {
            if (canCreateMemory(userData, { funeralHomeId: publicCase.funeral_home.id, caseId: publicCase.id })) {
                initialState.stepState.active = LoginDialogStep.loginCreationSuccess;
            } else {
                initialState.stepState.active = LoginDialogStep.relationship;
            }
        }
    } else if (
        (srcAction === SelfLoginSrcAction.flowerSale
            || srcAction === SelfLoginSrcAction.treeSale)
        && customerDetails
    ) {
        const nameParts = splitFullName(customerDetails.name);
        initialState.firstName = nameParts.fname;
        initialState.lastName = nameParts.lname;
        initialState.email = customerDetails.email;
        initialState.phone = customerDetails.phone;
    } else if (srcAction === SelfLoginSrcAction.other) {
        initialState.stepState = {
            active: LoginDialogStep.existingUser,
            subStep: ExistingUserStep.login,
        };
    }
    return { ...INITIAL_STATE, ...initialState };
};

export enum StepDirection {
    forward = 'forward',
    back = 'back',
}
class SelfLoginDialog extends React.Component<CombinedProps, State> {
    protected fileUploadInput: HTMLInputElement | null = null;
    protected contentRef = React.createRef<HTMLInputElement>();

    constructor(props: CombinedProps) {
        super(props);
        const initialState = initializeState(props);
        this.state = {
            ...initialState,
            ...this.setUserDetails(initialState),
        };
    }

    componentDidUpdate(prevProps: Readonly<CombinedProps>, prevState: Readonly<State>) {
        const { customerDetails: prevDetails } = prevProps;
        const { customerDetails, srcAction, isOpen } = this.props;

        if (isOpen && !prevProps.isOpen) {
            const activeStep = srcAction === SelfLoginSrcAction.other
                ? LoginDialogStep.existingUser
                : srcAction === SelfLoginSrcAction.postMemories
                    ? LoginDialogStep.postComments
                    : LoginDialogStep.name;

            this.setState(state => ({
                stepState: {
                    ...state.stepState,
                    active: activeStep
                }
            }));
        }

        if ((srcAction === SelfLoginSrcAction.flowerSale
            || srcAction === SelfLoginSrcAction.treeSale)
            && customerDetails && !isEqual(customerDetails, prevDetails)
        ) {
            const nameParts = splitFullName(customerDetails.name);
            this.setState({
                firstName: nameParts.fname,
                lastName: nameParts.lname,
                email: customerDetails.email,
                phone: customerDetails.phone,
            });
        }
    }

    setUserDetails = (currentState: State = this.state): {
        firstName: string;
        lastName: string;
        profilePhoto: string | null;
        relationship: EntityRelationship | null;
        relationshipAlias: string;
        relationshipType: EntityRelationshipType | null;
    } | null => {
        const { userData, publicCase } = this.props;
        if (!userData) {
            return null;
        }
        const caseEntity = getCaseEntity(userData, publicCase.id);
        return {
            firstName: userData.fname,
            lastName: userData.lname || '',
            profilePhoto: userData.photo,
            relationship: caseEntity ? caseEntity.relationship : currentState.relationship,
            relationshipAlias: caseEntity
                ? caseEntity.relationship_alias || '' : currentState.relationshipAlias,
            relationshipType: caseEntity ? caseEntity.relationship_type : currentState.relationshipType,
        };
    };

    /**
     * @returns 
     *   true if canCreateMemory and an onLoginSuccessCallback is defined 
     *   null if canCreateMemory but an onLoginSuccessCallback is NOT defined
     *   false if canCreateMemory is FALSE
     */
    runLoginSuccessActionIfAble = (user: UserProfile | null = this.props.userData): boolean | null => {
        const { publicCase, onLoginSuccessCallback } = this.props;
        if (canCreateMemory(user, { funeralHomeId: publicCase.funeral_home.id, caseId: publicCase.id })) {
            if (onLoginSuccessCallback) {
                onLoginSuccessCallback(user?.id);
                return true;
            }
            return null;
        }
        return false;
    };

    closeDialogWithAbort = () => {
        // User did not finish the process 
        const { onAbortCallback } = this.props;
        if (onAbortCallback) {
            onAbortCallback();
        }
        this.closeDialog();
    };

    closeDialogWithFailAndMessage = (args: { displayMessage: boolean }) => {
        const { displayMessage } = args;
        const { dispatch } = this.props;
        if (displayMessage) {
            dispatch(registerAppError('Funeral Home users can only post on cases within their Funeral Home.'));
        }
        this.closeDialog();
    };

    /**
     * This is the final closeDialog that must run to 
     * dispatch a call to redux to close the dialog
     * if onCloseCallback was set it will be processed 
     * before closing the dialog 
     * To check to see if they really want to close the dialog use handleCloseDialog()
     */
    closeDialog = () => {
        const { dispatch, onCloseCallback } = this.props;
        if ((this.state.stepState.active === LoginDialogStep.existingUser &&
            this.state.stepState.subStep === ExistingUserStep.passwordReset)
            || (this.state.stepState.active === LoginDialogStep.loginCreationSuccess)
            || (this.state.stepState.active === LoginDialogStep.existingUser)
        ) {
            this.resetState();
        }
        if (onCloseCallback) {
            onCloseCallback();
        }
        dispatch(closeSelfLoginDialog());
    };

    getNextStep = (currentStep: LoginDialogStep, direction: StepDirection): LoginDialogStep => {
        const { isUserLoggedIn } = this.props;
        switch (currentStep) {
            case LoginDialogStep.name:
                return direction === StepDirection.back ? LoginDialogStep.unknown : LoginDialogStep.relationship;
            case LoginDialogStep.relationship:
                return isUserLoggedIn
                    ? direction === StepDirection.back ? LoginDialogStep.unknown : LoginDialogStep.loginCreationSuccess
                    : direction === StepDirection.back ? LoginDialogStep.name : LoginDialogStep.loginCredentials;
            case LoginDialogStep.loginCredentials:
                return direction === StepDirection.back
                    ? LoginDialogStep.relationship : LoginDialogStep.postComments;
            case LoginDialogStep.postComments:
                return direction === StepDirection.back
                    ? LoginDialogStep.relationship : LoginDialogStep.postPhotos;
            case LoginDialogStep.postPhotos:
                return direction === StepDirection.back
                    ? LoginDialogStep.postComments : LoginDialogStep.sendFlowers;
            case LoginDialogStep.sendFlowers:
                return direction === StepDirection.back
                    ? LoginDialogStep.postPhotos : LoginDialogStep.unknown;
            case LoginDialogStep.existingUser:
                return direction === StepDirection.back
                    ? LoginDialogStep.name : LoginDialogStep.unknown;
            case LoginDialogStep.unknown:
            default:
                return isUserLoggedIn ? LoginDialogStep.relationship : LoginDialogStep.name;
        }
    };

    registerFileUpload = (fileUploadInput: HTMLInputElement) => {
        this.fileUploadInput = fileUploadInput;
    };

    renderNameStep = () => {
        const { srcAction, publicCase } = this.props;
        const { firstName, lastName } = this.state;

        return (
            <NameStep
                firstName={firstName}
                lastName={lastName}
                srcAction={srcAction}
                setFirstName={name => this.setState({ firstName: name })}
                setLastName={name => this.setState({ lastName: name })}
                goToStep={this.goToStep}
                nextStep={this.getNextStep(LoginDialogStep.name, StepDirection.forward)}
                classes={this.props.classes}
                caseDisplayFname={publicCase.display_fname}
                hasFHLogo={!!publicCase.funeral_home.photo}
            />
        );
    };

    /**
     * Step2 collect the relationship data
     *
     * @returns 
     */
    renderRelationshipStep = () => {
        const { srcAction, publicCase, isUserLoggedIn } = this.props;
        const { firstName, relationship, relationshipAlias, relationshipType } = this.state;

        return (
            <RelationshipStep
                publicCase={publicCase}
                isUserLoggedIn={isUserLoggedIn}
                srcAction={srcAction}
                firstName={firstName}
                relationshipType={relationshipType}
                relationshipAlias={relationshipAlias}
                relationship={relationship}
                setRelationship={this.setRelationship}
                goToStep={this.goToStep}
                registerExistingVisitor={this.registerExistingVisitor}
                nextStep={this.getNextStep(LoginDialogStep.relationship, StepDirection.forward)}
                classes={this.props.classes}
            />
        );
    };

    renderLoginStep = () => {
        const { classes, publicCase } = this.props;
        const {
            stepState,
            loginErrorMessage,
            hasLoginDetailsError,
            invalidCredentials,
            password,
            existingUserLoginEmailPhone,
            isLoading
        } = this.state;

        const isActive = stepState.active === LoginDialogStep.existingUser
            && stepState.subStep === ExistingUserStep.login;

        return (
            <LoginStep
                classes={classes}
                hasFHLogo={!!publicCase.funeral_home.photo}
                isActive={isActive}
                invalidCredentials={invalidCredentials}
                hasLoginDetailsError={hasLoginDetailsError}
                loginErrorMessage={loginErrorMessage}
                existingUserLoginEmailPhone={existingUserLoginEmailPhone}
                password={password}
                isLoading={isLoading}
                resetInvalidFlag={this._resetInvalidFlag}
                goToSubStep={this.goToSubStep}
                goBack={this.goBack}
                attemptLogin={this.attemptLogin}
                setPassword={_password => this.setState({ password: _password })}
                setExistingUserEmailPhone={_existingUserLoginEmailPhone =>
                    this.setState({ existingUserLoginEmailPhone: _existingUserLoginEmailPhone })
                }
            />
        );
    };

    renderResetPasswordStep = () => {
        const { classes, publicCase, passwordResetMessage, passwordResetPending } = this.props;
        const {
            isPasswordResetInProgress,
            hasLoginDetailsError,
            existingUserLoginEmailPhone,
            stepState,
            localPasswordResetMessage
        } = this.state;

        const isActive = stepState.active === LoginDialogStep.existingUser
            && stepState.subStep === ExistingUserStep.passwordReset;
        return (
            <ResetPasswordStep
                classes={classes}
                hasFHLogo={!!publicCase.funeral_home.photo}
                passwordResetMessage={passwordResetMessage}
                passwordResetPending={passwordResetPending}
                hasLoginDetailsError={hasLoginDetailsError}
                existingUserLoginEmailPhone={existingUserLoginEmailPhone}
                isActive={isActive}
                isPasswordResetInProgress={isPasswordResetInProgress}
                localPasswordResetMessage={localPasswordResetMessage}
                goToStep={this.goToStep}
                goToSubStep={this.goToSubStep}
                handleResetPassword={this.handleResetPassword}
                setExistingUserEmailPhone={_existingUserLoginEmailPhone =>
                    this.setState({ existingUserLoginEmailPhone: _existingUserLoginEmailPhone })
                }
            />
        );
    };

    renderLoginCredentialsStep = () => {
        const { classes, publicCase, loginError, } = this.props;
        const {
            loginType,
            password,
            email,
            phone,
            hasPasswordError,
            hasEmailPhoneError,
            isLoading,
            passwordErrorMessage,
            hasLoginDetailsError
        } = this.state;

        return (
            <LoginCredentialsStep
                hasFHLogo={!!publicCase.funeral_home.photo}
                loginError={loginError || ''}
                password={password}
                email={email}
                phone={phone}
                loginType={loginType}
                hasPasswordError={hasPasswordError}
                hasEmailPhoneError={hasEmailPhoneError}
                hasLoginDetailsError={hasLoginDetailsError}
                isLoading={isLoading}
                passwordErrorMessage={passwordErrorMessage}
                onUpdateEmailPhone={this.updateEmailPhone}
                onChangePassword={_password => this.setState({ password: _password }, this.validatePassword)}
                goToExistingUserLogin={this.goToExistingUserLogin}
                toggleLoginType={this.toggleLoginType}
                openConfirmCloseDialog={this.openConfirmCloseDialog}
                createNewVisitor={this.createNewVisitor}
                classes={classes}
            />
        );
    };

    renderLoginSuccess = () => {
        const { srcAction, zIndex } = this.props;
        return (
            <SelfLoginAccountCreationSuccess
                srcAction={srcAction}
                gotoLink={() => this.goToStep(LoginDialogStep.postComments)}
                gotoDisplayMessage={'Leave Comments'}
                zIndex={zIndex + 1}
            />
        );
    };

    renderProfilePhotoSection = () => {
        const {
            publicCase,
            userData,
            srcAction,
            photosToUploadCount,
            zIndex,
            memoryList,
            activeTheme,
            classes
        } = this.props;

        return (
            <Grid item xs={12}>
                <PostCommentsStep
                    publicCase={publicCase}
                    userData={userData}
                    srcAction={srcAction}
                    photosToUploadCount={photosToUploadCount}
                    memoryList={memoryList}
                    zIndex={zIndex + 1}
                    activeTheme={activeTheme}
                    classes={classes}
                    onCloseDialog={this.handleCloseDialog}
                    onSocialIconPopperOpen={this._openSocialIconPopper}
                    onSocialIconPopperClose={this._closeSocialIconPopper}
                    onShareOptionsDialogOpen={this._openShareOptionsDialog}
                />
            </Grid>
        );
    };

    renderPostPhotosSection = () => {
        const {
            publicCase,
            userData,
            srcAction,
            zIndex,
            classes,
            isFlowersLinkEnabled,
            isTreesLinkEnabled,
        } = this.props;

        const isFlowerEnabled = isFlowersLinkEnabled && publicCase.options.sell_flowers;
        const isTreeEnabled = isTreesLinkEnabled && publicCase.options.plant_trees;

        return (
            <Grid item xs={12} className={classes.positionRelative}>
                <KeyboardArrowLeftIcon
                    color="secondary"
                    className={classNames(classes.backArrow, classes.positionAbsolute, classes.cursorPointer)}
                    onClick={e => this.goToStep(this.getNextStep(this.state.stepState.active, StepDirection.back))}
                    sx={theme => ({ margin: 1, [theme.breakpoints.up('md')]: { margin: 0.5 } })}
                />

                <PostPhotosStep
                    publicCase={publicCase}
                    userData={userData}
                    srcAction={srcAction}
                    zIndex={zIndex + 1}
                    isFlowersLinkEnabled={isFlowerEnabled}
                    isTreesLinkEnabled={isTreeEnabled}
                    onCloseDialog={this.handleCloseDialog}
                    classes={classes}
                    onShareOptionsClick={this._openShareOptionsDialog}
                    onSocialIconPopperOpen={this._openSocialIconPopper}
                    onSocialIconPopperClose={this._closeSocialIconPopper}
                />
            </Grid>
        );
    };

    renderSendFlowersSection = () => {
        const {
            publicCase,
            classes
        } = this.props;

        return (
            <Grid item xs={12} className={classNames(classes.positionRelative, classes.height100)}>
                <KeyboardArrowLeftIcon
                    color="secondary"
                    className={classNames(classes.backArrow, classes.positionAbsolute, classes.cursorPointer)}
                    onClick={e => this.goToStep(this.getNextStep(this.state.stepState.active, StepDirection.back))}
                    sx={theme => ({ margin: 1, [theme.breakpoints.up('md')]: { margin: 0.5 } })}
                />

                <SendFlowersStep
                    publicCase={publicCase}
                    onCloseDialog={this.handleCloseDialog}
                    onForceCloseDialog={this.closeDialog}
                    classes={classes}
                />
            </Grid>
        );
    };

    renderStep = (step: LoginDialogStep) => {
        const { classes } = this.props;

        let content: JSX.Element | null = null;
        switch (step) {
            case LoginDialogStep.relationship:
                content = this.renderRelationshipStep(); // Relationship
                break;
            case LoginDialogStep.loginCredentials:
                content = this.renderLoginCredentialsStep(); // login creds (email/phone) and pass
                break;
            case LoginDialogStep.loginCreationSuccess:
                content = this.renderLoginSuccess(); // login creds (email/phone) and pass
                break;
            case LoginDialogStep.existingUser:
                content = (
                    <>
                        {this.renderResetPasswordStep()}
                        {this.renderLoginStep()}
                    </>
                );
                break;
            case LoginDialogStep.name:
            default:
                content = this.renderNameStep(); // Name (first/last)
                break;
        }

        return (
            <Grid
                item
                xs={12}
                className={classNames(
                    classes.leftSection,
                    step === LoginDialogStep.relationship && classes.step2
                )}
            >
                {content}
            </Grid>
        );
    };

    renderLeftSection = () => {
        const { stepState, isPreviousStepRendering } = this.state;

        const steps: LoginDialogStep[] = [
            LoginDialogStep.name,  //  Step 1
            LoginDialogStep.relationship,  // Step 2
            LoginDialogStep.loginCredentials, // Step 3
            LoginDialogStep.existingUser, // Step 
            LoginDialogStep.postComments,
            LoginDialogStep.loginCreationSuccess,
        ];

        return (
            <>
                {steps.map((step: LoginDialogStep) =>
                    <SelfLoginSectionAnimation
                        timeout={900}
                        key={`${step}_loginSectionAnimation`}
                        in={step === stepState.active}
                        enteringTransform={isPreviousStepRendering ? 'translateY(-100%)' : 'translateY(100%)'}
                        exitingTransform={isPreviousStepRendering ? 'translateY(100%)' : 'translateY(-100%)'}
                    >
                        {this.renderStep(step)}
                    </SelfLoginSectionAnimation>
                )}
            </>
        );
    };

    render() {
        const {
            classes,
            fullScreen,
            publicCase,
            zIndex,
            isUserLoggedIn,
            isOpen,
            activeTheme
        } = this.props;
        const { stepState, isConfirmCloseDialogOpen } = this.state;

        const hasFHLogo = publicCase.funeral_home.photo;
        const hasBackArrow = this.getNextStep(stepState.active, StepDirection.back) !== LoginDialogStep.unknown;

        const themeSecondaryColor = activeTheme && activeTheme.secondary_color || undefined;
        const themeBackgroundImage = activeTheme
            && `url(${activeTheme.secondary_background_image}) 0% 0%/50%`
            || undefined;

        const isCommentsOrPostPhotosOrFlowersActive = stepState.active === LoginDialogStep.postComments
            || stepState.active === LoginDialogStep.postPhotos
            || stepState.active === LoginDialogStep.sendFlowers;
        const paperClass = isCommentsOrPostPhotosOrFlowersActive
            ? classes.step4DialogPaper
            : classes.dialogPaper;

        return (
            <>
                <Dialog
                    open={isOpen}
                    fullScreen={fullScreen || isCommentsOrPostPhotosOrFlowersActive}
                    onClose={(evt, reason) => {
                        if (isCommentsOrPostPhotosOrFlowersActive && reason === 'backdropClick') {
                            return;
                        }

                        this.handleCloseDialog();
                    }}
                    scroll="body"
                    disableEscapeKeyDown={isCommentsOrPostPhotosOrFlowersActive}
                    TransitionComponent={SlideTransition}
                    transitionDuration={300}
                    style={{ zIndex }}
                    classes={{ paper: paperClass }}
                >
                    <DialogContent className={classes.dContentRoot} ref={this.contentRef}>
                        <Fade
                            in={!isCommentsOrPostPhotosOrFlowersActive}
                            timeout={stepState.active === LoginDialogStep.loginCredentials ? 900 : 0}
                            appear={stepState.active === LoginDialogStep.loginCredentials}
                            mountOnEnter
                            unmountOnExit
                        >
                            <Grid item xs={12} className={classes.dialogContent}>
                                <div className={classes.leftSectionContainer}>
                                    <Grid
                                        container
                                        style={{ justifyContent: !hasFHLogo ? 'flex-start' : undefined }}
                                        className={classNames(classes.header, hasBackArrow && classes.hasBackArrow)}
                                    >
                                        <KeyboardArrowLeftIcon
                                            color="secondary"
                                            className={classNames(classes.backArrow, classes.cursorPointer)}
                                            onClick={e => this.goBack()}
                                            style={!hasFHLogo
                                                ? { marginLeft: 8, transform: 'unset' }
                                                : undefined}
                                        />
                                        {hasFHLogo &&
                                            <div className={classes.fhLogoContainer}>
                                                <FuneralHomeLogo />
                                            </div>}
                                    </Grid>

                                    <TransitionGroup className={classes.transitionGroup}>
                                        {this.renderLeftSection()}
                                    </TransitionGroup>
                                </div>

                                <Grid
                                    item
                                    className={classNames(
                                        classes.rightSection,
                                        activeTheme && classes.hasActiveTheme
                                    )}
                                    style={{ background: themeBackgroundImage }}
                                >
                                    {hasBackArrow && <KeyboardArrowLeftIcon
                                        color="secondary"
                                        className={classes.rightSectionbackArrow}
                                        onClick={e => this.goBack()}
                                    />}
                                    <Clear
                                        className={classes.clearIcon}
                                        onClick={e => this.handleCloseDialog()}
                                    />
                                    <Grid item xs={12} className={classes.caseDetails}>
                                        <Grid item xs={12}>
                                            <IconButton
                                                className={classes.avatarButton}
                                                disableRipple
                                                disableTouchRipple
                                                size="large"
                                            >
                                                <UserAvatar
                                                    user={publicCase}
                                                    size={160}
                                                    className={classes.avatar}
                                                />
                                            </IconButton>

                                            <Grid item xs={12}>
                                                <Typography
                                                    className={classes.caseName}
                                                    style={{ color: themeSecondaryColor }}
                                                >
                                                    {publicCase.full_name}
                                                </Typography>
                                                {publicCase.dob_date && publicCase.dod_start_date &&
                                                    <Typography
                                                        className={classes.lifeSpan}
                                                        style={{ color: themeSecondaryColor }}

                                                    >
                                                        {lifeSpan(publicCase, 'YYYY')}
                                                    </Typography>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Fade>

                        <Fade
                            in={stepState.active === LoginDialogStep.postComments}
                            timeout={900}
                            mountOnEnter
                            unmountOnExit
                            appear
                            onEntered={() => this.contentRef.current?.scrollTo({ top: 0 })}
                        >
                            {this.renderProfilePhotoSection()}
                        </Fade>

                        <Fade
                            in={stepState.active === LoginDialogStep.postPhotos}
                            timeout={900}
                            onEntered={() => this.contentRef.current?.scrollTo({ top: 0 })}
                            mountOnEnter
                            unmountOnExit
                            appear
                        >
                            {this.renderPostPhotosSection()}
                        </Fade>

                        <Fade
                            in={stepState.active === LoginDialogStep.sendFlowers}
                            timeout={900}
                            onEntered={() => this.contentRef.current?.scrollTo({ top: 0 })}
                            mountOnEnter
                            unmountOnExit
                            appear
                        >
                            {this.renderSendFlowersSection()}
                        </Fade>
                    </DialogContent>
                </Dialog>

                {isOpen && <ConfirmCloseDialog
                    isUserLoggedIn={isUserLoggedIn}
                    isOpen={isConfirmCloseDialogOpen}
                    gatherCase={publicCase}
                    zIndex={zIndex + 1}
                    closeDialog={this.closeConfirmCloseDialog}
                    closeParentDialog={() => {
                        this.closeConfirmCloseDialog();
                        this.closeDialog();
                    }}
                />}

                {this._renderSharOptionsDialog()}
                {this._renderShareIconPopper()}
            </>
        );
    }

    handleResetPassword = () => {
        const { dispatch } = this.props;
        const { existingUserLoginEmailPhone } = this.state;
        this.setState({
            isPasswordResetInProgress: true,
        });
        const guessLoginType = this.guessLoginTypeFromValue(existingUserLoginEmailPhone);
        const email = isValidEmail(existingUserLoginEmailPhone) ? existingUserLoginEmailPhone : null;
        const phone = isValidPhoneNumber(existingUserLoginEmailPhone) ? existingUserLoginEmailPhone : null;
        let errorMessage = guessLoginType === LoginType.phone && !phone ? 'Please verify your 10 digit phone number'
            : guessLoginType === LoginType.email && !email ? 'Please enter your full email address'
                : 'It looks like you typed that incorrectly';
        if (!email && !phone) {
            this.setState({
                localPasswordResetMessage: errorMessage,
                hasLoginDetailsError: true,
            });
            return;
        }
        dispatch(sendPasswordResetRequest(email, phone));
    };

    updateEmailPhone = (emailPhone: string) => {
        const { loginType } = this.state;
        if (loginType === LoginType.email) {
            this.setState({
                email: emailPhone,
                existingUserLoginEmailPhone: emailPhone,
            });
        } else { // phone number
            this.setState({
                phone: emailPhone,
                existingUserLoginEmailPhone: emailPhone,
            });
        }
    };

    toggleLoginType = (wantLoginType?: LoginType) => {
        const { dispatch } = this.props;
        if (wantLoginType) {
            this.setState({
                loginType: wantLoginType,
            });
        } else {
            this.setState((prevState) => ({
                loginType: prevState.loginType === LoginType.email ? LoginType.phone : LoginType.email
            }));
        }
        dispatch(setLoginError(undefined));
    };

    guessLoginTypeFromValue = (emailOrPhone: string): LoginType => {
        const guessLoginType =
            100 * ((emailOrPhone.match(/\d/g) || []).length / emailOrPhone.length) > 50 ?
                LoginType.phone : LoginType.email;
        return guessLoginType;
    };

    closeConfirmCloseDialog = () => {
        this.setState({ isConfirmCloseDialogOpen: false });
    };

    openConfirmCloseDialog = () => {
        this.setState({ isConfirmCloseDialogOpen: true });
    };

    handleCloseDialog = () => {
        const { isFlowersLinkEnabled, isTreesLinkEnabled, publicCase } = this.props;
        const { stepState } = this.state;

        const isPostPhotosStep = stepState.active === LoginDialogStep.postPhotos && !(
            (isFlowersLinkEnabled && publicCase.options.sell_flowers)
            || (isTreesLinkEnabled && publicCase.options.plant_trees)
        );

        if (stepState.active === LoginDialogStep.loginCreationSuccess
            || isPostPhotosStep
            || stepState.active === LoginDialogStep.sendFlowers
        ) {
            this.closeDialog();

            if (stepState.active === LoginDialogStep.sendFlowers || isPostPhotosStep) {
                this._openShareOptionsDialog();
            }

            return;
        }

        if (stepState.active === LoginDialogStep.postComments) {
            this.goToStep(LoginDialogStep.postPhotos);
            return;
        } else if (stepState.active === LoginDialogStep.postPhotos) {
            this.goToStep(LoginDialogStep.sendFlowers);
            return;
        }
        this.openConfirmCloseDialog();
    };

    goToStep = (activeStep: LoginDialogStep, isBack?: boolean) => {
        const { isPreviousStepRendering } = this.state;

        const timeout = isPreviousStepRendering ? 350 : 0;
        this.setState(
            prevState => ({
                isPreviousStepRendering: isBack ? true : false,
                stepState: timeout !== 0
                    ? { ...prevState.stepState, active: LoginDialogStep.unknown }
                    : prevState.stepState
            }),
            () => setTimeout(
                () => {
                    if (activeStep === LoginDialogStep.existingUser) {
                        this.setSubStep(ExistingUserStep.login);
                        this.setState({ isPasswordResetInProgress: false });
                    }
                    this.setActiveStep(activeStep);
                },
                timeout
            )
        );
    };

    goToSubStep = (subStep: ExistingUserStep, delayOverride?: number) => {
        this.setSubStep(null);
        const delay = delayOverride !== undefined ? delayOverride : 650;
        setTimeout(() => this.setSubStep(subStep), delay);
    };

    goToExistingUserLogin = () => {
        this.goToStep(LoginDialogStep.existingUser);
        this.goToSubStep(ExistingUserStep.login, 10);
    };

    goBack = () => {
        const { stepState } = this.state;

        this.setState(
            prevState => ({
                isPreviousStepRendering: prevState.isPreviousStepRendering ? false : true,
                isPasswordResetInProgress: false,
            }),
            () => this.goToStep(this.getNextStep(stepState.active, StepDirection.back), true)
        );
    };

    validatePassword = (): void => {
        const { password } = this.state;
        const checkResults: PasswordCheckResult = isValidPassword(password);
        this.setState({
            hasPasswordError: !checkResults.valid,
            passwordErrorMessage: checkResults.errorMessage,
        });
    };

    setActiveStep = (active: LoginDialogStep) => {
        this.setState(prevState => ({
            stepState: { ...prevState.stepState, active }
        }));
    };
    setSubStep = (subStep: ExistingUserStep | null) => {
        this.setState(prevState => ({
            stepState: { ...prevState.stepState, subStep }
        }));
    };

    setRelationship = (
        relationshipType: EntityRelationshipType | null,
        relationship: EntityRelationship | null,
        relationshipAlias: string
    ) => {
        if (relationshipType && relationshipType === EntityRelationshipType.family
            && relationshipAlias && relationshipAlias.trim().length > 0) {
            relationship = EntityRelationship.other;
        }

        this.setState({
            relationshipType,
            relationship,
            relationshipAlias
        });
    };

    resetState = () => {
        this.setState({
            ...INITIAL_STATE
        });
    };

    createNewVisitor = async () => {
        const { dispatch, publicCase } = this.props;
        const {
            firstName,
            lastName,
            relationshipType,
            relationship,
            relationshipAlias,
            email,
            phone,
            password,
            loginType,
        } = this.state;

        let emailPhone: string | null = null;
        let eMessage: string | null = null;
        if (loginType === LoginType.email) {
            if (isValidEmail(email)) {
                emailPhone = email;
            } else {
                eMessage = 'Must enter valid email';
            }
        } else if (loginType === LoginType.phone) {
            if (isValidPhoneNumber(phone)) {
                emailPhone = phone;
            } else {
                eMessage = 'Must enter valid mobile phone number';
            }
        } else {
            log.warn('Invalid loginType', { loginType });
        }

        if (eMessage || !emailPhone) {
            dispatch(registerAppError(eMessage ?? 'Must Enter valid email or valid mobile phone number'));
            return;
        }
        const newVisitorDetails: VisitorCreateRequest = {
            emailOrPhone: emailPhone,
            password,
            fname: firstName,
            lname: lastName,
            relationship_type: relationshipType,
            relationship: relationship,
            relationship_alias: relationshipAlias,
        };

        this.validatePassword();
        const passwordValidResult = isValidPassword(this.state.password);
        if (!passwordValidResult.valid) {
            dispatch(registerAppError('Invalid Password'));
            return;
        }

        this.setState({ isLoading: true });
        const result = await dispatch(registerNewVisitor(publicCase.name, newVisitorDetails));

        if (result) {
            this.runLoginSuccessActionIfAble();
            this.goToStep(LoginDialogStep.postComments);
        } else {
            this.setState({
                hasLoginDetailsError: true,
            });
        }
        this.setState({ isLoading: false });
    };

    registerExistingVisitor = async () => {
        const { dispatch, publicCase, isUserLoggedIn, userData } = this.props;
        const {
            relationshipType,
            relationship,
            relationshipAlias,
        } = this.state;
        if (!isUserLoggedIn || !userData) {
            return;
        }
        const visitorCaseRequest: VisitorCaseRequest = {
            relationship,
            relationship_alias: relationshipAlias || '',
            relationship_type: relationshipType,
        };
        const result = await dispatch(registerVisitorToCase(publicCase.uuid, userData, visitorCaseRequest));

        if (result) {
            this.runLoginSuccessActionIfAble();
            this.goToStep(LoginDialogStep.loginCreationSuccess);
        } else {
            this.setState({
                hasLoginDetailsError: true,
            });
        }
    };

    /**
     * attemptLogin is used to try to login an existing user
     * The username used here could be email or phone and is 
     * independent from the new user creation 
     */
    attemptLogin = async () => {
        const { dispatch, publicCase } = this.props;
        const { password, existingUserLoginEmailPhone } = this.state;
        const guessLoginType = this.guessLoginTypeFromValue(existingUserLoginEmailPhone);

        const email = isValidEmail(existingUserLoginEmailPhone) ? existingUserLoginEmailPhone : null;
        const phone = isValidPhoneNumber(existingUserLoginEmailPhone) ? existingUserLoginEmailPhone : null;
        let errorMessage = guessLoginType === LoginType.phone && !phone ? 'Please verify your 10 digit phone number'
            : guessLoginType === LoginType.email && !email ? 'Please enter your full email address'
                : 'Login is invalid';
        if (!email && !phone) {
            this.setState({
                loginErrorMessage: errorMessage,
                invalidCredentials: true,
            });
            return;
        }
        this.setState({ isLoading: true });
        const result = await dispatch(attemptUserLogin(email, phone, password));
        this.setState({ isLoading: false });
        if (!result) {
            this.setState({
                invalidCredentials: true,
            });
        } else {
            const { userData } = result.data;
            if (canCreateMemory(userData, { funeralHomeId: publicCase.funeral_home.id, caseId: publicCase.id })) {
                this.runLoginSuccessActionIfAble(userData);
                this.closeDialog();
            } else if (UserRoles.isFHUser(userData)) {
                this.closeDialogWithFailAndMessage({ displayMessage: true });
            } else {
                this.goToStep(LoginDialogStep.relationship);
            }

        }
    };

    private _resetInvalidFlag = () => {
        this.setState({
            invalidCredentials: false,
            loginErrorMessage: '',
        });
    };

    private _openShareOptionsDialog = () => {
        this.setState({ isShareOptionsDialogOpen: true });
    };

    private _closeShareOptionsDialog = () => {
        this.setState({ isShareOptionsDialogOpen: false });
    };

    private _renderSharOptionsDialog = () => {
        const { publicCase, zIndex } = this.props;
        const { isShareOptionsDialogOpen } = this.state;

        return (
            <ShareOptionsDialog
                isOpen={isShareOptionsDialogOpen}
                closeDialog={this._closeShareOptionsDialog}
                publicCase={publicCase}
                zIndex={zIndex + 1}
            />
        );
    };

    private _closeSocialIconPopper = () => {
        this.setState({
            popperState: 'close',
            socialIconPopperAnchor: null
        });
    };

    private _openSocialIconPopper = (
        event: React.MouseEvent<HTMLElement>,
        shareOptionType: ShareOptionType,
        timeout?: number
    ) => {
        const target = event.currentTarget;

        this.setState({ popperState: 'open' });

        setTimeout(
            () => {
                if (timeout && this.state.popperState === 'close') {
                    return;
                }

                this.setState({
                    socialIconPopperAnchor: target,
                    shareOptionType
                });
            },
            timeout
        );
    };

    private _renderShareIconPopper = () => {
        const { publicCase, zIndex } = this.props;
        const { socialIconPopperAnchor, shareOptionType } = this.state;

        const shareOptionSettings = getShareOptions(publicCase);

        return (
            <SocialIconPopper
                clickAwayListener={this._closeSocialIconPopper}
                popperAnchorEle={socialIconPopperAnchor}
                popperContent={getOptionContent(shareOptionType, publicCase, shareOptionSettings)}
                activeCase={publicCase}
                setAppSnackbarSuccess={(_message) => void 0}
                type={shareOptionType}
                zIndex={zIndex + 1}
            />
        );
    };
}

export default compose(
    withFullScreen(),
    withState(mapStateToProps),
    withGStyles(selfLoginStyles<CombinedProps>())
)(SelfLoginDialog) as React.ComponentType<Props>;
