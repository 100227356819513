import AddCircleIcon from '@mui/icons-material/AddCircle';
import {
    Autocomplete,
    Chip,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    Menu,
    MenuItem,
    Radio,
    RadioGroup,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import {
    GridRenderEditCellParams,
    GridRowId,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarDensitySelector,
    GridToolbarExport,
    GridToolbarFilterButton,
    useGridApiRef,
} from '@mui/x-data-grid-premium';
import classNames from 'classnames';
import { debounce, map, omit, values } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import {
    dataURIToGatherPhoto,
    photoToGatherPhoto,
    uploadFuneralHomeProductPhotos,
} from '../../../../actions/Photo.action';
import {
    createProductManufacturer,
    createProductTag,
    createProductTaxRate,
    deleteFuneralHomeProduct,
    deleteProductTag,
    updateFuneralHomeProduct,
    updateProductManufacturer,
    updateProductTaxRate,
} from '../../../../actions/product/FHProduct.action';
import { getDataURIFromFile, getPhotoUrl } from '../../../../services';
import {
    ModerationStatus,
    Photo,
    PhotoScopeEnum,
    PhotoTransformationsType,
    PhotoTypeEnum,
    PricingModelEnum,
    PricingModelDisplayLookup,
    ProductCategory,
    ProductCategoryDisplayLookup,
    ProductCategoryEnum,
    ProductManufacturerRecord,
    ProductRequest,
    ProductTagRecord,
    ProductTaxRateUX,
    ProductUX,
    UserRoles,
} from '../../../../shared/types';
import { GStyles } from '../../../../styles/GStyles';
import { GatherPhoto, useGDispatch, useGSelector } from '../../../../types';
import AvailableManufacturersDialog from '../../../allProducts/AvailableManufacturers.dialog';
import AvailableTagsDialog from '../../../allProducts/AvailableTags.dialog';
import AvailableTaxRatesDialog from '../../../allProducts/AvailableTaxRates.dialog';
import { useFuneralHomeId } from '../../../common/hooks/useFuneralHomeId';
import GSwitch from '../../../common/inputElements/GSwitch';
import PhotoCropper from '../../../profileImage/PhotoCropper';
import PhotoList from '../../../profileImage/PhotoList';
import ListAltIcon from '@mui/icons-material/ListAlt';
import GDataGrid from '../../../common/GDataGrid/GDataGrid';
import { GDataGridTableColumn } from '../../../common/GDataGrid/types';
import DeleteIcon from '@mui/icons-material/Delete';
import { setSnackbarSuccess } from "../../../../actions/AppSnackbar.action";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { styled } from '@mui/styles';
import { ORANGE_COLOR, RED_COLOR_2 } from '../../../../constants/colorVariables';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import useFullScreen from '../../../common/hooks/useFullScreen';
import { booleanOperators, localeText, photosOperators } from './utils';
import usePriceFilterOperators from '../../../reports/hooks/usePriceFilterOperators';
import useNumericFilterOperators from '../../../reports/hooks/useNumericFilterOperators';
import { formatPriceForReports, getCellRenderer } from '../../../reports/utils';

const useStyles = makeStyles(
    (theme: Theme) => ({
        mainContainer: {
            padding: '8px 0px',
            width: '100%',
            '@media (min-width: 960px)': {
                padding: '16px 0px',
            },
            '& .MuiTablePagination-actions': {
                '& button': {
                    color: theme.palette.primary.main,
                    '&:disabled': {
                        color: theme.palette.secondary.main,
                    }
                }
            }
        },
        imageContainer: {
            height: 'auto',
            marginTop: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100% !important',
            mixBlendMode: 'color-burn',
            '@media (min-width: 360px)': {
                width: 60,
            },
            '& img': {
                width: '60px !important',
            },
        },
        grid: {
            height: 160,
            borderRadius: 4,
        },
        addNewPhotoContainer: {
            mixBlendMode: 'color-burn',
            padding: 0,
            color: theme.palette.secondary.main,
            position: 'relative',
            textAlign: 'center',
            verticalAlign: 'top',
            display: 'inline-block',
            background: 'transparent !important',
            margin: 0,
            '& svg': {
                fontSize: 20,
            },
            '& p': {
                fontSize: 10,
            },
            '@media (min-width: 420px)': {
                margin: 12,
            },
        },
        dataGridHeader: {
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            flexDirection: 'column',
            [theme.breakpoints.up(900)]: {
                alignItems: 'flex-end',
                flexDirection: 'row',
                justifyContent: 'space-between',
            },
            '& .MuiBadge-badge': {
                backgroundColor: ORANGE_COLOR,
            },
        },
    }),
    { name: 'ProductItemsDataGrid' },
);

const ProductsDataGridHeader = () => {
    const classes = useStyles();
    return (
        <div className={classes.dataGridHeader}>
            <GridToolbarContainer>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
            </GridToolbarContainer>
        </div>
    );
};

const RowActionsContainer = styled(Grid)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    position: 'absolute',
    right: '0px',
    '& button': {
        height: 20,
        width: 20,
    }
});

interface Props {
    productItems: ProductUX[];
    onEditClick?: (productId: number, category?: ProductCategory) => void;
    zIndex: number;
    category: ProductCategory;
}

type ProductRow = ProductUX & { row_id: number };

export const transformations = [
    {
        width: 52,
        quality: 'auto',
        fetch_format: 'auto',
        crop: 'limit',
    },
];

export const pricingModelOptions = [
    {
        option: PricingModelEnum.fixed,
        tooltip: `A single price will be used when the product is added to the Statement 
        (e.g., casket, urn, etc.) . The fixed price can have a discount or premium applied 
        per case if the following user-level permission is set: Can adjust STATEMENT ITEMS`,
    },
    {
        option: PricingModelEnum.base_plus_variable,
        tooltip: `Allows you to determine a base price and a variable per unit price (e.g., hearse with a fixed
             price and a per mile charge). You can set a quantity that is included with the base price. You can
              also set a default and maximum quantity. The total calculated price can have a discount or premium
               applied per case if the following user-level permission is set: Can adjust STATEMENT ITEMS`,
    },
    {
        option: PricingModelEnum.variable,
        tooltip: `Allows you to determine a variable per unit price (e.g., memorial folders). You can also set a
         default and maximum quantity. The total calculated price can have a discount or premium applied per case
          if the following user-level permission is set: Can adjust STATEMENT ITEMS`,
    },
    {
        option: PricingModelEnum.manual,
        tooltip: `Used for single quantity products whose price varies from statement to statement. For example, 
        Obituary, Catering, Honorarium, etc. This will allow you to set the price of this product on a 
        per-statement-basis. Once initially set, the manual price can only be adjusted if the following 
        user-level permission is set: Can Modify price`,
    },
    {
        option: PricingModelEnum.allowance,
        tooltip: `Used to grant a credit allowance for a particular category of items. For example, a 
        "$300 Flower Allowance" allows you to select any $300 worth of flowers. Any unused allowance will 
        NOT be credited toward the overall contract price. This option is typically only used in packages.`,
    }
];

const ProductItemsDataGrid = ({ productItems, onEditClick, zIndex, category }: Props) => {
    const classes = useStyles();
    const fullScreen = useFullScreen();
    const dispatch = useGDispatch();
    const muiApiRef = useGridApiRef();

    const user = useGSelector(({ userSession }) => userSession.userData);
    const productStateData = useGSelector(({ productState }) => productState);
    const { tags, taxRates, manufacturers } = productStateData;

    const funeralHomeId = useFuneralHomeId();

    const isGatherUser = UserRoles.isGOMUser(user);

    const [isAvailableTagsDialogOpen, setIsAvailableTagsDialogOpen] = useState(false);
    const [rowId, setRowId] = useState(-1);
    const [isPhotoCropperOpen, setIsPhotoCropperOpen] = useState(false);
    const [imageUri, setImageURI] = useState('');
    const [isAvailableTaxRatesDialogOpen, setAvailableTaxRatesDialogOpen] = useState(false);
    const [isAvailableManufacturersDialogOpen, setAvailableManufacturersDialogOpen] = useState(false);
    const [currentCategory, setCurrentCategory] = useState<ProductCategory>(category);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>();

    const updateProduct = useCallback(
        async (id: number, changes: Partial<ProductRequest>) => {
            const product = productItems.find((p) => p.id === id);

            if (!product || typeof funeralHomeId !== 'number') {
                return;
            }

            const productRequest: ProductRequest = {
                category: product.category,
                pricing_model: product.pricing_model,
                name: product.name,
                description: product.description,
                sku: product.sku,
                manufacturer_id: product.manufacturer_id,
                model_number: product.model_number,
                external_link: product.external_link,
                is_package_exclusive: product.is_package_exclusive,
                is_hidden: product.is_hidden,
                featured_rank: product.featured_rank,
                photos: product.photos,
                tags: product.tags,
                cost: product.cost,
                base_price: product.base_price,
                base_quantity: product.base_quantity,
                var_price: product.var_price,
                var_increment: product.var_increment,
                var_increment_units: product.var_increment_units,
                var_max_quantity: product.var_max_quantity,
                var_default_quantity: product.var_default_quantity,
                tax_rate_id: product.tax_rate_id,
                is_always_displayed: product.is_always_displayed,
                use_na_when_always_displayed: product.use_na_when_always_displayed,
                display_model_number: product.display_model_number,
                display_manufacturer: product.display_manufacturer,
                display_tags: product.display_tags,
                persistent_contract_text: product.persistent_contract_text,
                bold_contract_text: product.bold_contract_text,
                underline_contract_text: product.underline_contract_text,
                indent_contract_text: product.indent_contract_text,
                show_on_website: product.show_on_website,
                show_price_on_website: product.show_price_on_website,
                ...changes,
            };
            return await dispatch(updateFuneralHomeProduct(id, productRequest, funeralHomeId));
        }, [dispatch, funeralHomeId, productItems]);

    const handleRemoveTag = useCallback((tagName: string, id: number) => {
        const product = productItems.find((p) => p.id === id);

        if (!product) {
            return;
        }

        updateProduct(product.id, {
            tags: omit(product.tags, tagName),
        });
    }, [productItems, updateProduct]);

    const renderProductTags = useCallback((row: ProductUX) => (
        <FormControl
            sx={{
                flexDirection: 'row',
                gap: '10px',
                overflow: 'auto',
            }}
        >
            <Chip
                key="add_new_tag"
                label="Add New Tag"
                clickable
                color="primary"
                onDelete={setIsAvailableTagsDialogOpen}
                deleteIcon={<AddCircleIcon />}
                onClick={() => {
                    setIsAvailableTagsDialogOpen(true);
                    setRowId(row.id);
                    setCurrentCategory(row.category);
                }}
            />
            {map(row.tags, (tagValue, tagName) => (
                <Chip
                    key={`${tagName}: ${tagValue}`}
                    label={`${tagName}: ${tagValue}`}
                    onDelete={() => handleRemoveTag(tagName, row.id)}
                />
            ))}
        </FormControl>
    ), [handleRemoveTag]);

    const onFileUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length !== 0) {
            const dataURI = await getDataURIFromFile(event.target.files[0]);
            setImageURI(dataURI);
            setIsPhotoCropperOpen(true);
        }
    };

    const saveNewImage = (transformationss: PhotoTransformationsType, imageURI: string) => {
        onUploadPhotos([imageURI]);
    };

    const onUploadPhotos = async (dataURIs: string[]) => {
        const product = productItems.find((p) => p.id === rowId);
        const uploadingPhotos: GatherPhoto[] = dataURIs.map(dataURIToGatherPhoto);

        if (!product || typeof funeralHomeId !== 'number') {
            return;
        }

        const newPhotos = await dispatch(uploadFuneralHomeProductPhotos(uploadingPhotos, funeralHomeId));

        const photos = newPhotos
            .map((p) => p.photo)
            .filter((p): p is Photo => p !== null)
            .map((photo) => photo.public_id);

        updateProduct(product.id, {
            photos: [...product.photos, ...photos],
        });
    };

    const handleDeleteSelectedRow = useCallback(async (id: GridRowId) => {
        if (typeof funeralHomeId !== 'number') {
            return;
        }

        const result = await dispatch(deleteFuneralHomeProduct(Number(id), funeralHomeId));
        if (result?.length === 0) {
            dispatch(setSnackbarSuccess(`1 Product has been deleted`));
        }
        setAnchorEl(null);
    }, [funeralHomeId, dispatch]);

    const renderProductPhoto = useCallback(
        (row: ProductUX) => (
            <Tooltip id="tooltip-productName" title={''} placement="top" enterDelay={400}>
                {row.photos && row.photos.length > 0 && row.photos[0]
                    ? <Grid className={classes.imageContainer}>
                        <img src={getPhotoUrl(row.photos[0], transformations)} />
                    </Grid>
                    : <div onClick={() => setRowId(row.id)}>
                        <FormControl>
                            <PhotoList
                                photoList={row.photos ? row.photos.map(
                                    (publicId): GatherPhoto =>
                                        photoToGatherPhoto({
                                            public_id: publicId,
                                            width: 0,
                                            height: 0,
                                            uploaded_by: -1,
                                            photo_type: PhotoTypeEnum.product,
                                            moderated_by: 0,
                                            moderation_reason: 'product photo',
                                            moderation_status: ModerationStatus.approved,
                                            moderation_time: new Date(),
                                            moderation_required: false,
                                        }),
                                ) : []}
                                isLoading={false}
                                hideDownloadButton
                                onFileUpload={onFileUpload}
                                onPhotoDelete={() => void 0}
                                photoContainerClass={classNames(classes.grid, classes.addNewPhotoContainer)}
                                disableZoomAnimation={true}
                            />
                        </FormControl>
                    </div>
                }
            </Tooltip>
        ), [classes.addNewPhotoContainer, classes.grid, classes.imageContainer]);

    const handleCreateTag = (tag: ProductTagRecord) => {
        dispatch(createProductTag(tag));
        handleAddTag(tag);
    };

    const handleTaxRateSelect = (taxRate: ProductTaxRateUX | null) => {
        const product = productItems.find((p) => p.id === rowId);

        if (!product) {
            return;
        }

        updateProduct(product.id, {
            tax_rate_id: taxRate ? taxRate.id : null,
        });
    };

    const handleAddTag = (tag: ProductTagRecord) => {
        const product = productItems.find((p) => p.id === rowId);

        if (!product) {
            return;
        }

        updateProduct(product.id, {
            tags: {
                ...product.tags,
                [tag.name]: tag.value,
            },
        });
    };

    const handleManufacturerSelect = (manufacturer: ProductManufacturerRecord) => {
        const product = productItems.find((p) => p.id === rowId);

        if (!product) {
            return;
        }

        updateProduct(product.id, {
            manufacturer_id: manufacturer.id,
        });
    };

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>, id: GridRowId) => {
        setAnchorEl(event.currentTarget);
        setRowId(Number(id));
    };

    const getEditCellRendered = useCallback((_params: GridRenderEditCellParams) => {
        const cellValue = parseFloat((_params.value / 100).toFixed(2));
        return (
            <TextField
                defaultValue={cellValue ?? 0}
                type='number'
                variant="outlined"
                onChange={(event) => {
                    const newValue = (Number(event.target.value) * 100);
                    _params.api.setEditCellValue({ id: _params.id, field: _params.field, value: newValue });
                }}
            />
        );
    }, []);

    const priceOperators = usePriceFilterOperators();
    const numericOperators = useNumericFilterOperators();

    const renderInput = useCallback((params) => <TextField {...params} label="Category" />, []);

    const renderCategoryCell = useCallback(
        (row: ProductRow) => (
            <Autocomplete
                fullWidth
                options={values(ProductCategoryEnum)}
                getOptionLabel={(option) => ProductCategoryDisplayLookup[option] ?? option}
                disableClearable
                value={ProductCategoryEnum[row.category]}
                onChange={async (event, value) => {
                    if (typeof funeralHomeId !== 'number') {
                        return;
                    }
                    await updateProduct(row.id, { category: ProductCategoryEnum[value] });
                }}
                sx={{
                    '& .MuiAutocomplete-endAdornment': {
                        top: 'calc(50% - 24px);',
                    },
                }}
                renderInput={renderInput}
            />
        ),
        [funeralHomeId, updateProduct, renderInput]
    );

    const renderTextField = useCallback(
        (row: ProductUX, label: string, value: string | null, onClick: () => void) => (
            <TextField
                size="small"
                onClick={onClick}
                value={value ?? ''}
                fullWidth
                label={label}
                disabled
                InputProps={{
                    readOnly: true,
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton color="primary" sx={{ width: 32, height: 32 }} onClick={onClick}>
                                <ListAltIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        ),
        []
    );

    const pricingModelOptionsMemo = useMemo(
        () => pricingModelOptions.map((v) => v.option),
        []
    );

    const renderPricingModelCell = useCallback(
        (row: ProductRow) => (
            <Autocomplete
                options={pricingModelOptionsMemo}
                getOptionLabel={(option) => PricingModelDisplayLookup[option] ?? option}
                fullWidth
                disableClearable
                value={PricingModelEnum[row.pricing_model] || null}
                onChange={(event, value) => updateProduct(row.id, { pricing_model: PricingModelEnum[value] })}
                sx={{
                    [`& .MuiAutocomplete-endAdornment`]: {
                        top: 'calc(50% - 24px);',
                    },
                }}
                renderInput={(params) => <TextField {...params} label="Pricing Model" />}
                renderOption={(props, option) => {
                    const pricingModel = pricingModelOptions.find((o) => o.option === option);
                    if (!pricingModel) {
                        return null;
                    };

                    return (
                        <Tooltip title={pricingModel.tooltip} key={pricingModel.option} placement='top'>
                            <li {...props}>
                                <Grid container alignItems="center">
                                    <Grid item>
                                        {PricingModelDisplayLookup[pricingModel.option] ?? pricingModelOptions}
                                    </Grid>
                                </Grid>
                            </li>
                        </Tooltip>
                    );
                }}
            />
        ),
        [updateProduct, pricingModelOptionsMemo]
    );

    const renderProductName = useCallback(
        (row: ProductRow) => (
            <Typography
                color="primary"
                textTransform="capitalize"
                fontWeight={400}
                className={GStyles.textUnderline}
                onClick={() => (onEditClick ? onEditClick(row.id, row.category) : undefined)}
                sx={{ cursor: 'pointer' }}
            >
                {row.name}
            </Typography>
        ),
        [onEditClick]
    );

    const SwitchRenderer = useCallback(
        ({
            row,
            field,
            isDisabled = false,
            onChangeCallBack,
        }: {
            row: ProductRow;
            field: keyof ProductUX;
            isDisabled?: boolean;
            onChangeCallBack: (value: boolean) => void;
        }) => (
            <FormControl>
                <GSwitch
                    checked={!!row[field]}
                    disabled={isDisabled}
                    onChangeCallBack={(value) => onChangeCallBack(value)}
                    disableToggleOnTextClick
                />
            </FormControl>
        ), []
    );

    const renderManufacturerName = useCallback(
        (row: ProductRow) => (
            renderTextField(row, 'Product Line Manufacturer', row.manufacturer_name, () => {
                setRowId(row.id);
                setAvailableManufacturersDialogOpen(true);
            })
        ),
        [renderTextField]
    );

    const renderIsHidden = useCallback(
        (row: ProductRow) => (
            <SwitchRenderer
                field={"is_hidden"}
                onChangeCallBack={(value) => updateProduct(row.id, { is_hidden: value })}
                row={row}
            />
        ),
        [SwitchRenderer, updateProduct]
    );

    const renderTaxRate = useCallback(
        (row: ProductRow) => (
            renderTextField(
                row,
                'Tax Rate',
                row.tax_rate_name
                    ? `${row.tax_rate_name}${row.tax_rate_description ? ` (${row.tax_rate_description})` : ''}`
                    : 'Not Taxable',
                () => {
                    setRowId(row.id);
                    setAvailableTaxRatesDialogOpen(true);
                })
        ),
        [renderTextField]
    );

    const renderShowOnWebsite = useCallback(
        (row: ProductRow) => (
            <SwitchRenderer
                field={"show_on_website"}
                onChangeCallBack={(value) => updateProduct(row.id, {
                    show_on_website: value,
                    show_price_on_website: !value ? false : row.show_price_on_website
                })}
                row={row}
            />
        ),
        [SwitchRenderer, updateProduct]
    );

    const renderShowPriceOnWebsite = useCallback(
        (row: ProductRow) => (
            <SwitchRenderer
                field={"show_price_on_website"}
                isDisabled={!row.show_on_website}
                onChangeCallBack={(value) => updateProduct(row.id, { show_price_on_website: value })}
                row={row}
            />
        ),
        [SwitchRenderer, updateProduct]
    );

    const renderBoldContractText = useCallback(
        (row: ProductRow) => (
            <SwitchRenderer
                field={"bold_contract_text"}
                onChangeCallBack={(value) => updateProduct(row.id, { bold_contract_text: value })}
                row={row}
            />
        ),
        [SwitchRenderer, updateProduct]
    );

    const renderUnderlineContractText = useCallback(
        (row: ProductRow) => (
            <SwitchRenderer
                field={"underline_contract_text"}
                onChangeCallBack={(value) => updateProduct(row.id, { underline_contract_text: value })}
                row={row}
            />
        ),
        [SwitchRenderer, updateProduct]
    );

    const renderIndentContractText = useCallback(
        (row: ProductRow) => (
            <SwitchRenderer
                field={"indent_contract_text"}
                onChangeCallBack={(value) => updateProduct(row.id, { indent_contract_text: value })}
                row={row}
            />
        ),
        [SwitchRenderer, updateProduct]
    );

    const renderIsAlwaysDisplayed = useCallback(
        (row: ProductRow) => (
            <SwitchRenderer
                field={"is_always_displayed"}
                onChangeCallBack={(value) => updateProduct(row.id, { is_always_displayed: value })}
                row={row}
            />
        ),
        [SwitchRenderer, updateProduct]
    );

    const renderDisplayManufacturer = useCallback(
        (row: ProductRow) => (
            <SwitchRenderer
                field={"display_manufacturer"}
                onChangeCallBack={(value) => updateProduct(row.id, { display_manufacturer: value })}
                row={row}
            />
        ),
        [SwitchRenderer, updateProduct]
    );

    const renderDisplayModelNumber = useCallback(
        (row: ProductRow) => (
            <SwitchRenderer
                field={"display_model_number"}
                onChangeCallBack={(value) => updateProduct(row.id, { display_model_number: value })}
                row={row}
            />
        ),
        [SwitchRenderer, updateProduct]
    );

    const renderDisplayTags = useCallback(
        (row: ProductRow) => (
            <SwitchRenderer
                field={"display_tags"}
                onChangeCallBack={(value) => updateProduct(row.id, { display_tags: value })}
                row={row}
            />
        ),
        [SwitchRenderer, updateProduct]
    );

    const renderUseNaWhenAlwaysDisplayed = useCallback(
        (row: ProductRow) => (
            <RadioGroup
                row
                value={row.use_na_when_always_displayed ? 'true' : 'false'}
                onChange={(e, value) => {
                    if (value === 'false' || value === 'true') {
                        updateProduct(row.id, { use_na_when_always_displayed: value === 'true' });
                    }
                }}
            >
                <FormControlLabel
                    disabled={!row.is_always_displayed}
                    value="false"
                    control={<Radio color="primary" />}
                    label={`Use "___"`}
                    labelPlacement="end"
                />
                <FormControlLabel
                    disabled={!row.is_always_displayed}
                    value="true"
                    control={<Radio color="primary" />}
                    label={`Use "N/A"`}
                    labelPlacement="end"
                />
            </RadioGroup>
        ),
        [updateProduct]
    );

    const renderActionCell = useCallback(
        ({ id }: { id: GridRowId }) => (
            <RowActionsContainer>
                <IconButton onClick={evt => handleMenuClick(evt, id)}>
                    <MoreVertIcon color='primary' />
                </IconButton>
            </RowActionsContainer>
        ),
        []
    );

    const columns: GDataGridTableColumn<ProductUX & { row_id: number }>[] = useMemo(
        () => [
            {
                field: 'name',
                description: `The name of the product that will display on the Statement when added. 
                The Product Name can be edited per case if the following user-level permission is set: 
                Can Modify Item Name. You can also make this field display on the Statement by using the Display:
                 Product Name product toggle.`,
                editable: true,
                headerName: 'Product Name',
                minWidth: 200,
                renderCell: getCellRenderer(({ row }) =>
                    !row.name ? null : renderProductName(row)
                ),
            },
            {
                field: 'photos',
                align: 'center',
                description: `One or more photos can be added to a product which will show in the digital Showroom
                 and in the product details popup. The first photo listed will be the one that shows on the product
                  card in the digital Showroom.`,
                editable: true,
                filterOperators: photosOperators,
                headerName: 'Product Photos',
                minWidth: 206,
                groupable: false,
                renderCell: getCellRenderer(({ row }) => renderProductPhoto(row)),
            },
            {
                field: 'manufacturer_name',
                headerName: 'Product Line Manufacturer',
                description: `Each product can be grouped under a manufacturer. You can also filter by this field
                 in the digital Showroom. You can also make this field display on the Statement by using the 
                 Display: Manufacturer product toggle.`,
                minWidth: 280,
                type: 'string',
                groupingValueGetter: ({ value }) => value ?? 'Unknown',
                renderCell: getCellRenderer(({ row }) => renderManufacturerName(row)),
            },
            {
                field: 'model_number',
                headerName: 'Model Number',
                description: `The Model Number will be shown on the product details popup. You can also make this 
                number display on the Statement by using the Display: Model Number product toggle.`,
                minWidth: 200,
                editable: true,
                groupingValueGetter: ({ value }) => value ?? 'Unknown',
            },
            {
                field: 'base_price',
                headerName: 'Base Price',
                minWidth: 170,
                editable: true,
                headerAlign: 'left',
                align: 'right',
                type: 'number',
                valueGetter: ({ value }) => Number(value),
                groupingValueGetter: ({ value }) => formatPriceForReports(value, 'USD'),
                valueFormatter: ({ value }) => formatPriceForReports(value, 'USD'),
                renderEditCell: getEditCellRendered,
                filterOperators: priceOperators,
            },
            {
                field: 'tags',
                headerName: 'Product Tag(s)',
                minWidth: 400,
                groupable: false,
                renderCell: getCellRenderer(({ row }) => renderProductTags(row)),
            },
            {
                field: 'sku',
                headerName: 'SKU',
                description: `The Stock Keeping Unit (SKU) is a unique number used to identify products. It will
                 NOT be shown in the digital Showroom but can be used in reports.`,
                minWidth: 180,
                editable: true,
                groupingValueGetter: ({ value }) => value ?? 'Unknown',
            },
            {
                field: 'category',
                headerName: 'Product Category',
                description: `Each product can be added to one of 10 product categories which will determine where it
                 displays both in the digital Showroom and on the Statement.`,
                minWidth: 220,
                type: 'string',
                groupingValueGetter: ({ value }: { value: ProductCategoryEnum }) =>
                    ProductCategoryDisplayLookup[value] ?? 'Unknown',
                renderCell: getCellRenderer(({ row }) => renderCategoryCell(row)),
            },
            {
                field: 'description',
                headerName: 'Product Description',
                description: `The Product Description will be shown on the product details popup. If you would like 
                product text to show under the product when added to a Statement, please use the Display: Persistent 
                Text field.`,
                minWidth: 230,
                editable: true,
                groupingValueGetter: ({ value }) => value ?? null,
            },
            {
                field: 'is_hidden',
                headerName: 'Hidden from digital Showroom?',
                description: `This setting will remove the product from the digital Showroom but will still allow it 
                to be selected from the green button on the Statement page.`,
                minWidth: 310,
                align: 'center',
                filterOperators: booleanOperators,
                valueGetter: ({ value }) => value,
                groupingValueGetter: ({ value }) => value ? 'True' : 'False',
                renderCell: getCellRenderer(({ row }) => renderIsHidden(row)),
            },
            {
                field: 'pricing_model',
                headerName: 'Pricing Model',
                description: `Each product can have one of five pricing models applied to it which will determine how 
                the price is determined when added to a Statement.`,
                minWidth: 190,
                editable: true,
                groupingValueGetter: ({ value }: { value: PricingModelEnum }) =>
                    PricingModelDisplayLookup[value],
                renderCell: getCellRenderer(({ row }) => renderPricingModelCell(row)),
            },
            {
                field: 'cost',
                headerName: 'Cost to FH',
                minWidth: 170,
                headerAlign: 'left',
                align: 'right',
                editable: true,
                type: 'number',
                valueGetter: ({ value }) => Number(value),
                groupingValueGetter: ({ value }) => formatPriceForReports(value, 'USD'),
                valueFormatter: ({ value }) => formatPriceForReports(value, 'USD'),
                renderEditCell: getEditCellRendered,
                filterOperators: priceOperators,
            },
            {
                field: 'base_quantity',
                headerName: 'Units with Base Price',
                minWidth: 240,
                editable: true,
                type: 'number',
                headerAlign: 'left',
                align: 'right',
                filterOperators: numericOperators,
                groupingValueGetter: ({ value }) => value ?? 'Unknown',
            },
            {
                field: 'var_price',
                headerName: 'Variable Price',
                minWidth: 194,
                editable: true,
                headerAlign: 'left',
                align: 'right',
                valueGetter: ({ value }) => Number(value),
                groupingValueGetter: ({ value }) => formatPriceForReports(value, 'USD'),
                valueFormatter: ({ value }) => formatPriceForReports(value, 'USD'),
                renderEditCell: getEditCellRendered,
                filterOperators: priceOperators,
            },
            {
                field: 'var_increment',
                headerName: 'Variable Increment',
                minWidth: 230,
                editable: true,
                type: 'number',
                headerAlign: 'left',
                align: 'right',
                filterOperators: numericOperators,
                groupingValueGetter: ({ value }) => value ?? 'Unknown',
            },
            {
                field: 'var_increment_units',
                headerName: 'Units',
                minWidth: 150,
                editable: true,
                groupingValueGetter: ({ value }) => value ?? 'Unknown',
            },
            {
                field: 'var_max_quantity',
                headerName: 'Max Unit Amount',
                minWidth: 220,
                editable: true,
                type: 'number',
                headerAlign: 'left',
                align: 'right',
                filterOperators: numericOperators,
                groupingValueGetter: ({ value }) => value ?? 'Unknown',
            },
            {
                field: 'var_default_quantity',
                headerName: 'Default Unit Amount',
                minWidth: 240,
                editable: true,
                type: 'number',
                headerAlign: 'left',
                align: 'right',
                filterOperators: numericOperators,
                groupingValueGetter: ({ value }) => value ?? 'Unknown',
            },
            {
                field: 'tax_rate_name',
                headerName: 'Product Taxability',
                minWidth: 250,
                groupingValueGetter: ({ row }) => row.tax_rate_name
                    ? `${row.tax_rate_name}${row.tax_rate_description ? ` (${row.tax_rate_description})` : ''}`
                    : 'Not Taxable',
                renderCell: getCellRenderer(({ row }) => renderTaxRate(row)),
            },
            {
                field: 'show_on_website',
                headerName: 'Show product on website',
                minWidth: 270,
                align: 'center',
                filterOperators: booleanOperators,
                valueGetter: ({ value }) => value,
                groupingValueGetter: ({ value }) => value ? 'True' : 'False',
                renderCell: getCellRenderer(({ row }) => renderShowOnWebsite(row)),
            },
            {
                field: 'show_price_on_website',
                headerName: 'Show product price on website',
                minWidth: 300,
                align: 'center',
                filterOperators: booleanOperators,
                valueGetter: ({ value }) => value,
                groupingValueGetter: ({ value }) => value ? 'True' : 'False',
                renderCell: getCellRenderer(({ row }) => renderShowPriceOnWebsite(row)),
            },
            {
                field: 'bold_contract_text',
                headerName: 'Display: Bold',
                minWidth: 190,
                editable: true,
                align: 'center',
                filterOperators: booleanOperators,
                valueGetter: ({ value }) => value,
                groupingValueGetter: ({ value }) => value ? 'True' : 'False',
                renderCell: getCellRenderer(({ row }) => renderBoldContractText(row)),
            },
            {
                field: 'underline_contract_text',
                headerName: 'Display: Underline',
                minWidth: 230,
                editable: true,
                align: 'center',
                filterOperators: booleanOperators,
                valueGetter: ({ value }) => value,
                groupingValueGetter: ({ value }) => value ? 'True' : 'False',
                renderCell: getCellRenderer(({ row }) => renderUnderlineContractText(row)),
            },
            {
                field: 'indent_contract_text',
                headerName: 'Display: Indent',
                minWidth: 210,
                editable: true,
                align: 'center',
                filterOperators: booleanOperators,
                valueGetter: ({ value }) => value,
                groupingValueGetter: ({ value }) => value ? 'True' : 'False',
                renderCell: getCellRenderer(({ row }) => renderIndentContractText(row)),
            },
            {
                field: 'is_always_displayed',
                headerName: 'Display: Product Name',
                minWidth: 250,
                align: 'center',
                filterOperators: booleanOperators,
                valueGetter: ({ value }) => value,
                groupingValueGetter: ({ value }) => value ? 'True' : 'False',
                renderCell: getCellRenderer(({ row }) => renderIsAlwaysDisplayed(row)),
            },
            {
                field: 'use_na_when_always_displayed',
                headerName: 'Use N/A when always displayed',
                minWidth: 250,
                groupingValueGetter: ({ value }) => value ? 'True' : 'False',
                renderCell: getCellRenderer(({ row }) => renderUseNaWhenAlwaysDisplayed(row)),
            },
            {
                field: 'display_manufacturer',
                headerName: 'Display: Manufacturer',
                minWidth: 250,
                editable: true,
                align: 'center',
                filterOperators: booleanOperators,
                valueGetter: ({ value }) => value,
                groupingValueGetter: ({ value }) => value ? 'True' : 'False',
                renderCell: getCellRenderer(({ row }) => renderDisplayManufacturer(row)),
            },
            {
                field: 'display_model_number',
                headerName: 'Display: Model Number',
                minWidth: 260,
                editable: true,
                align: 'center',
                filterOperators: booleanOperators,
                valueGetter: ({ value }) => value,
                groupingValueGetter: ({ value }) => value ? 'True' : 'False',
                renderCell: getCellRenderer(({ row }) => renderDisplayModelNumber(row)),
            },
            {
                field: 'display_tags',
                headerName: 'Display: Product Tag(s)',
                minWidth: 250,
                editable: true,
                align: 'center',
                filterOperators: booleanOperators,
                valueGetter: ({ value }) => value,
                groupingValueGetter: ({ value }) => value ? 'True' : 'False',
                renderCell: getCellRenderer(({ row }) => renderDisplayTags(row)),
            },
            {
                field: 'persistent_contract_text',
                headerName: 'Display: Persistent Text',
                minWidth: 260,
                editable: true,
                groupingValueGetter: ({ value }) => value.trim() ?? null,
            },
            {
                field: 'Actions',
                headerName: '',
                renderCell: renderActionCell,
                disableReorder: true,
                filterable: false,
                disableColumnMenu: true,
                sortable: false,
                minWidth: 20,
                width: 20,
                maxWidth: 20,
            },
        ],
        [
            renderActionCell,
            renderBoldContractText,
            renderDisplayManufacturer,
            renderDisplayModelNumber,
            renderDisplayTags,
            renderIndentContractText,
            renderIsAlwaysDisplayed,
            renderIsHidden,
            renderManufacturerName,
            renderShowOnWebsite,
            renderShowPriceOnWebsite,
            renderTaxRate,
            renderUnderlineContractText,
            renderUseNaWhenAlwaysDisplayed,
            getEditCellRendered,
            renderProductName,
            renderCategoryCell,
            renderPricingModelCell,
            renderProductPhoto,
            renderProductTags,
            priceOperators,
            numericOperators,
        ],
    );

    const debouncedUpdateProduct = debounce(async (id, changes) => {
        await updateProduct(Number(id), { ...changes });
    }, 200);

    const handleCellChange = useCallback(
        async (updatedProduct: ProductUX & { row_id: number }) => {
            const changes: Partial<ProductUX & { row_id: number }> = { ...updatedProduct };
            delete changes.id;
            delete changes.asset_type;
            delete changes.manufacturer_name;
            delete changes.row_id;
            delete changes.funeral_home_id;
            delete changes.category_rank;
            delete changes.cloned_from;
            delete changes.cloned_time;
            delete changes.created_by;
            delete changes.created_time;
            delete changes.updated_by;
            delete changes.updated_time;
            delete changes.deleted_by;
            delete changes.deleted_time;
            delete changes.tax_rate_name;
            delete changes.tax_rate_description;
            delete changes.manufacturer_link;

            debouncedUpdateProduct(updatedProduct.id, changes);

            return updatedProduct;
        }, [debouncedUpdateProduct]
    );

    const data = useMemo(() => productItems.map((p) => ({ ...p, row_id: p.id })), [productItems]);

    const pinnedColumns = useMemo(
        () => ({ left: fullScreen ? undefined : ['name'], right: ['Actions'] }),
        [fullScreen]
    );

    return (
        <div className={classes.mainContainer}>
            <GDataGrid
                muiApiRef={muiApiRef}
                localeText={localeText}
                pinnedColumns={pinnedColumns}
                data={data}
                hasChanges={false}
                loading={false}
                columns={columns}
                toolbarSlot={useCallback(() => <ProductsDataGridHeader />, [])}
                onProcessRowUpdate={handleCellChange}
                isCellEditable={useCallback(({ row, field }) => {
                    const { pricing_model } = row;

                    switch (field as keyof ProductUX) {
                        case 'base_price':
                            return (
                                pricing_model === PricingModelEnum.fixed ||
                                pricing_model === PricingModelEnum.allowance ||
                                pricing_model === PricingModelEnum.base_plus_variable
                            );

                        case 'cost':
                            return (
                                pricing_model === PricingModelEnum.fixed ||
                                pricing_model === PricingModelEnum.base_plus_variable ||
                                pricing_model === PricingModelEnum.variable
                            );

                        case 'var_price':
                        case 'var_increment':
                        case 'var_increment_units':
                        case 'var_default_quantity':
                        case 'var_max_quantity':
                            return (
                                pricing_model === PricingModelEnum.base_plus_variable ||
                                pricing_model === PricingModelEnum.variable
                            );

                        case 'base_quantity':
                            return pricing_model === PricingModelEnum.base_plus_variable;

                        default:
                            return true;
                    }
                }, [])}
                footerText="Products"
                rowsPerPage={25}
            />

            <Menu
                anchorEl={anchorEl || undefined}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                style={{ zIndex }}
            >
                <MenuItem onClick={() => onEditClick && onEditClick(rowId)}>
                    <LoyaltyIcon />&nbsp;Open Product Edit Popup
                </MenuItem>
                <MenuItem onClick={() => handleDeleteSelectedRow(rowId)}
                    sx={{ color: RED_COLOR_2 }}>
                    <DeleteIcon />&nbsp;Delete this product
                </MenuItem>
            </Menu>

            {isAvailableTagsDialogOpen &&
                <AvailableTagsDialog
                    zIndex={zIndex + 1}
                    tags={tags}
                    isDialogOpen={isAvailableTagsDialogOpen}
                    isGatherUser={isGatherUser}
                    closeDialog={() => setIsAvailableTagsDialogOpen(false)}
                    onCreate={(tag: ProductTagRecord) => handleCreateTag(tag)}
                    onDelete={(...args: Parameters<typeof deleteProductTag>) =>
                        dispatch(deleteProductTag(...args))}
                    category={ProductCategoryEnum[currentCategory]}
                    onTagSelect={handleAddTag}
                />
            }

            {isAvailableTaxRatesDialogOpen &&
                <AvailableTaxRatesDialog
                    zIndex={zIndex + 1}
                    isDialogOpen={isAvailableTaxRatesDialogOpen}
                    closeDialog={() => setAvailableTaxRatesDialogOpen(false)}
                    taxRates={taxRates}
                    isGatherUser={isGatherUser}
                    onCreate={(...args: Parameters<typeof createProductTaxRate>) =>
                        dispatch(createProductTaxRate(...args))}
                    onUpdate={(...args: Parameters<typeof updateProductTaxRate>) =>
                        dispatch(updateProductTaxRate(...args))}
                    onSelect={handleTaxRateSelect}
                />
            }

            {isAvailableManufacturersDialogOpen &&
                <AvailableManufacturersDialog
                    isDialogOpen={isAvailableManufacturersDialogOpen}
                    closeDialog={() => setAvailableManufacturersDialogOpen(false)}
                    isGatherUser={isGatherUser}
                    manufacturers={manufacturers}
                    onCreate={(...args: Parameters<typeof createProductManufacturer>) =>
                        dispatch(createProductManufacturer(...args))}
                    onUpdate={(...args: Parameters<typeof updateProductManufacturer>) =>
                        dispatch(updateProductManufacturer(...args))}
                    onManufacturerSelect={handleManufacturerSelect}
                    zIndex={zIndex + 1}
                />
            }

            {isPhotoCropperOpen &&
                <PhotoCropper
                    radius={0}
                    options={{
                        defaultOrientation: 'landscape',
                        hasAllOrientations: true,
                    }}
                    scope={PhotoScopeEnum.web}
                    imageURI={imageUri}
                    callBackAction={saveNewImage}
                    isDialogOpen={isPhotoCropperOpen}
                    closeDialog={() => setIsPhotoCropperOpen(false)}
                    zIndex={1330}
                />
            }
        </div>
    );
};

export default ProductItemsDataGrid;
