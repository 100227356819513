import * as React from 'react';
import classNames from 'classnames';
import momentTz from 'moment-timezone';
import { convertHexToRGBA, getFormattedPhoneNumber } from '../../../services/app.service';

import { Theme } from '@mui/material/styles';

import { StyleRulesCallback } from '@mui/styles/withStyles';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Grid from '@mui/material/Grid';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import AddIcon from '@mui/icons-material/Add';
import AlarmIcon from '@mui/icons-material/Alarm';
import Divider from '@mui/material/Divider';
import SendIcon from '@mui/icons-material/Send';
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import PersonAddDisabledIcon from '@mui/icons-material/PersonAddDisabled';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import {
    UserRole,
    UserRoles,
    GatherCaseUX,
    EntityCaseRole,
    CaseTaskUX,
    WorkOnButtonLink,
    TaskTemplateType,
    DocCategory,
    EntitySummary,
    getCaseEntity,
    isNonUserCaseHelper,
    isAlbumEntry,
    templateButtonLookup,
    FeatureKey
} from '../../../shared/types';
import { StoreState, GatherPhoto } from '../../../types';

import { SKYBLUE_COLOR_2, RED_COLOR } from '../../../constants/colorVariables';
import { openMySettingsDialog, openHelperInvitationDialog } from '../../../actions/Dialog.action';
import { resendInvitation, removeHelper } from '../../../actions/CaseHelper.action';
import ReminderPopper from '../tasks/ReminderPopper';
import ConfirmationDialog from '../../common/ConfirmationDialog';
import { setAppSnackbar } from '../../../actions/AppSnackbar.action';
import { openArrangementConferenceDialog } from '../../../actions/GatherCase.action';
import PhotoListDialog from '../../profileImage/PhotoList.dialog';
import { downloadCasePhotos, deleteCasePhoto } from '../../../actions/Photo.action';
import EventSlider from '../eventSlider';
import { createCaseDocs } from '../../../actions/Doc.action';
import InfoPopper from '../../invitation/InfoPopper';
import SaveTaskDialog from '../tasks/SaveTask.dialog';
import TaskDialog from '../taskDialogs/TaskDialog';
import { withTaskWorkOnButton, TaskWorkOnButtonProps } from '../tasks/TaskWorkOnButton';
import {
    WhenCanResendInvitation,
    WhenCanEditPerson,
    WhenCanDeletePerson,
    WhenCanRevokeInvitation,
    WhenCanInviteUser,
} from '../../common/AppPermissions';
import {
    canEditNonSensitivePersonDetails,
    canDeletePerson,
    canResendInvitation,
    canRevokeInvitation,
    canEditCasePermissions,
    canViewCaseTrackingSteps,
    canViewCaseBelongings,
} from '../../../shared/authority/can';
import { createUploadFromDispatch, performCasePhotoUpload } from '../../profileImage/utility';
import { AppDispatch } from '../../../store';
import withState from '../../common/utilHOC/WithState';
import withGStyles, { WithGStyles } from '../../../styles/WithGStyles';
import { getHelpersTasks, HelperTask } from '../../../shared/utils/task';
import { compose } from 'redux';
import GPopper from '../../common/GPopper';
import { MySettingsTab } from '../../dialogs/mySettings';
import { HelperUserSettingsTab } from '../../dialogs/mySettings/HelperUserSettings';
import { WhenEnabled } from '../../common/AppFeature';
import EmailPhonePopper from './EmailPhonePopper';
import HelperPopperHeader from './HelperPopperHeader';

export const getConfirmationDialogContentForHelpers = (selectedCase: GatherCaseUX, entity: EntitySummary) => {
    let relationToDeceased: string[] = [];
    let isDCEntity = false;

    if (entity.entity_id === selectedCase.dc_father_entity_id) {
        relationToDeceased.push('father');
        isDCEntity = true;
    }
    if (entity.entity_id === selectedCase.dc_mother_entity_id) {
        relationToDeceased.push('mother');
        isDCEntity = true;
    }
    if (entity.entity_id === selectedCase.dc_spouse_entity_id) {
        relationToDeceased.push('spouse');
        isDCEntity = true;
    }
    if (entity.entity_id === selectedCase.dc_informant_entity_id) {
        relationToDeceased.push('informant');
        isDCEntity = true;
    }

    const caseEntity = getCaseEntity(entity, selectedCase.id);
    const fname = entity.fname;
    const header = isDCEntity ? `Oops, ${fname} can't be removed` : 'Are you sure?';
    const confirmText = isDCEntity ? `Because ${fname} has been added to the death ` +
        `certificate as ${selectedCase.fname}'s ${relationToDeceased.join(`, ${selectedCase.fname}'s `)}, ` +
        `if you were to remove ${fname}'s access, that information would be lost. To complete this removal, ` +
        `you must first remove ${fname} from the Death Certificate. You can always add ${fname} to the ` +
        'death certificate without sending an invite.'
        : caseEntity && caseEntity.accepted_time
            ? `Deleting will prevent ${fname} from being able to log in.`
            : !entity.user
                ? `Are you sure to Delete ${fname}`
                : `Revoking this invite will prevent ${fname} from being able to log in.`;
    const confirmButtonText = caseEntity && caseEntity.accepted_time
        ? `Delete ${fname}`
        : !entity.user
            ? `Delete ${fname}`
            : `Revoke ${fname}'s Invitation`;
    const cancelButtonText = isDCEntity && 'Got it, Thanks.' || undefined;

    return { header, confirmText, confirmButtonText, cancelButtonText, isDCEntity };
};

function mapStateToProps({ casesState, tasksState, caseDocState, teamState, userSession }: StoreState) {
    const { casePhotos, isCasePhotosLoading, helpers } = casesState;

    const formDD214Detail = caseDocState.docs.find((doc) => doc.doc !== null
        && doc.doc.doc_category === DocCategory.form_dd214);
    const formDD214 = formDD214Detail && formDD214Detail.doc || null;

    return {
        userSession,
        tasks: tasksState.checklistTasks,
        casePhotos,
        isCasePhotosLoading,
        formDD214,
        helpers,
        team: teamState.team,
    };
}

interface InjectedProps extends ReturnType<typeof mapStateToProps> {
    dispatch: AppDispatch;
}

interface Props {
    selectedCase: GatherCaseUX;
    activeEntityId: number | null;
    popperAnchorEle: HTMLElement | null;
    clickAwayListener: (event: MouseEvent | TouchEvent) => void;
    closeHelperPopper: () => void;
    closeParentDialog?: () => void;
    zIndex: number;
    isParentDialogOpen?: boolean;
}

interface State {
    menuAnchor: HTMLElement | null;
    isMenuOpen: boolean;
    isUploading: boolean;
    isConfirmDialogOpen: boolean;
    reminderAnchorEl: HTMLElement | null;
    activeTask: CaseTaskUX | null;
    emailPhoneAnchor: HTMLElement | null;
    isEmailSelected: boolean;
    isPhotoListDialogOpen: boolean;
    isFormDD214Saving: boolean;
    infoPopperAnchor: HTMLElement | null;
    taskType: 'standard' | 'after-care';
    isSaveTaskDialogOpen: boolean;
    infoPopperType: EntityCaseRole | null;
}

const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
    root: {},
    popoverPaper: {
        borderRadius: 12,
        WebkitBorderRadius: 4,
        MozBorderRadius: 4,
        boxShadow: theme.shadows[10],
    },
    popperContent: {
        borderRadius: 4,
        WebkitBorderRadius: 4,
        MozBorderRadius: 4,
    },
    activeUserName: {
        fontSize: 24,
        lineHeight: '1.25em',
        '&$editable': {
            cursor: 'pointer',
            fontSize: 22,
            maxWidth: 300,
            '&:hover': {
                textDecoration: 'underline'
            }
        }
    },
    arrow: {
        border: '20px solid transparent',
    },
    popper: {
        '&[data-popper-placement*="bottom"] $arrow': {
            borderBottom: '20px solid #fff',
            top: '-20px',
        },
        '&[data-popper-placement*="top"] $arrow': {
            borderTop: '20px solid #fff',
            bottom: '-20px',
        },
        width: '100%',
        maxWidth: 360,
    },
    relationShipText: {
        fontSize: 14,
        lineHeight: '1.0em',
        fontWeight: 500,
        '& span': {
            color: convertHexToRGBA(theme.palette.common.black, 0.3),
        }
    },
    topHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    adminLabel: {
        color: SKYBLUE_COLOR_2,
        fontSize: 14,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        '& svg': {
            color: SKYBLUE_COLOR_2,
            fontSize: 18,
        }
    },
    guestLabel: {
        color: '#757575',
        fontSize: 14,
        marginLeft: 4,
        cursor: 'pointer',
        '&$isDeceased': {
            cursor: 'auto !important'
        }
    },
    taskSection: {
        width: '100%',
        flexBasis: '100%',
        display: 'flex',
        minHeight: 200,
        maxHeight: 200,
        overflowY: 'auto',
        overflowX: 'hidden',
        background: convertHexToRGBA(theme.palette.primary.main, 0.12),
        boxShadow: 'inset 0px 0px 6px 0px rgba(0,0,0,0.2)',
    },
    invitedTime: {
        marginBottom: 8,
        fontSize: 11,
        fontWeight: 400
    },
    noTaskSection: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
        '& p': {
            fontSize: 14,
            lineHeight: '1.25em',
            textTransform: 'uppercase',
            maxWidth: 185,
            fontWeight: 300,
            color: theme.palette.primary.main,
        },
        '& svg': {
            fontSize: 40,
            color: convertHexToRGBA(theme.palette.primary.main, 0.8)
        }
    },
    footerSection: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '0 4px',
        minHeight: 32,
        '& button': {
            padding: '0px 6px',
            fontSize: 12,
            color: RED_COLOR,
            minHeight: 24,
            fontWeight: 400,
            '& svg': {
                fontSize: 14,
                color: RED_COLOR
            }
        },
        '& p': {
            padding: '0 6px',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
        }
    },
    taskListContainer: {
        margin: '0 4px',
        width: '100%',
    },
    taskListHeader: {
        fontSize: 14,
        textTransform: 'uppercase',
        marginTop: 8,
        fontWeight: 500
    },
    taskListHeaderHelperText: {
        margin: '2px 0px 8px',
        '& p': {
            color: convertHexToRGBA(theme.palette.primary.main, 0.8),
            fontSize: 11,
            lineHeight: 1.25,
        },
    },
    noTaskSectionHelperText: {
        '& p': {
            textTransform: 'none !important' as 'none',
            maxWidth: '280px !important',
        },
    },
    divider: {
        width: '100%',
        backgroundColor: theme.palette.primary.main,
        opacity: 0.2
    },
    taskContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '4px 4px 4px 8px',
    },
    taskName: {
        fontSize: 16,
        lineHeight: '1.25em',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline',
        },
        '&$completedOrSkipped': {
            textDecoration: 'line-through !important',
        }
    },
    taskDetails: {
        fontSize: 11,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    reminderButton: {
        padding: 0,
        minWidth: 42,
        maxWidth: 42,
    },
    reminderButtonContent: {
        fontSize: '0.5em',
        display: 'flex',
        flexDirection: 'column',
        lineHeight: '1.2em',
    },
    remindText: {
        fontSize: 9,
        lineHeight: '0.85em',
        textTransform: 'uppercase'
    },
    alarmIcon: {
        fontSize: 26,
    },
    noTaskSectionDivider: {
        height: 1,
        backgroundColor: theme.palette.primary.main,
        width: '100%'
    },
    noTaskSectionInnerContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '90%',
        '& hr': {
            margin: '8px 0'
        },
        '& svg': {
            marginBottom: 8
        }
    },
    emailPhoneMiddleSection: {
        display: 'flex',
        padding: 8,
        textAlign: 'center',
        alignItems: 'center',
        borderRadius: 4,
        justifyContent: 'center',
        '& button': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: 90,
            height: 80,
            textTransform: 'capitalize',
            '& p': {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            },
            '& svg': {
                fontSize: 36
            }
        },
        '& $emailButton': {
            width: 120
        },
    },
    emailPhonePopperCommonButton: {
        justifyContent: 'center',
        whiteSpace: 'nowrap',
        display: 'flex',
        borderBottom: `1px solid ${theme.palette.primary.main}`,
        textTransform: 'uppercase',
        borderRadius: 0,
        maxWidth: '90%',
        minWidth: '90%',
        margin: 'auto',
        '& svg': {
            verticalAlign: 'bottom',
            fontSize: 20
        },
        '&$emailPhonePopperBottomButton': {
            borderTop: `1px solid ${theme.palette.primary.main}`,
            borderBottom: 0
        }
    },
    bottomButtons: {
        cursor: 'pointer',
        fontSize: 11
    },
    noRelationshipText: {
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline'
        }
    },
    nonClickableHeadingText: {
        textDecoration: 'none !important',
        '&:hover': {
            cursor: 'default !important'
        }
    },
    nonGlobalTask: {
        '&:hover': {
            cursor: 'pointer !important',
            textDecoration: 'underline !important'
        },
    },
    isBottomsButtonsDisabled: {
        color: 'rgba(0, 0, 0, 0.26) !important'
    },
    menuPopper: {
        width: 'fit-content',
        maxWidth: 300,
        position: 'fixed',
        '&[data-popper-placement*="bottom"]': {
            marginTop: '15px !important'
        },
        '&[data-popper-placement*="top"]': {
            marginBottom: '15px !important'
        },
        '&[data-popper-placement*="bottom"] $arrow': {
            borderBottom: '20px solid #fff',
            top: '-20px',
        },
        '&[data-popper-placement*="top"] $arrow': {
            borderTop: '20px solid #fff',
            bottom: '-20px',
        },
    },
    menuPaper: {
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 4,
        WebkitBorderRadius: 4,
        MozBorderRadius: 4,
        filter: 'drop-shadow(0 2px 6px rgba(0, 0, 0, 0.4))',
        '-webkit-filter': 'drop-shadow(0 2px 6px rgba(0, 0, 0, 0.4))',
    },
    invitedBy: {
        fontSize: 12,
        fontWeight: 400
    },
    invitedTimeing: {
        fontSize: 12,
        fontWeight: 400
    },
    menuPopperHeader: {
        padding: 6
    },
    menuPopperDivider: {
        width: 'calc(100% - 16px)',
        margin: 'auto',
        backgroundColor: theme.palette.primary.main,
    },
    maxWidth100: {
        maxWidth: 100
    },
    addEmailButton: {
        maxWidth: 'calc(100% - 120px)'
    },
    menuItem: {
        '& p': {
            maxWidth: 260,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            fontSize: 'inherit'
        }
    },
    noInvitedSection: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        '& p': {
            fontSize: 14,
            lineHeight: '1.25em',
            textTransform: 'uppercase',
            maxWidth: 185,
            fontWeight: 300,
        },
    },
    notInvitedSectionInnerContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '90%',
        '& hr': {
            margin: '8px 0px 4px'
        },
    },
    playListIcon: {
        fontSize: 32,
        marginBottom: 4,
        color: convertHexToRGBA(theme.palette.primary.main, 0.8)
    },
    inviteNotInvitedUserButton: {
        textTransform: 'none !important' as 'none',
        maxWidth: '280px !important',
        fontWeight: 300,
        padding: '0px 16px',
        fontSize: 14,
        '& svg': {
            fontSize: 18,
            color: convertHexToRGBA(theme.palette.primary.main, 0.8)
        }
    },
    notInvitedDivider: {
        height: 1,
        backgroundColor: theme.palette.primary.main,
        width: '100%'
    },
    clickableText: {
        '&:hover': {
            textDecoration: 'underline',
            cursor: 'pointer',
        }
    },
    isNonUser: {},
    isDeceased: {},
    isAccepted: {},
    emailButton: {},
    emailPhonePopperBottomButton: {},
    completedOrSkipped: {},
    editable: {}
});

type StyledProps = WithGStyles<'root' | 'popoverPaper' | 'popperContent' | 'activeUserName' | 'header'
    | 'arrow' | 'popper' | 'relationShipText' | 'topHeader' | 'adminLabel' | 'guestLabel'
    | 'taskSection' | 'invitedTime' | 'noTaskSection' | 'footerSection' | 'taskListContainer' | 'taskListHeader'
    | 'taskListHeaderHelperText' | 'noTaskSectionHelperText' | 'divider' | 'taskContainer' | 'taskName'
    | 'taskDetails' | 'reminderButton' | 'reminderButtonContent' | 'remindText' | 'alarmIcon' | 'bottomButtons'
    | 'noTaskSectionDivider' | 'noTaskSectionInnerContainer' | 'noRelationshipText'
    | 'nonClickableHeadingText' | 'nonGlobalTask' | 'isBottomsButtonsDisabled' | 'menuPopper' | 'menuPaper'
    | 'invitedBy' | 'invitedTimeing' | 'menuPopperHeader' | 'menuPopperDivider' | 'maxWidth100' | 'addEmailButton'
    | 'menuItem' | 'noInvitedSection' | 'notInvitedSectionInnerContainer' | 'playListIcon'
    | 'inviteNotInvitedUserButton' | 'notInvitedDivider' | 'isNonUser' | 'isDeceased' | 'isAccepted'
    | 'emailButton' | 'emailPhonePopperBottomButton' | 'completedOrSkipped' | 'editable' | 'clickableText'>;

type CombinedProps = Props & StyledProps & TaskWorkOnButtonProps & InjectedProps;
class HelperPopper extends React.Component<CombinedProps, State> {

    state: State = {
        menuAnchor: null,
        isMenuOpen: false,
        isUploading: false,
        isConfirmDialogOpen: false,
        reminderAnchorEl: null,
        activeTask: null,
        emailPhoneAnchor: null,
        isEmailSelected: false,
        isPhotoListDialogOpen: false,
        isFormDD214Saving: false,
        infoPopperAnchor: null,
        taskType: 'standard',
        isSaveTaskDialogOpen: false,
        infoPopperType: EntityCaseRole.admin
    };

    protected fileUploadInput: HTMLInputElement | null;

    getActiveEntity = (): EntitySummary | null => {
        const { activeEntityId, team, helpers, selectedCase } = this.props;

        if (!activeEntityId) {
            return null;
        }

        if (selectedCase.assignee.entity_id === activeEntityId) {
            return selectedCase.assignee;
        }

        const helper = helpers.find((e) => e.entity_id === activeEntityId);
        if (helper) {
            return helper;
        }
        const teamMember = team.find((e) => e.entity_id === activeEntityId);
        if (teamMember) {
            return teamMember;
        }

        return null;
    };

    renderUploadFormContent = () => {
        const { classes } = this.props;
        return (
            <input
                type="file"
                accept="image/gif, image/jpeg, image/png, application/pdf, application/msword, 
                    application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                multiple={false}
                className={classes.displayNone}
                ref={ele => this.fileUploadInput = ele}
                onChange={this.handleFileUploadEvent}
                onClick={e => {
                    const element = e.target as HTMLInputElement;
                    // clear this value to so that same photo can be chosen each time
                    element.value = '';
                }}
            />
        );
    };

    renderMenuOptions = (activeEntity: EntitySummary) => {
        const { selectedCase, classes, zIndex } = this.props;
        const { menuAnchor, isMenuOpen } = this.state;

        const caseEntity = getCaseEntity(activeEntity, selectedCase.id);

        const invitedByFname = caseEntity && caseEntity.created_by_fname;
        const invitedTime = momentTz(caseEntity && caseEntity.created_time || new Date()).fromNow();

        const isAssignee = activeEntity.entity_id === selectedCase.assignee.entity_id;

        const acceptedTime = isAssignee
            ? selectedCase.created_time
            : caseEntity && caseEntity.accepted_time && caseEntity.accepted_time;
        const acceptedTimeStr = acceptedTime
            ? `Invitation accepted ${momentTz(acceptedTime).fromNow()}`
            : 'Invitation not yet accepted';

        const isActiveMenuOpen: boolean = Boolean(isMenuOpen && (acceptedTime || !activeEntity.user));
        const isPendingMenuOpen: boolean = Boolean(isMenuOpen && activeEntity.user && !acceptedTime);

        return (
            <>
                <GPopper
                    anchorEle={isActiveMenuOpen ? menuAnchor : null}
                    className={classes.menuPopper}
                    arrowClass={classes.arrow}
                    paperClass={classes.menuPaper}
                    zIndex={zIndex + 1}
                    closePopper={e => this.closeMenu()}
                    placement='bottom'
                >
                    {activeEntity.user &&
                        <>
                            <div className={classes.menuPopperHeader}>
                                <Typography
                                    align="center"
                                    color="secondary"
                                    className={classes.invitedBy}
                                >
                                    {isAssignee
                                        ? `${selectedCase.funeral_home.name}`
                                        : invitedByFname && `Invited by ${invitedByFname} ${invitedTime}`
                                    }
                                </Typography>
                                <Typography
                                    align="center"
                                    color="secondary"
                                    className={classNames(
                                        classes.invitedTimeing, acceptedTime
                                        ? ''
                                        : classes.colorOrange
                                    )}
                                >
                                    {acceptedTimeStr}
                                </Typography>
                            </div>
                            <Divider className={classes.menuPopperDivider} />
                        </>}
                    <WhenCanEditPerson target={activeEntity}>
                        <MenuItem
                            onClick={e => this.openMySettingsDialog()}
                        >
                            <ContactMailIcon />&nbsp;
                            Edit {activeEntity.fname}'s Details
                        </MenuItem>
                    </WhenCanEditPerson>
                    {!isAssignee &&
                        <WhenCanDeletePerson target={activeEntity}>
                            <MenuItem
                                onClick={e => this.confirmBeforeRemovingHelper()}
                                className={classes.colorRed}
                            >
                                <PersonAddDisabledIcon />&nbsp;
                                Delete {activeEntity.fname}
                            </MenuItem>
                        </WhenCanDeletePerson>
                    }
                    {!activeEntity.user && !activeEntity.is_deceased &&
                        <WhenCanInviteUser role={UserRole.User} caseRole={EntityCaseRole.guest}>
                            <MenuItem
                                onClick={e =>
                                    this.openInvitationDialog(
                                        EntityCaseRole.guest,
                                        activeEntity.entity_id
                                    )
                                }
                            >
                                <SendIcon />&nbsp;
                                Invite {activeEntity.fname}
                            </MenuItem>
                        </WhenCanInviteUser>
                    }
                </GPopper>

                <GPopper
                    anchorEle={isPendingMenuOpen ? menuAnchor : null}
                    className={classes.menuPopper}
                    arrowClass={classes.arrow}
                    paperClass={classes.menuPaper}
                    zIndex={zIndex + 1}
                    closePopper={e => this.closeMenu()}
                    placement='bottom'
                >
                    <div className={classes.menuPopperHeader}>
                        <Typography
                            align="center"
                            color="secondary"
                            className={classes.invitedBy}
                        >
                            {isAssignee
                                ? selectedCase.funeral_home.name
                                : invitedByFname && `Invited by ${invitedByFname} ${invitedTime}`
                            }
                        </Typography>
                        <Typography
                            align="center"
                            color="secondary"
                            className={classNames(
                                classes.invitedTimeing, acceptedTime ? '' : classes.colorOrange
                            )}
                        >
                            {acceptedTimeStr}
                        </Typography>
                    </div>
                    <Divider className={classes.menuPopperDivider} />
                    <WhenCanResendInvitation target={activeEntity}>
                        <MenuItem
                            onClick={e => this.resendInvitation()}
                            className={classes.menuItem}
                        >
                            <SendIcon />&nbsp;&nbsp;
                            <Typography>Resend Invitation</Typography>
                        </MenuItem>
                    </WhenCanResendInvitation>
                    <WhenCanRevokeInvitation target={activeEntity}>
                        <MenuItem
                            onClick={e => this.confirmBeforeRemovingHelper()}
                            className={classes.menuItem}
                        >
                            <CancelScheduleSendIcon />&nbsp;&nbsp;
                            <Typography>Revoke Invitation</Typography>
                        </MenuItem>
                    </WhenCanRevokeInvitation>
                    <WhenCanEditPerson target={activeEntity}>
                        <MenuItem
                            onClick={e => this.openMySettingsDialog()}
                            className={classes.menuItem}
                        >
                            <ContactMailIcon />&nbsp;&nbsp;
                            <Typography>Edit {activeEntity.fname}'s Details</Typography>
                        </MenuItem>
                    </WhenCanEditPerson>
                </GPopper>
            </>
        );
    };

    getHeaderHelperText = (activeEntity: EntitySummary) => {
        const { classes, selectedCase, userSession } = this.props;
        const { userData } = userSession;

        if (!userData) {
            return;
        }

        const canViewAllTasks = this.isAdminEntity(activeEntity);

        const canViewQRDetails = canViewCaseTrackingSteps(activeEntity, selectedCase.id, selectedCase.funeral_home_id);
        const canViewBelongings = canViewCaseBelongings(activeEntity, selectedCase.id, selectedCase.funeral_home_id);

        const canChangePermissions = canEditCasePermissions(userData, activeEntity, selectedCase.id);

        return (
            <>
                <Typography align="center">
                    {`${activeEntity.fname} can view ` +
                        `${canViewAllTasks && 'ALL family tasks' ||
                        'ONLY assigned tasks'} `}
                </Typography>

                <WhenEnabled feature={FeatureKey.KEEP_TRACK}>
                    {canViewQRDetails &&
                        <Typography
                            align="center"
                            onClick={e => {
                                if (canChangePermissions) {
                                    this.openMySettingsDialog(HelperUserSettingsTab.permissions);
                                }
                            }}
                            className={classNames(canChangePermissions && classes.clickableText)}
                        >
                            {`${activeEntity.fname} can view Tracking Step Details`}
                        </Typography>
                    }

                    {canViewBelongings &&
                        <Typography
                            align="center"
                            onClick={e => {
                                if (canChangePermissions) {
                                    this.openMySettingsDialog(HelperUserSettingsTab.permissions);
                                }
                            }}
                            className={classNames(canChangePermissions && classes.clickableText)}
                        >
                            {`${activeEntity.fname} can view case belongings`}
                        </Typography>
                    }
                </WhenEnabled>
            </>
        );
    };

    renderTaskList = (activeEntity: EntitySummary, tasks: HelperTask[]) => {
        const { classes, zIndex } = this.props;

        return (
            <Grid item xs={12} className={classes.taskListContainer}>
                <Grid item xs={12}>
                    <Typography align="center" color="primary" className={classes.taskListHeader}>
                        {`${activeEntity.fname} is assigned to ` +
                            `${tasks.length === 1 && '1 Task' || `${tasks.length} Tasks`}`}
                    </Typography>
                    <Grid className={classes.taskListHeaderHelperText}>
                        {this.getHeaderHelperText(activeEntity)}
                    </Grid>
                </Grid>
                <Divider className={classes.divider} />
                {tasks.map(task => {

                    const fname = task.assignedTo && task.assignedTo.fname || '';

                    const assignedTime = task.assignedTo && task.assignedTo.time || '';
                    const assignedText = `Assigned By ${fname} ${assignedTime}`;

                    const isTaskIncomplete = CaseTaskUX.isIncomplete(task);
                    const isTaskCompleted = CaseTaskUX.isComplete(task);

                    const completedTime = momentTz(task.marked_complete_time || new Date()).fromNow();
                    const skippedTime = momentTz(task.skipped_time || new Date()).fromNow();

                    const completerFname = isTaskCompleted && task.marked_complete_by && task.marked_complete_by.fname
                        || task.skipped_by && task.skipped_by.fname;
                    const completedOrSkippedText = `${isTaskCompleted && 'Completed' || 'Skipped'} By ` +
                        `${completerFname || ''} ${isTaskCompleted && completedTime || skippedTime}`;

                    const shouldShowRemindButton = isTaskIncomplete && this.isAdminRole();

                    const isScheduleServicesTask = task.template_type === TaskTemplateType.schedule_services;

                    return (
                        <div key={task.id.toString()}>
                            <Grid item xs={12} className={classes.taskContainer}>
                                <Grid xs={shouldShowRemindButton && 10 || 12} item>
                                    <Typography
                                        color="primary"
                                        className={classNames(
                                            classes.taskName,
                                            this.titleNotClickable(task) && classes.nonClickableHeadingText,
                                            !task.from_task_id && classes.nonGlobalTask,
                                            !isTaskIncomplete && classes.completedOrSkipped
                                        )}
                                        onClick={e => {
                                            const taskType = task.is_after_care && 'after-care' || 'standard';
                                            this.handleClickOnTaskTitle(taskType, isScheduleServicesTask, task);
                                        }}
                                    >
                                        {isScheduleServicesTask &&
                                            <EventSlider
                                                parentComponent={'DETAIL_TASKCARD'}
                                                renderOnlyAddEventButton
                                                zIndex={zIndex + 1}
                                            /> ||
                                            task.title}
                                    </Typography>
                                    <Typography
                                        color="primary"
                                        className={classes.taskDetails}
                                    >
                                        {`${isTaskIncomplete && assignedText || completedOrSkippedText}`}
                                    </Typography>
                                </Grid>
                                {shouldShowRemindButton &&
                                    <Grid item xs={2} className={classes.textRight}>
                                        <Button
                                            aria-label="Send a Reminder"
                                            size="small"
                                            className={classes.reminderButton}
                                            onClick={e => this.openReminderPopover(e, task)}
                                        >
                                            <div className={classes.reminderButtonContent}>
                                                <span>
                                                    <AlarmIcon color="primary" className={classes.alarmIcon} />
                                                </span>
                                                <Typography
                                                    color="primary"
                                                    className={classes.remindText}
                                                >
                                                    Remind
                                                </Typography>
                                            </div>
                                        </Button>
                                    </Grid>}
                            </Grid>
                            <Divider className={classes.divider} />
                        </div>
                    );
                })}
            </Grid>
        );
    };

    renderNoTaskSection = (activeEntity: EntitySummary) => {
        const { classes } = this.props;

        return (
            <Grid item xs={12} className={classes.noTaskSection}>
                <Grid item xs={12} className={classes.noTaskSectionInnerContainer}>
                    <PlaylistAddCheckIcon />
                    <Typography align="center" color="primary">
                        {activeEntity.fname}'s assigned tasks will display here
                    </Typography>

                    <Divider className={classes.noTaskSectionDivider} />

                    <Grid className={classes.noTaskSectionHelperText}>
                        {this.getHeaderHelperText(activeEntity)}
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    renderNotInvited = (activeEntity: EntitySummary) => {
        const { classes } = this.props;

        return (
            <Grid item xs={12} className={classes.noInvitedSection}>
                <Grid item xs={12} className={classes.notInvitedSectionInnerContainer}>
                    <VisibilityOffIcon className={classes.playListIcon} />
                    <Typography align="center" color="primary">
                        {activeEntity.fname} has not yet been invited
                    </Typography>

                    <Divider className={classes.notInvitedDivider} />
                    <Button
                        color="primary"
                        className={classes.inviteNotInvitedUserButton}
                        onClick={e => this.openInvitationDialog(EntityCaseRole.guest, activeEntity.entity_id)}
                    >
                        <SendIcon />&nbsp;
                        Click here to Invite {activeEntity.fname}
                    </Button>
                </Grid>
            </Grid>
        );
    };

    renderTaskSection = (activeEntity: EntitySummary) => {
        const {
            classes,
            tasks,
            userSession,
            selectedCase,
        } = this.props;
        const { userData } = userSession;

        if (!activeEntity.user && !activeEntity.is_deceased) {
            return (
                <Grid item xs={12} className={classes.taskSection}>
                    {this.renderNotInvited(activeEntity)}
                </Grid>
            );
        }
        const targetIsFHUser = UserRoles.isFHUser(activeEntity);

        const shouldHideTasks = Boolean(
            // First - Hide tasks for deceased (user/nonuser)
            (activeEntity.is_deceased)  // Hide tasks for deceased (user)
            || !activeEntity.user_id // Hide tasks for non-user

            // Hide Funeral Home User tasks from Family users viewing the case
            || ((targetIsFHUser || selectedCase.assignee_entity_id === activeEntity.entity_id)
                && userData && UserRoles.isFamilyOnCase(userData, selectedCase.id))

            // Hide tasks for all users when the viewer is a Family Guest
            // except let them view their own tasks
            || (userData
                && UserRoles.isFamilyGuest(userData, selectedCase.id)
                && userData.id !== activeEntity.user_id)
        );

        if (shouldHideTasks) {
            return null;
        }

        const userTasks = getHelpersTasks({ tasks, helper: activeEntity });

        return (
            <Grid item xs={12} className={classes.taskSection}>
                {userTasks.length === 0
                    ? this.renderNoTaskSection(activeEntity)
                    : this.renderTaskList(activeEntity, userTasks)
                }
            </Grid>
        );
    };

    renderPopperFooter = (activeEntity: EntitySummary) => {
        const { classes, userSession, selectedCase } = this.props;
        const { userData } = userSession;
        if (!userData) {
            return <Grid item xs={12} className={classes.footerSection} />;
        }

        const caseEntity = getCaseEntity(activeEntity, selectedCase.id);
        const activeEntityMail = activeEntity.email;
        const activeEntityPhone = activeEntity.phone;

        const isGuestInvitee = UserRoles.isFamilyGuest(userData, selectedCase.id)
            && activeEntity.user && userData.id !== activeEntity.user.user_id;
        const isAdminInviteeAndAdminUser = UserRoles.isFamilyAdmin(userData, selectedCase.id)
            && UserRoles.isFamilyAdmin(activeEntity, selectedCase.id)
            && activeEntity.user && userData.id !== activeEntity.user.user_id;
        const hasHelperAcceptedInvitation = UserRoles.isFamilyOnCase(userData, selectedCase.id)
            && caseEntity && caseEntity.accepted_time !== null;
        const tooltipText = isGuestInvitee
            && 'For security reasons, only the funeral director can change a phone number or email.'
            || isAdminInviteeAndAdminUser
            && 'For security reasons, you cannot change another ADMIN\'s phone number or email.'
            || hasHelperAcceptedInvitation && activeEntity.user
            && userData.id !== activeEntity.user.user_id
            && 'For security reasons, once a GUEST has accepted their invite, ' +
            'you cannot change their phone number or email.' || '';

        const isAddButtonDisabled = activeEntity.user && userData.id !== activeEntity.user.user_id
            && (isGuestInvitee || isAdminInviteeAndAdminUser || hasHelperAcceptedInvitation);

        const isDeceased = activeEntity.is_deceased;

        return (
            <Grid item xs={12} className={classes.footerSection}>
                {activeEntityMail &&
                    <Typography
                        color="secondary"
                        onClick={e => this.openEmailPhonePopper(e, true)}
                        id="active-invitee--email"
                        className={classNames(classes.bottomButtons, classes.addEmailButton)}
                    >
                        {activeEntityMail}
                    </Typography>
                    || isDeceased && <span />
                    || <Tooltip title={tooltipText} placement="top">
                        <div className={classes.addEmailButton}>
                            <Button
                                onClick={this.openActiveHelperSettings}
                                disabled={Boolean(isAddButtonDisabled)}
                                className={classNames(
                                    isAddButtonDisabled && classes.isBottomsButtonsDisabled
                                )}
                            >
                                <AddIcon
                                    className={classNames(isAddButtonDisabled && classes.isBottomsButtonsDisabled)}
                                />
                                add email
                            </Button>
                        </div>
                    </Tooltip>}
                {activeEntityPhone &&
                    <Typography
                        color="secondary"
                        onClick={e => this.openEmailPhonePopper(e, false)}
                        id="active-invitee--phone"
                        className={classNames(classes.bottomButtons, classes.maxWidth100)}
                    >
                        {getFormattedPhoneNumber(activeEntityPhone)}
                    </Typography>
                    || isDeceased && <span />
                    || <Tooltip title={tooltipText} placement="top">
                        <div className={classes.maxWidth100}>
                            <Button
                                onClick={this.openActiveHelperSettings}
                                disabled={Boolean(isAddButtonDisabled)}
                            >
                                <AddIcon
                                    className={classNames(isAddButtonDisabled && classes.isBottomsButtonsDisabled)}
                                />
                                add phone
                            </Button>
                        </div>
                    </Tooltip>}
            </Grid>
        );
    };

    render() {
        const {
            classes,
            popperAnchorEle,
            selectedCase,
            casePhotos,
            isCasePhotosLoading,
            zIndex,
            isParentDialogOpen,
            taskDialogToBeMounted,
            handleTaskDialogClose,
            clickAwayListener
        } = this.props;
        const {
            isConfirmDialogOpen,
            reminderAnchorEl,
            isMenuOpen,
            isUploading,
            activeTask,
            emailPhoneAnchor,
            isPhotoListDialogOpen,
            infoPopperAnchor,
            taskType,
            isSaveTaskDialogOpen,
            infoPopperType,
            isEmailSelected
        } = this.state;

        const activeEntity = this.getActiveEntity();

        const confirmationDialogContent = activeEntity &&
            getConfirmationDialogContentForHelpers(selectedCase, activeEntity);
        const header = confirmationDialogContent && confirmationDialogContent.header || '';
        const confirmText = confirmationDialogContent && confirmationDialogContent.confirmText || '';
        const confirmButtonText = confirmationDialogContent && confirmationDialogContent.confirmButtonText || '';
        const cancelButtonText = confirmationDialogContent && confirmationDialogContent.cancelButtonText || '';
        const isDCEntity = confirmationDialogContent && confirmationDialogContent.isDCEntity || '';
        return (
            <>
                <GPopper
                    anchorEle={popperAnchorEle}
                    className={classes.popper}
                    popperContentClass={classes.popperContent}
                    arrowClass={classes.arrow}
                    paperClass={classes.popoverPaper}
                    zIndex={zIndex}
                    placement="top"
                    closePopper={e => {
                        if (isParentDialogOpen !== undefined && !isParentDialogOpen) {
                            this.closeMenu();
                            this.closeEmailPhonePopper();
                            clickAwayListener(e);
                            return;
                        }
                        if (reminderAnchorEl
                            || !popperAnchorEle
                            || isMenuOpen
                            || emailPhoneAnchor
                            || infoPopperAnchor
                            || isConfirmDialogOpen
                        ) {
                            return;
                        }
                        clickAwayListener(e);
                    }}
                >
                    {activeEntity &&
                        <>
                            <HelperPopperHeader
                                selectedCase={selectedCase}
                                activeEntity={activeEntity}
                                isAdminEntity={this.isAdminEntity(activeEntity)}
                                isMenuOpen={isMenuOpen}
                                onEntityStatusClick={this.openMenu}
                                onEntityDetailsClick={this.openMySettingsDialog}
                                onEntityTypeClick={this.openInvitationPopper}
                            />

                            {this.renderTaskSection(activeEntity)}
                            {this.renderPopperFooter(activeEntity)}
                        </>
                    }

                    {activeTask &&
                        <ReminderPopper
                            anchorEle={reminderAnchorEl}
                            isActive
                            task={activeTask}
                            selectedCase={selectedCase}
                            closePopover={this.closeReminderPopover}
                            zIndex={zIndex + 1}
                        />
                    }
                </GPopper>

                {activeEntity &&
                    <>
                        {this.renderMenuOptions(activeEntity)}

                        <EmailPhonePopper
                            key={emailPhoneAnchor?.id}
                            zIndex={zIndex + 1}
                            anchorEle={emailPhoneAnchor}
                            activeEntity={activeEntity}
                            selectedCase={selectedCase}
                            isEmailSelected={isEmailSelected}
                            closePopper={this.closeEmailPhonePopper}
                            onMySettingsDialogOpen={this.openMySettingsDialog}
                        />
                    </>
                }

                {this.renderUploadFormContent()}

                <ConfirmationDialog
                    header={header}
                    subHeader={confirmText}
                    confirmationButtonText={confirmButtonText}
                    onClose={this.closeConfirmationDialog}
                    open={isConfirmDialogOpen}
                    onConfirm={isDCEntity ? undefined : this.removeHelper}
                    zIndex={zIndex + 1}
                    cancelButtonText={cancelButtonText}
                />

                <PhotoListDialog
                    downloadCasePhotos={this.downloadCasePhotos}
                    activeCase={selectedCase}
                    isLoading={isCasePhotosLoading}
                    isUploading={isUploading}
                    photoList={casePhotos}
                    isDialogOpen={isPhotoListDialogOpen}
                    activePhotoId={selectedCase.photo_id || undefined}
                    closeDialog={this.closePhotoListDialog}
                    onFileUpload={this.handlePhotoUploadEvent}
                    onPhotoDelete={this.handlePhotoDelete}
                    allowMultiSelection={false}
                    acceptMultiple
                    zIndex={zIndex + 1}
                    hideDownloadButton
                />

                <InfoPopper
                    zIndex={zIndex + 1}
                    popperAnchorEle={infoPopperAnchor}
                    clickAwayListener={this.invitationPopperClickAwayListener}
                    caseFName={selectedCase.fname}
                    infoPopperType={infoPopperType}
                />

                <SaveTaskDialog
                    key={activeTask ? activeTask.id : taskType}
                    existing={activeTask}
                    activeCase={selectedCase}
                    type={taskType}
                    zIndex={zIndex + 1}
                    isOpen={isSaveTaskDialogOpen}
                    closeDialog={this.closeSaveTaskDialog}
                />

                <TaskDialog
                    taskDialogToBeMounted={taskDialogToBeMounted}
                    activeCase={selectedCase}
                    activeTaskId={activeTask && activeTask.id || undefined}
                    closeDialog={handleTaskDialogClose}
                    zIndex={zIndex + 1}
                />
            </>
        );
    }

    openInvitationDialog = (currentTab: EntityCaseRole = EntityCaseRole.admin, entityId: number) => {
        const { dispatch, closeHelperPopper, zIndex } = this.props;
        dispatch(openHelperInvitationDialog({
            zIndex: zIndex + 1,
            defaultTab: currentTab,
            sendInvite: true,
            selectedEntityId: entityId,
        }));
        this.closeMenu();
        closeHelperPopper();
    };

    closeSaveTaskDialog = () => {
        this.setState({ isSaveTaskDialogOpen: false });
    };

    handleClickOnTaskTitle = (
        taskType: 'standard' | 'after-care',
        isScheduleServicesTask: boolean,
        task: HelperTask
    ) => {
        if (!task.from_task_id) {
            this.handleEditTask(taskType, task);
            return;
        }
        if (!isScheduleServicesTask) {
            this.openTask(task);
        }

    };

    handleEditTask = (taskType: 'standard' | 'after-care', task: CaseTaskUX) => {

        this.setState({
            activeTask: task,
            taskType,
            isSaveTaskDialogOpen: true
        });
    };

    openActiveHelperSettings = () => {
        const { userSession, selectedCase } = this.props;

        const activeEntity = this.getActiveEntity();

        if (userSession.userData && activeEntity &&
            canEditNonSensitivePersonDetails(
                userSession.userData,
                activeEntity,
                selectedCase.funeral_home_id,
                selectedCase.id
            )) {
            this.openMySettingsDialog();
        }
    };

    openInvitationPopper = (e: React.MouseEvent<HTMLElement>, infoPopperType: EntityCaseRole) => {
        e.preventDefault();
        e.stopPropagation();

        this.setState({
            infoPopperAnchor: e.currentTarget,
            infoPopperType
        });
    };

    invitationPopperClickAwayListener = (e: MouseEvent | TouchEvent) => {
        this.setState({
            infoPopperAnchor: null
        });
    };

    handleFileUploadEvent = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const { dispatch, selectedCase, userSession } = this.props;

        const userId = userSession.userData?.user_id;
        if (userId === undefined) {
            return;
        }

        this.setState({ isFormDD214Saving: true, isUploading: true });
        await dispatch(createCaseDocs({
            files: event.target.files,
            userId,
            caseUuid: selectedCase.uuid,
            docCategory: DocCategory.form_dd214,
        }));
        this.setState({ isFormDD214Saving: false, isUploading: true });
    };

    handlePhotoDelete = (photoToDelete: GatherPhoto) => {
        const { dispatch, selectedCase } = this.props;

        if (photoToDelete && isAlbumEntry(photoToDelete.photo)) {
            dispatch(deleteCasePhoto({
                gatherCase: selectedCase,
                albumEntryId: photoToDelete.photo.id,
            }));
        }
    };

    handlePhotoUploadEvent = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        const { selectedCase, dispatch } = this.props;

        await performCasePhotoUpload(files, selectedCase, createUploadFromDispatch(dispatch));
    };

    closePhotoListDialog = () => {
        this.setState({ isPhotoListDialogOpen: false });
    };

    downloadCasePhotos = (): Promise<string | null> => {
        const { dispatch, selectedCase } = this.props;

        return dispatch(downloadCasePhotos(selectedCase.uuid));
    };

    openPhotoListDialog = () => {
        this.setState({ isPhotoListDialogOpen: true });
    };

    uploadFormDD214 = () => {
        if (this.fileUploadInput) {
            this.fileUploadInput.click();
        }
    };

    openScheduleArrangementConferenceDialog = () => {
        const { dispatch, zIndex } = this.props;

        if (!this.isAdminRole()) {
            return;
        }

        dispatch(openArrangementConferenceDialog(zIndex + 1));
    };

    scrollToFlowerCards = () => {
        const { closeHelperPopper } = this.props;
        const flowersCardsUl: HTMLElement | null = document.getElementById('flowers-cards-ul');

        if (flowersCardsUl) {
            flowersCardsUl.scrollIntoView({ behavior: 'smooth' });
        }
        closeHelperPopper();
    };

    openTask = (task: HelperTask) => {
        const { selectedCase, onWorkOnButtonClicked, closeHelperPopper, closeParentDialog } = this.props;
        closeHelperPopper();

        if (closeParentDialog) {
            closeParentDialog();
        }

        switch (task.template_type) {
            case TaskTemplateType.upload_photos:
                this.openPhotoListDialog();
                break;
            case TaskTemplateType.casket_bearers:
                onWorkOnButtonClicked({
                    link: WorkOnButtonLink.specifyCasketBearers,
                    caseName: selectedCase.name,
                    funeralHomeKey: selectedCase.funeral_home.key,
                    taskId: task.id,
                });
                break;
            case TaskTemplateType.build_photo_slideshow:
                onWorkOnButtonClicked({
                    link: WorkOnButtonLink.photoSlideshow,
                    caseName: selectedCase.name,
                    funeralHomeKey: selectedCase.funeral_home.key,
                    taskId: task.id,
                });
                break;
            case TaskTemplateType.arrangement_conference:
                this.openScheduleArrangementConferenceDialog();
                break;
            case TaskTemplateType.form_dd_214:
                this.uploadFormDD214();
                break;
            case TaskTemplateType.flowers_cards:
                this.scrollToFlowerCards();
                break;
            default:
                const button = task.template_type && templateButtonLookup[task.template_type];
                if (button && button.link) {
                    onWorkOnButtonClicked({
                        link: button.link,
                        caseName: selectedCase.name,
                        funeralHomeKey: selectedCase.funeral_home.key,
                    });
                }
                break;
        }
    };

    handleCopyToClipboard = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation();
        e.preventDefault();
        const { dispatch } = this.props;
        const { isEmailSelected } = this.state;

        const activeEntity = this.getActiveEntity();
        if (!activeEntity) {
            return;
        }

        dispatch(setAppSnackbar(
            `${activeEntity.fname}'s ${isEmailSelected ? 'Email' : 'Phone Number'} ` +
            'is copied to your clipboard',
            'success'
        ));
        this.setState({ emailPhoneAnchor: null });
    };

    openEmailPhonePopper = (e: React.MouseEvent<HTMLElement>, isEmailSelected: boolean) => {
        this.setState({ emailPhoneAnchor: e.currentTarget, isEmailSelected });
    };

    closeEmailPhonePopper = () => {
        this.setState({ emailPhoneAnchor: null });
    };

    openReminderPopover = (event: React.MouseEvent<HTMLElement>, activeTask: CaseTaskUX) => {
        this.setState({ reminderAnchorEl: event.currentTarget, activeTask });
    };

    closeReminderPopover = () => {
        this.setState({ reminderAnchorEl: null, activeTask: null });
    };

    isAdminEntity = (activeEntity: EntitySummary) => {
        const { selectedCase } = this.props;
        return UserRoles.isFamilyAdmin(activeEntity, selectedCase.id) ||
            UserRoles.isFHorGOMUser(activeEntity) ||
            selectedCase.assignee_entity_id === activeEntity.entity_id;
    };

    isAdminRole = () => {
        const { userSession } = this.props;
        return UserRoles.isFHorGOMUser(userSession.userData);
    };

    closeConfirmationDialog = () => {
        this.setState({
            isConfirmDialogOpen: false,
        });
    };

    removeHelper = () => {
        const { dispatch, selectedCase } = this.props;

        const activeEntity = this.getActiveEntity();
        if (!activeEntity) {
            console.warn('No active entity for removeHelper');
            return;
        }

        dispatch(removeHelper(selectedCase.uuid, activeEntity));
        this.closeConfirmationDialog();
    };

    closeMenu = () => {
        this.setState({
            isMenuOpen: false,
            menuAnchor: null,
        });
    };

    openMenu = (e: React.MouseEvent<HTMLElement>) => {
        this.setState({
            menuAnchor: e.currentTarget,
            isMenuOpen: true,
        });
    };

    shouldShowMenu = (): boolean => {
        const { userSession, selectedCase } = this.props;
        const { userData } = userSession;

        const activeEntity = this.getActiveEntity();

        if (!userData || !activeEntity) {
            return false;
        }

        const activeCaseEntity = getCaseEntity(activeEntity, selectedCase.id);
        const hasActiveEntityLoggedIn = activeCaseEntity
            ? activeCaseEntity.accepted_time
            : UserRoles.isFHUser(activeEntity) || activeEntity.entity_id === selectedCase.assignee_entity_id;

        const canDelete = canDeletePerson(userData, activeEntity, selectedCase.funeral_home_id, selectedCase.id);
        const canEdit = canEditNonSensitivePersonDetails(
            userData,
            activeEntity,
            selectedCase.funeral_home_id,
            selectedCase.id
        );
        const canResend = canResendInvitation(
            userData,
            activeEntity,
            selectedCase.id
        );
        const canRevoke = canRevokeInvitation(
            userData,
            activeEntity,
            selectedCase.id
        );
        return hasActiveEntityLoggedIn || isNonUserCaseHelper(activeEntity, selectedCase.id)
            ? (canDelete || canEdit)
            : (canResend || canRevoke);
    };

    confirmBeforeRemovingHelper = () => {
        this.setState({
            isConfirmDialogOpen: true,
        });
        this.closeMenu();
    };

    openMySettingsDialog = (tab?: MySettingsTab) => {
        const {
            dispatch,
            activeEntityId,
            closeHelperPopper,
            popperAnchorEle,
            zIndex,
        } = this.props;
        const { emailPhoneAnchor } = this.state;

        if (activeEntityId) {
            dispatch(openMySettingsDialog(zIndex + 1, activeEntityId, tab));
        }

        this.closeMenu();
        if (emailPhoneAnchor) {
            this.closeEmailPhonePopper();
        }
        if (popperAnchorEle) {
            closeHelperPopper();
        }
    };

    resendInvitation = async () => {
        const { selectedCase, dispatch } = this.props;

        const activeEntity = this.getActiveEntity();
        const caseEntity = getCaseEntity(activeEntity, selectedCase.id);
        if (!activeEntity || !activeEntity.user || !caseEntity) {
            return;
        }

        if (!caseEntity.accepted_time && activeEntity.user) {
            await dispatch(resendInvitation(selectedCase.uuid, activeEntity));
        }
        this.closeMenu();
    };

    titleNotClickable = (activeTask: HelperTask) => {
        const { formDD214 } = this.props;
        const hasFormDD214 = Boolean(formDD214);

        return !activeTask.template_type || (activeTask.template_type && (
            (activeTask.template_type === TaskTemplateType.form_dd_214 && hasFormDD214) ||
            (!this.isAdminRole() && activeTask.template_type === TaskTemplateType.arrangement_conference)
        ))
            ? true : false;
    };
}

export default compose(
    withState(mapStateToProps),
    withGStyles(styles),
    withTaskWorkOnButton
)(HelperPopper) as React.ComponentType<Props & Partial<TaskWorkOnButtonProps>>;
