import * as React from 'react';
import classNames from 'classnames';
import momentTz from 'moment-timezone';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import DialogTitle from '@mui/material/DialogTitle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import PhotoSizeSelectActualIcon from '@mui/icons-material/PhotoSizeSelectActual';
import Tooltip from '@mui/material/Tooltip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArchiveIcon from '@mui/icons-material/Archive';

import { Props as PhotoListProps } from './PhotoList';
import MasonryPhotoList from './MasonryPhotoList';
import PrivateEventUserWidget from '../family/remember/events/PrivateEventUserWidget';
import { EntityCaseRole, GatherCasePublic, GatherCaseUX, isAlbumEntry, UserRoles } from '../../shared/types';
import { GatherPhoto, StoreState } from '../../types';
import { _getPhotoId } from '../family/photoSlideshow';
import { ORANGE_COLOR, SUCCESS_COLOR } from '../../constants/colorVariables';
import { convertHexToRGBA } from '../../services';
import ConfirmationDialog from '../common/ConfirmationDialog';
import { openHelperInvitationDialog } from '../../actions/Dialog.action';
import DownloadCasePhotosDialog from '../family/photoSlideshow/DownloadCasePhotos.dialog';
import FuneralHomeLogo from '../common/FuneralHomeLogo';
import { AppDispatch } from '../../store';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback } from '@mui/styles';
import withState from '../common/utilHOC/WithState';
import withGStyles, { WithGStyles } from '../../styles/WithGStyles';
import { compose } from 'redux';
import { SlideTransition } from '../common/Transitions';
import withFullScreen from '../common/utilHOC/WithFullScreen';
import { withDialogMounter } from '../../DialogMounter';

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {
        '& $dialogPaper': {
            display: 'flex',
            flexWrap: 'nowrap',
            justifyContent: 'space-around',
            overflow: 'hidden',
            width: '100%',
            maxWidth: '100%',
            minHeight: '90vh',
            '@media (min-width: 960px)': {
                maxWidth: 920,
                width: 920,
                maxHeight: '90vh',
            },
        },
    },
    fixPadding: {
        padding: '0 24px',
        '@media (max-width: 960px)': {
            padding: 0
        }
    },
    dialogHeader: {
        zIndex: 1,
        padding: 10,
        borderBottom: '1px solid rgba(0,0,0,0.21)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        minHeight: 40,
    },
    dialogPaper: {},
    dialogContent: {
        zIndex: 0,
        padding: 0,
        overflowX: 'hidden',
        paddingTop: 0,
        '&:first-of-type': {
            padding: 0,
        },
        height: 'calc(100vh - 61px)',
        '@media (min-width: 960px)': {
            height: 'calc(90vh - 61px)',
        },
    },
    button: {
        position: 'relative',
        zIndex: 5,
        margin: theme.spacing(),
    },
    footer: {
        position: 'absolute',
        left: 0,
        bottom: 0,
        width: '100%',
        padding: 8,
        textAlign: 'center',
    },
    masonryWrapper: {
        marginTop: 10,
        marginBottom: 10,
    },
    backButton: {
        width: 36,
        height: 36,
        '& svg': {
            color: theme.palette.common.white
        }
    },
    headerContainer: {
    },
    photosCount: {
        fontSize: 40,
        lineHeight: '0.90em'
    },
    photoSelectedText: {
        lineHeight: '0.875em'
    },
    counterContainer: {
        margin: '12px 0px'
    },
    divider: {
        width: 'calc(100% - 16px)',
        margin: 'auto',
        backgroundColor: theme.palette.primary.main
    },
    ofText: {
        lineHeight: '0.875em',
        fontSize: 14,
        verticalAlign: 'middle',
        margin: '0px 4px'
    },
    cancelButton: {
        color: theme.palette.common.white
    },
    selectButtonContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '12px 16px'
    },
    dialogFooter: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: '0px 10px',
        marginBottom: 40
    },
    smallDivider: {
        width: '50%',
        margin: 'auto',
        backgroundColor: theme.palette.primary.main
    },
    libraryIntruction: {
        margin: '10px 0px 6px 0px'
    },
    inviteOtherHeading: {
        marginTop: 10
    },
    closeIcon: {
        color: ORANGE_COLOR
    },
    deSelectButton: {
        color: ORANGE_COLOR,
        borderColor: ORANGE_COLOR,
        '&:hover': {
            borderColor: ORANGE_COLOR,
            backgroundColor: convertHexToRGBA(ORANGE_COLOR, 0.08)
        }
    },
    selectOrDeselectButton: {
        maxHeight: 42,
        minHeight: 42
    },
    svgContainer: {
        display: 'flex',
        alignItems: 'center',
        maxWidth: 84,
        justifyContent: 'space-between',
        width: '100%'
    },
    smallIconButton: {
        height: 48,
        display: 'flex',
        alignItems: 'flex-end',
        marginBottom: 4,
        '& svg': {
            fontSize: 32,
            opacity: 0.3,
            '&:hover': {
                cursor: 'pointer'
            }
        }
    },
    largeIconButton: {
        height: 48,
        '& svg': {
            height: 48,
            width: 48,
            opacity: 0.3,
            '&:hover': {
                cursor: 'pointer'
            }
        }
    },
    selectedSvg: {
        opacity: '1 !important' as unknown as number
    },
    fhLogoContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 10
    },
    selectedAllButton: {
        color: SUCCESS_COLOR,
        borderColor: SUCCESS_COLOR,
        '&:hover': {
            borderColor: SUCCESS_COLOR,
            backgroundColor: convertHexToRGBA(SUCCESS_COLOR, 0.08)
        }
    },
    downloadButton: {
        display: 'flex',
        margin: '0 auto 16px'
    },
    checkCircleIcon: {
        color: SUCCESS_COLOR
    },
});

type StyledProps = Props & WithGStyles<'root' | 'dialogPaper' | 'dialogContent' | 'button' | 'footer' | 'masonryWrapper'
    | 'dialogHeader' | 'backButton' | 'headerContainer' | 'photosCount' | 'photoSelectedText' | 'counterContainer'
    | 'divider' | 'ofText' | 'cancelButton' | 'selectButtonContainer' | 'dialogFooter' | 'smallDivider'
    | 'libraryIntruction' | 'inviteOtherHeading' | 'closeIcon' | 'deSelectButton' | 'selectOrDeselectButton'
    | 'smallIconButton' | 'largeIconButton' | 'svgContainer' | 'selectedSvg' | 'fhLogoContainer' | 'selectedAllButton'
    | 'checkCircleIcon' | 'fixPadding' | 'downloadButton'>;

interface DialogProps {
    fullScreen: boolean;
}

export enum PhotoSizeEnum {
    smallIcon = 'smallIcon',
    largeIcon = 'largeIcon'
}

type CombinedProps = DialogProps & StyledProps;

const mapStateToProps = ({ casesState, userSession }: StoreState, ownProps: OwnProps) => {
    const { helpers } = casesState;
    const { activeCase } = ownProps;
    const { userData } = userSession;

    return {
        helpers: helpers.filter(h => UserRoles.isFamilyOnCase(h, activeCase.id)),
        isInvitedFamily: UserRoles.isInvitedFamilyOnCase(userData, activeCase.id),
        isFHorGOMUser: UserRoles.isFHorGOMUserOnFH(userData, activeCase.funeral_home.id)
    };
};

interface OwnProps extends PhotoListProps {
    isUploading: boolean;
    isDialogOpen: boolean;
    initialSelectedPhotoIds?: number[];
    allowMultiSelection: boolean;
    disablePhotoSwipeOnClick?: boolean;
    hideDeleteButton?: boolean;
    hideUploadButton?: boolean;
    onDownloadClick?: (albumId: number) => void;
    zIndex: number;
    activeCase: GatherCaseUX | GatherCasePublic;
    closeDialog: (selectedPhotoIds?: number[]) => void;
    hideEditButton?: boolean;
    enablePhotoSwipe?: boolean;
}

interface Props extends OwnProps, ReturnType<typeof mapStateToProps> {
    dispatch: AppDispatch;
}

interface State {
    selectedPhotoIds: number[];
    hasSomethingChanged: boolean;
    hasAnySelectionChanged: boolean;
    isConfirmationDialogOpen: boolean;
    selectedButton: PhotoSizeEnum;
    showLoader: boolean;
    isSelecting: boolean;
    isDeselecting: boolean;
    selectedPhotoId: number | null;
    masonryRef: HTMLElement | null;
    isDownloadPhotosDialogOpen: boolean;
}

class PhotoListDialog extends React.Component<CombinedProps, State> {

    constructor(props: CombinedProps) {
        super(props);
        this.state = {
            selectedPhotoIds: this.props.initialSelectedPhotoIds || [],
            hasSomethingChanged: false,
            hasAnySelectionChanged: false,
            isConfirmationDialogOpen: false,
            selectedButton: PhotoSizeEnum.smallIcon,
            showLoader: false,
            isSelecting: false,
            isDeselecting: false,
            selectedPhotoId: null,
            masonryRef: null,
            isDownloadPhotosDialogOpen: false,
        };
    }

    handlePhotoClick = (chosenImage: GatherPhoto) => {
        const { onPhotoClick, allowMultiSelection } = this.props;
        // if in single mode handle and return
        if (!allowMultiSelection) {
            if (onPhotoClick) {
                onPhotoClick(chosenImage);
            }
            return;
        }
        // otherwise thins get a bit more involved and we need to track changes
        this.setState({ hasAnySelectionChanged: true });
        const chosenId = _getPhotoId(chosenImage);
        let newIds;
        let stateKey: keyof State = 'isSelecting';
        this.setState(
            prevState => {
                if (prevState.selectedPhotoIds.indexOf(chosenId) !== -1) {
                    stateKey = 'isDeselecting';
                    newIds = prevState.selectedPhotoIds.filter((id) => id !== chosenId);
                } else {
                    stateKey = 'isSelecting';
                    newIds = [...prevState.selectedPhotoIds, chosenId];
                }
                return {
                    ...prevState,
                    selectedPhotoIds: newIds,
                    hasSomethingChanged: true,
                    selectedPhotoId: chosenId,
                    [stateKey]: true
                };
            });
    };

    doneClicked = () => {
        const { selectedPhotoIds } = this.state;
        const { closeDialog } = this.props;

        closeDialog(selectedPhotoIds);
    };

    resetState = () => {
        const { initialSelectedPhotoIds } = this.props;
        this.setState({ hasSomethingChanged: false, selectedPhotoIds: initialSelectedPhotoIds || [] });
    };

    openConfirmationDilaog = () => {
        this.setState({ isConfirmationDialogOpen: true });
    };

    closeConfirmationDilaog = () => {
        this.setState({ isConfirmationDialogOpen: false });
    };

    renderConfirmationDialog = (zIndex: number) => {
        const { isConfirmationDialogOpen } = this.state;
        const header = 'Are you sure?';
        const subHeader = 'You\'ve made selections that won\'t be saved if you close.';
        const confirmButtonText = 'Close Photo Selection Screen';
        const cancelButtonText = 'Cancel';

        return (
            <ConfirmationDialog
                header={header}
                subHeader={subHeader}
                confirmationButtonText={confirmButtonText}
                onClose={this.closeConfirmationDilaog}
                open={isConfirmationDialogOpen}
                onConfirm={() => this.onConfirm()}
                cancelButtonText={cancelButtonText}
                zIndex={zIndex + 1}
            />
        );
    };

    onConfirm = () => {
        this.closeConfirmationDilaog();
        this.props.closeDialog();
    };

    closePhotoListDialog = () => {
        const { closeDialog, isUploading } = this.props;
        const { hasAnySelectionChanged } = this.state;
        if (isUploading) {
            return; // we punt on closing till images uploaded or fail
        }
        return (
            <>
                {hasAnySelectionChanged ? this.openConfirmationDilaog() : closeDialog()}
            </>
        );
    };

    togglePhotoSize = (photoSize: PhotoSizeEnum) => {
        this.setState({
            selectedButton: photoSize,
            showLoader: true
        });
        setTimeout(() => this.setState({ showLoader: false }), 400);
    };

    renderDownloadPhotosDialog = () => {
        const { zIndex, photoList, activeCase } = this.props;
        const { isDownloadPhotosDialogOpen } = this.state;

        return (
            <DownloadCasePhotosDialog
                photosCount={photoList.length}
                activeCase={activeCase}
                isDialogOpen={isDownloadPhotosDialogOpen}
                closeDialog={this.closeDownloadPhotosDialog}
                zIndex={zIndex + 1}
            />
        );
    };

    closeDownloadPhotosDialog = () => {
        this.setState({ isDownloadPhotosDialogOpen: false });
    };

    render() {
        const {
            classes,
            fullScreen,
            isUploading,
            isDialogOpen,
            closeDialog,
            zIndex,
            photoList,
            hideUploadButton,
            dispatch,
            helpers,
            isInvitedFamily,
            isFHorGOMUser,
            activeCase,
            onFileUpload,
            initialSelectedPhotoIds,
            allowMultiSelection,
            onPhotoClick,
            ...others
        } = this.props;
        const { selectedPhotoIds, hasSomethingChanged, selectedButton, showLoader } = this.state;

        if (!activeCase) {
            return null;
        }
        // we need a default for a public case
        const caseCreatedTime = ('created_time' in activeCase) ? momentTz(activeCase.created_time) : momentTz();
        const caseCreatedDays = caseCreatedTime.diff(30, 'day', true);
        const allPhotosIds = photoList.map(({ photo }) =>
            isAlbumEntry(photo) ? photo.photo_id : -1);
        const hasAllPhotosSelected = this.state.selectedPhotoIds.length === allPhotosIds.length;
        const largeIconTitle = 'Click to view larger photos';
        const smallIconTitle = 'Click to view smaller photos';

        const hasCasePrivileges = isFHorGOMUser || isInvitedFamily;

        return (
            <>
                <Dialog
                    fullScreen={fullScreen}
                    open={isDialogOpen}
                    TransitionComponent={SlideTransition}
                    transitionDuration={300}
                    onClose={() => this.closePhotoListDialog()}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                    className={classes.root}
                    classes={{ paper: classes.dialogPaper }}
                    style={{ zIndex }}
                >
                    <DialogTitle
                        className={classNames(
                            classes.dialogHeader,
                            classes.backgroundPrimary
                        )}
                    >
                        {(!allowMultiSelection || !hasSomethingChanged) && <IconButton
                            disabled={isUploading}
                            className={classes.backButton}
                            onClick={() => closeDialog()}
                            size="large">
                            <ArrowBackIcon />
                        </IconButton>}
                        {(hasSomethingChanged && allowMultiSelection) &&
                            <>
                                <Button
                                    className={classes.cancelButton}
                                    disabled={isUploading}
                                    onClick={(e) => this.doneClicked()}
                                >
                                    <DoneIcon />&nbsp;
                                    save
                                </Button>
                                <Button
                                    disabled={isUploading}
                                    className={classes.cancelButton}
                                    onClick={() => this.resetState()}
                                >
                                    Cancel
                                </Button>
                            </>}
                    </DialogTitle>
                    <DialogContent className={classes.fixPadding}>
                        <div className={classes.dialogContent} ref={ref => {
                            if (!this.state.masonryRef) {
                                this.setState({ masonryRef: ref });
                            }
                        }}>
                            <Grid item xs={12} className={classes.headerContainer}>
                                <div className={classes.counterContainer}>
                                    {photoList.length !== 0 ?
                                        <Typography align="center" color="primary" className={classes.photosCount}>
                                            {allowMultiSelection &&
                                                <>
                                                    {selectedPhotoIds ? selectedPhotoIds.length : 0}
                                                    <span className={classes.ofText}>
                                                        of
                                                    </span>
                                                </>
                                            }
                                            {photoList.length}
                                        </Typography> :
                                        <Typography align="center" color="primary" className={classes.photosCount}>
                                            0
                                        </Typography>
                                    }
                                    <Typography align="center" color="primary" className={classes.photoSelectedText}>
                                        {`Photos${allowMultiSelection ? ' Selected' : ''}`}
                                    </Typography>
                                </div>
                                <Divider color="primary" className={classes.divider} />

                                {(photoList.length >= 3 && allowMultiSelection) &&
                                    <Grid item xs={12} className={classes.selectButtonContainer}>
                                        <Button
                                            color="primary"
                                            variant="outlined"
                                            className={classNames(
                                                classes.selectOrDeselectButton,
                                                hasAllPhotosSelected
                                                && classes.deSelectButton
                                                || classes.selectedAllButton
                                            )}
                                            onClick={e => {
                                                if (hasAllPhotosSelected) {
                                                    this.setState({
                                                        hasSomethingChanged: true,
                                                        hasAnySelectionChanged: true,
                                                        selectedPhotoIds: []
                                                    });
                                                } else {
                                                    this.setState({
                                                        hasSomethingChanged: true,
                                                        hasAnySelectionChanged: true,
                                                        selectedPhotoIds: allPhotosIds
                                                    });
                                                }
                                            }}
                                        >
                                            {hasAllPhotosSelected ?
                                                <>
                                                    <CloseIcon className={classes.closeIcon} />&nbsp;
                                                    deselect all
                                                </> :
                                                <>
                                                    <CheckCircleIcon className={classes.checkCircleIcon} />&nbsp;
                                                    select all
                                                </>
                                            }
                                            &nbsp;{photoList.length}
                                        </Button>
                                        <div className={classes.svgContainer}>
                                            <div className={classes.smallIconButton}>
                                                <Tooltip title={smallIconTitle} enterDelay={400}>
                                                    <PhotoSizeSelectActualIcon
                                                        className={classNames(
                                                            selectedButton === PhotoSizeEnum.smallIcon &&
                                                            classes.selectedSvg
                                                        )}
                                                        color="primary"
                                                        onClick={() => this.togglePhotoSize(PhotoSizeEnum.smallIcon)}
                                                    />
                                                </Tooltip>
                                            </div>
                                            <div className={classNames(classes.largeIconButton)}>
                                                <Tooltip title={largeIconTitle} enterDelay={400}>
                                                    <PhotoSizeSelectActualIcon
                                                        className={classNames(
                                                            selectedButton === PhotoSizeEnum.largeIcon &&
                                                            classes.selectedSvg
                                                        )}
                                                        color="primary"
                                                        onClick={() => this.togglePhotoSize(PhotoSizeEnum.largeIcon)}
                                                    />
                                                </Tooltip>
                                            </div>
                                        </div>
                                    </Grid>
                                }
                            </Grid>
                            <Grid item xs={12}>
                                <MasonryPhotoList
                                    scrollElement={this.state.masonryRef || undefined}
                                    isUploading={isUploading}
                                    photoList={photoList}
                                    selectedPhotoIds={selectedPhotoIds}
                                    allowMultiSelection={allowMultiSelection}
                                    showLoader={showLoader}
                                    hideUploadButton={hideUploadButton}
                                    isLargePhotoView={selectedButton === PhotoSizeEnum.largeIcon}
                                    onPhotoClick={(photo) => this.handlePhotoClick(photo)}
                                    onFileUpload={onFileUpload}
                                    isPhotoSelecting={this.state.isSelecting}
                                    isPhotoDeselecting={this.state.isDeselecting}
                                    resetPhotoSelectDeselect={() =>
                                        this.setState({
                                            isSelecting: false,
                                            isDeselecting: false,
                                            selectedPhotoId: null
                                        })
                                    }
                                    zIndex={zIndex + 1}
                                    selectedPhotoId={this.state.selectedPhotoId}
                                    {...others}
                                />
                            </Grid>
                            {photoList.length !== 0 && hasCasePrivileges &&
                                <Button
                                    color="primary"
                                    variant="outlined"
                                    size="small"
                                    className={classes.downloadButton}
                                    onClick={e => this.setState({ isDownloadPhotosDialogOpen: true })}
                                >
                                    Download all photos&nbsp;<ArchiveIcon color="inherit" />
                                </Button>
                            }
                            <Divider color="primary" className={classes.divider} />
                            <Grid item xs={12} className={classes.dialogFooter}>
                                <Typography align="center" color="primary" className={classes.libraryIntruction}>
                                    {caseCreatedDays <= 30 ?
                                        `This shared photo library will be preserved by 
                                    ${activeCase && activeCase.funeral_home.name}.`
                                        :
                                        `This shared photo library can be used throughout the planning process and will 
                                        be preserved by ${activeCase && activeCase.funeral_home.name}.`
                                    }
                                </Typography>
                                <div className={classes.fhLogoContainer}>
                                    <FuneralHomeLogo logoSize="small" />
                                </div>
                                <Divider color="primary" className={classes.smallDivider} />
                                {hasCasePrivileges &&
                                    <Grid item xs={12}>
                                        <Typography
                                            align="center"
                                            color="secondary"
                                            className={classes.inviteOtherHeading}
                                        >
                                            Invite others to help share photos:
                                        </Typography>
                                        <PrivateEventUserWidget
                                            openInvitationDialog={e =>
                                                dispatch(openHelperInvitationDialog({
                                                    zIndex: zIndex + 1,
                                                    defaultTab: EntityCaseRole.guest,
                                                }))
                                            }
                                            invitedHelpers={helpers}
                                            hasCasePrivileges={hasCasePrivileges}
                                            zIndex={zIndex}
                                            hideAbstarctInfo
                                            activeCaseId={activeCase.id}
                                            caseDisplayFname={activeCase.fname}
                                        />
                                    </Grid>
                                }
                            </Grid>
                        </div>
                    </DialogContent>
                </Dialog>
                {this.renderConfirmationDialog(zIndex)}
                {hasCasePrivileges && this.renderDownloadPhotosDialog()}
            </>
        );
    }
}

export default compose(
    withState(mapStateToProps),
    withGStyles(styles),
    withFullScreen('md'),
    withDialogMounter,
)(PhotoListDialog) as React.ComponentType<OwnProps>;
