import * as t from 'io-ts';
import { DateFromISOString } from 'io-ts-types';
import { ModerationStatusDefinition } from './moderation';
import { getValidator } from './utils';

export type CloudinaryTransformationsType = {
    x?: number;
    y?: number;
    width?: number;
    height?: number;
    angle?: number;
    crop?: string;
    radius?: string | number;
    format?: string;
    fetch_format?: string;
    quality?: string;
    default_image?: string;
    border?: string;
    aspect_ratio?: string;
    background?: string;
    gravity?: string;
};

type AvatarEditorTransformationsType = {
    x?: number;
    y?: number;
    scale?: number;
};

export type PhotoTransformationsType = {
    cloudinary?: CloudinaryTransformationsType;
    avatarEditor?: AvatarEditorTransformationsType;
};

export type PhotoType = 'case' | 'funeral_home_logo' | 'funeral_home_icon' |
    'user_profile' | 'system' | 'case_gift' | 'product' | 'goods_services_contract' |
    'supplier' | 'task_location' | 'tree_project_hero' | 'tree_project_detail';

export enum PhotoTypeEnum {
    case = 'case',
    funeral_home_logo = 'funeral_home_logo',
    funeral_home_icon = 'funeral_home_icon',
    user_profile = 'user_profile',
    system = 'system',
    case_gift = 'case_gift',
    product = 'product',
    goods_services_contract = 'goods_services_contract',
    supplier = 'supplier',
    task_location = 'task_location',
    tree_project_hero = 'tree_project_hero',
    tree_project_detail = 'tree_project_detail',
}

export function isKeyOfPhotoTypeEnum(key: string): key is PhotoType {
    return Boolean(PhotoTypeEnum[key]);
}

export type PhotoScope = 'print' | 'logo' | 'web';
export enum PhotoScopeEnum {
    print = 'print',
    logo = 'logo',
    web = 'web',
}

// ---> Photo <---
const PhotoRequired = t.type({
    public_id: t.string,
    photo_type: t.string,
    uploaded_by: t.number,
    width: t.number,
    height: t.number,
    moderation_status: ModerationStatusDefinition,
    moderation_reason: t.union([t.string, t.null]),
    moderation_time: t.union([DateFromISOString, t.null]),
    moderated_by: t.union([t.number, t.null]),
    moderation_required: t.boolean,
});

const PhotoOptional = t.partial({
    id: t.number,
});

export const PhotoTypeIO = t.intersection([PhotoRequired, PhotoOptional]);

export interface Photo extends t.TypeOf<typeof PhotoTypeIO> {
    photo_type: PhotoType;
}

export class Photo {
    public static fromRequest = getValidator<Photo>(PhotoTypeIO);
}

// ---> CaseGiftPhoto <---

export enum CaseGiftPhotoType {
    card = 'card',
    gift = 'gift',
}

const CaseGiftPhotoTypeDefinition = t.union([
    t.literal(CaseGiftPhotoType.card),
    t.literal(CaseGiftPhotoType.gift),
]);

// ---> CaseGiftPhotoRequest <---
const caseGiftPhotoRequestDefinition = {
    gift_public_id: t.string,
    gift_width: t.number,
    gift_height: t.number,
    card_public_id: t.string,
    card_width: t.number,
    card_height: t.number,
    excluded: t.union([CaseGiftPhotoTypeDefinition, t.null]),
};
// All fields are optional
const CaseGiftPhotoRequestType = t.type(caseGiftPhotoRequestDefinition);

export interface CaseGiftPhotoRequest extends t.TypeOf<typeof CaseGiftPhotoRequestType> {
    excluded: CaseGiftPhotoType | null;
}

export class CaseGiftPhotoRequest {
    public static fromRequest = getValidator<CaseGiftPhotoRequest>(CaseGiftPhotoRequestType);
}

// ---> CaseGiftPhotoRecord <---
export interface CaseGiftPhotoRecord {
    uuid: string;
    gift_photo_id: number | null;
    card_photo_id: number | null;
    excluded: CaseGiftPhotoType | null;
    gather_case_id: number;
    created_time: Date;
}

// ---> CaseGiftPhotoUX <---
export interface CaseGiftPhotoUXRecord extends Pick<CaseGiftPhotoRecord,
    'uuid'
    | 'excluded'
    | 'gather_case_id'
    | 'created_time'
> {
    card_photo: string;
    gift_photo: string;
    giver_photo: string;
    type: 'tree' | 'photo';
    giver_name: string;
    giver_display_name: string;
    card_message: string;
    quantity: number;
    project_name: string;
}

export interface CaseGiftPhotoUX extends CaseGiftPhotoUXRecord {
    case_uuid: string;
}

// ---> CaseGiftPhotoState <---
export interface CaseGiftPhotoTypeWithState {
    isUploading: boolean;
    isUploaded: boolean;
    public_id: string;
    width: number;
    height: number;
    dataURI: string | null;
    giver_name: string;
    giver_display_name: string;
    card_message: string;
    project_name: string;
    quantity: number;
    giver_photo: string;
};

export interface CaseGiftPhotoState extends Pick<CaseGiftPhotoRecord,
    'uuid'
    | 'excluded'
    | 'gather_case_id'
    | 'created_time'
> {
    card_photo: CaseGiftPhotoTypeWithState;
    gift_photo: CaseGiftPhotoTypeWithState;
    case_uuid: string;
    type: 'tree' | 'photo';
    giver_name: string;
    giver_display_name: string;
    card_message: string;
    quantity: number;
    project_name: string;
    giver_photo: string;
}

export class CaseGiftPhotoState {
    public static fromUXType(ux: CaseGiftPhotoUX): CaseGiftPhotoState {
        return {
            ...ux,
            gift_photo: {
                public_id: ux.gift_photo,
                isUploaded: ux.gift_photo !== '',
                isUploading: false,
                width: 0,
                height: 0,
                dataURI: null,
                giver_name: ux.giver_name,
                giver_display_name: ux.giver_display_name,
                card_message: ux.card_message,
                project_name: ux.project_name,
                quantity: ux.quantity,
                giver_photo: ux.giver_photo,
            },
            card_photo: {
                public_id: ux.card_photo,
                isUploaded: ux.card_photo !== '',
                isUploading: false,
                width: 0,
                height: 0,
                dataURI: null,
                giver_name: ux.giver_name,
                giver_display_name: ux.giver_display_name,
                card_message: ux.card_message,
                project_name: ux.project_name,
                quantity: ux.quantity,
                giver_photo: ux.giver_photo,
            },
        };
    }
}

// ---> Cloudinary <---

export type CloudinaryFolder = 'user_provided/case' | 'user_provided/funeral_home' | 'user_provided/user_profile'
    | 'user_provided/system' | 'user_provided/case/deleted' | 'user_provided/product' | 'user_provided/product/deleted'
    | 'user_provided/product/global' | 'user_provided/product/funeral_home';
export enum CloudinaryFolderEnum {
    case = 'user_provided/case',
    deleted_cases = 'user_provided/case/deleted',
    funeral_home = 'user_provided/funeral_home',
    user_profile = 'user_provided/user_profile',
    entity = 'user_provided/entity',
    system = 'user_provided/system',
    product = 'user_provided/product',
    treeProject = 'user_provided/tree_project',
}

export type CloudinaryCaseSubfolder = 'case' | 'gift';
export enum CloudinaryCaseSubfolderEnum {
    case = 'case',
    gift = 'gift',
}

export interface CloudinarySignatureContext {
    case_id?: number;
    case_name?: string;
    funeral_home_id?: number;
    funeral_home_name?: string;
    uploaded_by_id?: number;
    uploaded_by_name?: string;
    tree_project_id?: number;
}

export interface CloudinarySignaturePayload {
    public_id: string;
    timestamp: string;
    signature: string;
    api_key: string;
    context: CloudinarySignatureContext;
}

export interface CloudinaryPhoto {
    // additional fields returned by Cloudinary are ignored
    public_id: string;
    width?: number;
    height?: number;
    secure_url?: string;
    created_at?: Date;
}

export enum CoverPhotoEnum {
    desktop = 'desktop',
    mobile = 'mobile'
}

export interface UploadPhotoResponse {
    public_id: string;
    width: number;
    height: number;
}


// ---> UploadPhotoRequest <---
const UploadPhotoRequestType = t.type({
    publicId: t.string,
    width: t.number,
    height: t.number,
});

export interface UploadPhotoRequest extends t.TypeOf<typeof UploadPhotoRequestType> {
}

export class UploadPhotoRequest {
    public static fromRequest = getValidator<UploadPhotoRequest>(UploadPhotoRequestType);
}