import { isRight } from 'fp-ts/lib/Either';
import * as t from 'io-ts';
import { PathReporter } from 'io-ts/lib/PathReporter';
import values from 'lodash/values';

export type PartialWithRequiredField<T, U extends keyof T> = Partial<T> & Pick<T, U>;
export type Nullable<T> = { [K in keyof T]: T[K] | null };

export interface HasIdProperty {
    id: number;
}

export const enumTypeGuard = <EnumKey extends string, EnumValue extends string>(
    myEnum: { [key in EnumKey]: EnumValue }, // magic for "enum"
) => (val: unknown): val is EnumValue => {
    return values(myEnum).some((v) => {
        return v === val;
    });
};

export type PaginatedResponse<T> = {
    data: T[];
    hasMoreData: boolean;
    totalcount?: number;
};

export const getTypeGuard = <T, U = T>(
    type: t.HasProps | ((body: T | U) => t.HasProps),
): ((body: T | U) => body is U) => {
    return (body: T | U): body is U => {
        let iotsType: t.HasProps;
        if (typeof type === 'function') {
            iotsType = type(body);
        } else {
            iotsType = type;
        }

        // This double parsing is necessary to force Dates to strings
        const deserializedBody = JSON.parse(JSON.stringify(body));
        return t.exact(iotsType).is(deserializedBody);
    };
};

export const getValidator = <T, U = T>(type: t.HasProps | ((body: T) => t.HasProps), options?: {
    mapperFn?: (result: T) => U;
    quiet?: boolean;
}): (body: T) => U => {
    return (body: T): U => {
        let iotsType: t.HasProps;
        if (typeof type === 'function') {
            iotsType = type(body);
        } else {
            iotsType = type;
        }

        // This double parsing is necessary to force Dates to strings
        const deserializedBody = JSON.parse(JSON.stringify(body));
        const validation = t.exact(iotsType).decode(deserializedBody);
        if (isRight(validation)) {
            const value = validation.right;
            const originalKeys = Object.keys(deserializedBody);
            const sanitizedKeys = Object.keys(value);
            if (sanitizedKeys.length < originalKeys.length) {
                const context = {
                    sanitizedResult: value,
                    originalBody: deserializedBody,
                    removedKeys: originalKeys.filter((ogKey) => sanitizedKeys.every((k) => k !== ogKey))
                };
                if (!options?.quiet) {
                    console.warn(`Unused keys have been removed from request body.` +
                        ` Should only send necessary keys.`,
                        JSON.stringify(context),
                    );
                }
            }
            if (options?.mapperFn) {
                return options.mapperFn(value);
            } else {
                return value;
            }
        } else {
            throw new Error(PathReporter.report(validation).join('\n--------\n'));
        }
    };
};

const moderationFlaggedPhraseDef = {
    start: t.number,
    end: t.number,
    phrase: t.string,
    reason: t.string,
};

const ModerationFlaggedPhraseType = t.type(moderationFlaggedPhraseDef);

export interface ModerationFlaggedPhrase extends t.TypeOf<typeof ModerationFlaggedPhraseType> { }

const moderationResponseDef = {
    publish: t.boolean,
    note: t.string,
    flagged: t.array(ModerationFlaggedPhraseType),
};

const ModerationResponseType = t.type(moderationResponseDef);

export interface ModerationResponse extends t.TypeOf<typeof ModerationResponseType> { }

export class ModerationResponse {
    constructor(note: string, publish: boolean = false) {
        this.note = note;
        this.publish = publish;
        this.flagged = [];
    }

    public static fromRequest = getValidator<ModerationResponse>(ModerationResponseType);
}

const RawModerationResponseType = t.type({
    publish: t.boolean,
    note: t.string,
    flagged: t.union([t.array(t.type({
        phrase: t.string,
        reason: t.string,
    })), t.undefined]),
});

export interface RawModerationResponse extends t.TypeOf<typeof RawModerationResponseType> { }

export class RawModerationResponse {
    public static fromRequest = (responseText?: string) => {
        if (responseText) {
            const tryParse: RawModerationResponse = JSON.parse(responseText);
            return getValidator<RawModerationResponse>(RawModerationResponseType)(tryParse);
        }
        throw 'Unable to parse or validate empty raw moderation response';
    };
}

export interface ModerationInput {
    prompt: string;
    deceasedName: string;
    respondentName: string;
    memoryText: string;
}
