import { useMemo } from "react";
import {
    getGridNumericOperators,
    GridCellParams,
    GridFilterItem,
} from '@mui/x-data-grid-premium';

export const getNumericFilterOperators = <T>() =>
    getGridNumericOperators().map((operator) => {
        switch (operator.value) {
            case '=':
                return {
                    ...operator, label: 'is',
                    getApplyFilterFn: (filterItem: GridFilterItem) => {
                        if (!filterItem.value) {
                            return null;
                        }
                        return (params: GridCellParams) => {
                            if (params.value) {
                                const cellValue = Number(params.value) / 100;
                                return cellValue === Number(filterItem.value);
                            }
                            return false;
                        };
                    },
                };
            case '!=':
                return {
                    ...operator, label: 'is not',
                    getApplyFilterFn: (filterItem: GridFilterItem) => {
                        if (!filterItem.value) {
                            return null;
                        }
                        return (params: GridCellParams) => {
                            if (params.value) {
                                const cellValue = Number(params.value) / 100;
                                return cellValue !== filterItem.value;
                            }
                            return false;
                        };
                    },
                };
            case '>':
                return {
                    ...operator, label: 'greater than',
                    getApplyFilterFn: (filterItem: GridFilterItem) => {
                        if (!filterItem.value) {
                            return null;
                        }
                        return (params: GridCellParams) => {
                            if (params.value) {
                                const cellValue = Number(params.value) / 100;
                                return cellValue > filterItem.value;
                            }
                            return false;
                        };
                    },
                };
            case '<':
                return {
                    ...operator, label: 'less than',
                    getApplyFilterFn: (filterItem: GridFilterItem) => {
                        if (!filterItem.value) {
                            return null;
                        }
                        return (params: GridCellParams) => {
                            if (params.value) {
                                const cellValue = Number(params.value) / 100;
                                return cellValue < filterItem.value;
                            }
                            return false;
                        };
                    },
                };
            case '>=':
                return {
                    ...operator, label: 'greater than or equals',
                    getApplyFilterFn: (filterItem: GridFilterItem) => {
                        if (!filterItem.value) {
                            return null;
                        }
                        return (params: GridCellParams) => {
                            if (params.value) {
                                const cellValue = Number(params.value) / 100;
                                return cellValue >= filterItem.value;
                            }
                            return false;
                        };
                    },
                };
            case '<=':
                return {
                    ...operator, label: 'less than or equals',
                    getApplyFilterFn: (filterItem: GridFilterItem) => {
                        if (!filterItem.value) {
                            return null;
                        }
                        return (params: GridCellParams) => {
                            if (params.value) {
                                const cellValue = Number(params.value) / 100;
                                return cellValue <= filterItem.value;
                            }
                            return false;
                        };
                    },
                };
            default:
                return operator;
        }
    });

const usePriceFilterOperators = () => {
    return useMemo(() => getNumericFilterOperators(), []);
};

export default usePriceFilterOperators;
