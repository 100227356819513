import arrayMove from 'array-move';

import {
    ADD_CASE_HELPER,
    CASE_HELPERS_LOAD_FAILED,
    CASE_HELPERS_LOADED,
    CASE_HELPERS_LOADING,
    REMOVE_CASE_HELPER,
    SET_CASE_HELPER,
    SET_CASE_HELPERS,
} from '../actions/CaseHelper.action';

import { GatherAction } from '../actions';

import {
    GatherCasesState,
    GatherPhoto,
    RECENT_DASHBOARD_CASES,
} from '../types';

import { USER_LOGGED_OUT } from '../constants';

import {
    CLEAR_ACTIVE_FUNERALHOME,
    FUNERAL_HOME_LOADING,
} from '../actions/FuneralHome.action';

import {
    ADD_CASE_SERVICE_DETAIL_FIELD,
    ADD_SELECTED_CASE_TYPE,
    CLOSE_ARRANGEMENT_CONFERENCE_DIALOG,
    DEATH_CERTIFICATE_OUTDATED,
    DEATH_CERTIFICATE_OUTDATED_DISMISSED,
    DELETE_CASE_SERVICE_DETAIL,
    DELETE_SELECTED_CASE_TYPE,
    getSelectedCaseTypesFromLocalStorage,
    CASE_CREATED,
    OPEN_ARRANGEMENT_CONFERENCE_DIALOG,
    PUSH_CASE_SERVICE_DETAIL,
    LOADED_PUBLIC_CASE,
    REMOVE_CASE,
    REMOVE_CASE_SERVICE_DETAIL_FIELD,
    REORDER_CASE_SERVICE_DETAIL_FIELDS,
    SELECT_DASHBOARD_CASES_ASSIGNEE,
    SET_CASE_ROLLUP_LOADING,
    SET_CASE_SAVING,
    SET_CASE_SERVICE_DETAILS,
    SET_DASHBOARD_CASE_TYPE_TOTALS,
    SET_DASHBOARD_CASES,
    SET_DASHBOARD_CASES_ASSIGNEES,
    SET_DASHBOARD_CASES_ASSIGNEES_LOADING,
    SET_DASHBOARD_CASES_LOADING,
    SET_DASHBOARD_CASES_TYPE,
    SET_DASHBOARD_ROLLUP,
    SET_OBITUARY_ERROR,
    SET_ONE_OFF_PRODUCT_SELECTION_DIALOG_OPEN,
    UPDATE_CASE_SERVICE_DETAIL,
    UPDATE_CASE_SERVICE_DETAIL_FIELD,
    UPDATE_DEATH_NOTICE,
    UPDATE_OBITUARY,
    UPDATE_SYNC_STATE,
    TRACKING_PAGE_LOADED,
    ORGANIZE_PAGE_LOADED,
    TRACKING_PAGE_LOADING,
    FAILED_TRACKING_PAGE_LOAD,
    LOADED_PRIVATE_CASE,
    LOADING_PUBLIC_CASE,
    LOADING_PRIVATE_CASE,
    UPDATING_CASE,
    CASE_UPDATED,
    UPDATING_CASE_FAILED,
    CASE_MOVED,
    CASE_NUMBER_UPDATED,
} from '../actions/GatherCase.action';
import {
    ADD_CASE_GIFT_PHOTO,
    ADD_CASE_PHOTO,
    createBlankCaseGiftPhoto,
    DELETE_CASE_GIFT_PHOTO,
    DELETE_CASE_PHOTO,
    isCardComplete,
    isGiftComplete,
    photoToGatherPhoto,
    processCaseGiftPhotoList,
    SET_CASE_GIFT_PHOTOS,
    SET_CASE_GIFT_PHOTOS_LOADING,
    SET_CASE_PHOTOS,
    SET_CASE_PHOTOS_LOADING,
    SET_CASE_PROFILE_PHOTO_SAVING,
    UPDATE_CASE_GIFT_PHOTO,
    UPDATE_CASE_PHOTO,
} from '../actions/Photo.action';

import {
    CaseType,
    GatherCaseDashboardAssignee,
    GatherCaseUX,
    generateDeathCertificate,
    GuestListUser,
    isAlbumEntry,
    isModerationPhotoList,
    isModerationVisitorList,
    fingerprintToCaseFingerprint,
    ModerationCategory,
    ServiceDetailDataField,
    TaskTemplateType,
    WebsiteVendor,
    TrackingStepType,
    isModerationMemoryList,
    CaseCurrentTaskLocation,
} from '../shared/types';

import { DECREASE_CASE_NOTE_COUNT_BY_1, INCREASE_CASE_NOTE_COUNT_BY_1 } from '../actions/Note.action';
import {
    UPDATE_TASK,
    BULK_UPDATE_CHECKLIST_TASKS,
    TASK_LOADED,
    CREATING_MOVE_STEP,
    INITIALIZE_STEP_LOADED,
} from '../actions/task/Task.action';
import {
    SET_REMEMBER_PREVIEW_ALBUM,
} from '../actions/Album.action';
import { UPDATE_DOC_PACKET_SIGNER } from '../actions/DocPacket.action';
import {
    UPDATED_DEATH_CERTIFICATE_CONFIG_FOR_CASE,
} from '../actions/DeathCertificateConfig.action';
import { SET_VISITOR, VISITOR_ADDED_TO_CASE } from '../actions/visitor.action';
import { DELETE_OBITUARY_LINK, INSERT_OBITUARY_LINK, UPDATE_OBITUARY_LINK } from '../actions/ObituaryLink.action';
import { DELETED_MODERATION_ITEM, MADE_MODERATION_DECISION } from '../actions/Moderation.action';
import {
    CASE_LABEL_UPDATED,
    LABEL_ADDED_TO_CASE,
    LABEL_REMOVED_FROM_CASE
} from '../actions/CaseLabel.action';
import {
    FINGERPRINT_CREATED,
    FINGERPRINT_DELETED,
    FINGERPRINT_UPDATED,
    FINGERPRINT_UPLOADED,
    FINGERPRINTS_LOADED,
    FINGERPRINTS_LOADING
} from '../actions/Fingerprints.action';
import {
    CASE_BELONGINGS_LOADED,
    CASE_BELONGING_CREATED,
    CASE_BELONGING_DELETED,
    CASE_BELONGING_UPDATED
} from '../actions/Belongings.action';
import {
    CASE_ID_PHOTOS_LOADED,
    LOADING_CASE_ID_PHOTOS,
    REMOVE_CASE_ID_PHOTO,
} from '../actions/CaseIdPhotos.action';
import { getPublicCaseFromUX } from '../shared/utils';
import { MEMORIES_LOADED, MEMORY_REMOVED, NEW_MEMORY } from '../actions/Memory.action';
import { setGTMFuneralHomeContext } from '../services';
import {
    ATTACHED_POLICY_TO_CASE,
    ATTACHING_POLICY_TO_CASE,
    REMOVED_POLICY_FROM_DASHBOARD,
    UNATTACHING_POLICY_TO_CASE
} from '../actions/Insurance.action';

export const casesStateInitData: GatherCasesState = {
    publicCase: null,
    dashboardCasesLoading: false,
    dashboardCaseRollupLoading: false,
    dashboardCasesAssigneesLoading: false,
    dashboardCases: null,
    dashboardPolicies: null,
    dashboardCasesPage: 1,
    dashboardCaseRollup: null,
    dashboardCaseMonthlyRollup: null,
    dashboardCasesType: {
        type: RECENT_DASHBOARD_CASES
    },
    dashboardCasesAssignees: [],
    dashboardCaseTypeTotals: {
        [CaseType.pre_need]: 0,
        [CaseType.at_need]: 0,
        [CaseType.trade]: 0,
        [CaseType.one_off]: 0,
    },
    dashboardAssigneeCasesTotal: null,
    dashboardCasesSelectedAssignee: null,
    dashboardTotalCaseCount: null,
    casePhotos: [],
    caseRememberPreviewAlbum: null,
    caseGiftPhotos: [],
    caseObituary: null,
    caseObituaryLinks: [],
    caseServiceDetails: [],
    obituaryError: null,
    isCasePhotosLoading: false,
    isCaseGiftPhotosLoading: false,
    selectedCase: null,
    isSaving: false,
    isCaseProfilePhotoSaving: false,
    arrangementConferenceDialog: {
        isDialogOpen: false,
        zIndex: 1320
    },
    selectedCaseTypes: getSelectedCaseTypesFromLocalStorage(),
    isOneOffProductSelectionDialogOpen: false,
    helpers: [],
    guestList: [],
    guestListMap: {},
    isHelpersLoading: false,
    deathCertificateOutdated: null,
    caseIdPhotos: [],
    caseIdPhotosLoading: false,
    activeCaseFingerprints: {
        loading: false,
        prints: []
    },
    belongings: null,
    workflowChangeHistory: [],
    isLoadingTrackingPage: false,
};

const generateGuestListMap = (guestList: GuestListUser[]) => {
    const guestListMap = guestList.reduce<Record<number, GuestListUser>>(
        (map, guest) => {
            map[guest.user_id] = guest;
            return map;
        },
        {},
    );
    return guestListMap;
};

export const casesState = (
    state: GatherCasesState = casesStateInitData,
    action: GatherAction,
): GatherCasesState => {
    switch (action.type) {
        case SET_DASHBOARD_CASES:
            const {
                dashboardCases,
                dashboardPolicies,
                dashboardCasesPage,
                assignee,
                totalCaseCount
            } = action;

            const dashboardCasesList = (dashboardCasesPage !== 0 && state.dashboardCases) ?
                state.dashboardCases.concat(dashboardCases) : dashboardCases;

            const updatedState = {
                ...state,
                dashboardCasesPage,
                dashboardPolicies,
                dashboardCases: dashboardCasesList,
                dashboardCasesSelectedAssignee: assignee || null,
                dashboardCasesLoading: false,
                activeCaseFingerprints: {
                    loading: false,
                    prints: [],
                }
            };

            if (totalCaseCount !== null) {
                updatedState.dashboardTotalCaseCount = totalCaseCount;
            }

            return updatedState;
        case SET_DASHBOARD_CASES_TYPE:
            const {
                dashboardCasesType
            } = action;

            return {
                ...state,
                dashboardCasesType,
                dashboardCasesPage: 0,
                dashboardCases: (dashboardCasesType.type === state.dashboardCasesType.type) ?
                    state.dashboardCases : null
            };
        case SET_DASHBOARD_ROLLUP:
            const { dashboardCaseMonthlyRollup } = action;

            return {
                ...state,
                dashboardCaseMonthlyRollup,
                dashboardCaseRollupLoading: false
            };
        case SET_DASHBOARD_CASE_TYPE_TOTALS:
            const { dashboardCaseTypeTotals } = action;
            return {
                ...state,
                dashboardCaseTypeTotals: { ...dashboardCaseTypeTotals },
            };
        case SET_DASHBOARD_CASES_ASSIGNEES:
            const dashboardCasesAssigneesList: GatherCaseDashboardAssignee[] = action.dashboardCasesAssigneesList;

            return {
                ...state,
                dashboardAssigneeCasesTotal: dashboardCasesAssigneesList.reduce<number>(
                    (acc, { assigned_cases_count }) => acc + (Math.floor(assigned_cases_count || 0)),
                    0,
                ),
                dashboardCasesAssignees: dashboardCasesAssigneesList,
                dashboardCasesAssigneesLoading: false
            };

        case LOADED_PRIVATE_CASE: {

            // clear out publicCase unless setting the selected case to the same case as the publicCase
            const publicCase = state.publicCase?.id === action.gatherCase.id
                ? state.publicCase
                : null;

            setGTMFuneralHomeContext(action.gatherCase.funeral_home.key);

            return {
                ...state,
                selectedCase: {
                    ...action.gatherCase,
                    death_certificate: action.gatherCase.death_certificate ?? generateDeathCertificate(),
                },
                publicCase,
                caseIdPhotos: casesStateInitData.caseIdPhotos,
                activeCaseFingerprints: casesStateInitData.activeCaseFingerprints,
            };
        }
        // case ATTACHED_POLICY_TO_CASE:
        case REMOVED_POLICY_FROM_DASHBOARD:
            return {
                ...state,
                dashboardPolicies: (state.dashboardPolicies ?? [])
                    .filter((policy) => policy.policy_id !== action.policyId),
            };
        case SET_DASHBOARD_CASES_LOADING:
            return {
                ...state,
                dashboardCasesLoading: action.isLoading,
            };
        case SET_DASHBOARD_CASES_ASSIGNEES_LOADING:
            return { ...state, dashboardCasesAssigneesLoading: action.isLoading };
        case SET_CASE_ROLLUP_LOADING:
            return { ...state, dashboardCaseRollupLoading: action.isLoading };
        case SET_CASE_SAVING:
            return { ...state, isSaving: action.isSaving };
        case CASE_CREATED:
            return {
                ...state,
                dashboardCasesSelectedAssignee: null,
            };
        case UPDATE_DOC_PACKET_SIGNER: {
            const { helpers } = state;
            const { signerPersonId, changes } = action;
            return {
                ...state,
                helpers: helpers.map(person => {
                    if (person.entity_id === signerPersonId) {
                        return {
                            ...person,
                            ...changes.person,
                        };
                    } else {
                        return person;
                    }
                })
            };
        }
        case SET_CASE_HELPERS: {
            const { caseUuid, helpers } = action;
            if (helpers.length > 0) {
                const dashboardCasesUpdated = !state.dashboardCases ? null :
                    state.dashboardCases.map(dc => {
                        if (dc.uuid === caseUuid) {
                            return {
                                ...dc,
                                helper_count: helpers.length
                            };
                        } else {
                            return dc;
                        }
                    });

                if (caseUuid === state.selectedCase?.uuid) {
                    return {
                        ...state,
                        helpers,
                        dashboardCases: dashboardCasesUpdated,
                        selectedCase: {
                            ...state.selectedCase,
                            helper_count: helpers.length,
                        },
                    };
                } else {
                    return state;
                }
            }
            return state;
        }
        case CASE_UPDATED: {
            const selectedCase = state.selectedCase?.id === action.gatherCase.id
                ? action.gatherCase
                : state.selectedCase;
            const publicCase = state.publicCase?.id === action.gatherCase.id
                ? getPublicCaseFromUX(action.gatherCase)
                : state.publicCase;
            const updatedDashboardCases = state.dashboardCases?.map((c) => c.id === action.gatherCase.id
                ? {
                    ...c,
                    ...action.gatherCase,
                    death_certificate_percentage: action.gatherCase.death_certificate_percentage
                        || action.gatherCase.death_certificate_percentage === 0
                        ? action.gatherCase.death_certificate_percentage : c.death_certificate_percentage,
                    assignee: action.gatherCase.assignee !== undefined ? {
                        user_id: action.gatherCase.assignee.user_id || 0,
                        entity_id: action.gatherCase.assignee.entity_id,
                        fname: action.gatherCase.assignee.fname,
                        lname: action.gatherCase.assignee.lname,
                        email: action.gatherCase.assignee.email || '',
                        phone: action.gatherCase.assignee.phone || '',
                        photo: action.gatherCase.assignee.photo,
                        photo_transformations: action.gatherCase.assignee.photo_transformations,
                    }
                        : c.assignee,
                }
                : c
            );
            return {
                ...state,
                selectedCase,
                publicCase,
                workflowChangeHistory: action.workflowChangeHistory ?? state.workflowChangeHistory,
                isSaving: false,
                dashboardCases: updatedDashboardCases ?? null
            };
        }
        case CASE_NUMBER_UPDATED: {
            const selectedCase = state.selectedCase?.id === action.gatherCase.id
                ? action.gatherCase
                : state.selectedCase;
            const publicCase = state.publicCase?.id === action.gatherCase.id
                ? getPublicCaseFromUX(action.gatherCase)
                : state.publicCase;
            const updatedDashboardCases = state.dashboardCases?.map((c) => c.id === action.gatherCase.id
                ? {
                    ...c,
                    case_number: action.gatherCase.case_number,
                }
                : c
            );
            return {
                ...state,
                selectedCase,
                publicCase,
                isSaving: false,
                dashboardCases: updatedDashboardCases ?? null
            };

        }
        case CREATING_MOVE_STEP: {
            const caseCurrentLoc: CaseCurrentTaskLocation = {
                ...action.taskLocation,
                moved_time: new Date(),
            };
            const selectedCase = state.selectedCase?.uuid === action.caseUuid ?
                { ...state.selectedCase, current_task_location: caseCurrentLoc } : state.selectedCase;
            const dCases = state.dashboardCases?.map((c) => c.uuid !== action.caseUuid ? c : {
                ...c,
                current_task_location: {
                    id: caseCurrentLoc.id,
                    name: caseCurrentLoc.name,
                    moved_time: caseCurrentLoc.moved_time,
                },
            });
            return {
                ...state,
                selectedCase,
                dashboardCases: dCases ?? null,
            };
        }
        case UPDATING_CASE: {
            const updatedSelectedCase = state.selectedCase?.uuid !== action.caseUuid
                ? state.selectedCase
                : { ...state.selectedCase, ...action.changes };
            const updatedPublicCase = state.publicCase?.uuid !== action.caseUuid
                ? state.publicCase
                : { ...state.publicCase, ...action.changes };
            const updatedDashboardCases = state.dashboardCases?.map((c) => c.uuid === action.caseUuid
                ? {
                    ...c,
                    ...action.changes,
                    death_certificate_percentage: action.changes.death_certificate_percentage
                        || action.changes.death_certificate_percentage === 0
                        ? action.changes.death_certificate_percentage : c.death_certificate_percentage,
                    assignee: action.changes.assignee !== undefined ? {
                        user_id: action.changes.assignee.user_id || 0,
                        entity_id: action.changes.assignee.entity_id,
                        fname: action.changes.assignee.fname,
                        lname: action.changes.assignee.lname,
                        email: action.changes.assignee.email || '',
                        phone: action.changes.assignee.phone || '',
                        photo: action.changes.assignee.photo,
                        photo_transformations: action.changes.assignee.photo_transformations,
                    }
                        : c.assignee,
                }
                : c
            );
            const newAssignee = action.changes.assignee;
            let updatedDashboardAssignees: GatherCaseDashboardAssignee[] | null | undefined;
            if (newAssignee !== undefined) {
                // updated dashboard assignees
                const oldDashboardCase = state.dashboardCases?.find((dc) => dc.uuid === action.caseUuid);
                updatedDashboardAssignees = state.dashboardCasesAssignees?.map(a => {
                    if (a.user_id === oldDashboardCase?.assignee.user_id) {
                        return {
                            ...a,
                            assigned_cases_count: (a.assigned_cases_count ?? 1) - 1
                        };
                    } else if (a.user_id === newAssignee.user_id) {
                        return {
                            ...a,
                            assigned_cases_count: (a.assigned_cases_count ?? 0) + 1,
                        };
                    } else {
                        return a;
                    }
                }).filter((a) => (a.assigned_cases_count ?? 0) > 0);
            }
            return {
                ...state,
                isSaving: true,
                selectedCase: updatedSelectedCase,
                publicCase: updatedPublicCase,
                dashboardCases: updatedDashboardCases ?? null,
                dashboardCasesAssignees: updatedDashboardAssignees ?? null,
            };
        }
        case UPDATING_CASE_FAILED: {
            return {
                ...state,
                isSaving: false,
            };
        }
        case REMOVE_CASE:
            return {
                ...state,
                dashboardCases: (state.dashboardCases || []).filter(c => action.caseUuid !== c.uuid),
                selectedCase: state.selectedCase?.uuid === action.caseUuid
                    ? null
                    : state.selectedCase,
            };
        case INCREASE_CASE_NOTE_COUNT_BY_1: {
            return {
                ...state,
                dashboardCases: (state.dashboardCases || []).map(c => {
                    if (c.uuid === action.caseUuid) {
                        return { ...c, note_count: c.note_count + 1 };
                    }
                    return c;
                })
            };
        }
        case DECREASE_CASE_NOTE_COUNT_BY_1: {
            return {
                ...state,
                dashboardCases: (state.dashboardCases || []).map(c => {
                    if (c.uuid === action.caseUuid) {
                        return { ...c, note_count: c.note_count - 1 };
                    }
                    return c;
                })
            };
        }
        case SELECT_DASHBOARD_CASES_ASSIGNEE:
            const {
                dashboardCasesSelectedAssignee
            } = action;

            return {
                ...state,
                dashboardCasesSelectedAssignee,
            };

        case FUNERAL_HOME_LOADING:
        case CLEAR_ACTIVE_FUNERALHOME:
        case USER_LOGGED_OUT:
            return casesStateInitData;
        case SET_CASE_PHOTOS: {
            const selectedCase = action.caseUuid !== state.selectedCase?.uuid ? state.selectedCase : {
                ...state.selectedCase,
                photos_count: action.photos.length,
            };
            return {
                ...state,
                casePhotos: action.photos,
                selectedCase,
            };
        }
        case SET_CASE_PHOTOS_LOADING:
            return {
                ...state,
                isCasePhotosLoading: action.isLoading,
            };
        case SET_CASE_PROFILE_PHOTO_SAVING:
            return { ...state, isCaseProfilePhotoSaving: action.isSaving };
        case ADD_CASE_PHOTO: {
            const selectedCase = action.caseId !== state.selectedCase?.id ? state.selectedCase : {
                ...state.selectedCase,
                photos_count: state.selectedCase.photos_count + 1,
            };

            return {
                ...state,
                casePhotos: [...state.casePhotos, action.photo],
                selectedCase,
            };
        }
        case UPDATE_CASE_PHOTO: {
            return {
                ...state,
                casePhotos: state.casePhotos.map((photo) => {
                    if (photo.gatherId === action.photo.gatherId) {
                        return action.photo;
                    }
                    return photo;
                }),
            };
        }
        case DELETE_CASE_PHOTO: {
            const updatedCasePhotos = state.casePhotos.filter(({ photo }) => {
                return isAlbumEntry(photo)
                    ? photo.id !== action.albumEntryId
                    : true
                    ;
            });
            const existingCasePhoto = state.casePhotos.find(({ photo }) => {
                return isAlbumEntry(photo) && photo.id === action.albumEntryId;
            });
            // update selectedCase
            const updatedSelectedCase = state.selectedCase?.id !== action.caseId ? null : {
                ...state.selectedCase,
                photos_count: state.selectedCase.photos_count - 1,
            };
            if (updatedSelectedCase && existingCasePhoto?.photo) {
                if (updatedSelectedCase.photo === existingCasePhoto.photo.public_id) {
                    updatedSelectedCase.photo_id = null;
                    updatedSelectedCase.photo_view_id = null;
                    updatedSelectedCase.photo = null;
                    updatedSelectedCase.photo_transformations = null;
                }
                if (updatedSelectedCase.mobile_cover === existingCasePhoto.photo.public_id) {
                    updatedSelectedCase.mobile_cover_id = null;
                    updatedSelectedCase.mobile_cover_view_id = null;
                    updatedSelectedCase.mobile_cover = null;
                    updatedSelectedCase.mobile_transformations = null;
                }
                if (updatedSelectedCase.desktop_cover === existingCasePhoto.photo.public_id) {
                    updatedSelectedCase.desktop_cover_id = null;
                    updatedSelectedCase.desktop_cover_view_id = null;
                    updatedSelectedCase.desktop_cover = null;
                    updatedSelectedCase.desktop_transformations = null;
                }
            }
            // update publicCase
            const updatedPublicCase = state.publicCase?.id !== action.caseId ? null : {
                ...state.publicCase,
            };
            if (updatedPublicCase && existingCasePhoto?.photo) {
                if (updatedPublicCase.photo === existingCasePhoto.photo.public_id) {
                    updatedPublicCase.photo = null;
                    updatedPublicCase.photo_transformations = null;
                }
                if (updatedPublicCase.mobile_cover === existingCasePhoto.photo.public_id) {
                    updatedPublicCase.mobile_cover_id = null;
                    updatedPublicCase.mobile_cover = null;
                    updatedPublicCase.mobile_transformations = null;
                }
                if (updatedPublicCase.desktop_cover === existingCasePhoto.photo.public_id) {
                    updatedPublicCase.desktop_cover_id = null;
                    updatedPublicCase.desktop_cover = null;
                    updatedPublicCase.desktop_transformations = null;
                }
            }
            return {
                ...state,
                casePhotos: updatedCasePhotos,
                selectedCase: updatedSelectedCase ?? state.selectedCase,
                publicCase: updatedPublicCase ?? state.publicCase,
            };
        }
        case DELETED_MODERATION_ITEM: {
            const { item } = action;
            if (!state.dashboardCases) {
                return state;
            }

            return {
                ...state,
                dashboardCases: state.dashboardCases.map((c) => c.id === item.case_id
                    ? {
                        ...c,
                        memories_count: c.memories_count - 1,
                    }
                    : c,
                ),
            };
        }
        case MADE_MODERATION_DECISION: {
            const { decisions, caseUuid } = action;
            if (!caseUuid) {
                // when moderating at FH or GOM level we don't need to update the case photos
                return state;
            }

            const caseUpdates: Partial<GatherCaseUX> = {};

            const existingGuestList: GuestListUser[] = state.guestList;
            let updatedGuestList: GuestListUser[] = existingGuestList;

            const existingPhotos: GatherPhoto[] = state.casePhotos;
            let updatedPhotos: GatherPhoto[] = existingPhotos;

            if (action.category === ModerationCategory.photos && isModerationPhotoList(decisions)) {
                // when approving photos we have to do an API call to reload photos -- handled elsewhere
                // when blocking photos, make sure they are removed from the photo list
                if (!action.isApproval) {
                    updatedPhotos = existingPhotos.filter(({ photo }) =>
                        isAlbumEntry(photo) && decisions.every((d) => d.photo.photo_id !== photo.photo_id)
                    );
                    caseUpdates.photos_count = updatedPhotos.length;
                }
            } else if (action.category === ModerationCategory.visitors && isModerationVisitorList(decisions)) {
                if (action.isApproval) {
                    const approvedVisitors: GuestListUser[] = decisions.map(({ visitor }): GuestListUser => ({
                        ...visitor,
                        is_pending_decision: false,
                    }));
                    updatedGuestList = [...existingGuestList];
                    for (const approvedVisitor of approvedVisitors) {
                        // in the case of approved flagged content, make sure the visitor isn't already in the list
                        if (updatedGuestList.every((existing) => existing.user_id !== approvedVisitor.user_id)) {
                            updatedGuestList.unshift(approvedVisitor);
                        }
                    }
                } else {
                    updatedGuestList = existingGuestList.filter((guest) =>
                        decisions.every((d) => d.visitor.user_id !== guest.user_id)
                    );
                }
                caseUpdates.helper_count = updatedGuestList.length;
            } else if (action.category === ModerationCategory.memories && isModerationMemoryList(decisions)
                && state.selectedCase) {
                caseUpdates.memories_count = action.isApproval
                    ? state.selectedCase.memories_count += decisions.length
                    : state.selectedCase.memories_count -= decisions.length;
            }

            return {
                ...state,
                selectedCase: state.selectedCase?.uuid !== caseUuid ? state.selectedCase : {
                    ...state.selectedCase,
                    ...caseUpdates,
                },
                casePhotos: updatedPhotos,
                guestList: updatedGuestList,
                guestListMap: generateGuestListMap(updatedGuestList),
            };
        }
        case VISITOR_ADDED_TO_CASE: {
            const updatedGuestList: GuestListUser[] = [action.visitor, ...state.guestList];

            return {
                ...state,
                guestList: updatedGuestList,
                guestListMap: generateGuestListMap(updatedGuestList),
                selectedCase: !state.selectedCase ? null : {
                    ...state.selectedCase,
                    helper_count: updatedGuestList.length + 1,
                },
            };
        }
        case SET_VISITOR: {
            const updatedGuestList = state.guestList.map(guest => guest.user_id === action.visitor.user_id
                ? {
                    ...guest,
                    photo: action.visitor.photo,
                    photo_transformations: action.visitor.photo_transformations
                }
                : guest
            );

            return {
                ...state,
                guestList: updatedGuestList,
                guestListMap: generateGuestListMap(updatedGuestList)
            };
        }
        case SET_REMEMBER_PREVIEW_ALBUM: {
            return {
                ...state,
                caseRememberPreviewAlbum: action.album,
            };
        }
        case SET_CASE_GIFT_PHOTOS:
            return {
                ...state,
                caseGiftPhotos: action.photos,
            };
        case SET_CASE_GIFT_PHOTOS_LOADING:
            return {
                ...state,
                isCaseGiftPhotosLoading: action.isLoading,
            };
        case ADD_CASE_GIFT_PHOTO: {
            // Put the new photo in the second position (the first is special)
            //  unless the array is empty, then put it in the first position
            const newPhotos = state.caseGiftPhotos.length >= 1
                ? [state.caseGiftPhotos[0], action.photo, ...state.caseGiftPhotos.slice(1)]
                : [action.photo];
            return {
                ...state,
                caseGiftPhotos: newPhotos,
            };
        }
        case UPDATE_CASE_GIFT_PHOTO: {
            let updatedPhotos = state.caseGiftPhotos.map((photo) => {
                if (photo.uuid === action.photo.uuid) {
                    return action.photo;
                }
                return photo;
            });

            // check if the photo at index === 0 is complete
            if (updatedPhotos.length !== 0 && isCardComplete(updatedPhotos[0], true)
                && isGiftComplete(updatedPhotos[0], true)) {
                const newBlank = createBlankCaseGiftPhoto(action.caseUuid);
                updatedPhotos = [newBlank, ...updatedPhotos];
            }

            return {
                ...state,
                caseGiftPhotos: updatedPhotos,
            };
        }
        case DELETE_CASE_GIFT_PHOTO: {
            let updatedPhotos = state.caseGiftPhotos.filter((photo) => {
                return (photo.uuid !== action.uuid);
            });

            // check if the photo at index === 0 is complete
            if (updatedPhotos.length === 0 ||
                (isCardComplete(updatedPhotos[0], true) && isGiftComplete(updatedPhotos[0], true))
            ) {
                const newBlank = createBlankCaseGiftPhoto(action.caseUuid);
                updatedPhotos = [newBlank, ...updatedPhotos];
            }

            return {
                ...state,
                caseGiftPhotos: updatedPhotos,
            };
        }
        case SET_OBITUARY_ERROR: {
            return {
                ...state,
                obituaryError: action.error,
            };
        }
        case UPDATE_OBITUARY: {
            return {
                ...state,
                caseObituary: action.obituary,
                publicCase: state.publicCase && action.obituary
                    ? { ...state.publicCase, obituary_content: action.obituary.content } : state.publicCase,
                selectedCase: state.selectedCase && action.obituary
                    ? {
                        ...state.selectedCase,
                        obituary_content: action.obituary.content,
                        obituary_updated_time: action.obituary.updated_time,
                    } : state.selectedCase,
            };
        }
        case SET_CASE_SERVICE_DETAILS: {
            const {
                serviceTemplateId,
                details,
                serviceTemplateName
            } = action;

            const { selectedCase } = state;

            return {
                ...state,
                caseServiceDetails: details,
                selectedCase: !selectedCase ? null : {
                    ...selectedCase,
                    service_details_count: details.length,
                    service_template_id: serviceTemplateId || selectedCase.service_template_id,
                    service_template_name: serviceTemplateName || selectedCase.service_template_name
                },
            };
        }
        case OPEN_ARRANGEMENT_CONFERENCE_DIALOG: {
            return {
                ...state,
                arrangementConferenceDialog: {
                    ...state.arrangementConferenceDialog,
                    zIndex: action.zIndex,
                    isDialogOpen: true
                }
            };
        }
        case CLOSE_ARRANGEMENT_CONFERENCE_DIALOG: {
            return {
                ...state,
                arrangementConferenceDialog: {
                    ...state.arrangementConferenceDialog,
                    isDialogOpen: false
                }
            };
        }
        case PUSH_CASE_SERVICE_DETAIL: {
            const { detail } = action;
            const { selectedCase, caseServiceDetails } = state;

            const detailIndex = caseServiceDetails.reduce(
                (finalIndex, { key }, index) => {
                    if (finalIndex !== -1) {
                        return finalIndex;
                    }

                    const isCasketBearersDetail =
                        key === 'CASKET_BEARERS' ||
                        key === 'HONORARY_CASKET_BEARERS' ||
                        key === 'FLOWER_BEARERS';

                    return isCasketBearersDetail ? index : -1;
                },
                -1
            );

            let updatedDetails;

            if (detailIndex !== -1) {
                caseServiceDetails.splice(detailIndex, 0, detail);
                updatedDetails = caseServiceDetails;
            } else {
                updatedDetails = [...caseServiceDetails, detail];
            }

            return {
                ...state,
                caseServiceDetails: updatedDetails,
                selectedCase: !selectedCase ? null : {
                    ...selectedCase,
                    service_details_count: caseServiceDetails.length + 1
                },
            };
        }
        case UPDATE_CASE_SERVICE_DETAIL: {
            const { detail } = action;
            const { caseServiceDetails } = state;

            return {
                ...state,
                caseServiceDetails: caseServiceDetails
                    .map(sd => sd.id === detail.id ? detail : sd)
            };
        }
        case UPDATE_CASE_SERVICE_DETAIL_FIELD: {
            const { detailId, index, value } = action;
            const { caseServiceDetails } = state;

            return {
                ...state,
                caseServiceDetails: caseServiceDetails
                    .map(detail => {
                        if (detail.id !== detailId) {
                            return detail;
                        }

                        const { data } = detail;
                        const { fields } = data;
                        const oldField = fields[index];

                        if (!oldField) {
                            return detail;
                        }

                        fields.splice(index, 1, { ...oldField, value });

                        return {
                            ...detail,
                            data: {
                                ...data,
                                fields
                            }
                        };
                    })
            };
        }

        case REMOVE_CASE_SERVICE_DETAIL_FIELD: {
            const { detailId, index } = action;
            const { caseServiceDetails } = state;

            return {
                ...state,
                caseServiceDetails: caseServiceDetails
                    .map(detail => {
                        if (detail.id !== detailId) {
                            return detail;
                        }

                        const { data } = detail;
                        const { fields } = data;

                        fields.splice(index, 1);

                        return {
                            ...detail,
                            data: {
                                ...data,
                                fields
                            }
                        };
                    })
            };
        }
        case ADD_CASE_SERVICE_DETAIL_FIELD: {
            const { detailId, fieldType } = action;
            const { caseServiceDetails } = state;

            return {
                ...state,
                caseServiceDetails: caseServiceDetails
                    .map(detail => {
                        if (detail.id !== detailId) {
                            return detail;
                        }

                        const { data } = detail;
                        const fields = data?.fields ?? [];
                        const newField = ServiceDetailDataField.initFromDefaultDetailAndFieldType(detail, fieldType);

                        return {
                            ...detail,
                            data: {
                                ...data,
                                fields: [...fields, newField]
                            }
                        };
                    })
            };
        }
        case REORDER_CASE_SERVICE_DETAIL_FIELDS: {
            const { serviceDetailId, sort } = action;
            const { caseServiceDetails } = state;

            return {
                ...state,
                caseServiceDetails: caseServiceDetails.map(serviceDetail => {
                    if (serviceDetail.id !== serviceDetailId) {
                        return serviceDetail;
                    }

                    return {
                        ...serviceDetail,
                        data: {
                            ...serviceDetail.data,
                            fields: arrayMove(serviceDetail.data.fields, sort.oldIndex, sort.newIndex)
                        }
                    };
                })
            };
        }
        case DELETE_CASE_SERVICE_DETAIL: {
            const { serviceDetailId } = action;
            const { caseServiceDetails } = state;

            const updatedCase = state.selectedCase ? {
                ...state.selectedCase,
                service_details_count: caseServiceDetails.length - 1,
            } : null;

            return {
                ...state,
                caseServiceDetails: caseServiceDetails.filter(({ id }) => id !== serviceDetailId),
                selectedCase: updatedCase,
            };
        }
        case ADD_SELECTED_CASE_TYPE: {
            return {
                ...state,
                selectedCaseTypes: [...state.selectedCaseTypes, action.caseType]
            };
        }
        case DELETE_SELECTED_CASE_TYPE: {
            return {
                ...state,
                selectedCaseTypes: state.selectedCaseTypes.filter(caseType => caseType !== action.caseType)
            };
        }
        case SET_ONE_OFF_PRODUCT_SELECTION_DIALOG_OPEN: {
            return {
                ...state,
                isOneOffProductSelectionDialogOpen: action.isOpen,
            };
        }
        case LOADING_PRIVATE_CASE:
        case LOADING_PUBLIC_CASE: {
            return {
                ...state,
                selectedCase: action.caseName !== state.selectedCase?.name ? null : state.selectedCase,
                publicCase: action.caseName !== state.publicCase?.name ? null : state.publicCase,
                caseIdPhotos: casesStateInitData.caseIdPhotos,
                activeCaseFingerprints: casesStateInitData.activeCaseFingerprints,
            };
        }
        case LOADED_PUBLIC_CASE: {
            const {
                publicCase,
                photos,
                giftPhotos,
                caseServiceDetails,
                obituaryLinks,
                guestList,
                rememberPreviewAlbums,
                memories,
                flowerSales,
            } = action;

            const selectedCase: GatherCaseUX | null = state.selectedCase?.id !== publicCase.id ? null : {
                ...state.selectedCase,
                photos_count: photos.length,
                helper_count: guestList.length + 1,
                flowers_count: flowerSales.length,
                memories_count: memories.length,
            };

            // Whenever a public case is loaded, set the funeral home context for GTM
            setGTMFuneralHomeContext(publicCase.funeral_home.key);

            return {
                ...state,
                publicCase,
                selectedCase,
                casePhotos: photos.map(photoToGatherPhoto),
                caseGiftPhotos: processCaseGiftPhotoList(publicCase.uuid, giftPhotos, state.caseGiftPhotos),
                caseRememberPreviewAlbum: rememberPreviewAlbums,
                caseServiceDetails,
                caseObituaryLinks: obituaryLinks,
                guestList,
                guestListMap: generateGuestListMap(guestList),
                caseIdPhotos: casesStateInitData.caseIdPhotos,
                activeCaseFingerprints: casesStateInitData.activeCaseFingerprints,
            };
        }
        case MEMORIES_LOADED: {
            return {
                ...state,
                selectedCase: !state.selectedCase ? null : {
                    ...state.selectedCase,
                    memories_count: action.memories.length,
                },
            };
        }
        case NEW_MEMORY: {
            return {
                ...state,
                selectedCase: !state.selectedCase ? null : {
                    ...state.selectedCase,
                    memories_count: state.selectedCase.memories_count + 1,
                },
            };
        }
        case MEMORY_REMOVED: {
            return {
                ...state,
                selectedCase: !state.selectedCase ? null : {
                    ...state.selectedCase,
                    memories_count: state.selectedCase.memories_count - 1,
                },
            };
        }
        case UPDATE_TASK: {
            if (!state.selectedCase || state.selectedCase.uuid !== action.caseUuid) {
                return state;
            }
            const changes: Partial<GatherCaseUX> = {};
            // If the obituary task is updated then the case needs to reflect its new state
            if (action.taskTemplateType === TaskTemplateType.complete_obituary
                && action.taskChanges.state
            ) {
                changes.obituary_task_status = action.taskChanges.state;
            }
            // if task location changed then update the current location on case
            if (action.taskChanges.task_location_id !== undefined) {
                if (action.taskChanges.task_location_id !== null) {
                    changes.last_move_task_location_id = action.taskChanges.task_location_id;
                    changes.last_move_completed_time = new Date();
                    changes.current_task_location = {
                        id: action.taskChanges.task_location_id,
                        rank: 1,
                        cover_fallback_url: null,
                        cover_photo: null,
                        cover_photo_id: null,
                        address: null,
                        name: '...',
                        cases_in_location: 1,
                        capacity: 1,
                        is_3rd_party: false,
                        fhs_shared_with: [],
                        moved_time: new Date(),
                    };
                } else {
                    changes.current_task_location = null;
                }
            }
            if (action.taskChanges.marked_complete_time) {
                if (action.trackingStepType === TrackingStepType.initialize) {
                    changes.keeptrack_assigned_time = new Date();
                } else if (action.trackingStepType === TrackingStepType.finalize) {
                    changes.keeptrack_finalized_time = new Date();
                }
            }
            return {
                ...state,
                selectedCase: {
                    ...state.selectedCase,
                    ...changes,
                },
            };
        }
        case BULK_UPDATE_CHECKLIST_TASKS: {
            // If the obituary task is one of the updated then the case needs to reflect its new state
            if (state.selectedCase
                && action.tasks.find(t => t.template === TaskTemplateType.complete_obituary)
                && action.taskChanges.state) {
                return {
                    ...state,
                    selectedCase: {
                        ...state.selectedCase,
                        obituary_task_status: action.taskChanges.state,
                    }
                };
            } else {
                return state;
            }
        }
        case UPDATE_SYNC_STATE: {
            const updatedDashboardCases = state.dashboardCases?.map(gatherCase => gatherCase.uuid === action.caseUuid
                ? ({
                    ...gatherCase,
                    is_published: action.vendor === WebsiteVendor.gather ?
                        action.isAuto : gatherCase.is_published
                })
                : gatherCase
            );

            if (state.selectedCase) {
                return {
                    ...state,
                    dashboardCases: updatedDashboardCases || state.dashboardCases,
                    selectedCase: {
                        ...state.selectedCase,
                        is_published: action.vendor === WebsiteVendor.gather ?
                            action.isAuto : state.selectedCase.is_published,
                        sync: action.isAuto
                            ? Array.from(new Set([...state.selectedCase.sync, action.vendor]))
                            : [] // empty set, or add this type to the existing set (distinct)
                    }
                };
            } else {
                return {
                    ...state,
                    dashboardCases: updatedDashboardCases || state.dashboardCases
                };
            }
        }
        case UPDATE_DEATH_NOTICE: {
            if (state.selectedCase) {
                return {
                    ...state,
                    selectedCase: {
                        ...state.selectedCase,
                        obituary_published_content: action.deathNotice
                    }
                };
            } else {
                return state;
            }
        }
        case CASE_HELPERS_LOADING: {
            return {
                ...state,
                isHelpersLoading: true,
            };
        }
        case CASE_HELPERS_LOADED: {
            return {
                ...state,
                isHelpersLoading: false,
                helpers: action.helpers,
                guestList: action.guestList,
                guestListMap: generateGuestListMap(action.guestList),
                selectedCase: !state.selectedCase ? null : {
                    ...state.selectedCase,
                    helper_count: action.guestList.length + 1,
                },
            };
        }
        case CASE_HELPERS_LOAD_FAILED: {
            return {
                ...state,
                isHelpersLoading: false,
            };
        }
        case ADD_CASE_HELPER: {
            return {
                ...state,
                helpers: [
                    ...state.helpers,
                    action.helper,
                ],
            };
        }
        case SET_CASE_HELPER: {
            const updatedHelpers = state.helpers.map((e) =>
                e.entity_id === action.helper.entity_id ? action.helper : e);
            const updatedGuestList = state.guestList.map(guest => guest.user_id === action.helper.user?.user_id
                ? {
                    ...guest,
                    photo: action.helper.photo,
                    photo_transformations: action.helper.photo_transformations
                }
                : guest
            );
            return {
                ...state,
                helpers: updatedHelpers,
                guestList: updatedGuestList,
                guestListMap: generateGuestListMap(updatedGuestList)
            };
        }
        case REMOVE_CASE_HELPER:
            return {
                ...state,
                helpers: state.helpers.filter((e) => e.entity_id !== action.entityId)
            };
        case UPDATED_DEATH_CERTIFICATE_CONFIG_FOR_CASE: {
            return {
                ...state,
                selectedCase: action.caseUuid === state.selectedCase?.uuid
                    ? {
                        ...state.selectedCase,
                        dc_config_rev_id: action.config.revision.id,
                    }
                    : state.selectedCase,
            };
        }
        case INSERT_OBITUARY_LINK: {
            const existingLinks = [...state.caseObituaryLinks];
            existingLinks.push(action.link);
            return {
                ...state,
                caseObituaryLinks: existingLinks,
            };
        }
        case UPDATE_OBITUARY_LINK: {
            // super easy way to update the record and get new array at same time
            const updatedList = state.caseObituaryLinks.map(record => {
                if (record.id !== action.link.id) {
                    return record;
                } else {
                    return { ...record, ...action.link };
                }
            });
            return {
                ...state,
                caseObituaryLinks: updatedList
            };
        }
        case DELETE_OBITUARY_LINK: {
            const updatedList = state.caseObituaryLinks.filter(record => record.id !== action.link.id);
            return {
                ...state,
                caseObituaryLinks: updatedList
            };
        }
        case DEATH_CERTIFICATE_OUTDATED: {
            return {
                ...state,
                deathCertificateOutdated: action.info,
            };
        }
        case DEATH_CERTIFICATE_OUTDATED_DISMISSED: {
            return {
                ...state,
                deathCertificateOutdated: null,
            };
        }
        case LOADING_CASE_ID_PHOTOS: {
            return {
                ...state,
                caseIdPhotosLoading: true,
            };
        }
        case CASE_ID_PHOTOS_LOADED: {
            return {
                ...state,
                caseIdPhotos: action.idPhotos,
                caseIdPhotosLoading: false,
            };
        }
        case REMOVE_CASE_ID_PHOTO: {
            if (state.caseIdPhotos) {
                const newIdPhotoList = state.caseIdPhotos.filter(p => p.s3_file_id !== action.s3FileId);
                return {
                    ...state,
                    caseIdPhotos: newIdPhotoList,
                };
            }

            return state;
        }
        case LABEL_ADDED_TO_CASE: {
            if (state.selectedCase?.uuid === action.caseUuid) {
                return {
                    ...state,
                    selectedCase: {
                        ...state.selectedCase,
                        labels: [
                            ...state.selectedCase.labels,
                            action.caseLabel
                        ]
                    }
                };
            }

            if (state.dashboardCases) {
                return {
                    ...state,
                    dashboardCases: state.dashboardCases.map(c => {
                        if (c.uuid === action.caseUuid) {
                            return {
                                ...c,
                                labels: [...c.labels, action.caseLabel]
                            };
                        }
                        return c;
                    })
                };
            }
            return state;
        }
        case LABEL_REMOVED_FROM_CASE: {
            if (state.selectedCase?.uuid === action.caseUuid) {
                const updatedCaseLabels = state.selectedCase.labels.filter(({ id }) => id !== action.caseLabel.id);
                return {
                    ...state,
                    selectedCase: {
                        ...state.selectedCase,
                        labels: updatedCaseLabels
                    }
                };
            }

            if (state.dashboardCases) {
                return {
                    ...state,
                    dashboardCases: state.dashboardCases.map(c => {
                        if (c.uuid === action.caseUuid) {
                            return {
                                ...c,
                                labels: c.labels.filter(l => l.id !== action.caseLabel.id)
                            };
                        }
                        return c;
                    })
                };
            }

            return state;
        }
        case CASE_LABEL_UPDATED: {
            if (state.selectedCase) {
                return {
                    ...state,
                    selectedCase: {
                        ...state.selectedCase,
                        labels: state.selectedCase.labels.map(l => {
                            if (l.id === action.caseLabel.id) {
                                return {
                                    ...l,
                                    ...action.caseLabel
                                };
                            }
                            return l;
                        })
                    }
                };
            }

            if (state.dashboardCases) {
                return {
                    ...state,
                    dashboardCases: state.dashboardCases.map(c => {
                        return {
                            ...c,
                            labels: c.labels.map(l => {
                                if (l.id === action.caseLabel.id) {
                                    return {
                                        ...l,
                                        ...action.caseLabel
                                    };
                                }
                                return l;
                            })
                        };
                    })
                };
            }

            return state;
        }
        case FINGERPRINTS_LOADING: {
            return {
                ...state,
                activeCaseFingerprints: {
                    ...state.activeCaseFingerprints,
                    loading: true
                }
            };
        }
        case FINGERPRINTS_LOADED: {
            return {
                ...state,
                activeCaseFingerprints: {
                    loading: false,
                    prints: action.fingerprints.map(fingerprintToCaseFingerprint),
                }
            };
        }
        case FINGERPRINT_CREATED: {
            return {
                ...state,
                activeCaseFingerprints: {
                    ...state.activeCaseFingerprints,
                    prints: [...state.activeCaseFingerprints.prints, action.caseFingerprint]
                }
            };

        }
        case FINGERPRINT_UPLOADED: {
            const updatedFingerPrints = state.activeCaseFingerprints.prints.map(print => {
                if (print.uuid !== action.caseFingerprint.uuid) {
                    return print;
                }
                return action.caseFingerprint;
            });
            return {
                ...state,
                activeCaseFingerprints: {
                    ...state.activeCaseFingerprints,
                    prints: updatedFingerPrints
                }
            };

        }
        case FINGERPRINT_UPDATED: {
            const updatedFingerPrints = state.activeCaseFingerprints.prints.map(print => {
                if (print.fingerprint?.s3_file_id === action.s3FileId) {
                    return {
                        ...print,
                        fingerprint: {
                            ...print.fingerprint,
                            type: action.fingerprint.type || print.fingerprint.type
                        }
                    };
                }
                return print;
            });
            return {
                ...state,
                activeCaseFingerprints: {
                    ...state.activeCaseFingerprints,
                    prints: updatedFingerPrints
                }
            };
        }
        case FINGERPRINT_DELETED: {
            return {
                ...state,
                activeCaseFingerprints: {
                    ...state.activeCaseFingerprints,
                    prints: state.activeCaseFingerprints.prints.filter(({ fingerprint }) =>
                        fingerprint && action.fingerprint.s3_file_id !== fingerprint.s3_file_id
                    ),
                }
            };
        }
        case CASE_BELONGINGS_LOADED: {
            return {
                ...state,
                belongings: action.belongings,
            };
        }
        case CASE_BELONGING_CREATED: {
            if (state.belongings === null) {
                return {
                    ...state,
                    belongings: [action.newBelonging],
                };
            }

            return {
                ...state,
                belongings: [...state.belongings, action.newBelonging],
            };
        }
        case CASE_BELONGING_UPDATED: {
            if (state.belongings) {
                return {
                    ...state,
                    belongings: state.belongings.map(b => {
                        if (b.id === action.updatedBelonging.id) {
                            return action.updatedBelonging;
                        }
                        return b;
                    })
                };
            }

            return state;
        }
        case CASE_BELONGING_DELETED: {
            if (state.belongings) {
                return {
                    ...state,
                    belongings: state.belongings.filter(b => b.id !== action.deletedBelonging.id)
                };
            }

            return state;
        }
        case TRACKING_PAGE_LOADING: {
            return {
                ...state,
                isLoadingTrackingPage: true,
            };
        }
        case FAILED_TRACKING_PAGE_LOAD: {
            return {
                ...state,
                isLoadingTrackingPage: false,
            };
        }
        case TRACKING_PAGE_LOADED: {
            return {
                ...state,
                workflowChangeHistory: action.workflowChangeHistory,
                belongings: action.belongings,
                activeCaseFingerprints: {
                    loading: false,
                    prints: action.fingerprints.map(fingerprintToCaseFingerprint),
                },
                caseIdPhotos: action.idPhotos,
                isLoadingTrackingPage: false,
            };
        }
        case ATTACHING_POLICY_TO_CASE: {
            return {
                ...state,
                selectedCase: !state.selectedCase ? null : {
                    ...state.selectedCase,
                    insurance_policies: [...state.selectedCase.insurance_policies, action.policyId]
                }
            };
        }
        case ATTACHED_POLICY_TO_CASE: {
            return {
                ...state,
                selectedCase: !state.selectedCase ? null : {
                    ...state.selectedCase,
                    insurance_policies: [...state.selectedCase.insurance_policies, action.policyId]
                }
            };
        }
        case UNATTACHING_POLICY_TO_CASE: {
            return {
                ...state,
                selectedCase: !state.selectedCase ? null : {
                    ...state.selectedCase,
                    insurance_policies: state.selectedCase.insurance_policies.filter(id => id !== action.policyId)
                }
            };
        }
        case ORGANIZE_PAGE_LOADED: {
            return {
                ...state,
                helpers: action.helpers,
                guestList: action.guestList,
                guestListMap: generateGuestListMap(action.guestList),
                selectedCase: !state.selectedCase ? null : {
                    ...state.selectedCase,
                    helper_count: action.guestList.length + 1,
                },
                activeCaseFingerprints: {
                    loading: false,
                    prints: action.fingerprints.map(fingerprintToCaseFingerprint),
                },
            };
        }
        case INITIALIZE_STEP_LOADED:
        case TASK_LOADED: {
            return {
                ...state,
                belongings: action.belongings ?? state.belongings,
                activeCaseFingerprints: !action.fingerprints ? state.activeCaseFingerprints : {
                    loading: false,
                    prints: action.fingerprints.map(fingerprintToCaseFingerprint),
                },
                caseIdPhotos: action.idPhotos ?? state.caseIdPhotos,
            };
        }
        case CASE_MOVED: {
            return {
                ...state,
                dashboardCases: state.dashboardCases
                    ? state.dashboardCases.filter((c) => c.id !== action.updatedCase.id)
                    : state.dashboardCases,
            };
        }

        default:
            return state;
    }
};
