import CopyToClipboard from 'react-copy-to-clipboard';
import classNames from 'classnames';

import Grid from '@mui/material/Grid';
import { Theme } from '@mui/material/styles';
import withStyles, { WithStyles, StyleRulesCallback } from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import LinkIcon from '@mui/icons-material/Link';

import { GatherCasePublic } from '../../../../../shared/types';
import RememberPageDialog from '../../widgets/RememberPageDialog';
import ShareButton from './listing/ShareButton';
import { getShareOptions } from '../../utils';
import { getOptionContent, PopperContentType, ShareOptionType } from '../../widgets/ShareLinkSection';
import { convertHexToRGBA, getRememberURLForSharing } from '../../../../../services';
import { setSnackbarSuccess } from '../../../../../actions/AppSnackbar.action';
import RememberThemeGraphics from '../../widgets/RememberThemeGraphics';
import { useDispatch } from 'react-redux';
import { useGSelector } from '../../../../../types';
import SocialIconPopper from '../../widgets/SocialIconPopper';
import { useState } from 'react';

const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
    root: {},
    dialogPaper: {
        width: '100%',
        maxWidth: '100%',
        height: '100%',
        maxHeight: '100%',
        margin: 0,
        display: 'flex',
        flexDirection: 'column',
        '@media (min-width: 1400px)': {
            margin: '72px auto !important',
            maxWidth: '1080px !important',
            width: '100%',
            height: 'unset',
            maxHeight: 'unset',
        },
    },
    content: {
        flexGrow: 1,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        wordBreak: 'break-all',
        padding: '8px 8px 0',
        '@media (min-width: 768px)': {
            paddingTop: 20,
        },
        '@media (min-width: 1024px)': {
            padding: '20px 0 0',
        },
        '&$noActiveTheme': {
            alignItems: 'center',
            justifyContent: 'center',
            padding: '0 8px',
            '& $graphicsContainer': {
                margin: 0,
            },
        },
    },
    shareButtonsContainer: {
        borderBottom: `1px solid ${convertHexToRGBA(theme.palette.secondary.main, 0.5)}`,
        width: 'fit-content',
        margin: '0 auto',
        '@media (min-width: 768px)': {
            paddingBottom: 4,
        },
    },
    linkContainer: {
        marginTop: 12,
        '& button': {
            padding: 8,
            marginTop: 8,
        },
        '& p': {
            fontSize: 16,
            fontWeight: 300,
            '@media (min-width: 768px)': {
                fontSize: 20,
            },
        },
    },
    graphicsContainer: {
        marginTop: 'auto',
        '@media (min-width: 1024px)': {
            justifyContent: 'space-between',
        },
    },
    dialogTitle: {
        margin: 0,
        paddingTop: 4,
        '@media (min-width: 600px)': {
            fontSize: 34,
        },
        '@media (min-width: 1400px)': {
            paddingTop: 52,
        },
    },
    userAvatar: {
        left: 'unset',
        position: 'unset',
        transform: 'none',
        '@media (min-width: 768px)': {
            marginTop: 12,
        },
        '@media (min-width: 1400px)': {
            left: '50%',
            position: 'absolute',
            transform: 'translate(-50%, -50%)',
            marginTop: 0,
        },
    },
    dialogContent: {
        height: '100%',
        minHeight: 'unset',
        '&$noActiveTheme': {
            minHeight: 480,
            '@media (min-width: 600px)': {
                marginBottom: 40,
            },
            '@media (min-width: 1400px)': {
                display: 'flex',
                alignItems: 'center',
            },
        },
    },
    subtitle: {
        '@media (min-width: 600px)': {
            fontSize: 19,
        },
    },
    noActiveTheme: {},
});
type Classes =
    | 'root'
    | 'dialogPaper'
    | 'content'
    | 'shareButtonsContainer'
    | 'linkContainer'
    | 'graphicsContainer'
    | 'dialogTitle'
    | 'userAvatar'
    | 'dialogContent'
    | 'noActiveTheme'
    | 'subtitle';
type StyledProps = WithStyles<Classes>;

interface Props {
    isOpen: boolean;
    zIndex: number;
    publicCase: GatherCasePublic;
    closeDialog: () => void;
}

interface ShareOption {
    name: ShareOptionType;
    content: PopperContentType | null;
    onClick?: React.MouseEventHandler<HTMLElement>;
}
const ShareOptionsDialog = (props: Props & StyledProps) => {
    const { publicCase, classes, ...others } = props;

    const dispatch = useDispatch();

    const activeTheme = useGSelector(({ whiteLabel }) => whiteLabel.rememberTheme);

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const shareOptions = getShareOptions(publicCase);
    const facebookOption = getOptionContent('FACEBOOK', publicCase, shareOptions);
    const emailOption = getOptionContent('EMAIL', publicCase, shareOptions);
    const shareOption = getOptionContent('SHARE', publicCase, shareOptions);
    const smsOption = getOptionContent('SMS', publicCase, shareOptions);
    const options: ShareOption[] = [
        {
            name: 'FACEBOOK',
            content: facebookOption && { ...facebookOption.content, title: 'Share on Facebook' },
        },
        {
            name: 'EMAIL',
            content: emailOption && { ...emailOption.content, title: 'Share via Email' },
        },
        {
            name: 'SMS',
            content: smsOption && { ...smsOption.content, title: 'Send a Text' },
        },
        {
            name: 'SHARE',
            content: shareOption && { ...shareOption.content, title: 'Share on Mobile' },
            onClick: (evt) => setAnchorEl(evt.currentTarget),
        },
    ];
    const link = getRememberURLForSharing(publicCase.name);
    const hasActiveTheme = Boolean(activeTheme);

    const handleSetSnackbarSuccess = () => {
        dispatch(setSnackbarSuccess('Link copied to your clipboard'));
    };

    return (
        <>
            <RememberPageDialog
                title="Who else might like to contribute?"
                subtitle={`You might be the best person to help find others who knew ${publicCase.display_fname}`}
                dialogPaperClass={classes.dialogPaper}
                dialogContentClass={classNames(classes.dialogContent, !hasActiveTheme && classes.noActiveTheme)}
                publicCase={publicCase}
                titleClass={classes.dialogTitle}
                userAvatarClass={classes.userAvatar}
                subTitleClass={classes.subtitle}
                scroll="body"
                {...others}
            >
                <Grid item xs={12} className={classNames(classes.content, !hasActiveTheme && classes.noActiveTheme)}>
                    <Grid container justifyContent="center" className={classes.shareButtonsContainer}>
                        {options.map(
                            ({ name, content, onClick }) =>
                                content && (
                                    <ShareButton
                                        key={name}
                                        option={name}
                                        icon={content.icon}
                                        title={content.title}
                                        link={content.link}
                                        onClick={onClick}
                                    />
                                ),
                        )}
                    </Grid>

                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        className={classes.linkContainer}
                    >
                        <Typography color="secondary" component="p">
                            {link}
                        </Typography>

                        <CopyToClipboard text={link}>
                            <Button color="secondary" variant="outlined" onClick={handleSetSnackbarSuccess}>
                                <LinkIcon color="inherit" />
                                &nbsp;Copy Link
                            </Button>
                        </CopyToClipboard>
                    </Grid>

                    <Grid container className={classes.graphicsContainer}>
                        <RememberThemeGraphics activeTheme={activeTheme} type="bottom_left_graphic" />

                        <RememberThemeGraphics activeTheme={activeTheme} type="bottom_right_graphic" />

                        <RememberThemeGraphics activeTheme={activeTheme} type="bottom_mobile_graphic" />
                    </Grid>
                </Grid>
            </RememberPageDialog>

            <SocialIconPopper
                zIndex={others.zIndex + 1}
                type="SHARE"
                popperAnchorEle={anchorEl}
                popperContent={shareOption}
                activeCase={publicCase}
                setAppSnackbarSuccess={() => void 0}
                clickAwayListener={() => setAnchorEl(null)}
            />
        </>
    );
};

export default withStyles(styles)(ShareOptionsDialog);
