import { StoreState } from '../types';
import {
    EntitySummary,
    PhotoTransformationsType,
    TeamMemberCreateResponse,
    UserCreateRequest,
    UserInviteStatusEnum,
    UserPatchRequest,
    UserUpdateRequest,
    UserRoles,
    isEntityUpdateValidateError,
    TaskPreview,
} from '../shared/types';
import { getFromAPI, postToAPI, deleteFromAPI, patchAPI, advancedAPIRequest, parseJSON } from '.';
import { registerAppError } from './errors';
import { bootIntercom } from '../services';
import { uploadPhoto } from './Photo.action';
import { areEmailsEqual } from '../shared/utils';
import { log } from '../logger';
import { AppDispatch } from '../store';
import { canViewTeamMembers } from '../shared/authority/can';

export const inviteTeamMember = (user: UserCreateRequest, funeralHomeId?: number) => {
    return async (dispatch: AppDispatch): Promise<EntitySummary | null> => {
        // Validate the object before sending to the server
        try {
            UserCreateRequest.fromRequest(user);
        } catch (ex) {
            console.warn('Failed to validate UserCreateRequest:', user, ex);
            return null;
        }
        dispatch(setTeamInvitationPending(true));
        const path = funeralHomeId ? `funeralhome/${funeralHomeId}/team` : 'api/team';
        const response = await postToAPI<TeamMemberCreateResponse>(path, { user }, dispatch);
        dispatch(setTeamInvitationPending(false));

        if (response) {
            const { status, team } = response;
            if (team && status === UserInviteStatusEnum.success) {
                dispatch(setTeamInvitationSuccess(true));
                dispatch(setTeam(team));
                const newMember = team.find(u => areEmailsEqual(u.email, user.email));
                return newMember ? newMember : null;
            } else {
                switch (status) {
                    case UserInviteStatusEnum.different_name_found:
                        dispatch(registerAppError('Same email and/or phone used for someone with a different name'));
                        break;
                    case UserInviteStatusEnum.different_role_found:
                        dispatch(registerAppError('Cannot invite an existing person to a different role'));
                        break;
                    case UserInviteStatusEnum.multiple_users_found:
                        dispatch(registerAppError('Email/phone combination is used by two different people'));
                        break;
                    default:
                        dispatch(registerAppError('Unable to invite team member'));
                }
            }
        } else {
            dispatch(registerAppError('Unable to invite team member'));
        }
        dispatch(setTeamInvitationSuccess(false));
        return null;
    };
};

export const resendInvitationToTeamMember = (teamMember: EntitySummary, funeralHomeId: number | null) => {
    return async (dispatch: AppDispatch) => {
        if (!teamMember.user_id) {
            console.warn('Team member does not have a login!');
            return;
        }

        dispatch(setTeamInvitationPending(true));
        const path = `${funeralHomeId ? `funeralhome/${funeralHomeId}/` : 'api/'}team/${teamMember.user_id}/resend`;
        const team = await postToAPI<EntitySummary[]>(path, {}, dispatch);
        dispatch(setTeamInvitationPending(false));

        if (team) {
            dispatch(setTeamInvitationSuccess(true));
            dispatch(setTeam(team));
            return;
        } else {
            dispatch(registerAppError('Unable to resend invitation'));
        }
        dispatch(setTeamInvitationSuccess(false));
    };
};

export const removeTeamMember = (teamMember: EntitySummary, funeralHomeId: number | null) => {
    return async (dispatch: AppDispatch) => {
        if (!teamMember.user_id) {
            console.warn('Team member does not have a login!');
            return;
        }
        dispatch(setTeamInvitationPending(true));
        const path = funeralHomeId ?
            `funeralhome/${funeralHomeId}/team/${teamMember.user_id}` :
            `api/team/${teamMember.user_id}`;
        const team = await deleteFromAPI<EntitySummary[]>(path, dispatch);
        dispatch(setTeamInvitationPending(false));
        if (team) {
            dispatch(setTeam(team));
        } else {
            dispatch(registerAppError(`Unable to remove ${teamMember.fname}.`));
        }
    };
};

export const updateTeamMember = (
    existingTeamMember: EntitySummary,
    updates: UserUpdateRequest,
    funeralHomeKey: string | null, 
    funeralHomeId: number | null,
) => {
    return async (dispatch: AppDispatch, getState: () => StoreState) => {
        // Validate the object before sending to the server, and strip off the UX-only fields
        try {
            UserUpdateRequest.fromRequest(updates);
        } catch (ex) {
            log.warn('Failed to validate UserUpdateRequest', { updates, ex });
            return;
        }

        if (!existingTeamMember.user_id) {
            console.warn('Team member does not have a login!');
            return;
        }

        const path = funeralHomeId !== null && !UserRoles.isGOMUser(existingTeamMember) ?
            `funeralhome/${funeralHomeId}/team/${existingTeamMember.user_id}` :
            `api/team/${existingTeamMember.user_id}`;

        const response = await advancedAPIRequest(path, 'PUT', { user: updates }, dispatch);
        if (response) {
            if (response.status === 409 || response.ok) {
                const body = await parseJSON(response);

                if (isEntityUpdateValidateError(body)) {
                    return body;
                }
                const updatedTeamMember: EntitySummary = body;
                dispatch(setTeamMember(updatedTeamMember));

                const { userSession } = getState();
                const { userData } = userSession;
                if (userData && userData.user_id === existingTeamMember.user_id) {
                    bootIntercom(updatedTeamMember, 
                        funeralHomeKey? funeralHomeKey : undefined, 
                        funeralHomeId?.toString() );
                }
                return updatedTeamMember;
            }
        }
        dispatch(registerAppError('Unable to update team member.'));
        return null;
    };
};

export const patchTeamMember = (
    userId: number,
    entityId: number,
    updates: UserPatchRequest,
    funeralHomeId: number | null,
) => {
    return async (dispatch: AppDispatch, getState: () => StoreState): Promise<EntitySummary | null> => {
        const existingUser = getState().userSession.userData;

        if (!existingUser) {
            log.warn('No user is logged in', { existingUser, userId, entityId, updates });
            return null;
        }

        // Optimistically update the User
        dispatch(updateTeamMemberInStore(userId, entityId, updates));

        try {
            UserPatchRequest.fromRequest(updates);
        } catch (ex) {
            console.warn('Failed to validate UserPatchRequest:', updates, ex);
            return null;
        }

        const path = funeralHomeId && !UserRoles.isGOMUser(existingUser) ?
            `funeralhome/${funeralHomeId}/team/${userId}` :
            `api/team/${userId}`;

        const updatedEntity = await patchAPI<EntitySummary>(
            path, { user: updates }, dispatch
        );
        if (updatedEntity) {
            if (existingUser.id === userId) {
                bootIntercom(updatedEntity);
            }
            return updatedEntity;
        }
        return null;
    };
};

export const SET_TEAM_MEMBER_PHOTO_SAVING = 'SET_TEAM_MEMBER_PHOTO_SAVING';

export interface SetTeamMemberPhotoSaving {
    type: typeof SET_TEAM_MEMBER_PHOTO_SAVING;
    entityId: number;
    isPhotoSaving: boolean;
}

function setTeamMemberPhotoSaving(entityId: number, isPhotoSaving: boolean): SetTeamMemberPhotoSaving {
    return {
        type: SET_TEAM_MEMBER_PHOTO_SAVING,
        entityId,
        isPhotoSaving,
    };
}

export function updateTeamMemberPhoto(
    teamMember: EntitySummary,
    photo: string,
    transformations: PhotoTransformationsType,
    funeralHomeId: number | null,
) {
    return async (dispatch: AppDispatch): Promise<EntitySummary | null> => {
        if (!teamMember.user_id) {
            console.warn('Trying to upload photo for team member without user');
            return null;
        }
        // need to upload photo to Cloudinary first
        dispatch(setTeamMemberPhotoSaving(teamMember.entity_id, true));

        const path = funeralHomeId ?
            `funeralhome/${funeralHomeId}/team/${teamMember.user_id}/photo` :
            `api/team/${teamMember.user_id}/photo`;

        const gatherSignatureURL = `${path}/signature`;

        const cloudinaryResult = await uploadPhoto(photo, gatherSignatureURL, dispatch);
        if (cloudinaryResult) {
            const updatedEntity = await postToAPI<EntitySummary>(
                path,
                {
                    public_id: cloudinaryResult.public_id,
                    width: cloudinaryResult.width,
                    height: cloudinaryResult.height,
                    transformations,
                },
                dispatch,
            );
            if (updatedEntity) {
                dispatch(setTeamMember(updatedEntity));

                // Create an event for pages to listen for
                const event = new Event('gather.user_profile.photo_update', { bubbles: true, cancelable: true });
                document.dispatchEvent(event);
                dispatch(setTeamMemberPhotoSaving(teamMember.entity_id, false));
                return updatedEntity;
            } else {
                dispatch(registerAppError('Unable to set user profile photo.'));
            }
        } else {
            dispatch(registerAppError('Unable to upload photo.'));
        }
        dispatch(setTeamMemberPhotoSaving(teamMember.entity_id, false));
        return null;
    };
}

export function loadTeamForFuneralHomeList(funeralHomeIdList: number[]) {
    return async (dispatch: AppDispatch, getState: () => StoreState) => {
        const user = getState().userSession.userData;
        if (!user) {
            return [];
        }

        // filter out any FH IDs the User doesn't have access to
        const fhIds = funeralHomeIdList.filter((fhId) => canViewTeamMembers(user, fhId));
        if (fhIds.length === 0) {
            return [];
        }
        const searchParam = new URLSearchParams({
            fhIds: fhIds.join(','),
        });
        const team = await getFromAPI<EntitySummary[]>(`funeralhome/team?${searchParam.toString()}`, dispatch);
        return team;
    };
}

// Load the team members for the funeral home ID provided.
// This will not store the resulting team members in the Redux store.
// It is used when we want to load team members but use local state rather than Redux for storing them
export function loadTeamApi(funeralHomeId: number | null) {
    return async (dispatch: AppDispatch) => {
        const resource = funeralHomeId ? `funeralhome/${funeralHomeId}/team/` : 'api/team/';
        const team = await getFromAPI<EntitySummary[]>(resource, dispatch);
        return team;
    };
}

// Load the team members for the funeral home ID provided and store them in Redux
// This differs from loadTeamApi in that it will store the team members in the Redux store
// It should only be used when the active Funeral Home in Redux is the same as the funeralHomeId provided
export function loadTeam(funeralHomeId: number | null) {
    return async (dispatch: AppDispatch) => {
        dispatch(setTeamLoading(true));
        const team = await dispatch(loadTeamApi(funeralHomeId));
        if (team) {
            dispatch(setTeam(team));
        } else {
            dispatch(registerAppError('Unable to load team members.'));
        }
        dispatch(setTeamLoading(false));
    };
}

export const LOADING_TASK_PREVIEWS = 'LOADING_TASK_PREVIEWS';

interface LoadingTaskPreviews {
    type: typeof LOADING_TASK_PREVIEWS;
}

function loadingTaskPreviews(): LoadingTaskPreviews {
    return {
        type: LOADING_TASK_PREVIEWS,
    };
}

export const LOADED_TASK_PREVIEWS = 'LOADED_TASK_PREVIEWS';

interface LoadedTaskPreviews {
    type: typeof LOADED_TASK_PREVIEWS;
    taskPreviews: TaskPreview[];
}

function loadedTaskPreviews(taskPreviews: TaskPreview[]): LoadedTaskPreviews {
    return {
        type: LOADED_TASK_PREVIEWS,
        taskPreviews,
    };
}

export const FAILED_LOAD_TASK_PREVIEWS = 'FAILED_LOAD_TASK_PREVIEWS';

interface FailedLoadTaskPreviews {
    type: typeof FAILED_LOAD_TASK_PREVIEWS;
}

function failedLoadTaskPreviews(): FailedLoadTaskPreviews {
    return {
        type: FAILED_LOAD_TASK_PREVIEWS,
    };
}

export function loadTeamMembersTasks(funeralHomeId: number, userId: number) {
    return async (dispatch: AppDispatch) => {
        dispatch(loadingTaskPreviews());
        const resource = `funeralhome/${funeralHomeId}/team/${userId}/task/`;
        const taskPreviews = await getFromAPI<TaskPreview[]>(resource, dispatch);
        if (!taskPreviews) {
            dispatch(failedLoadTaskPreviews());
            dispatch(registerAppError(`Unable to load team member's assigned tasks.`));
        } else {
            dispatch(loadedTaskPreviews(taskPreviews));
        }
        return taskPreviews;
    };
}

export const SET_TEAM_INVITATION_PENDING = 'SET_TEAM_INVITATION_PENDING';
export type SET_TEAM_INVITATION_PENDING = typeof SET_TEAM_INVITATION_PENDING;

interface SetTeamInvitationPending {
    type: SET_TEAM_INVITATION_PENDING;
    isInvitationPending: boolean;
}

function setTeamInvitationPending(isInvitationPending: boolean): SetTeamInvitationPending {
    return {
        type: SET_TEAM_INVITATION_PENDING,
        isInvitationPending
    };
}

export const SET_TEAM_INVITATION_SUCCESS = 'SET_TEAM_INVITATION_SUCCESS';
export type SET_TEAM_INVITATION_SUCCESS = typeof SET_TEAM_INVITATION_SUCCESS;

interface SetTeamInvitationSuccess {
    type: SET_TEAM_INVITATION_SUCCESS;
    isInvitationSuccess: boolean;
}

export function setTeamInvitationSuccess(isInvitationSuccess: boolean): SetTeamInvitationSuccess {
    return {
        type: SET_TEAM_INVITATION_SUCCESS,
        isInvitationSuccess
    };
}

export const SET_TEAM_LOADING = 'SET_TEAM_LOADING';
export type SET_TEAM_LOADING = typeof SET_TEAM_LOADING;

interface SetTeamLoading {
    type: SET_TEAM_LOADING;
    isLoading: boolean;
}

function setTeamLoading(isLoading: boolean): SetTeamLoading {
    return {
        type: SET_TEAM_LOADING,
        isLoading,
    };
}

export const SET_TEAM = 'SET_TEAM';
export type SET_TEAM = typeof SET_TEAM;

interface SetTeam {
    type: SET_TEAM;
    team: EntitySummary[];
}

function setTeam(team: EntitySummary[]): SetTeam {
    return {
        type: SET_TEAM,
        team,
    };
}

export const UPDATE_TEAM_MEMBER = 'UPDATE_TEAM_MEMBER';

interface UpdateTeamMember {
    type: typeof UPDATE_TEAM_MEMBER;
    userId: number;
    entityId: number;
    changes: UserPatchRequest;
}

function updateTeamMemberInStore(userId: number, entityId: number, changes: UserPatchRequest): UpdateTeamMember {
    return {
        type: UPDATE_TEAM_MEMBER,
        userId,
        entityId,
        changes,
    };
}

export const SET_TEAM_MEMBER = 'SET_TEAM_MEMBER';

interface SetTeamMember {
    type: typeof SET_TEAM_MEMBER;
    teamMember: EntitySummary;
}

function setTeamMember(teamMember: EntitySummary): SetTeamMember {
    return {
        type: SET_TEAM_MEMBER,
        teamMember,
    };
}

export type TeamAction = SetTeamInvitationPending
    | SetTeamInvitationSuccess
    | SetTeamLoading
    | SetTeam
    | UpdateTeamMember
    | SetTeamMember
    | SetTeamMemberPhotoSaving
    | LoadingTaskPreviews
    | LoadedTaskPreviews
    | FailedLoadTaskPreviews;
