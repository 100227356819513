import classNames from 'classnames';

import { StyledProps, styleWrapper, typographyStyle } from '../styles';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import { getAddressFromLongAddress, getFormattedPhoneNumber } from '../../../../../services';
import Button from '@mui/material/Button';
import { CaseType, LongAddress, ResidencePlaceType } from '../../../../../shared/types';
import moment from 'moment';
import withGStyles from '../../../../../styles/WithGStyles';
import FuneralHomeLogo from '../../../../common/FuneralHomeLogo';
import { Payer } from '../../payment';
import { joinNameParts } from '../../../../../shared/utils';

export type FuneralHomeDetails = {
    fhName: string | null;
    fhAddress: string | null;
    fhCity: string | null;
    fhState: string | null;
    fhPostalCode: string | null;
    fhPhone: string | null;
};

export type CaseDetails = {
    caseName: string;
    caseFirstName: string;
    caseNumber?: string;
    age: number | null;
    caseLifeSpan: string;
    assigneeName: string;
    assigneeTitle: string;
    gender: string;
    caseType: CaseType;
    deathPlace: LongAddress | null;
    deathDate: moment.Moment | null;
    birthDate: moment.Moment | null;
    residencePlace: ResidencePlaceType | null;
};

type Props = {
    isPrintMode: boolean;
    isCondensedView: boolean;
    funeralHomeDetails: FuneralHomeDetails;
    caseDetails: CaseDetails;
    contractGrandTotal: string;
    onCaseNumberClick?: () => void;
    isNewJerseyContract: boolean;
    canUserEditCase: boolean;
    isItemizedStatement?: boolean | undefined;
    isDownloadingInvoice?: boolean | undefined;
    payer: Payer | undefined;
    renderInvoiceLanguage: JSX.Element | null;
};

const Header = (props: StyledProps & Props) => {
    const {
        classes,
        isCondensedView,
        isPrintMode,
        funeralHomeDetails,
        caseDetails,
        onCaseNumberClick,
        isNewJerseyContract,
        canUserEditCase,
        isItemizedStatement,
        isDownloadingInvoice,
        payer,
        renderInvoiceLanguage,
    } = props;
    const {
        fhName,
        fhAddress,
        fhCity,
        fhState,
        fhPostalCode,
        fhPhone,
    } = funeralHomeDetails;
    const {
        assigneeName,
        assigneeTitle,
        caseName,
        caseNumber,
        age,
        gender,
        caseType,
        deathDate,
        deathPlace,
        birthDate,
        residencePlace
    } = caseDetails;

    const isOneOff = caseType === CaseType.one_off;
    const caseDeathPlace = deathPlace && deathPlace.city && deathPlace.state
        ? ` in ${deathPlace.city}, ${deathPlace.state}`
        : '';
    const caseDeathDetails = deathDate
        && `${isOneOff ? 'Created' : 'Passed away'} on ${deathDate.format('D MMM YYYY')}` + caseDeathPlace;
    const caseBirthAndDeathDetails = birthDate
        ? `Born ${birthDate.format('MM/DD/YYYY')}. `
        + ((deathDate || deathPlace) ? `Died ` : '')
        + (deathDate ? deathDate.format('MM/DD/YYYY') : '')
        + caseDeathPlace
        : '';
    const residence = getAddressFromLongAddress(residencePlace);

    const payerName = payer ? joinNameParts(payer.payerEntity) : '';

    let address1: string | undefined = undefined;
    let city: string | undefined = undefined;
    let state: string | undefined = undefined;
    let postalCode: string | undefined = undefined;

    if (payer && payer.home_address) {
        address1 = payer.home_address.address1;
        city = payer.home_address.city;
        state = payer.home_address.state;
        postalCode = payer.home_address.postalCode;
    }

    const getCaseDetails = () => {
        return (
            <Grid
                item
                xs={isDownloadingInvoice ? undefined : 12}
                className={classes.caseDetails}
                style={{
                    textAlign: (isPrintMode || isDownloadingInvoice) && 'right' || undefined,
                }}
            >
                <Typography
                    color="secondary"
                    className={classes.typographyBig}
                    align="center"
                    style={{
                        ...typographyStyle,
                        textAlign: isPrintMode ? 'right' : undefined,
                        fontWeight: 500,
                        lineHeight: 1.25,
                        color: '#2e2e2e',
                        display: isPrintMode ? 'flex' : undefined,
                        alignItems: isPrintMode ? 'center' : undefined,
                        justifyContent: isPrintMode ? 'flex-end' : undefined
                    }}
                >
                    {caseName}{((gender && isNewJerseyContract) || age) && ', '}
                    {isNewJerseyContract && gender}{age && ` ${age}`}
                    &nbsp;
                    {canUserEditCase && !isPrintMode ? <Button
                        className={classes.caseNumber}
                        onClick={onCaseNumberClick}
                        color="primary"
                        variant="outlined"
                        style={{
                            fontSize: 12,
                            fontWeight: 400,
                            background: isPrintMode && 'none' || undefined,
                            border: isPrintMode && '1px solid' || undefined,
                            borderRadius: 24,
                            height: 18,
                            padding: '0 10px',
                            outline: 'none'
                        }}
                    >
                        {caseNumber ? caseNumber : '+ Case Number'}
                    </Button>
                        : <> {caseNumber ? caseNumber : ''} </>}
                    {isNewJerseyContract &&
                        <>
                            &nbsp;
                            <span
                                className={classNames(
                                    classes.caseType,
                                    caseType === CaseType.at_need && classes.atNeed,
                                    caseType === CaseType.pre_need && classes.preNeed,
                                    caseType === CaseType.trade && classes.trade,
                                    caseType === CaseType.one_off && classes.oneOff
                                )}
                                style={isPrintMode && {
                                    fontSize: 12,
                                    textTransform: 'capitalize',
                                    borderRadius: 24,
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: '0 10px',
                                    height: 18,
                                    border: `1px solid`,
                                    boxSizing: 'border-box'
                                } || undefined}
                            >
                                {caseType}
                            </span>
                        </>
                    }
                </Typography>

                <Typography
                    color="secondary"
                    className={classes.typographySmall}
                    style={{
                        ...typographyStyle,
                        fontSize: 12,
                        lineHeight: '1.2em',
                        minHeight: 12,
                        textAlign: isPrintMode ? 'right' : undefined,
                        color: '#2e2e2e'
                    }}
                >
                    {isNewJerseyContract && caseBirthAndDeathDetails || caseDeathDetails}
                </Typography>

                {isNewJerseyContract && residence &&
                    <Typography
                        color="secondary"
                        className={classes.typographySmall}
                        style={{
                            ...typographyStyle,
                            fontSize: 12,
                            lineHeight: '1.25em',
                            minHeight: 12,
                            textAlign: isPrintMode ? 'right' : undefined,
                            color: '#2e2e2e'
                        }}
                    >
                        Lived at {residence}
                    </Typography>
                }

                <Typography
                    color="secondary"
                    className={classNames(classes.typographySmall, classes.marginTop8)}
                    style={{
                        ...typographyStyle,
                        marginTop: 8,
                        fontSize: 12,
                        lineHeight: '1em',
                        textAlign: isPrintMode ? 'right' : undefined,
                        color: '#2e2e2e'
                    }}
                >
                    {assigneeTitle || 'Funeral Director'}:&nbsp;
                    <span>
                        {assigneeName}
                    </span>
                    {fhPhone && <span>, {getFormattedPhoneNumber(fhPhone)}</span>}
                </Typography>

                <Typography
                    color="secondary"
                    className={classes.typographySmall}
                    style={{
                        ...typographyStyle,
                        lineHeight: '1.25',
                        fontSize: 12,
                        textAlign: isPrintMode ? 'right' : undefined,
                        color: '#2e2e2e'
                    }}
                >
                    {fhAddress && <span>{fhAddress}, </span>}
                    {fhCity && <span>{fhCity}, </span>}
                    <span>{fhState} </span>
                    <span>{fhPostalCode}</span>
                </Typography>
            </Grid>
        );
    };

    return (
        <Grid
            item
            xs={12}
            className={classes.contractHeader}
            style={{
                margin: isPrintMode ? '0 auto' : '8px auto 0',
                paddingBottom: isDownloadingInvoice ? 0 : isPrintMode ? 4 : undefined
            }}
        >
            {/* Header for Invoice Starts 
            Adding it to the DOM before printing
            */}
            <Typography
                style={{
                    fontSize: 18,
                    fontWeight: 600,
                    textAlign: 'center',
                    display: isDownloadingInvoice ? 'block' : 'none',
                    marginBottom: 10,
                }}
            >
                Invoice for: {caseName}
            </Typography>
            <Grid
                item
                xs={12}
                sm={10}
                md={8}
                className={classNames(
                    classes.inner,
                    isNewJerseyContract && classes.fiveCategoryGroups,
                )}
                style={{
                    margin: '0 auto 4px',
                    padding: '8px 0px',
                    borderBottom: !isCondensedView ? '1px solid' : undefined,
                    display: isDownloadingInvoice ? 'flex' : 'none',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    flexDirection: 'column',
                }}
            >
                <Grid
                    item
                    xs={12}
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        width: '100%',
                        minHeight: 160,
                    }}
                >
                    <Grid
                        item
                        style={{
                            textAlign: 'left',
                            maxWidth: 330,
                            height: 80,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            flexDirection: 'row',
                            marginTop: 10,
                            gap: 8,
                            marginLeft: isPrintMode ? undefined : 20,
                            marginRight: isPrintMode ? 'auto' : undefined,
                        }}
                    >
                        <Grid
                            item
                            className={classes.funeralHomeDetails}
                            style={{
                                textAlign: isPrintMode && 'left' || undefined,
                                marginRight: 8,
                            }}
                        >
                            <FuneralHomeLogo
                                logoSize={'large'}
                                className={classNames(classes.displayBlock, classes.margin_0)}
                                style={{
                                    width: 150,
                                    maxWidth: 360,
                                    maxHeight: 80,
                                    objectFit: 'contain',
                                    objectPosition: 'center',
                                }}
                                ignoreTranformationsHeight={isPrintMode}
                            />
                        </Grid>
                        <div style={{
                            fontSize: 12,
                            marginLeft: isPrintMode ? 'auto' : 12,
                            marginRight: isPrintMode ? 'auto' : undefined,
                            textAlign: 'left',
                            color: '#000'
                        }}>
                            {fhName && <><span>{fhName}</span><br /></>}
                            {fhAddress && <><span>{fhAddress} </span><br /></>}
                            {fhCity && <span>{fhCity}, </span>}
                            {fhState && <span>{fhState} </span>}
                            {fhPostalCode && <span>{fhPostalCode}</span>}
                            {fhPhone && <>
                                <br />
                                <span>T: {getFormattedPhoneNumber(fhPhone)}</span>
                            </>}
                        </div>
                    </Grid>

                    <Grid style={{ width: '50%' }}>
                        {renderInvoiceLanguage}
                    </Grid>
                </Grid>

                <Grid
                    container
                    style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                        marginBottom: 20
                    }}
                >
                    <Grid
                        item
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            maxWidth: 360,
                            padding: '0px 0px 8px',
                            marginLeft: 20,
                            justifyContent: 'center',
                            alignContent: 'flex-start',
                            alignItems: 'flex-start',
                            alignSelf: 'flex-start',
                        }}
                    >
                        <Typography
                            style={{
                                ...typographyStyle,
                                fontSize: 16,
                                textAlign: 'left',
                                lineHeight: '20px'
                            }}
                        >
                            {payerName}
                        </Typography>
                        <Typography
                            style={{
                                ...typographyStyle,
                                fontSize: 16,
                                textAlign: 'left',
                                lineHeight: '20px'
                            }}
                        >
                            {address1 && <><span>{address1}</span><br /></>}
                            <span>
                                {city}{state && `${city && ',' || ''} ${state}`}{postalCode && `, ${postalCode}`}
                            </span>
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            {/* Header for Invoice Ends */}

            <Grid
                item
                xs={12}
                sm={10}
                md={8}
                className={classNames(
                    classes.inner,
                    isNewJerseyContract && classes.fiveCategoryGroups,
                )}
                id="overview-header-inner"
                style={{
                    margin: 'auto',
                    padding: '4px 0',
                    borderBottom: !isCondensedView ? '1px solid' : undefined,
                    display: !isDownloadingInvoice ? 'flex' : 'none',
                    alignItems: 'center',
                    justifyContent: isItemizedStatement ? 'center' : 'space-between',
                    flexDirection: isPrintMode && 'row' || undefined,
                }}
            >
                <Grid
                    item={true}
                    className={classes.funeralHomeDetails}
                    style={{
                        marginLeft: isPrintMode && 'auto' || undefined,
                        marginRight: isPrintMode && 'auto' || undefined,
                        textAlign: isPrintMode && 'center' || undefined
                    }}
                >
                    <FuneralHomeLogo
                        logoSize={'large'}
                        style={{
                            maxWidth: 360,
                            maxHeight: 80,
                        }}
                        ignoreTranformationsHeight={isPrintMode}
                    />
                    {isItemizedStatement &&
                        <Typography
                            style={{
                                textAlign: 'center',
                                fontSize: 18,
                                fontWeight: 600,
                            }}>
                            Statement Report for: {caseName}
                        </Typography>
                    }
                </Grid>
                {!isItemizedStatement && getCaseDetails()}

            </Grid>
        </Grid>
    );
};

export default withGStyles(styleWrapper<Props>())(Header);