import classNames from 'classnames';
import { includes } from 'lodash';

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

import { Theme } from '@mui/material/styles';
import { StyleRulesCallback } from '@mui/styles';
import withGStyles, { WithGStyles } from '../../../../styles/WithGStyles';

export type Filter = {
    name: string;
    options: string[];
};

export type FilterCounts = {
    [name: string]: {
        [option: string]: number;
    };
};

export type ActiveFilters = {
    [name: string]: string[];
};

export type SortType = 'desc' | 'asc';
export enum SortTypeEnum {
    desc = 'desc',
    asc = 'asc',
}

interface Props {
    haveActiveFilters: boolean;
    sortType: SortType;
    filters: Filter[];
    activeFilters: ActiveFilters;
    filterCounts: FilterCounts;
    searchText: string;
    resetFilters: () => void;
    onSortTypeChange: (value: SortType) => void;
    onFilterClick: (name: string, option: string) => void;
    onSearchChange: (searchText: string) => void;
}

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {},
    cardContent: {},
    searchField: {
        margin: '16px 0',
        width: 'calc(100% - 48px)'
    },
    sortByContainer: {
        textAlign: 'center',
        width: '100%',
        marginTop: 8
    },
    toggleContainer: {
        height: 56,
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        width: '100%',
    },
    sortTypeToggleBtn: {
        padding: '4px 20px',
        fontSize: '13px !important',
        color: 'rgba(0, 0, 0, 0.54)'
    },
    listContainer: {
        margin: 16,
        padding: 0,
        textAlign: 'left'
    },
    listTitle: {
        textTransform: 'uppercase'
    },
    listItem: {
        padding: '4px 16px',
    },
    itemCheckBox: {
        width: 24,
        height: 24
    },
    itemText: {
        margin: '0 4px',
        padding: 0
    },
    disabledFilter: {
        opacity: 0.5,
    },
});

type StyledProps = Props & WithGStyles<'root' | 'searchField' | 'sortByContainer' | 'listContainer' | 'disabledFilter'
    | 'toggleContainer' | 'sortTypeToggleBtn' | 'listTitle' | 'listItem' | 'itemCheckBox' | 'itemText'>;

const FilterResults = (props: StyledProps) => {

    const {
        classes,
        sortType,
        filters,
        activeFilters,
        filterCounts,
        searchText,
        onSortTypeChange,
        onFilterClick,
        onSearchChange,
        haveActiveFilters,
        resetFilters
    } = props;

    const renderFilters = () => {
        if (filters.length === 0) {
            return null;
        }
        return (
            <ul className={classes.listContainer}>
                {filters.map((filter) =>
                    <Grid key={filter.name} className={classes.marginBottom16}>
                        <Typography className={classes.listTitle}>
                            {filter.name}
                        </Typography>
                        <List dense>
                            {filter.options.map((option) => {
                                const count = filterCounts[filter.name] && filterCounts[filter.name][option] || 0;
                                return (
                                    <ListItem
                                        key={option}
                                        className={classNames(classes.listItem, !count && classes.disabledFilter)}
                                        onClick={count ? () => onFilterClick(filter.name, option) : undefined}
                                    >
                                        <Checkbox
                                            checked={includes(activeFilters[filter.name], option)}
                                            tabIndex={-1}
                                            className={classes.itemCheckBox}
                                            disabled={!count}
                                        />
                                        <ListItemText
                                            className={classes.itemText}
                                            primary={`${option} (${count})`}
                                        />
                                    </ListItem>
                                );
                            })}
                        </List>
                    </Grid>
                )}
            </ul>
        );
    };

    return (
        <div className={classes.root}>

            <Grid item xs={12} className={classes.textCenter}>
                <form
                    noValidate
                    autoComplete="off"
                >
                    <TextField
                        autoComplete="off"
                        className={classes.searchField}
                        fullWidth
                        value={searchText}
                        id="input-with-icon-textfield"
                        placeholder="Start typing to filter list..."
                        name="searchText"
                        onChange={(e) => onSearchChange(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon color="primary" />
                                </InputAdornment>
                            )
                        }}
                    />
                </form>
            </Grid>

            <Grid item className={classes.sortByContainer}>
                <Typography color="textSecondary">
                    Sort Products by Price
                </Typography>
                <Grid
                    container
                    alignItems="flex-end"
                    justifyContent="center"
                    className={classes.toggleContainer}
                >
                    <ToggleButtonGroup
                        value={sortType}
                        exclusive
                        onChange={(e, val: SortType) => onSortTypeChange(val)}
                    >
                        <ToggleButton
                            value={SortTypeEnum.desc}
                            className={classes.sortTypeToggleBtn}
                        >
                            High to low
                        </ToggleButton>
                        <ToggleButton
                            value={SortTypeEnum.asc}
                            className={classes.sortTypeToggleBtn}
                        >
                            Low to high
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
            </Grid>

            {renderFilters()}

            <Grid item xs={12} className={classes.flexCentred}>
                <Button
                    className={classNames(classes.colorRed, classes.margin_8)}
                    disabled={!haveActiveFilters}
                    onClick={e => resetFilters()}
                >
                    <RemoveCircleOutlineIcon />&nbsp;Reset All Filters
                </Button>
            </Grid>
        </div>
    );
};

export default withGStyles(styles)(FilterResults);
