import * as t from 'io-ts';
import { LongAddress, longAddress } from './deathcertificate';
import { PhotoTransformationsType } from './photo';
import { PaymentStatus, PaymentMethod } from './finance';
import {
    DocUXDetail,
    LoadEventsResponse,
    PaginatedResponse,
    getValidator,
    AddressRecord,
    FlowerSalesProductCategory,
    KeepTrackCustomerType,
} from '.';
import {
    FlowerSalesProductCategoryDefinition
} from './flowersales';
import { EntitySummary } from './user';
import { CaseOptions, GatherCasePublic, GatherCaseUX, GatherCasePreview } from './case';
import { AppPermissions } from './permissions';
import { TaskPreview } from './task';
import { DateFromISOString } from 'io-ts-types/lib/DateFromISOString';

/**
 * funeral home util functions
 */

export const getFuneralHomeId = (
    funeralHome: FuneralHomeUX | null,
    gatherCase: GatherCaseUX | null,
    publicCase: GatherCasePublic | null,
): number | null => {
    return funeralHome ? funeralHome.id :
        gatherCase ? gatherCase.funeral_home.id :
            publicCase ? publicCase.funeral_home.id : null;
};


export const getFuneralHomeKey = (
    funeralHome: FuneralHomeUX | null,
    gatherCase: GatherCaseUX | null,
    publicCase: GatherCasePublic | null,
): string | null => {
    return funeralHome ? funeralHome.key :
        gatherCase ? gatherCase.funeral_home.key :
            publicCase ? publicCase.funeral_home.key : null;
};


/**
 * funeralhome types
 */
export type CustomAssetsType = {
    themeColor: string;
};

export enum FH_LogoType {
    defaultLogo = 'defaultLogo',
    themeLogo = 'themeLogo'
}

// ---> FuneralHomeCreateRequest <---
const funeralHomeCreateRequestDefinition = {
    name: t.string,
    long_address: longAddress,
    use_address_description: t.boolean,
    timezone: t.string,
    email: t.string,
    phone: t.string,
    website_url: t.string,
    group_id: t.union([t.number, t.null]),
};

const FuneralHomeCreateRequestType = t.type(funeralHomeCreateRequestDefinition);

export interface FuneralHomeCreateRequest extends t.TypeOf<typeof FuneralHomeCreateRequestType> {
    long_address: LongAddress;
}

export class FuneralHomeCreateRequest {

    public static fromRequest = getValidator<FuneralHomeCreateRequest>(FuneralHomeCreateRequestType);
}

export enum OnboardingStatus {
    PROSPECT = 'prospect',
    ONBOARDING = 'onboarding',
    ADOPTION = 'adoption',
    STEADY_STATE = 'steady_state',
    CANCELED = 'canceled',
}

export const OnboardingStatusDisplayLookup = {
    [OnboardingStatus.PROSPECT]: 'Prospect',
    [OnboardingStatus.ONBOARDING]: 'Onboarding',
    [OnboardingStatus.ADOPTION]: 'Adoption',
    [OnboardingStatus.STEADY_STATE]: 'Steady State',
    [OnboardingStatus.CANCELED]: 'Canceled',
};

export const OnboardingStatusEnumValue = t.keyof({
    [OnboardingStatus.PROSPECT]: null,
    [OnboardingStatus.ONBOARDING]: null,
    [OnboardingStatus.ADOPTION]: null,
    [OnboardingStatus.STEADY_STATE]: null,
    [OnboardingStatus.CANCELED]: null,
});

// ---> FuneralHomeUpdateRequest <---
const funeralHomeUpdateRequestDefinition = {
    name: t.string,
    long_address: longAddress,
    timezone: t.string,
    phone: t.string,
    email: t.string,
    website_url: t.union([t.string, t.null]),
    branch_id: t.union([t.string, t.null]),
    hlc_ext_id: t.union([t.string, t.null]),
    is_demo: t.boolean,
    is_test: t.boolean,
    custom_assets: t.Dictionary,
    video_embed_code: t.union([t.string, t.null]),
    florist_one_affiliate: t.union([t.string, t.null]),
    hidden_flower_categories: t.union([t.array(FlowerSalesProductCategoryDefinition), t.null]),
    is_startup_enabled: t.boolean,
    startup_headline_title: t.union([t.string, t.null]),
    startup_main_message: t.union([t.string, t.null]),
    startup_documents_header: t.union([t.string, t.null]),
    startup_button_text: t.string,
    startup_button_helper_text: t.union([t.string, t.null]),
    use_address_description: t.boolean,
    default_dc_config_id: t.number,
    group_id: t.number,
    started_time: t.union([DateFromISOString, t.null]),
    default_obit_instructions: t.string,
    onboarding_status: OnboardingStatusEnumValue,
};

const FuneralHomeUpdateRequestType = t.partial(funeralHomeUpdateRequestDefinition);

export interface FuneralHomeUpdateRequest extends t.TypeOf<typeof FuneralHomeUpdateRequestType> {
    custom_assets?: CustomAssetsType;
    long_address?: LongAddress;
    hidden_flower_categories?: FlowerSalesProductCategory[] | null;
    is_demo?: boolean;
    onboarding_status?: OnboardingStatus;
}

export class FuneralHomeUpdateRequest {

    public static fromRequest = getValidator<FuneralHomeUpdateRequest>(FuneralHomeUpdateRequestType);
}

// ---> StartupMesage <---
export interface StartupMessage {
    is_startup_enabled: boolean;
    startup_headline_title: string | null;
    startup_main_message: string | null;
    startup_documents_header: string | null;
    startup_button_text: string | null;
    startup_button_helper_text: string | null;
}

// ---> FuneralHomeGroupRecord <---
export interface FuneralHomeGroupRecord {
    id: number;
    created_time: Date;
    created_by: number;
    name: string;
}

export interface FuneralHomeForGroup extends Pick<FuneralHomeRecord,
    | 'id'
    | 'name'
    | 'key'
>, Pick<AddressRecord,
    | 'city'
    | 'state'
> {
    icon: string | null;
    icon_transformations: PhotoTransformationsType | null;
}

export interface FuneralHomeGroupUX extends Pick<FuneralHomeGroupRecord,
    | 'id'
    | 'name'
> {
    funeral_homes: FuneralHomeForGroup[];
}

// ---> FuneralHomeRecord <---
export interface FuneralHomeRecord {
    id: number;
    group_id: number;
    name: string;
    key: string;
    phone: string;
    website_url: string | null;
    branch_id: string | null;
    hlc_ext_id: string | null;
    video_embed_code: string | null;
    photo_view_id: number | null;
    theme_photo_view_id: number | null;
    icon_view_id: number | null;
    theme_icon_view_id: number | null;
    custom_assets: CustomAssetsType;
    address_id: number;
    stripe_customer: string | null;
    stripe_account: string | null;
    stripe_location: string | null;
    ledger_extract_type: string;
    qb_tax_rate: number;
    google_calendar_creds: GoogleCalendarCredentials | null;
    google_calendar_webhook: GoogleCalendarWebhookType | null;
    google_calendar_id: number | null;
    gcal_connect_start_time: Date | null;
    gcal_disconnect_start_time: Date | null;
    is_demo: boolean;
    is_test: boolean;
    started_time: Date | null;
    deleted_time: Date | null;
    florist_one_affiliate: string | null;
    florist_one_facility: number | null;
    florist_1day_lead_time: boolean;
    ext_flower_store_url: string | null;
    hidden_flower_categories: FlowerSalesProductCategory[] | null;
    is_startup_enabled: boolean;
    startup_headline_title: string | null;
    startup_main_message: string | null;
    startup_documents_header: string | null;
    startup_button_text: string | null;
    startup_button_helper_text: string | null;
    default_case_options: CaseOptions;
    email: string;
    default_permissions: AppPermissions;
    default_dc_config_id: number;
    default_obit_instructions: string;
    case_number_template_id: number | null;
    onboarding_status: OnboardingStatus;
}

export enum FuneralHomeLogAction {
    feature_changed = 'feature_changed',
    column_changed = 'column_changed',
}

// ---> FuneralHomeLogRecord <---
export interface FuneralHomeLogRecord {
    id: number;
    funeral_home_id: number;
    action_type: FuneralHomeLogAction;
    feature: FeatureKey | null;
    column_name: keyof FuneralHomeRecord | null; // either feature or column must be set
    old_value: string;
    new_value: string;
    note: string | null;
    action_time: Date;
    action_by: number;
}

type GoogleCalendarWebhookType = {
    resourceId?: string;
    channelId?: string;
    expiration?: Date;
};
// copied from google-auth-library types file
type GoogleCalendarCredentials = {
    refresh_token?: string | null;
    expiry_date?: number | null;
    access_token?: string | null;
    token_type?: string | null;
    id_token?: string | null;
};

// If changing this enum, you may need to update the definitions in the following files:
// api/src/utils/intercom.ts
// api/src/shared/Intercom.type.ts
export enum FeatureKey {
    GOODS_AND_SERVICES = 'GOODS_AND_SERVICES',
    PAYMENTS_CARD_PRESENT = 'PAYMENTS_CARD_PRESENT',
    PAYMENTS_ONLINE = 'PAYMENTS_ONLINE',
    ACCOUNTING = 'ACCOUNTING',
    BATCH_EXTRACT = 'BATCH_EXTRACT',
    PAYMENTS_PAY_REMOTELY = 'PAYMENTS_PAY_REMOTELY',
    DOC_LIBRARY_AND_AUTOFORMS = 'DOC_LIBRARY_AND_AUTOFORMS',
    DOCUMENTS_ESIGN = 'DOCUMENTS_ESIGN',
    PAYMENTS_ACH = 'PAYMENTS_ACH',
    SETUP_CASE_SLIDER = 'SETUP_CASE_SLIDER',
    SETUP_FH_SETTINGS = 'SETUP_FH_SETTINGS',
    SETUP_EXPLAINER_VIDEO = 'SETUP_EXPLAINER_VIDEO',
    SETUP_ONBOARDING_DOCS = 'SETUP_ONBOARDING_DOCS',
    DEMO_ALLOW_CASE_CREATION = 'DEMO_ALLOW_CASE_CREATION',
    DEMO_ALLOW_TEAM_INVITES = 'DEMO_ALLOW_TEAM_INVITES',
    DEMO_ALLOW_FAMILY_INVITES = 'DEMO_ALLOW_FAMILY_INVITES',
    LIVE_STREAM = 'LIVE_STREAM',
    REMEMBER_SECTION = 'REMEMBER_SECTION',
    REMEMBER_FLOWER_LINK = 'REMEMBER_FLOWER_LINK',
    REMEMBER_DOWNLOAD_LIVESTREAM = 'REMEMBER_DOWNLOAD_LIVESTREAM',
    FULL_REMEMBER_PAGE = 'FULL_REMEMBER_PAGE',
    ONE_OFF_TRANSACTIONS = 'ONE_OFF_TRANSACTIONS',
    CONFIGURE_WORKFLOWS = 'CONFIGURE_WORKFLOWS',
    CASE_NOTES = 'CASE_NOTES',
    GATHER_SYNC = 'GATHER_SYNC',
    FUNERAL_ONE_SYNC = 'FUNERAL_ONE_SYNC',
    CFS_SYNC = 'CFS_SYNC',
    FUNERAL_INNOVATIONS_SYNC = 'FUNERAL_INNOVATIONS_SYNC',
    ACCOUNTING_MANUAL_QBO_INTEGRATION = 'ACCOUNTING_MANUAL_QBO_INTEGRATION',
    CASE_MANAGEMENT = 'CASE_MANAGEMENT',
    DATA_MIGRATION = 'DATA_MIGRATION',
    LIVESTREAM_PAY_AS_YOU_GO = 'LIVESTREAM_PAY_AS_YOU_GO',
    LIVESTREAM_LEVEL_PAY = 'LIVESTREAM_LEVEL_PAY',
    PAYMENTS_CREDIT_CARD_READER = 'PAYMENTS_CREDIT_CARD_READER',
    PAYMENTS_REMOTE_PAYMENTS = 'PAYMENTS_REMOTE_PAYMENTS',
    WEBSITE_API_SLIDER_ONLY = 'WEBSITE_API_SLIDER_ONLY',
    WEBSITE_CUSTOM_HOSTED_SITE = 'WEBSITE_CUSTOM_HOSTED_SITE',
    PRINT_MESSENGER_EXPORT = 'PRINT_MESSENGER_EXPORT',
    SLIDESHOWS = 'SLIDESHOWS',
    CONFIGURE_DEATH_CERTIFICATE = 'CONFIGURE_DEATH_CERTIFICATE',
    MEMORY_CANDLE_LIGHTING = 'MEMORY_CANDLE_LIGHTING',
    PUBLIC_GPL = 'PUBLIC_GPL',
    KEEP_TRACK = 'KEEP_TRACK',
    KEEP_TRACK_USES_TRACKERS = 'KEEP_TRACK_USES_TRACKERS',
    KEEP_TRACK_REQUIRE_TRACKER = 'KEEP_TRACK_REQUIRE_TRACKER',
    AFTER_CARE = 'AFTER_CARE',
    QR_STICKER = 'QR_STICKER',
    QR_STICKER_ZEBRA = 'QR_STICKER_ZEBRA',
    PAYMENTS_GUEST_PAYMENTS = 'PAYMENTS_GUEST_PAYMENTS',
    AI_OBIT_BUILDER = 'AI_OBIT_BUILDER',
    PLANT_TREES = 'PLANT_TREES',
    DISPATCH_REPORT = 'DISPATCH_REPORT',
    PREP_REPORT = 'PREP_REPORT',
    GATHER_ACADEMY = 'GATHER_ACADEMY',
    GATHER_ANALYTICS = 'GATHER_ANALYTICS',
    INSURANCE_INTEGRATION = 'INSURANCE_INTEGRATION',
    SUBSCRIPTION_EVENT_REMINDER = 'SUBSCRIPTION_EVENT_REMINDER',
    SUBSCRIPTION_EVENT_SUMMARY = 'SUBSCRIPTION_EVENT_SUMMARY',
    SUBSCRIPTION_NEW_MEMORIES = 'SUBSCRIPTION_NEW_MEMORIES',
}
// If changing the enum above, you may need to update the definitions in the following files:
// api/src/utils/intercom.ts
// api/src/shared/Intercom.type.ts

export const isKeyOfFeatureKey = (key: string): key is FeatureKey => {
    return Boolean(FeatureKey[key]);
};

export type FeatureType = 'standard' | 'setup' | 'demo' | 'app_subscription';

export type Feature = {
    label: string;
    enabled: boolean;
    description: string;
    type: FeatureType;
};
export type Features = Record<FeatureKey, Feature>;

export enum FuneralHomeStatus {
    TEST = 'TEST',
    DEMO = 'DEMO',
    LIVE = 'LIVE',
    PENDING = 'PENDING',
}

// ---> FuneralHomeUXRecord <---
// TODO: JJT (AKT) this should do a Pick<FuneralHomeRecord> instead
export interface FuneralHomeUXRecord extends Omit<FuneralHomeRecord,
    'google_calendar_creds' | 'google_calendar_webhook' | 'default_case_options'
    | 'started_time' | 'default_permissions'> {
    photo: string | null;
    icon: string | null;
    theme_photo: string | null;
    photo_transformations: PhotoTransformationsType | null;
    theme_photo_transformations: PhotoTransformationsType | null;
    icon_transformations: PhotoTransformationsType | null;
    address1: string;
    address2: string | null;
    city: string;
    state: string;
    postal_code: string | null;
    timezone: string | null;
    long_address: LongAddress;
    google_calendar_name: string | null;
    has_google_calendar_creds: boolean;
    use_address_description: boolean;
    group: FuneralHomeGroupUX;
}

// ---> FuneralHomeUX <---
export interface FuneralHomeUX extends FuneralHomeUXRecord {
    startup_documents: DocUXDetail[];
    features: Features;
    demo_settings?: FuneralHomeDemoSettings;
}

export interface FuneralHomeDemoSettings {
    id: number;
    ob_fee: number | null;
    ob_fee_memo: string;
    ob_items: string;
    ob_fee_waived: boolean;
    ob_fee_status: PaymentStatus;
    ob_fee_method: PaymentMethod;
    monthly_fee: number | null;
    est_case_volume: number | null;
    fh_notes: string | null;
    show_get_started: boolean;
    show_rep_message: boolean;
    rep_id: number | null;
    rep_fname: string | null;
    rep_full_name: string | null;
    rep_photo: string | null;
    rep_photo_transformations: PhotoTransformationsType | null;
    rep_phone: string;
    rep_message: string;
    show_ob_message: boolean;
    ob_id: number | null;
    ob_fname: string | null;
    ob_full_name: string | null;
    ob_photo: string | null;
    ob_photo_transformations: PhotoTransformationsType | null;
    ob_phone: string;
    ob_message: string;
    calendly_url: string;
    agreement_id: number;
    agreement_is_master: boolean;
    agreement_content: string;
    agreement_waived: boolean;
    agreement_notes: string;
    agreed_by: number | null;
    agreed_time: Date | null;
    agreed_ip_addr: string | null;
    agreed_user_agent: string | null;
    gatherTeam: EntitySummary[];
}

export interface FuneralHomeForGatherViewRecord extends Pick<FuneralHomeRecord,
    'id' |
    'key' |
    'name' |
    'started_time' |
    'group_id'
>, Pick<AddressRecord,
    'city' |
    'state'
> {
    status: FuneralHomeStatus.TEST | FuneralHomeStatus.DEMO | FuneralHomeStatus.LIVE;
    is_demo: boolean;
    is_test: boolean;
    user_count: number;
    onboarding_status: OnboardingStatus;
}

export interface FuneralHomeForGatherView extends Omit<FuneralHomeForGatherViewRecord, 'status'> {
    status: FuneralHomeStatus;
    is_demo: boolean;
    is_test: boolean;
    onboarding_status: OnboardingStatus;
}

export interface FuneralHomeUXPreviewRecord extends Pick<FuneralHomeUX,
    'id' |
    'key' |
    'name' |
    'long_address' |
    'city' |
    'state' |
    'is_demo' |
    'icon' |
    'icon_transformations' |
    'custom_assets' |
    'default_dc_config_id'
> {
    keepTrackCustomerType: KeepTrackCustomerType;
}

export interface FuneralHomeUXPreview extends FuneralHomeUXPreviewRecord {}

export interface FuneralHomePublic extends Pick<FuneralHomeUX,
    | 'id'
    | 'name'
    | 'key'
    | 'photo'
    | 'photo_transformations'
    | 'theme_photo'
    | 'theme_photo_transformations'
    | 'icon'
    | 'icon_transformations'
    | 'custom_assets'
    | 'timezone'
    | 'address1'
    | 'city'
    | 'state'
    | 'postal_code'
    | 'phone'
    | 'email'
    | 'website_url'
    | 'features'
    | 'long_address'
    | 'ext_flower_store_url'
    | 'hidden_flower_categories'
    | 'florist_1day_lead_time'
> {
}

export const funeralHomeUXToGatherView = (fh: FuneralHomeUX): FuneralHomeForGatherView => ({
    id: fh.id,
    key: fh.key,
    name: fh.name,
    started_time: null,
    group_id: fh.group_id,
    city: fh.city,
    state: fh.state,
    user_count: 0,
    status: FuneralHomeStatus.PENDING,
    is_demo: fh.is_demo,
    is_test: fh.is_test,
    onboarding_status: OnboardingStatus.PROSPECT,
});

export const funeralHomeUXToGatherViewPartial = (fh: Partial<FuneralHomeUX>): Partial<FuneralHomeForGatherView> => ({
    id: fh.id,
    key: fh.key,
    name: fh.name,
    city: fh.city,
    state: fh.state,
    group_id: fh.group_id,
});

export interface LoadCaseSwitcherResponse {
    events: LoadEventsResponse | null;
    casePreviews: PaginatedResponse<GatherCasePreview>;
    taskPreviews: TaskPreview[] | null;
    team: EntitySummary[] | null;
}
