export const GATHER_LOGO = 'system_assets/logos/gather_logo';
export const GATHER_ICON = 'system_assets/logos/gather_icon';
export const EXPORT_GEARS_IMAGE = '/static/images/blue_gear_image.png';
export const EXPORT_BATCHREPORT_IMAGE = 'system_assets/images/batchreport';
export const MEVO_PLUS_IMAGE = 'system_assets/images/mevo_plus';
export const MEVO_PLUS_TRANSMITTING_IMAGE = 'system_assets/images/mevo_plus_transmitting';
export const MEVO_START_IMAGE = 'system_assets/images/mevo_start';
export const MEVO_START_POWER_ON_IMAGE = 'system_assets/images/mevo_start_power_on';
export const MEVO_START_BACK_IMAGE = 'system_assets/images/mevo_start_back';
export const MEVO_START_FRONT_IMAGE = 'system_assets/images/mevo_start_front';
export const IPHONE_CAMERA_IMAGE = 'system_assets/images/iphone_camera';
export const TABLET_CAMERA_IMAGE = 'system_assets/images/tablet_camera';
export const FLOWER_AD_IMAGE = 'system_assets/images/flower_ad';
export const FLOWERS_AND_CARDS_FLOWER_AD_IMAGE = '/static/images/flowers_and_cards_flower_ad.webp';
export const FLOWERS_AND_CARDS_TREE_AD_IMAGE = '/static/images/flowers_and_cards_tree_ad.webp';
export const FLOWER_BACKGROUND_IMAGE = '/static/images/flower-background.jpg';
export const TREE_BACKGROUND_IMAGE = '/static/images/tree-background.jpg';
export const TREE_STORE_BG_IMAGE = '/static/images/tree_store_bg.jpg';
export const TREE_CARD_TREE_IMAGE = '/static/images/tree_card_tree.png';
export const TREE_CARD_TREE_IMAGE_01 = '/static/images/tree_card_tree_01.jpg';
export const TREE_CARD_TREE_IMAGE_02 = '/static/images/tree_card_tree_02.jpg';
export const TREE_CARD_TREE_IMAGE_03 = '/static/images/tree_card_tree_03.jpg';
export const TREE_CARD_TREE_IMAGE_04 = '/static/images/tree_card_tree_04.jpg';
export const TREE_CARD_TREES_IMAGE = '/static/images/tree_card_trees.png';
export const TREE_CARD_BG_IMAGE = '/static/images/tree_card_background.png';
export const ARBOR_DAY_LOGO = '/static/images/arbor_day_logo.png';
export const ARBOR_DAY_LOGO_MINI = '/static/images/arbor_day_logo_mini.png';
export const FLOWER_TREES_IMAGE = 'system_assets/images/Flowers_Trees.jpg';
export const FLOWER_BASKETS_IMAGE = 'system_assets/images/Flowers_Baskets';
export const FLOWER_CROSSES_IMAGE = 'system_assets/images/Flowers_Crosses';
export const FLOWER_HEARTS_IMAGE = 'system_assets/images/Flowers_Hearts';
export const FLOWER_PLANTS_IMAGE = 'system_assets/images/Flowers_Plants';
export const FLOWER_SPRAYS_IMAGE = 'system_assets/images/Flowers_Sprays';
export const FLOWER_TABLES_IMAGE = 'system_assets/images/Flowers_Tables';
export const FLOWER_WREATH_IMAGE = 'system_assets/images/Flowers_Wreath';
export const FLOWER_CASKET_SPRAYS_IMAGE = 'system_assets/images/Flowers_CasketSprays';
export const FLOWER_INSIDE_IMAGE = 'system_assets/images/Flowers_Inside';
export const FLOWER_URNS_IMAGE = 'system_assets/images/Flowers_Urns';
export const HOMESTEADER_LOGO = '/static/images/HomeSteaderLogo.webp';
export const HOMESTEADER_WHITE_LOGO = '/static/images/homeSteadersWhiteLogo.jpeg';
export const HOMESTEADER_LOGO_SMALL = '/static/images/HomeSteaderLogoSmall.png';

export const SET_LOGIN_SUCCESS = 'SET_LOGIN_SUCCESS';
export type SET_LOGIN_SUCCESS = typeof SET_LOGIN_SUCCESS;

export const SET_LOGIN_ERROR = 'SET_LOGIN_ERROR';
export type SET_LOGIN_ERROR = typeof SET_LOGIN_ERROR;

export const USER_LOGGED_IN = 'USER_LOGGED_IN';
export type USER_LOGGED_IN = typeof USER_LOGGED_IN;

export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';
export type USER_LOGGED_OUT = typeof USER_LOGGED_OUT;

export const UPDATE_LOGIN_USER = 'UPDATE_LOGIN_USER';
export type UPDATE_LOGIN_USER = typeof UPDATE_LOGIN_USER;

export const CLEAR_TEMPLATE = 'CLEAR_TEMPLATE';
export type CLEAR_TEMPLATE = typeof CLEAR_TEMPLATE;

export const USER_EMAIL = 'USER_LOGIN';
export type USER_EMAIL = typeof USER_EMAIL;

export const OPEN_INTERCOM = 'OPEN_INTERCOM';
export type OPEN_INTERCOM = typeof OPEN_INTERCOM;

export const SET_PASSWORD_RESET_PENDING = 'SET_PASSWORD_RESET_PENDING';
export type SET_PASSWORD_RESET_PENDING = typeof SET_PASSWORD_RESET_PENDING;

export const SET_PASSWORD_RESET_MESSAGE = 'SET_PASSWORD_RESET_MESSAGE';
export type SET_PASSWORD_RESET_MESSAGE = typeof SET_PASSWORD_RESET_MESSAGE;

export const SET_DESIRED_DESTINATION = 'SET_DESIRED_DESTINATION';
export type SET_DESIRED_DESTINATION = typeof SET_DESIRED_DESTINATION;

export const SET_DEEP_LINK_LOADING = 'SET_DEEP_LINK_LOADING';
export type SET_DEEP_LINK_LOADING = typeof SET_DEEP_LINK_LOADING;

export const SET_DEEP_LINK_INVITATION_STATUS = 'SET_DEEP_LINK_INVITATION_STATUS';
export type SET_DEEP_LINK_INVITATION_STATUS = typeof SET_DEEP_LINK_INVITATION_STATUS;

export const SET_DEEP_LINK_VALUE = 'SET_DEEP_LINK_VALUE';
export type SET_DEEP_LINK_VALUE = typeof SET_DEEP_LINK_VALUE;

export const SET_WHITE_LABEL_LOGO = 'SET_WHITE_LABEL_LOGO';
export type SET_WHITE_LABEL_LOGO = typeof SET_WHITE_LABEL_LOGO;

export const MONTHS = ['January', 'February', 'March', 'April', 'May',
    'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export const MONTH_ABR = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

export const WEEKDAYS_FULL = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
export const WEEKDAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const MOBILE_PATTERN = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

export const DRAWER_WIDTH_UP_SM: number = 320;
export const DRAWER_WIDTH_DOWN_SM: number = 240;

export const MAX_ZEBRA_HEALTH_CHECK_COUNT: number = 10;

export enum AppUrl {
    localhost = 'http://localhost:3000',
    qa = 'https://my.gatherqa.app',
    prod = 'https://my.gather.app',
    branchtest = 'https://branchtest.internal.gather.app:3000',
}

export const KEEP_TRACK_TRADEMARK = <>KeepTrack&trade;</>;

export const NULL_STRING = '___';
export const NULL_NO_ANSWER = 'no answer';

export const TOOLTIPS = {
    ACCESS_DISABLED: 'Please contact your administrator for access',
    DISABLED_FEATURE: 'This option has been disabled. Please contact a funeral home admin to enable.',
    DISABLED_FEATURE_CONTACT_GATHER: 'This option is disabled. Please contact Gather to enable.',
    CREATE_TICKET: `We want Gather to be perfect for you, your team, and your families. 
Clicking here will allow you to create a quick ticket that will be routed instantly
to our team so we can address it for you ASAP. You will be automatically notified as we
make progress and you can view the status of all your tickets by clicking the bottom-right
“Chat with us” button and then selecting the “Tickets” icon at the bottom of the chat window.`,
};

export const POWERED_BY_GATHER = '/static/images/powered_by_gather.png';
export const GATHER_LOGO_SRC = '/static/images/Logo_poweredBy.png';
export const TUKIOS_LOGO_SRC = '/static/images/tukios_transparent.png';
export const ZEBRA_LOGO_SRC = '/static/images/zebra-logo.jpeg';
export const FLOWER_LEFT = '/static/images/flower_left.png';
export const FLOWER_RIGHT = '/static/images/flower_right.png';
export const TREE_LEFT = '/static/images/tree_left.png';
export const TREE_RIGHT = '/static/images/tree_right.png';
export const IPAD_IMAGE = '/static/images/ipad.png';
export const LARIX_RECORD = '/static/images/larix-broadcast.jpg';
export const LARIX_ICON = '/static/images/LarixIcon.png';
export const CANDLE_LIT = '/static/images/lit.png';
export const CANDLE_UNLIT = '/static/images/unlit.png';
export const GOOGLE_LOGO = '/static/images/Google.png';
export const POWERED_BY_STRIPE = '/static/images/powered_by_stripe.png';
export const PAYMENT_METHODS = '/static/images/payment_methods.png';

export const QR_MEDALLION_GOLD = '/static/images/qr_medallion_gold.png';
export const CARD_KEEPSAKE = '/static/images/card_keepsake.png';
export const CARD_TRACKER = '/static/images/card_tracker.png';
export const CREMATORY = '/static/images/crematory.jpeg';
export const COOLER = '/static/images/cooler.jpeg';
export const SIGNATURE = '/static/images/signature.png';
export const QRLABEL = '/static/images/QRLabel.png';

export const KEEPTRACK_GATHER_CLOUD = '/static/images/KeepTrackLogoWhiteOutlineCloud.png';
export const KEEPTRACK_ADVERTISEMENT = '/static/images/KeepTrackAdvert.png';
export const KEEP_TRACK_BADGE = '/static/images/KeepTrackBadge.png';
export const LOGO_KEEP_TRACK = '/static/images/logo-keeptrack.png';
export const ZC_DESKTOP = '/static/images/zc_desktop.png';
export const ZC_MOBILE = '/static/images/zc_mobile.png';
export const KEEP_TRACK_CERT_BADGE = '/static/images/KTCert.png';
export const KEEPTRACK_FLOW_EXTRA = '/static/images/kt-flow-extra.jpg';
export const KT_MARKETING_STAND = '/static/images/marketing-stand.jpg';
export const KT_MEDALLION_STAND = '/static/images/family-medallion-holder.png';
export const KT_BOX = '/static/images/keeptrack-box.png';
export const KT_MEDALLION_TRACKER_SET = '/static/images/medallion-tracker-set.png';
export const KT_TAG_BAND = '/static/images/kt-tag-band.jpg';
export const KT_FINGERPRINT = '/static/images/kt-flow-fingerprint.jpg';
export const KT_ADDITIONAL_CARDS = '/static/images/kt-flow-additional.jpg';
export const ACCOUNTS_TEAM = '/static/images/team.png';
export const ACCOUNTS_TEAM_LARGE = '/static/images/team_large.jpg';
export const PAYMENT_TRUST_BADGE = '/static/images/trust_badge.png';
export const HERO_ICON = '/static/images/icon_hero.png';
export const SUBSCRIPTION_MAIL = '/static/images/subscription_email.png';

// client logos
export const LOGO_CLIENT_LINCOLN = '/static/images/client_logos/logo_client_Lincoln-Family-Funeral-Care.png';
export const LOGO_CLIENT_FAMILY_FIRST = '/static/images/client_logos/logo_client_Family-First-Funeral-Homes.png';
export const LOGO_CLIENT_FIRTION_ADAMS = '/static/images/client_logos/logo_client_Firtion-Adams-Funeral-Service.png';
export const LOGO_CLIENT_GILLIES = '/static/images/client_logos/logo_client_Gillies-Funeral-Chapel.png';
export const LOGO_CLIENT_GRISIER = '/static/images/client_logos/logo_client_Grisier-Funeral-Homes.jpg';
export const LOGO_CLIENT_JENKINS_SOFFE = '/static/images/client_logos/logo_client_Jenkins-Soffe-Funeral-Chapels.png';
export const LOGO_CLIENT_LEWIS_WINT = '/static/images/client_logos/logo_client_Lewis-E-Wint-And-Son.png';
export const LOGO_CLIENT_MCCORKLE = '/static/images/client_logos/logo_client_McCorkle-Funeral-Home.png';
export const LOGO_CLIENT_RONALD = '/static/images/client_logos/logo_client_Ronald-Taylor-Funeral-Homes.png';
export const LOGO_COOP = '/static/images/client_logos/logo_coop.webp';
export const LOGO_DOPKINS = '/static/images/client_logos/logo_dopkins.webp';
export const LOGO_HURT = '/static/images/client_logos/logo_hurt.webp';
export const LOGO_MCGEEHEN = '/static/images/client_logos/logo_mcgeehan.webp';
export const LOGO_MYERS = '/static/images/client_logos/logo_myers_color.png';
export const LOGO_NELSON = '/static/images/client_logos/logo_nelson.webp';
export const LOGO_RAHMA = '/static/images/client_logos/logo_rahma.webp';
export const LOGO_ROSENAU = '/static/images/client_logos/logo_rosenau.webp';
export const LOGO_ZIEREN = '/static/images/client_logos/logo_zieren.webp';

export const MAGNIFYING_GLASS_DESKTOP = '/static/images/magnifyingGlass_desktop.png';
export const MAGNIFYING_GLASS_MOBILE = '/static/images/magnifyingGlass_Mobile_v2.png';
export const BIT_BACKGROUND_LIGHT = '/static/images/01_background.png';
export const GATHER_ACADEMY_SMALL_LOGO = '/static/images/GatherAcademy_small.png';
export const GATHER_ACADEMY_LARGE_LOGO = '/static/images/GatherAcademy_large.png';

// insurance companies
// Homesteaders
export const FILE_CLAIM_URL_HOMESTEADERS = 'https://www.myhomesteaders.com/UIS/PolicyDashboard.aspx?PolicyNum=';
export const LOGO_HOMESTEADERS = '/static/images/insurance_logos/logo_homesteaders_life.webp';
export const SMALL_LOGO_HOMESTEADERS = '/static/images/insurance_logos/hlc-small-logo.svg';

export const EMAIL_IMAGE = '/static/images/email_image.png';
export const LOGO_GATHER_ANALYTICS = '/static/images/logo_GatherAnalytics.png';
