import {
    CaseTaskUX,
    GatherCaseUX,
    CaseTaskCreateRequest,
    CaseTaskUpdateRequest,
    TaskUpdateRequestUX,
    CaseTaskCreateRequestUX,
    TaskState,
    TaskRecord,
    TaskTemplateType,
    CaseTaskRecord,
    ChecklistTaskCompleteRequest,
    CaseTaskSkipRequest,
    TrackingStepMoveRequest,
    TaskType,
    LoadCaseTaskResponse,
    TrackingStepCompleteRequest,
    TrackingStepType,
    LoadInitializeStepResponse,
    CaseTaskVisibilityRequest,
    TaskLocationUX,
    getGatherCaseUuid,
} from '../../shared/types';
import { StoreState } from '../../types';
import { postToAPI, getFromAPI, patchAPI, deleteFromAPI } from '..';
import { registerAppError } from '../errors';
import { setAppSnackbar } from '../AppSnackbar.action';
import { updateTaskNoteInStore, deleteTaskNote } from '../Note.action';
import { loadCaseHelpers } from '../CaseHelper.action';
import { AppDispatch } from '../../store';
import { log } from '../../logger';

export const SET_TASK_SAVING = 'SET_TASK_SAVING';
export type SET_TASK_SAVING = typeof SET_TASK_SAVING;

interface SetTaskSaving {
    type: SET_TASK_SAVING;
    isSaving: boolean;
}

function setTaskSaving(isSaving: boolean): SetTaskSaving {
    return {
        type: SET_TASK_SAVING,
        isSaving,
    };
}

export const ADD_TASK = 'ADD_TASK';

interface AddTask {
    type: typeof ADD_TASK;
    task: CaseTaskUX;
}

function addTaskInStore(task: CaseTaskUX): AddTask {
    return {
        type: ADD_TASK,
        task,
    };
}

export function createTask(taskRequest: CaseTaskCreateRequestUX, gatherCase: GatherCaseUX) {
    return async (dispatch: AppDispatch): Promise<CaseTaskUX | null> => {
        const task: CaseTaskCreateRequest = {
            ...taskRequest,
            assigned_to: taskRequest.assigned_to.map((assignee) => assignee.user_id),
        };

        try {
            CaseTaskCreateRequest.fromRequest(task);
        } catch (ex) {
            log.warn('Failed to validate TaskCreateRequest:', { task, ex });
            return null;
        }

        dispatch(setTaskSaving(true));
        const savedTask = await postToAPI<CaseTaskUX>(`api/case/${gatherCase.uuid}/task`, { task }, dispatch);
        dispatch(setTaskSaving(false));
        if (!savedTask) {
            dispatch(registerAppError('Unable to add task.'));
            return null;
        } else {
            dispatch(addTaskInStore(savedTask));
            return savedTask;
        }
    };
}

export function createTaskFromTaskTemplate(templateTaskId: number, caseUuid: string) {
    return async (dispatch: AppDispatch): Promise<CaseTaskUX[] | null> => {
        dispatch(setTaskSaving(true));
        const resource = `api/case/${caseUuid}/task/tasktemplate/${templateTaskId}`;
        const updatedTasks = await postToAPI<CaseTaskUX[]>(resource, {}, dispatch);
        dispatch(setTaskSaving(false));
        if (!updatedTasks) {
            dispatch(registerAppError('Unable to add task.'));
            return null;
        } else {
            dispatch(tasksLoaded(updatedTasks));
            return updatedTasks;
        }
    };
}

export const CREATING_MOVE_STEP = 'CREATING_MOVE_STEP';

interface CreatingMoveStep {
    type: typeof CREATING_MOVE_STEP;
    caseUuid: string;
    taskLocation: TaskLocationUX;
}

function creatingMoveStep(params: {
    caseUuid: string;
    taskLocation: TaskLocationUX;
}): CreatingMoveStep {
    return {
        type: CREATING_MOVE_STEP,
        ...params,
    };
}

export function createMoveTrackingStep(params: {
    moveRequest: TrackingStepMoveRequest;
    taskLocation: TaskLocationUX;
    caseUuid: string;
}) {
    const { moveRequest, taskLocation, caseUuid } = params;
    return async (dispatch: AppDispatch): Promise<CaseTaskUX | null> => {
        try {
            TrackingStepMoveRequest.fromRequest(moveRequest);
        } catch (ex) {
            log.warn('Failed to validate TrackingStepMoveRequest', { moveRequest, ex });
            return null;
        }

        dispatch(creatingMoveStep({ caseUuid, taskLocation }));

        const url = `api/case/${caseUuid}/task/move`;
        const savedTask = await postToAPI<CaseTaskUX>(url, { moveRequest }, dispatch);
        dispatch(setTaskSaving(false));
        if (!savedTask) {
            dispatch(registerAppError('Unable to move locations.'));
            return null;
        } else {
            dispatch(addTaskInStore(savedTask));
            return savedTask;
        }
    };
}

export const UPDATE_TASK = 'UPDATE_TASK';
export type UPDATE_TASK = typeof UPDATE_TASK;

interface UpdateTask {
    type: UPDATE_TASK;
    taskChanges: Partial<CaseTaskUX>;
    taskId: number;
    caseUuid: string;
    taskTemplateType: TaskTemplateType | null;
    trackingStepType: TrackingStepType | null;
}

export function updateTaskInStore(params: Omit<UpdateTask, 'type'>): UpdateTask {
    return {
        ...params,
        type: UPDATE_TASK,
    };
}

export const BULK_UPDATE_CHECKLIST_TASKS = 'BULK_UPDATE_CHECKLIST_TASKS';
export type BULK_UPDATE_CHECKLIST_TASKS = typeof BULK_UPDATE_CHECKLIST_TASKS;

interface UpdateTasks {
    type: BULK_UPDATE_CHECKLIST_TASKS;
    taskChanges: TaskUpdateRequestUX;
    tasks: BulkTask[];
}

function bulkUpdateChecklistTasks(taskChanges: TaskUpdateRequestUX, tasks: BulkTask[]): UpdateTasks {
    return {
        type: BULK_UPDATE_CHECKLIST_TASKS,
        taskChanges,
        tasks,
    };
}

export function updateTask(
    taskId: number,
    taskChanges: TaskUpdateRequestUX,
    caseUuid: string,
    taskTemplateType: TaskTemplateType | null,
    trackingStepType?: TrackingStepType | null,
) {
    return async (dispatch: AppDispatch, getState: () => StoreState): Promise<CaseTaskUX | null> => {
        const { userSession } = getState();

        const { assigned_to, ...rest } = taskChanges;
        const task: CaseTaskUpdateRequest = {
            ...rest,
        };
        if (assigned_to) {
            task.assigned_to = assigned_to.map((assignee) => assignee.user_id);
        }

        try {
            CaseTaskUpdateRequest.fromRequest(task);
        } catch (ex) {
            log.warn('Failed to validate TaskUpdateRequest', { task, ex });
            return null;
        }

        if (task.note === null) {
            taskChanges.note_updated_by = null;
            taskChanges.note_updated_time = null;
        } else if (task.note?.trim() && userSession.userData) {
            taskChanges.note_updated_by = userSession.userData.id;
            taskChanges.note_updated_time = new Date();
        }

        if (task.assigned_to_all) {
            taskChanges.assigned_to_all_by = userSession.userData && userSession.userData.id;
            taskChanges.assigned_to_all_time = new Date();
            taskChanges.assigned_to_all_by_fname = userSession.userData && userSession.userData.fname;
        }
        if (task.assigned_to_all === false) {
            taskChanges.assigned_to_all_by = null;
            taskChanges.assigned_to_all_time = null;
            taskChanges.assigned_to_all_by_fname = null;
        }

        dispatch(updateTaskInStore({
            taskChanges,
            taskId,
            caseUuid,
            taskTemplateType,
            trackingStepType: trackingStepType ?? null,
        }));

        dispatch(setTaskSaving(true));
        const route = `api/case/${caseUuid}/task/${taskId}`;
        const updatedTask = await patchAPI<CaseTaskUX>(route, { task }, dispatch);
        dispatch(setTaskSaving(false));
        if (!updatedTask) {
            dispatch(registerAppError('Unable to update task.'));
            return null;
        } else {
            dispatch(updateTaskNoteInStore(updatedTask, caseUuid));
            dispatch(loadCaseHelpers(caseUuid));
            return updatedTask;
        }
    };
}

export function updateStepVisibility(params: {
    stepId: number;
    visibleToFamily: boolean;
    caseUuid: string;
}) {
    return async (dispatch: AppDispatch): Promise<CaseTaskUX | null> => {
        const { stepId, visibleToFamily, caseUuid } = params;
        const taskRequest: CaseTaskVisibilityRequest = {
            visible_to_family: visibleToFamily,
        };

        try {
            CaseTaskVisibilityRequest.fromRequest(taskRequest);
        } catch (ex) {
            log.warn('Failed to validate CaseTaskVisibilityRequest', { taskRequest, ex });
            return null;
        }

        dispatch(updateTaskInStore({
            taskChanges: taskRequest,
            taskId: stepId,
            caseUuid,
            taskTemplateType: null,
            trackingStepType: null,
        }));

        dispatch(setTaskSaving(true));
        const route = `api/case/${caseUuid}/task/${stepId}/visibility`;
        const updatedTask = await patchAPI<CaseTaskUX>(route, { task: taskRequest }, dispatch);
        dispatch(setTaskSaving(false));
        if (!updatedTask) {
            dispatch(registerAppError('Unable to update step visibility.'));
            return null;
        } else {
            return updatedTask;
        }
    };
}


// ----> Update Task Ranks <----
export const UPDATED_TASK_RANKS = 'UPDATED_TASK_RANKS';

interface UpdatedTaskRanks {
    type: typeof UPDATED_TASK_RANKS;
    updatedTasks: CaseTaskUX[];
    taskType: TaskType;
}

function updatedTaskRanks(updatedTasks: CaseTaskUX[], taskType: TaskType): UpdatedTaskRanks {
    return {
        type: UPDATED_TASK_RANKS,
        updatedTasks,
        taskType,
    };
}

export function updateTaskRanks(
    tasks: CaseTaskUX[],
    existingTasks: CaseTaskUX[],
    gatherCase: GatherCaseUX,
    type: TaskType,
) {
    return async (dispatch: AppDispatch): Promise<CaseTaskUX[] | null> => {

        const taskIds = tasks.map((t) => t.id);
        const route = `api/case/${gatherCase.uuid}/task/rank`;
        dispatch(updatedTaskRanks(tasks, type));
        const updatedTasks = await postToAPI<CaseTaskUX[]>(route, { taskIds }, dispatch);
        if (updatedTasks) {
            dispatch(tasksLoaded(updatedTasks));
            return updatedTasks;
        } else {
            // rollback changes
            dispatch(updatedTaskRanks(existingTasks, type));
            dispatch(registerAppError('Unable to update task order.'));
            return null;
        }
    };
}

export function skipTask(params: {
    taskId: number;
    caseUuid: string;
    taskTemplateType: TaskTemplateType | null;
    skipRequest: CaseTaskSkipRequest;
    trackingStepType?: TrackingStepType | null;
}) {
    return async (dispatch: AppDispatch, getState: () => StoreState): Promise<CaseTaskUX[] | null> => {
        const { taskId, caseUuid, taskTemplateType, skipRequest, trackingStepType } = params;
        const { userSession, casesState } = getState();
        const { userData } = userSession;
        if (!userData) {
            log.warn('No user data for skipTask!');
            return null;
        }

        const currentCaseUuid = getGatherCaseUuid(casesState.selectedCase, casesState.publicCase);

        try {
            CaseTaskSkipRequest.fromRequest(skipRequest);
        } catch (ex) {
            log.warn('Failed to validate CaseTaskSkipRequest:', { skipRequest, ex });
            return null;
        }

        const taskChanges: Partial<CaseTaskUX> = {
            skipped_by: userData,
            skipped_time: new Date(),
            note_updated_by: userData.id,
            note_updated_time: new Date(),
            note_updated_by_user: { ...userData, time: new Date() },
            marked_complete_by: null,
            marked_complete_time: null,
            state: TaskState.skipped,
            note: skipRequest.note,
        };

        dispatch(updateTaskInStore({
            taskChanges,
            taskId,
            caseUuid,
            taskTemplateType,
            trackingStepType: trackingStepType ?? null,
        }));
        dispatch(setTaskSaving(true));
        const route = `api/case/${caseUuid}/task/${taskId}/skip`;
        const updatedTasks = await postToAPI<CaseTaskUX[]>(route, { skipRequest }, dispatch);
        dispatch(setTaskSaving(false));
        if (!updatedTasks) {
            dispatch(registerAppError('Unable to skip task.'));
            return null;
        } else {
            if (!currentCaseUuid || currentCaseUuid === caseUuid) {
                dispatch(tasksLoaded(updatedTasks));
            }
            return updatedTasks;
        }
    };
}

function completeTask(params: {
    route: string;
    taskId: number;
    caseUuid: string;
    taskTemplateType: TaskTemplateType | null;
    trackingStepType: TrackingStepType | null;
    completeRequest: ChecklistTaskCompleteRequest | TrackingStepCompleteRequest;
}) {
    return async (dispatch: AppDispatch, getState: () => StoreState): Promise<CaseTaskUX[] | null> => {
        const { route, taskId, caseUuid, taskTemplateType, completeRequest, trackingStepType } = params;
        const { userSession, casesState } = getState();
        const { userData } = userSession;
        if (!userData) {
            log.warn('No user data for completeTask!');
            return null;
        }

        const currentCaseUuid = getGatherCaseUuid(casesState.selectedCase, casesState.publicCase);

        const taskChanges: Partial<CaseTaskUX> = {
            ...completeRequest,
            performed_by: userData,
            marked_complete_by: userData,
            marked_complete_time: new Date(),
            note_updated_by: userData.id,
            note_updated_time: new Date(),
            note_updated_by_user: { ...userData, time: new Date() },
            skipped_by: null,
            skipped_time: null,
            state: TaskState.complete,
        };

        dispatch(updateTaskInStore({
            taskChanges,
            taskId,
            caseUuid,
            taskTemplateType,
            trackingStepType: trackingStepType ?? null,
        }));
        dispatch(setTaskSaving(true));

        const updatedTasks = await postToAPI<CaseTaskUX[]>(route, { completeRequest }, dispatch);

        dispatch(setTaskSaving(false));
        if (!updatedTasks) {
            const taskUnChanges: Partial<CaseTaskUX> = {
                ...completeRequest,
                performed_by: null,
                marked_complete_by: null,
                marked_complete_time: null,
                note_updated_by: null,
                note_updated_time: null,
                skipped_by: null,
                skipped_time: null,
                state: TaskState.incomplete,
            };
            dispatch(updateTaskInStore({
                taskChanges: taskUnChanges,
                taskId,
                caseUuid,
                taskTemplateType,
                trackingStepType: trackingStepType ?? null,
            }));

            dispatch(registerAppError('Unable to complete task.'));
            return null;
        } else {
            if (!currentCaseUuid || currentCaseUuid === caseUuid) {
                dispatch(tasksLoaded(updatedTasks));
            }
            return updatedTasks;
        }
    };
}

export function completeChecklistTask(params: {
    taskId: number;
    caseUuid: string;
    taskTemplateType: TaskTemplateType | null;
    completeRequest: ChecklistTaskCompleteRequest;
}) {
    return async (dispatch: AppDispatch): Promise<CaseTaskUX[] | null> => {
        const { caseUuid, taskId, completeRequest } = params;

        try {
            ChecklistTaskCompleteRequest.fromRequest(completeRequest);
        } catch (ex) {
            log.warn('Failed to validate ChecklistTaskCompleteRequest:', { completeRequest, ex });
            return null;
        }

        return dispatch(completeTask({
            ...params,
            route: `api/case/${caseUuid}/task/${taskId}/completetask`,
            trackingStepType: null,
        }));
    };
}

export function completeTrackingStep(params: {
    taskId: number;
    caseUuid: string;
    completeRequest: TrackingStepCompleteRequest;
    trackingStepType: TrackingStepType | null;
}) {
    return async (dispatch: AppDispatch): Promise<CaseTaskUX[] | null> => {
        const { caseUuid, taskId, completeRequest } = params;

        try {
            TrackingStepCompleteRequest.fromRequest(completeRequest);
        } catch (ex) {
            log.warn('Failed to validate TrackingStepCompleteRequest', { completeRequest, ex });
            return null;
        }

        return dispatch(completeTask({
            ...params,
            route: `api/case/${caseUuid}/task/${taskId}/completestep`,
            taskTemplateType: null,
        }));
    };
}

export function uncompleteTask(
    taskId: number,
    caseUuid: string,
    taskTemplateType: TaskTemplateType | null,
    trackingStepType: TrackingStepType | null,
) {
    return async (dispatch: AppDispatch): Promise<CaseTaskUX | null> => {

        const taskChanges: Partial<CaseTaskUX> = {
            marked_complete_by: null,
            marked_complete_time: null,
            skipped_by: null,
            skipped_time: null,
            state: TaskState.incomplete,
        };

        dispatch(updateTaskInStore({
            taskChanges,
            taskId,
            caseUuid,
            taskTemplateType,
            trackingStepType,
        }));
        dispatch(setTaskSaving(true));
        const route = `api/case/${caseUuid}/task/${taskId}/uncomplete`;
        const updatedTask = await postToAPI<CaseTaskUX>(route, {}, dispatch);
        dispatch(setTaskSaving(false));
        if (!updatedTask) {
            dispatch(registerAppError('Unable to uncomplete task.'));
            return null;
        } else {
            return updatedTask;
        }
    };
}

export type BulkTask = { id: number; template: TaskTemplateType | null };
export function bulkSkipTasks(tasks: BulkTask[], gatherCase: GatherCaseUX, note?: string | null) {
    return async (dispatch: AppDispatch, getState: () => StoreState): Promise<CaseTaskRecord[] | null> => {
        const { userSession } = getState();
        const { userData } = userSession;
        if (!userData) {
            log.warn('No user data for skipTasks!');
            return null;
        }

        const taskChanges: Partial<CaseTaskUX> = {
            skipped_by: userData,
            skipped_time: new Date(),
            marked_complete_by: null,
            marked_complete_time: null,
            state: TaskState.skipped,
        };
        if (note !== undefined) {
            taskChanges.note = note;
        }

        dispatch(bulkUpdateChecklistTasks(taskChanges, tasks));

        dispatch(setTaskSaving(true));
        const route = `api/case/${gatherCase.uuid}/task/skip`;
        const updatedTask = await postToAPI<CaseTaskRecord[]>(
            route,
            { taskIds: tasks.map(t => t.id) },
            dispatch);
        dispatch(setTaskSaving(false));
        if (!updatedTask) {
            dispatch(registerAppError('Unable to skip task(s)'));
            return null;
        } else {
            return updatedTask;
        }
    };
}

export function bulkCompleteTasks(tasks: BulkTask[], gatherCase: GatherCaseUX) {
    return async (dispatch: AppDispatch, getState: () => StoreState): Promise<CaseTaskRecord[] | null> => {
        const { userSession } = getState();
        const { userData } = userSession;
        if (!userData) {
            log.warn('No user data for completeTasks!');
            return null;
        }

        const taskChanges: Partial<CaseTaskUX> = {
            marked_complete_by: userData,
            marked_complete_time: new Date(),
            skipped_by: null,
            skipped_time: null,
            state: TaskState.complete,
        };

        dispatch(bulkUpdateChecklistTasks(taskChanges, tasks));

        dispatch(setTaskSaving(true));
        const route = `api/case/${gatherCase.uuid}/task/complete`;
        const updatedTask = await postToAPI<CaseTaskRecord[]>(
            route,
            { taskIds: tasks.map(t => t.id) },
            dispatch
        );
        dispatch(setTaskSaving(false));
        if (!updatedTask) {
            dispatch(registerAppError('Unable to complete task(s)'));
            return null;
        } else {
            return updatedTask;
        }
    };
}

export function bulkUncompleteTasks(tasks: BulkTask[], gatherCase: GatherCaseUX) {
    return async (dispatch: AppDispatch): Promise<CaseTaskRecord[] | null> => {
        const taskChanges: Partial<CaseTaskUX> = {
            marked_complete_by: null,
            marked_complete_time: null,
            skipped_by: null,
            skipped_time: null,
            state: TaskState.incomplete,
        };

        dispatch(bulkUpdateChecklistTasks(taskChanges, tasks));
        dispatch(setTaskSaving(true));
        const route = `api/case/${gatherCase.uuid}/task/uncomplete`;
        const updatedTasks = await postToAPI<CaseTaskRecord[]>(
            route,
            { taskIds: tasks.map(t => t.id) },
            dispatch);
        dispatch(setTaskSaving(false));
        if (!updatedTasks) {
            dispatch(registerAppError('Unable to uncomplete task(s).'));
            return null;
        } else {
            return updatedTasks;
        }
    };
}

export const DELETE_TASK = 'DELETE_TASK';

interface DeleteTask {
    type: typeof DELETE_TASK;
    taskId: number;
}

function removeTask(taskId: number): DeleteTask {
    return {
        type: DELETE_TASK,
        taskId,
    };
}

export function deleteTask(task: CaseTaskUX, gatherCase: GatherCaseUX) {
    return async (dispatch: AppDispatch): Promise<TaskRecord | null> => {
        dispatch(removeTask(task.id));

        dispatch(setTaskSaving(true));
        const route = `api/case/${gatherCase.uuid}/task/${task.id}`;
        const deletedTask = await deleteFromAPI<TaskRecord>(route, dispatch);
        dispatch(setTaskSaving(false));
        if (!deletedTask) {
            dispatch(registerAppError('Unable to remove task.'));
            dispatch(addTaskInStore(task));
        }
        dispatch(deleteTaskNote(task.id));
        return deletedTask;
    };
}

export const SET_TASKS_LOADING = 'SET_TASKS_LOADING';

interface SetTasksLoading {
    type: typeof SET_TASKS_LOADING;
    isLoading: boolean;
}

function setTasksLoading(isLoading: boolean): SetTasksLoading {
    return {
        type: SET_TASKS_LOADING,
        isLoading: isLoading,
    };
}

export const TASKS_LOADED = 'TASKS_LOADED';

interface TasksLoaded {
    type: typeof TASKS_LOADED;
    tasks: CaseTaskUX[];
}

function tasksLoaded(tasks: CaseTaskUX[]): TasksLoaded {
    return {
        type: TASKS_LOADED,
        tasks,
    };
}

export function loadCaseTasksWithExistingCaseData(gatherCase: GatherCaseUX | null) {
    return async (dispatch: AppDispatch) => {
        if (!gatherCase) {
            return null;
        }
        dispatch(setTasksLoading(true));
        const loadedTasks = await getFromAPI<CaseTaskUX[]>(`api/case/${gatherCase.uuid}/task/`, dispatch);
        dispatch(setTasksLoading(false));
        if (loadedTasks !== null) {
            dispatch(tasksLoaded(loadedTasks));
            return loadedTasks;
        } else {
            dispatch(registerAppError('Unable to load tasks.'));
            return null;
        }
    };
}

export const TASK_LOADED = 'TASK_LOADED';

interface TaskLoaded extends LoadCaseTaskResponse {
    type: typeof TASK_LOADED;
}

function taskLoaded(response: LoadCaseTaskResponse): TaskLoaded {
    return {
        type: TASK_LOADED,
        ...response
    };
}

export function loadTrackingStep(params: {
    taskId: number;
    caseUuid: string;
}) {
    return async (dispatch: AppDispatch) => {
        const { caseUuid, taskId } = params;

        const loadResponse = await getFromAPI<LoadCaseTaskResponse>(`api/case/${caseUuid}/task/${taskId}`, dispatch);
        if (loadResponse) {
            dispatch(taskLoaded(loadResponse));
            return loadResponse;
        } else {
            dispatch(registerAppError('Unable to load tracking step.'));
            return null;
        }
    };
}

export const INITIALIZE_STEP_LOADED = 'INITIALIZE_STEP_LOADED';

interface InitializeStepLoaded extends LoadInitializeStepResponse {
    type: typeof INITIALIZE_STEP_LOADED;
}

function initializeStepLoaded(response: LoadInitializeStepResponse): InitializeStepLoaded {
    return {
        type: INITIALIZE_STEP_LOADED,
        ...response
    };
}

export function loadInitializeStepForCase(params: {
    caseUuid: string;
}) {
    return async (dispatch: AppDispatch) => {
        const { caseUuid } = params;

        const resource = `api/case/${caseUuid}/task/initialize`;
        const loadResponse = await getFromAPI<LoadInitializeStepResponse>(resource, dispatch);
        if (loadResponse) {
            dispatch(initializeStepLoaded(loadResponse));
            return loadResponse;
        } else {
            return null;
        }
    };
}

export const SENDING_TASK_REMINDER = 'SENDING_TASK_REMINDER';
export type SENDING_TASK_REMINDER = typeof SENDING_TASK_REMINDER;

interface SendTaskReminder {
    type: SENDING_TASK_REMINDER;
    sendingTaskReminder: boolean;
}

export function setSendingTaskReminder(sendingTaskReminder: boolean): SendTaskReminder {
    return {
        type: SENDING_TASK_REMINDER,
        sendingTaskReminder,
    };
}

export type SendTaskReminderParameters = Parameters<typeof sendTaskReminder>;
export function sendTaskReminder(taskId: number, caseUuid: string) {
    return async (dispatch: AppDispatch, getState: () => StoreState) => {
        dispatch(setSendingTaskReminder(true));
        const resource = `api/case/${caseUuid}/task/${taskId}/reminder`;
        const response = await postToAPI<{ message: string }>(resource, {}, dispatch);
        dispatch(setSendingTaskReminder(false));
        if (!response) {
            dispatch(registerAppError('Unable to send reminder.'));
        } else {
            const { tasksState } = getState();
            const { checklistTasks, trackingSteps } = tasksState;
            const checklistTask = checklistTasks.find((t) => t.id === taskId);
            const trackingStep = trackingSteps.find((t) => t.id === taskId);
            const activeTask = checklistTask || trackingStep;

            const message: string = activeTask
                ? activeTask.assigned_to.length === 1
                    ? activeTask.assigned_to[0].fname
                    : activeTask.assigned_to.length === 0
                        ? 'everyone'
                        : `${activeTask.assigned_to.length} people`
                : '';
            dispatch(setAppSnackbar(`Reminder successfully sent to ${message}`, 'success'));
        }
    };
}

export type TaskAction = SetTaskSaving
    | AddTask
    | TasksLoaded
    | TaskLoaded
    | InitializeStepLoaded
    | UpdateTask
    | UpdateTasks
    | SetTasksLoading
    | SendTaskReminder
    | DeleteTask
    | UpdatedTaskRanks
    | CreatingMoveStep
    ;
