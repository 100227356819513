import * as t from 'io-ts';
import { DateFromISOString } from 'io-ts-types/lib/DateFromISOString';
import { StreamDeviceType, PlaybackURLs, StreamAssetSummary } from './live_stream';
import { getValidator } from '.';

export enum EventType {
    arrangement = 'arrangement',
    custom = 'custom'
}

const EventTypeDefinition = t.union([
    t.literal(EventType.arrangement),
    t.literal(EventType.custom),
]);

// ---> GatherEventRecord <---
export interface GatherEventRecord {
    id: number;
    name: string;
    description: string | null;
    start_time: Date;
    end_time: Date;
    gather_case_id: number;
    location_id: number | null;
    event_type: EventType;
    visible_to_family: boolean;
    editable_by_family: boolean;
    created_by: number | null;
    created_time: Date;
    updated_by: number | null;
    updated_time: Date;
    stream_device_id: number | null;
    is_streamable: boolean;
    broadcast_start_time: Date | null;
    broadcast_end_time: Date | null;
    message: string | null;
    gcal_event_id: string | null;
    gcal_created_time: Date | null;
    gcal_create_attempt_time: Date | null;
    is_private: boolean;
    sent_broadcast_interrupted: Date | null;
    sent_livestream_reminder: Date | null;
    sent_livestream_not_started: Date | null;
    email_summary_daybefore_sent_time: Date | null;
    email_service_reminder_sent_time: Date | null; 
    sms_summary_daybefore_sent_time: Date | null;
    sms_service_reminder_sent_time: Date | null;
}

// ---> EventCreateRequest <---
const eventCreateRequest = {
    name: t.string,
    description: t.union([t.string, t.null]),
    editable_by_family: t.boolean,
    location_id: t.union([t.number, t.null]),
    event_type: EventTypeDefinition,
    start_time: DateFromISOString,
    end_time: DateFromISOString,
    is_streamable: t.boolean,
    message: t.union([t.string, t.null]),
    is_private: t.boolean,
};
const EventCreateRequestType = t.type(eventCreateRequest);

export interface EventCreateRequest extends t.TypeOf<typeof EventCreateRequestType> {
    event_type: EventType;
    start_time: Date;
    end_time: Date;
}

export class EventCreateRequest {
    public static fromRequest = getValidator<EventCreateRequest>(EventCreateRequestType);
}

// ---> EventUpdateRequest <---
const EventUpdateRequestType = t.partial(eventCreateRequest);

export interface EventUpdateRequest extends t.TypeOf<typeof EventUpdateRequestType> {
    event_type?: EventType;
    start_time?: Date;
    end_time?: Date;
}

export class EventUpdateRequest {
    public static fromRequest = getValidator<EventUpdateRequest>(EventUpdateRequestType);
}

// ---> GatherEventUXRecord <---
export interface GatherEventUXRecord extends Pick<GatherEventRecord,
    | 'id'
    | 'name'
    | 'description'
    | 'gather_case_id'
    | 'location_id'
    | 'event_type'
    | 'start_time'
    | 'end_time'
    | 'created_by'
    | 'created_time'
    | 'updated_by'
    | 'updated_time'
    | 'visible_to_family'
    | 'editable_by_family'
    | 'gcal_event_id'
    | 'gcal_created_time'
    | 'gcal_create_attempt_time'
    | 'stream_device_id'
    | 'is_streamable'
    | 'is_private'
    | 'sent_broadcast_interrupted'
    | 'sent_livestream_reminder'
    | 'sent_livestream_not_started'
    | 'broadcast_start_time'
    | 'broadcast_end_time'
    | 'message'
    | 'email_summary_daybefore_sent_time'
    | 'email_service_reminder_sent_time'
    | 'sms_summary_daybefore_sent_time'
    | 'sms_service_reminder_sent_time'
> {
    case_fname: string;
    case_lname: string;
    case_name: string;
    case_assignee_full_name: string;
    funeral_home_id: number;
    funeral_home_name: string;
    funeral_home_key: string;
    location_name: string | null;
    address1: string | null;
    address2: string | null;
    city: string | null;
    state: string | null;
    country: string | null;
    postal_code: string | null;
    timezone: string;
    is_location_saved: boolean;
}

// ---> GatherEvent <---
export interface GatherEvent extends GatherEventUXRecord {

}

export class GatherEvent {
    public static isGatherEvent(event: GoogleEvent | GatherEvent): event is GatherEvent {
        return event.hasOwnProperty('gather_case_id');
    }
}

// ---> GatherEventWithPlayback <---
export interface GatherEventIdWithPlaybackRecord {
    id: number;
    is_broadcast_live: boolean;
    is_broadcast_complete: boolean;
    is_broadcast_interrupted: boolean;
    playback_urls: {
        live: string[];
        replay: StreamAssetSummary[];
    };
}

export interface GatherEventWithPlayback extends GatherEvent, Omit<GatherEventIdWithPlaybackRecord, 'playback_urls'> {
    playback_urls: PlaybackURLs;
}

// ---> GatherEventWithPlayback <---
export interface GatherEventPublic {
    id: number;
    name: string;
    description: string | null;
    start_time: Date;
    end_time: Date;
    event_type: EventType;
    location_id: number | null;
}

export interface GatherEventForGoogleSync {
    id: number;
    name: string;
    description: string | null;
    start_time: Date;
    end_time: Date;
    location_id: number | null;
    gather_case_id: number;
    funeral_home_id: number;
}

interface BaseGoogleEvent {
    id: number;
    name: string;
    description: string | null;
    location: string | null;
}

// ---> GoogleEventRecord <---
// start_date/end_date are string because they represent all-day events in YYYY-MM-DD format.
//   This is because all-day events should be timezone agnostic.
// start_datetime/end_datetime should be populated otherwise with a specific TZ-specific date and time.
export interface GoogleEventRecord extends BaseGoogleEvent {
    google_calendar_id: number;
    google_id: string;
    start_datetime: Date | null;
    end_datetime: Date | null;
    start_date: string | null;
    end_date: string | null;
    created_time: Date;
    updated_time: Date;
    sync_time: Date;
    url_to_event_in_gcal: string | null;
}

// ---> GoogleEvent <---
export interface GoogleEvent extends BaseGoogleEvent {
    start_time: Date | null;
    end_time: Date | null;
    is_all_day: boolean;
    url_to_event_in_gcal: string | null;
}

export class GoogleEvent {
    public static stripRecordFields(record: GoogleEventRecord): GoogleEvent {
        const start = record.start_date || record.start_datetime;
        const end = record.end_date || record.end_datetime;
        return {
            id: record.id,
            name: record.name,
            description: record.description,
            location: record.location,
            is_all_day: record.start_date !== null && record.start_date !== '',
            start_time: start ? new Date(start) : null,
            end_time: end ? new Date(end) : null,
            url_to_event_in_gcal: record.url_to_event_in_gcal,
        };
    }
}

export interface LoadEventsResponse {
    gatherEvents: GatherEvent[];
    googleEvents: GoogleEvent[];
}

export interface GatherEventActiveAndStreamableRecord {
    event_id: number;
    event_name: string;
    event_start_time: Date;
    event_end_time: Date;
    is_streamable: boolean;
    broadcast_start_time: Date;
    broadcast_end_time: Date;
    stream_device_id: number;
    stream_id: string;
    is_streaming: boolean;
    streaming_update_time: Date;
    stream_device_type: StreamDeviceType;
    nickname: string;
    is_private: boolean;
}

export interface ActiveStreamReportRecord {
    event_id: number;
    event_name: string;
    gather_case_id: number;
    case_name: string;
    case_full_name: string;
    funeral_home_id: number;
    funeral_home_key: string;
    funeral_home_name: string;
    start_time: Date;
    broadcast_status: 'Not Started' | 'Completed' | 'In Progress';
    device_name: string;
    is_streaming: boolean;
    asset_count: number;
    asset_duration_minutes: number;
}

export interface LiveStreamBillingRecord {
    gather_case_id: number;
    gather_case_uuid: string;
    case_name: string;
    case_full_name: string;
    case_deleted_time: Date | null;
    funeral_home_id: number;
    funeral_home_key: string;
    funeral_home_name: string;
    stripe_customer: string;
    asset_count: number;
    duration: number;
    last_stream_time: Date | null;
    has_billing_record: boolean;
    stripe_invoice_item_id: string | null;
    note: string;
    amount: number;
    bill_created_time: Date | null;
    billed_by: number | null;
    is_level_pay: boolean;
    is_pay_as_you_go: boolean;
}

export interface EventForAppConfigRecord {
    name: string;
    start_time: string;
    end_time: string;
    is_streamable: boolean;
    message: string | null;
    location_name: string | null;
    address1: string | null;
    address2: string | null;
    city: string | null;
    state: string | null;
    country: string | null;
    postal_code: string | null;
    playback_urls: {
        live: string[];
        replay: StreamAssetSummary[];
    };
}

export interface EventForAppConfig extends
    Omit<EventForAppConfigRecord, 'playback_urls' | 'start_time' | 'end_time'> {
    playback_urls: PlaybackURLs;
    start_time: Date;
    end_time: Date;
}
