import { Component } from 'react';
import classNames from 'classnames';
import {
    CoordinatesType,
    getImageSizeFromDataURI,
    getCloudinaryCoordinates,
    LANDSCAPE_ASPECT_RATIO,
    PORTRAIT_ASPECT_RATIO,
    PhotoOrientationType
} from '../../services/photo.service';

import AvatarEditor from 'react-avatar-editor';

import Dialog from '@mui/material/Dialog';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import CropSquareIcon from '@mui/icons-material/CropSquare';
import CropLandscapeIcon from '@mui/icons-material/CropLandscape';
import CropPortraitIcon from '@mui/icons-material/CropPortrait';

import { PhotoTransformationsType, PhotoScopeEnum } from '../../shared/types';
import BasePhotoCropper, {
    between,
    DEFAULT_ANGLE,
    DEFAULT_SCALE,
    DEFAULT_X_POSITION,
    DEFAULT_Y_POSITION,
    downsizeCanvas,
    getCropperOrientation,
    getDistanceBetweenPoints,
    getPhotoOrientationTypes,
    getPointFromTouch,
    MAX_SCALE,
    MIN_SCALE,
    PhotoCropperProps,
    StyledProps,
    styles
} from './BasePhotoCropper';
import { SlideTransition } from '../common/Transitions';
import CircularProgress from '@mui/material/CircularProgress';
import { GStyles } from '../../styles/GStyles';
import { withStyles } from '@mui/styles';

interface State {
    scale: number;
    angle: number;
    lastDistance: number | null;
    hasTouchEventsCompleted: boolean;
    position: CoordinatesType;
    isPositionChanged: boolean;
    isModified: boolean;
    isPhotoSaving: boolean;
    orientation: PhotoOrientationType | null;
    hideAvatarOverlay: boolean;
}

const INITIAL_STATE = {
    scale: DEFAULT_SCALE,
    angle: DEFAULT_ANGLE,
    lastDistance: null,
    hasTouchEventsCompleted: false,
    position: {
        x: DEFAULT_X_POSITION,
        y: DEFAULT_Y_POSITION,
    },
    isPositionChanged: false,
    isModified: false,
    isPhotoSaving: false,
    orientation: null,
    hideAvatarOverlay: false
};

class PhotoCropper extends Component<StyledProps & PhotoCropperProps, State> {
    state: State = INITIAL_STATE;

    protected avatarEditor: AvatarEditor | null = null;
    protected dialogContent: HTMLDivElement | null = null;

    componentDidUpdate(prevProps: PhotoCropperProps) {
        const { transformations, isDialogOpen } = this.props;
        const { isModified } = this.state;
        if (isDialogOpen && !prevProps.isDialogOpen && !isModified && transformations) {
            const avatarEditor = transformations.avatarEditor;
            const cloudinary = transformations.cloudinary;
            this.setState({
                position: {
                    x: avatarEditor && avatarEditor.x || DEFAULT_X_POSITION,
                    y: avatarEditor && avatarEditor.y || DEFAULT_Y_POSITION,
                },
                scale: avatarEditor && avatarEditor.scale || DEFAULT_SCALE,
                angle: cloudinary && cloudinary.angle || DEFAULT_ANGLE,
            });
        }
    }

    componentWillUnmount() {
        if (this.dialogContent) {
            this.dialogContent.removeEventListener('touchmove', this.handleTouchmoveEvent);
        }
    }

    registerAvatarEditor = (avatarEditor: AvatarEditor | null) => {
        this.avatarEditor = avatarEditor;
    };

    registerDialogContent = (dialogContent: HTMLDivElement | null) => {
        if (!dialogContent) {
            return;
        }
        this.dialogContent = dialogContent;

        this.dialogContent.addEventListener(
            'touchmove',
            this.handleTouchmoveEvent,
            false
        );
    };

    handleTouchmoveEvent = (event: TouchEvent) => {
        event.preventDefault();
    };

    handleScale = (event: Event, scale: number | number[]) => {
        event.preventDefault();

        if (!Array.isArray(scale)) {
            this.setState({
                scale,
                isModified: true,
            });
        }
    };

    saveNewImage = async (cropperOrientation: PhotoOrientationType) => {
        const {
            angle,
            scale,
            position,
            isPositionChanged,
        } = this.state;
        const {
            imageURI,
            callBackAction,
            generateBlob,
            maxSize,
            radius,
            scope,
        } = this.props;

        if (!this.avatarEditor) {
            console.warn('No ref to avatar editor when trying to save image');
            return;
        }

        const isLogo = scope === PhotoScopeEnum.logo;
        const isPrintQuality = scope === PhotoScopeEnum.print;
        this.setState({ isPhotoSaving: true });
        // if position changed use the avatarEditor position
        const avatarImagePosition = !isPositionChanged || !this.avatarEditor.state.image
            ? position : this.avatarEditor.state.image;
        const avatarRotationAngle = angle % 360;
        const imageSize = await getImageSizeFromDataURI(imageURI);
        const maxBound = imageSize.width <= imageSize.height ? imageSize.width : imageSize.height;
        const width = cropperOrientation === 'square' ? maxBound : imageSize.width;
        const height = cropperOrientation === 'square' ? maxBound : imageSize.height;
        const croppedSize = {
            width: Math.floor(width / scale),
            height: Math.floor(height / scale),
        };

        // We need to convert react-avatar-editor values to be compatible with Cloudinary transformations
        // The major difference is in the way coordinates are calculated. See the getCloudinayCoordinates
        // function for more details.
        const cloudinaryCoords = getCloudinaryCoordinates({
            imagePosition: {
                x: avatarImagePosition.x,
                y: avatarImagePosition.y,
            },
            imageSize,
            croppedSize,
            rotationAngle: avatarRotationAngle,
            exifRotationAngle: 0,
        });

        const newTransformations: PhotoTransformationsType = {
            cloudinary: {
                angle: avatarRotationAngle,
                width: croppedSize.width,
                height: croppedSize.height,
                x: cloudinaryCoords.x,
                y: cloudinaryCoords.y,
                crop: 'crop',
                radius: radius !== undefined && cropperOrientation === 'square' ? radius : 0,
                format: isLogo ? 'png' : 'jpg',
            },
            avatarEditor: {
                x: avatarImagePosition.x,
                y: avatarImagePosition.y,
                scale,
            },
        };

        // This returns a HTMLCanvasElement, it can be made into a data URL or a blob,
        // drawn on another canvas, or added to the DOM.
        // this will have the same width/height as the original image
        let canvas: HTMLCanvasElement | null = this.avatarEditor.getImage();
        if (maxSize) {
            canvas = downsizeCanvas(canvas, maxSize);
        }
        // if downsize fails revert to full size canvas
        const newImageURI = (canvas || this.avatarEditor.getImage()).toDataURL(
            isLogo ? 'image/png' : 'image/jpeg',
            isPrintQuality ? 1.0 : 0.75
        );
        if (generateBlob) {
            (canvas || this.avatarEditor.getImage()).toBlob(
                (blob) => callBackAction(newTransformations, newImageURI, blob || undefined),
                isLogo ? 'image/png' : 'image/jpeg',
                isPrintQuality ? 1.0 : 0.75,
            );
        } else {
            callBackAction(newTransformations, newImageURI);
        }
        this.handleCloseDialogEvent();
    };

    rotateRight = () => {
        this.setState({
            angle: this.state.angle + 90,
            isModified: true,
        });
    };

    resetEditor = () => {
        this.setState(INITIAL_STATE);
    };

    handleCloseDialogEvent = () => {
        this.resetEditor();
        this.props.closeDialog();
    };

    // functions to handle pinch zoom events start here 
    handlePinchMove(event: TouchEvent) {

        if (!this.avatarEditor) {
            console.warn('No ref to avatar editor when trying to pinch move');
            return;
        }

        const pointA = getPointFromTouch(event.touches[0], this.avatarEditor);
        const pointB = getPointFromTouch(event.touches[1], this.avatarEditor);
        const distance = getDistanceBetweenPoints(pointA, pointB);

        const { hasTouchEventsCompleted } = this.state;

        if (!hasTouchEventsCompleted) {
            this.setState({
                lastDistance: distance,
                hasTouchEventsCompleted: true,
                isModified: true,
            });
        }

        this.setState((prevState) => ({
            scale: between(
                MIN_SCALE,
                MAX_SCALE,
                prevState.scale * (distance / (prevState.lastDistance ? prevState.lastDistance : distance))),
            lastDistance: distance,
            isModified: true,
        }));
    }

    handleMouseMove = (event: TouchEvent) => {
        if (!event.touches || event.touches.length !== 2) {
            return;
        }

        if (event.touches.length === 2) {
            this.handlePinchMove(event);
        }
    };

    handleMouseUp = () => {
        this.setState({
            hasTouchEventsCompleted: false,
            isModified: true,
        });
    };

    handlePositionChange = () => {
        this.setState({
            isPositionChanged: true,
            isModified: true,
        });
    };

    // functions to handle pinch zoom events ends here 
    render() {
        const {
            classes,
            isDialogOpen,
            imageURI,
            radius,
            options,
            zIndex,
        } = this.props;
        const { scale, angle, position, isPositionChanged, isPhotoSaving, orientation } = this.state;

        const orientationTypes = getPhotoOrientationTypes(options);
        const cropperOrientation = orientation || getCropperOrientation(options);

        return (
            <Dialog
                open={isDialogOpen}
                onClose={this.handleCloseDialogEvent}
                TransitionComponent={SlideTransition}
                transitionDuration={300}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                classes={{
                    paper: classes.dialogPaper
                }}
                style={{ zIndex }}
            >
                <div ref={this.registerDialogContent}>
                    {orientationTypes.length !== 0 &&
                        <div className={classes.orientationHeader}>
                            {orientationTypes.map(o =>
                                <div
                                    key={o}
                                    className={classNames(
                                        classes.inner,
                                        o === cropperOrientation && classes.active
                                    )}
                                    onClick={e => this.setState({ orientation: o })}
                                >
                                    {o === 'square' && <CropSquareIcon />}
                                    {o === 'portrait' && <CropPortraitIcon />}
                                    {o === 'landscape' && <CropLandscapeIcon />}
                                    <Typography>{o}</Typography>
                                </div>
                            )}
                        </div>
                    }
                    <BasePhotoCropper
                        options={options}
                        handleMouseMove={this.handleMouseMove}
                        handleMouseUp={this.handleMouseUp}
                        handlePositionChange={this.handlePositionChange}
                        handleScale={this.handleScale}
                        registerAvatarEditor={this.registerAvatarEditor}
                        imageURI={imageURI}
                        rotateRight={this.rotateRight}
                        scale={scale}
                        angle={angle}
                        isPositionChanged={isPositionChanged}
                        position={position}
                        orientation={orientation}
                        portraitAspectRatio={PORTRAIT_ASPECT_RATIO}
                        landscapeAspectRatio={LANDSCAPE_ASPECT_RATIO}
                        radius={radius}
                    />

                    <Grid item xs={12}>
                        <Grid container justifyContent="space-between">
                            <Grid item>
                                <Button
                                    className={classes.button}
                                    size="small"
                                    onClick={this.handleCloseDialogEvent}
                                >
                                    CANCEL
                                </Button>
                            </Grid>

                            <Grid item>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    size="small"
                                    className={classes.button}
                                    onClick={e => this.saveNewImage(cropperOrientation)}
                                    disabled={isPhotoSaving}
                                >
                                    SAVE
                                    {isPhotoSaving
                                        && <CircularProgress size={24} className={GStyles.buttonProgress} />}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </Dialog>
        );
    }
}

export default withStyles(styles<PhotoCropperProps>())(PhotoCropper);
