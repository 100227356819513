import { convertHexToRGBA } from '../../../services';
import { Theme } from '@mui/material/styles';

import { StyleRulesCallback } from '@mui/styles';
import green from '@mui/material/colors/green';
import { WithGStyles } from '../../../styles/WithGStyles';
import makeGStyles from '../../../styles/makeGStyles';

export function styleWrapper<Props extends object>() {
    const TASKDIALOGSSTYLES: StyleRulesCallback<Theme, Props, Classes> = (theme) => ({
        root: {
            '& $dialogPaper': {
                display: 'flex',
                flexWrap: 'nowrap',
                justifyContent: 'space-around',
                width: '100%',
                maxWidth: '100%',
                [theme.breakpoints.up('md')]: {
                    maxWidth: 720,
                    width: 720,
                },
            },
        },
        dialogPaper: {},
        dialogHeader: {
            padding: 14,
            zIndex: 1,
            paddingBottom: 0,
            height: 'auto',
            '& button': {
                width: 36,
                height: 36,
                '@media (min-width: 400px)': {
                    width: 48,
                    height: 48,
                },
                '& svg': {
                    fontSize: 26,
                    '@media (min-width: 400px)': {
                        fontSize: 32,
                    },
                },
            },
        },
        dialogContent: {
            zIndex: 0,
            padding: '0 14px',
            [theme.breakpoints.up('md')]: {
                padding: 0,
            },
        },
        clearIcon: {
            position: 'absolute',
            top: 18,
            right: 10,
            fontSize: 28,
            '&:hover': {
                cursor: 'pointer',
            },
            '@media (min-width: 400px)': {
                fontSize: 34,
            },
        },
        dialogFooter: {
            margin: 'auto',
            display: 'block',
        },
        initialStateIconsSize: {
            height: 24,
            '& svg': {
                height: 24,
                width: 24,
            },
        },
        marginLeft15: {
            marginLeft: 15,
        },
        marginLeft10: {
            marginLeft: 10,
        },
        iconButtonGrid: {
            width: 'fit-content',
            marginLeft: 0,
            [theme.breakpoints.up('sm')]: {
                marginLeft: 15,
            },
        },
        textTransformNone: {
            textTransform: 'none',
        },
        iconButton: {
            [theme.breakpoints.down('md')]: {
                paddingLeft: 0,
            },
        },
        slideClassName: {
            overflow: 'hidden !important',
            overflowY: 'auto !important' as 'auto',
        },
        secondStateIcon: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            '& img': {
                width: 180,
                height: 'auto',
                marginBottom: 16,
            },
            '& svg, span': {
                height: 120,
                width: 120,
                fontSize: 120,
            },
        },
        secondStateMainGrid: {
            minHeight: 520,
        },
        borderBottom: {
            color: theme.palette.secondary.main,
            borderBottom: '1px solid',
        },
        detailBoxHeader: {
            color: '#fff',
        },
        detailBox: {
            borderRadius: 5,
        },
        button: {
            margin: theme.spacing(),
            fontSize: 12,
            '@media (min-width: 360px)': {
                fontSize: 14,
            },
        },
        openInNewIcon: {
            marginLeft: 10,
        },
        cancelAccConfirmationBtn: {
            justifyContent: 'center',
            '& button': {
                minWidth: 112,
                minHeight: 40,
            },
        },
        socialSecurityForm: {
            width: '100%',
            margin: 'auto',
            '@media (min-width: 421px)': {
                maxWidth: 360,
            },
        },
        themeSelectMenu: {
            '&:focus': {
                background: 'transparent',
            },
        },
        toggleButton: {
            padding: '4px 20px',
            fontSize: '13px !important',
        },
        toggleButtonGroupLabel: {
            position: 'relative',
            transform: 'none',
            textAlign: 'center',
        },
        toggleContainer: {
            height: 56,
            padding: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: '100%',
        },
        dataList: {
            margin: '22px 0 0',
            flexDirection: 'column',
            alignItems: 'center',
            '@media (min-width: 600px)': {
                margin: '22px 0 0 24px',
                flexDirection: 'row',
                alignItems: 'flex-start',
            },
            '& $leftSection': {
                minWidth: 300,
                '@media (min-width: 600px)': {
                    minWidth: 'auto',
                },
            },
            '& $rightSection': {
                maxWidth: 300,
            },
            '& ul': {
                paddingLeft: 20,
                margin: 0,
            },
            '& li': {
                color: theme.palette.secondary.main,
                margin: '2px 0',
            },
        },
        listHeading: {
            fontWeight: 500,
            fontSize: 16,
        },
        messengerExportInitbottomText: {
            borderTop: `1px solid ${convertHexToRGBA(theme.palette.primary.main, 0.5)}`,
            width: '100%',
            margin: '18px 16px 0',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        },
        exportDataButton: {
            color: theme.palette.common.white,
            margin: '20px 0 8px',
        },
        customLink: {
            textDecoration: 'none',
            color: theme.palette.secondary.main,
        },
        // eslint-disable-next-line @typescript-eslint/naming-convention
        lineHeight1_25: {
            lineHeight: 1.25,
        },
        messengerDialogHeader: {
            fontSize: 16,
            textTransform: 'capitalize',
            '@media (min-width: 380px)': {
                fontSize: 20,
            },
        },
        circularProgress: {
            position: 'absolute',
            '& svg': {
                color: green[500],
            },
        },
        initialStateContainer: {
            margin: '18px auto 20px',
        },
        rootVideoSection: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            padding: '36px 0',
            maxWidth: 400,
            margin: '12px auto',
            width: 'calc(100% - 24px)',
            '@media (min-width: 624px)': {
                width: '100%',
            },
        },
        videoSection: {
            width: '100% !important',
            height: '300px !important',
            margin: '12px',
            boxShadow: '0px 2px 22px 6px rgba(0,0,0,0.2)',
        },
        paddingRight20: {
            paddingRight: 20,
        },
        whiteSpaceNowrap: {
            whiteSpace: 'nowrap'
        },
        leftSection: {},
        rightSection: {},
    });

    return TASKDIALOGSSTYLES;
}

export const useTaskDialogStyles = makeGStyles(styleWrapper(), { name: 'TaskDialog' });

type Classes = 'root' | 'leftSection' | 'rightSection' | 'paddingRight20' | 'videoSection' | 'rootVideoSection'
    | 'initialStateContainer' | 'circularProgress' | 'messengerDialogHeader' | 'lineHeight1_25' | 'customLink'
    | 'exportDataButton' | 'messengerExportInitbottomText' | 'listHeading' | 'dataList' | 'toggleContainer'
    | 'toggleButtonGroupLabel' | 'toggleButton' | 'themeSelectMenu' | 'socialSecurityForm' | 'cancelAccConfirmationBtn'
    | 'openInNewIcon' | 'button' | 'detailBox' | 'detailBoxHeader' | 'borderBottom' | 'secondStateMainGrid'
    | 'secondStateIcon' | 'slideClassName' | 'iconButton' | 'textTransformNone' | 'iconButtonGrid' | 'marginLeft10'
    | 'marginLeft15' | 'initialStateIconsSize' | 'dialogFooter' | 'clearIcon' | 'dialogContent' | 'dialogHeader'
    | 'dialogPaper' | 'whiteSpaceNowrap';

export type TaskDialogStyledProps = WithGStyles<Classes>;