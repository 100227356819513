import * as React from 'react';

import Grid from '@mui/material/Grid';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';

import {
    EntityCaseRole,
    GatherCaseUX,
    isAlbumEntry,
} from '../../../shared/types';
import { GatherPhoto, StoreState } from '../../../types';

import FamilySecondaryAppBar from '../../appBars/family/FamilySecondary.appBar';
import {
    loadAlbumPhotosForTask,
    reorderPhotos,
    startAlbumDownload,
    updateAlbumEntries,
} from '../../../actions/Album.action';
import SortablePhotoList from './SortablePhotoList';
import { SortEnd } from 'react-sortable-hoc';
import AssignTask from '../../assignmentPoppers/AssignTask';
import { loadCasePhotos } from '../../../actions/Photo.action';
import PhotoListDialog from '../../profileImage/PhotoList.dialog';
import { registerAppError } from '../../../actions/errors';
import { openHelperInvitationDialog } from '../../../actions/Dialog.action';
import LockPopover from './LockPopover';
import { updateTask } from '../../../actions/task/Task.action';
import { performCasePhotoUpload, createUploadFromDispatch } from '../../profileImage/utility';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback } from '@mui/styles';
import withState from '../../common/utilHOC/WithState';
import withGStyles, { WithGStyles } from '../../../styles/WithGStyles';
import { AppDispatch } from '../../../store';

const styles: StyleRulesCallback<Theme, Props> = (theme) => ({
    root: {
        textAlign: 'center',
        marginTop: '0 !important',
        position: 'relative',
        marginBottom: 24
    },
    mainWrapper: {
        padding: '0',
        textAlign: 'center',
        '@media (min-width: 380px)': {
            padding: '16px 10px 0'
        }
    },
    loader: {
        margin: 24,
    },
    lockCard: {
        margin: '24px 0'
    },
    snackbarContent: {
        minWidth: 'unset',
        '@media (max-width: 960px)': {
            flexGrow: 0
        },
    },
    sortContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
    },
});

function mapStateToProps({
    userSession,
    casesState,
    tasksState,
    albumState,
}: StoreState, ownProps: OwnProps) {
    const { albumTaskId } = ownProps;

    const albumTask = tasksState.checklistTasks.find((task) => task.id === albumTaskId);
    return {
        userSession,
        isCasePhotosLoading: casesState.isCasePhotosLoading,
        casePhotos: casesState.casePhotos,
        albumTask,
        albumState,
    };
}
interface OwnProps {
    activeCase: GatherCaseUX;
    albumTaskId: number;
}
interface Props extends ReturnType<typeof mapStateToProps>, OwnProps {
    dispatch: AppDispatch;
}

interface State {
    isSaving: boolean;
    isUploading: boolean;
    isPhotoListDialogOpen: boolean;
    isCustomSnackbarOpen: boolean;
    popperAnchorEle: HTMLElement | null;
    selectedPhotoIds: number[];
}

export const _getPhotoId = (photo: GatherPhoto): number => {
    if (!photo.photo) {
        return -1;
    }
    return 'photo_id' in photo.photo ? photo.photo.photo_id : photo.photo.id || -1;
};

type StyledProps = WithGStyles<'root' | 'mainWrapper' | 'loader' | 'snackbarContent' | 'lockCard' | 'sortContainer'>;

type CombinedProps = Props & StyledProps;

class PhotoSlideshow extends React.Component<CombinedProps, State> {

    state: State = {
        isSaving: false,
        isUploading: false,
        isPhotoListDialogOpen: false,
        isCustomSnackbarOpen: false,
        popperAnchorEle: null,
        selectedPhotoIds: [],
    };

    componentDidMount() {
        const { dispatch, albumTaskId, activeCase } = this.props;
        window.scrollTo(0, 0);
        // load the album photos in
        dispatch(loadAlbumPhotosForTask(activeCase.uuid, albumTaskId));
        dispatch(loadCasePhotos(activeCase.uuid));
    }

    getAlbumId = () => {
        const { albumState, albumTaskId } = this.props;
        // extract the correct album for this task
        return albumState.albumTaskInfoMap[albumTaskId] ? albumState.albumTaskInfoMap[albumTaskId].album.id : null;
    };

    getAlbumPhotos = (albumId: number | null) => {
        const { albumState } = this.props;
        return albumId && albumState.albums[albumId] && albumState.albums[albumId].photos ?
            albumState.albums[albumId].photos : [];
    };

    reorderPhotos = (sort: SortEnd): void => {
        const { dispatch, activeCase } = this.props;
        const albumId = this.getAlbumId();
        if (albumId) {
            dispatch(reorderPhotos(activeCase.uuid, albumId, sort, this.getAlbumPhotos(albumId)));
        }
    };

    openPhotoListDialog = () => {
        const { dispatch, activeCase } = this.props;
        const albumId = this.getAlbumId();
        const albumPhotos = this.getAlbumPhotos(albumId);
        const photoIds = albumPhotos.map((photo) => photo.photo ? photo.photo.photo_id : -1);

        dispatch(loadCasePhotos(activeCase.uuid));

        this.setState({
            isPhotoListDialogOpen: true,
            selectedPhotoIds: photoIds
        });
    };

    closePhotoListDialog = (selectedPhotoIds?: number[]) => {
        this.setState({
            isPhotoListDialogOpen: false,
        });
        if (selectedPhotoIds) { // apply changes if we hit done instead of just closing window
            this.doneClicked(selectedPhotoIds);
        }
    };

    doneClicked = (selectedPhotoIds: number[]): void => {
        const { dispatch, activeCase, casePhotos } = this.props;
        const albumId = this.getAlbumId();
        const albumPhotos = this.getAlbumPhotos(albumId);

        this.setState({
            isPhotoListDialogOpen: false,
            selectedPhotoIds: selectedPhotoIds
        });

        // if we don't have case photos or an album then trigger and error
        if (!albumId) {
            dispatch(registerAppError('Problem updating album'));
            return;
        }

        // find photo ids of any photos not in the album then map them to the corresponding
        // photo_view id of the image in the case album so we can copy any transformations
        const photoIds = albumPhotos.map((entry) => entry.photo?.photo_id);
        const missingPhotoIds = selectedPhotoIds.filter((id) => photoIds.indexOf(id) === -1);
        const addPhotoViewIds: number[] = missingPhotoIds.map((missingPhotoId) => {
            const casePhoto = casePhotos.find((entry) =>
                isAlbumEntry(entry.photo) && entry.photo.photo_id === missingPhotoId);
            return casePhoto && isAlbumEntry(casePhoto.photo)
                ? casePhoto.photo.photo_view_id
                : null;
        }).filter((photoViewId: number | null): photoViewId is number => photoViewId !== null);

        // find entries we still have, they should already be in the correct order
        const keptEntryIds = albumPhotos
            .filter((entry) => isAlbumEntry(entry.photo) && selectedPhotoIds.indexOf(entry.photo.photo_id) !== -1)
            .map((entry) => (entry.photo ? entry.photo.id : -1));

        dispatch(updateAlbumEntries(activeCase.uuid, albumId, addPhotoViewIds, keptEntryIds));
    };

    handlePhotoUploadEvent = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        const { activeCase, dispatch } = this.props;

        this.setState({ isUploading: true });
        await performCasePhotoUpload(files, activeCase, createUploadFromDispatch(dispatch));
        this.setState({ isUploading: false });
    };

    toggleCustomSnackbar = (isCustomSnackbarOpen: boolean) => {
        this.setState({
            isCustomSnackbarOpen
        });
    };

    /**
     * popper operations start
     */

    openPopper = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({ popperAnchorEle: event.currentTarget });
    };

    closePopper = () => {
        this.setState({ popperAnchorEle: null });
    };

    handleStartDownload = () => {
        const { dispatch, activeCase } = this.props;
        const albumId = this.getAlbumId();
        if (albumId) {
            dispatch(startAlbumDownload(activeCase.uuid, albumId));
        }
    };

    renderAssignTask = (activeCase: GatherCaseUX) => {
        const { classes, albumTask } = this.props;

        if (!albumTask) { // wait till task loaded
            return null;
        }
        return (
            <Grid container justifyContent="center" className={classes.root}>
                <Grid
                    item
                    xs={12}
                >
                    <AssignTask
                        zIndex={11}
                        task={albumTask}
                        activeCase={activeCase}
                    />

                </Grid>
            </Grid>
        );
    };

    lockUnlockAlbum = (isLocked: boolean): void => {
        const { albumTaskId, dispatch, activeCase } = this.props;

        dispatch(updateTask(albumTaskId, { is_locked: isLocked }, activeCase.uuid, null));

        // Show toast
        if (isLocked) {
            this.toggleCustomSnackbar(true);
        }
    };

    openInvitationDialog = (currentTab: EntityCaseRole = EntityCaseRole.guest) => {
        const { dispatch } = this.props;
        dispatch(openHelperInvitationDialog({
            zIndex: 1320 + 1,
            defaultTab: currentTab,
        }));
    };

    render() {
        const {
            isUploading,
            isPhotoListDialogOpen,
            selectedPhotoIds,
            popperAnchorEle,
        } = this.state;
        const {
            classes,
            activeCase,
            userSession,
            albumTaskId,
            albumState,
            isCasePhotosLoading,
            albumTask,
            casePhotos,
            ...others
        } = this.props;
        const albumId = this.getAlbumId();
        const albumPhotos = this.getAlbumPhotos(albumId);
        const isLocked = !!albumTask && albumTask.is_locked;
        return (
            <div>
                <FamilySecondaryAppBar
                    {...others}
                    lockIcon={isLocked ? <LockIcon /> : <LockOpenIcon />}
                    userAvatarPrecedent
                    openPopper={this.openPopper}
                    activeCase={activeCase}
                    zIndex={10}
                />

                <Grid container>
                    <Grid item xs={12} className={classes.mainWrapper}>
                        {this.renderAssignTask(activeCase)}
                        <div className={classes.sortContainer}>
                            <SortablePhotoList
                                albumId={albumId || -1}
                                isLoading={albumState.isNetworkBusy}
                                isLocked={isLocked ? true : false}
                                lockUnlockAlbum={this.lockUnlockAlbum}
                                photoList={albumPhotos}
                                onPhotosReordered={this.reorderPhotos}
                                onAddButtonClicked={this.openPhotoListDialog}
                                onDownloadClicked={this.handleStartDownload}
                                activeCase={activeCase}
                            />
                        </div>
                    </Grid>
                    <PhotoListDialog
                        activeCase={activeCase}
                        isLoading={isCasePhotosLoading}
                        isUploading={isUploading}
                        photoList={casePhotos}
                        isDialogOpen={isPhotoListDialogOpen}
                        closeDialog={this.closePhotoListDialog}
                        onFileUpload={this.handlePhotoUploadEvent}
                        acceptMultiple
                        initialSelectedPhotoIds={selectedPhotoIds}
                        allowMultiSelection
                        hideDownloadButton
                        hideDeleteButton
                        disablePhotoSwipeOnClick
                        zIndex={1320}
                    />
                    <LockPopover
                        activeCase={activeCase}
                        popperAnchorEle={popperAnchorEle}
                        closePopper={() => this.closePopper()}
                        userSession={userSession}
                        lockUnlockAlbum={this.lockUnlockAlbum}
                        zIndex={1320}
                        locked={isLocked ? true : false}
                        casePhotosCount={casePhotos.length}
                        albumPhotosCount={albumPhotos.length}
                        task={albumTask || null}
                    />
                </Grid>
            </div>
        );
    }
}

export default withState(mapStateToProps)(withGStyles(styles)(PhotoSlideshow));
