import * as React from 'react';
import classNames from 'classnames';

import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import FlagIcon from '@mui/icons-material/Flag';

import UserAvatar from '../../common/UserAvatar';
import ConfirmationDialog from '../../common/ConfirmationDialog';
import {
    GatherCasePublic,
    GatherCaseUX,
    isAlbumEntry,
    GuestListUser,
    caseAssigneeAsGuestListUser,
    ModerationCategory,
} from '../../../shared/types';
import { AlbumPhoto, GatherPhoto, StoreState } from '../../../types';
import { deleteCasePhoto, modifyCasePhoto } from '../../../actions/Photo.action';
import { removeAlbumEntry, updateAlbumEntry } from '../../../actions/Album.action';
import { openPhotoSwipeDialog } from '../../../actions/PhotoSwipe.action';
import { downloadPhoto, flagTitle, getPhotoUrlForDownload, isRememberPage } from '../../../services';
import { joinNameParts } from '../../../shared/utils';
import { flagContent } from '../../../actions/Moderation.action';
import { closeGuestPopper, openGuestPopper } from '../../../actions/Dialog.action';
import { Theme } from '@mui/material/styles';
import withStyles, { StyleRulesCallback, WithStyles } from '@mui/styles/withStyles';
import withState from '../../common/utilHOC/WithState';
import { AppDispatch } from '../../../store';
import GPopper from '../../common/GPopper';
import { canEditCasePhoto } from '../../../shared/authority/can';

const styles: StyleRulesCallback<Theme, OwnProps> = (theme) => ({
    popper: {
        '&$uploaderDetailsHidden': {
            '&[data-popper-placement*="top"] $arrow': {
                borderTop: '20px solid #fff',
            }
        },
        '&[data-popper-placement*="bottom"] $arrow': {
            borderBottom: '20px solid #fff',
            top: '-8px',
        },
        '&[data-popper-placement*="top"] $arrow': {
            borderTop: `20px solid ${theme.palette.primary.main}`,
            bottom: '-20px',

        },
        width: '100%',
        maxWidth: 300,
        '@media (min-width: 360px)': {
            maxWidth: 320,
        }
    },
    popoverPaper: {
        borderRadius: 12,
        WebkitBorderRadius: 4,
        MozBorderRadius: 4,
        boxShadow: theme.shadows[10],
    },
    popperContent: {
        borderRadius: 4,
        WebkitBorderRadius: 4,
        MozBorderRadius: 4,
        marginTop: 12
    },
    arrow: {
        border: '20px solid transparent',
    },
    popperHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    rotateButton: {
        padding: 8
    },
    divider: {
        width: 'calc(100% - 16px)',
        margin: 'auto',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& button': {
            textTransform: 'none',
            fontWeight: 400,
            fontSize: 16
        }
    },
    footerContainer: {
        backgroundColor: theme.palette.primary.main,
        display: 'flex',
        alignItems: 'center',
        padding: 8,
        borderRadius: '0px 0px 12px 12px'
    },
    avatarClass: {
        width: 52,
        height: 52
    },
    userAvatar: {
        textTransform: 'uppercase',
        width: 52,
        height: 52,
    },
    leftSide: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        marginLeft: 8
    },
    smallText: {
        fontSize: 12,
        color: theme.palette.common.white
    },
    largeText: {
        fontSize: 16,
        lineHeight: '1.1em',
        color: theme.palette.common.white
    },
    uploaderDetailsHidden: {}
});

type StyledProps = WithStyles<'root' | 'popper' | 'popoverPaper' | 'popperContent' | 'arrow' | 'popperHeader'
    | 'rotateButton' | 'divider' | 'buttonContainer' | 'footerContainer' | 'avatarClass' | 'userAvatar'
    | 'leftSide' | 'smallText' | 'largeText' | 'uploaderDetailsHidden'>;

const mapStateToProps = ({ casesState, userSession, dialogState }: StoreState, ownProps: OwnProps) => {
    const { guestListMap, selectedCase } = casesState;
    const { guestPopper } = dialogState;
    const { userData } = userSession;
    const { activePhoto, activeCase } = ownProps;

    const uploaderUserId = Number(activePhoto && activePhoto.photo && activePhoto.photo.uploaded_by);
    let contributor: GuestListUser | null = null;
    if (!Number.isNaN(uploaderUserId)) {
        contributor = guestListMap[uploaderUserId] || null;
        if (!contributor && selectedCase && selectedCase.assignee.user_id === uploaderUserId) {
            contributor = caseAssigneeAsGuestListUser(selectedCase.assignee, selectedCase.funeral_home.id);
        }
    }
    const canEditPhoto = canEditCasePhoto({
        user: userData,
        activeCase,
        entry: isAlbumEntry(activePhoto?.photo) ? activePhoto?.photo : null,
    });
    return {
        contributor,
        canEditPhoto,
        canFlagPhoto: Boolean(userData),
        isGuestPopperOpen: !!(guestPopper.getTarget && guestPopper.getTarget()),
    };
};

interface OwnProps {
    albumId?: number;
    activePhoto: GatherPhoto | null;
    photoList: GatherPhoto[];
    activeCase: GatherCaseUX | GatherCasePublic;
    popperEl: HTMLElement | null;
    zIndex: number;
    timeSinceUploaded?: string;
    hideRemoveAlbum?: boolean;
    isOpenPhotoPopper: boolean;
    closePopper: () => void;
    skipParentPopperOpenCheck?: boolean;
    hideUploaderDetails?: boolean;
    hideFlagInappropriateButton?: boolean;
}

interface InjectedProps extends ReturnType<typeof mapStateToProps> {
    dispatch: AppDispatch;
}

interface State {
    isPhotoDeleteDialogOpen: boolean;
    isFlagPhotoDialogOpen: boolean;
}

type CombinedProps = OwnProps & StyledProps & InjectedProps;

class PhotoPopper extends React.Component<CombinedProps, State> {
    state: State = {
        isPhotoDeleteDialogOpen: false,
        isFlagPhotoDialogOpen: false,
    };

    componentDidUpdate(prevProps: CombinedProps) {
        const { popperEl: prevPopperEl } = prevProps;
        const { popperEl, dispatch, isGuestPopperOpen, skipParentPopperOpenCheck } = this.props;

        // check if GuestPopper is open but the PhotoPopper has changed its element
        if (prevPopperEl && popperEl
            && prevPopperEl !== popperEl
            && isGuestPopperOpen && !skipParentPopperOpenCheck
        ) {
            dispatch(closeGuestPopper());
        }
    }

    getTransformations = (activePhoto: GatherPhoto | AlbumPhoto, direction: 'left' | 'right') => {
        let newTransformations = { cloudinary: { angle: 0 } };

        if (activePhoto.photo && isAlbumEntry(activePhoto.photo)) {
            let trans = activePhoto.photo.transformations || { cloudinary: { angle: 0 } };
            if (!trans.cloudinary) {
                trans = {
                    ...trans,
                    cloudinary: {
                        angle: 0
                    }
                };
            }
            // it will never be undefined as ts complains, as we check that above
            const currAngle = trans.cloudinary && trans.cloudinary.angle ? trans.cloudinary.angle : 0;
            newTransformations = {
                ...trans,
                cloudinary: {
                    ...trans.cloudinary,
                    angle: direction === 'left' ? currAngle - 90 : currAngle + 90
                }
            };
        }

        return newTransformations;
    };

    handleRotatePhoto = (direction: 'left' | 'right') => {
        const { activePhoto, activeCase, dispatch, closePopper, albumId } = this.props;

        if (activePhoto && isAlbumEntry(activePhoto.photo)) {
            const newTrans = this.getTransformations(activePhoto, direction);
            if (albumId) {
                dispatch(updateAlbumEntry(
                    activeCase.uuid,
                    albumId,
                    activePhoto.photo.id,
                    { transformations: newTrans },
                ));
            } else {
                dispatch(modifyCasePhoto(activeCase.uuid, activePhoto, { transformations: newTrans }));
            }
        }
        closePopper();
    };

    handleDownloadPhoto = () => {
        const { activePhoto, activeCase, closePopper } = this.props;

        if (activePhoto && activePhoto.photo) {
            downloadPhoto(
                getPhotoUrlForDownload(activePhoto.photo.public_id),
                `${activeCase.name}-${activePhoto.photo.id || 'photo'}`
            );
        }

        closePopper();
    };

    openDeletePhotoConfirmationDialog = () => {
        this.setState({ isPhotoDeleteDialogOpen: true });
    };

    closeDeletePhotoConfirmationDialog = () => {
        this.setState({ isPhotoDeleteDialogOpen: false });
        this.props.closePopper();
    };

    openFlagPhotoConfirmationDialog = () => {
        this.setState({ isFlagPhotoDialogOpen: true });
    };

    closeFlagPhotoConfirmationDialog = () => {
        this.setState({ isFlagPhotoDialogOpen: false });
        this.props.closePopper();
    };

    handleDeletePhoto = () => {
        const { activePhoto, activeCase, dispatch } = this.props;
        this.closeDeletePhotoConfirmationDialog();
        if (activePhoto && activePhoto.photo && isAlbumEntry(activePhoto.photo)) {
            dispatch(deleteCasePhoto({
                gatherCase: activeCase,
                albumEntryId: activePhoto.photo.id,
            }));
        }
    };

    handleRemovePhoto = () => {
        const { dispatch, activeCase, activePhoto, photoList, albumId, closePopper } = this.props;
        if (activePhoto && isAlbumEntry(activePhoto.photo) && photoList && albumId) {
            const keepEntryIds: number[] = photoList
                .filter(e => e.gatherId !== activePhoto.gatherId && e.photo)
                .map(e => isAlbumEntry(e.photo) ? e.photo.id : -1);  // -1 will not occur due to above filter
            dispatch(removeAlbumEntry(activeCase.uuid, albumId, keepEntryIds));
        }
        closePopper();
    };

    handleFlagPhoto = () => {
        const { dispatch, activeCase, activePhoto, closePopper } = this.props;
        this.closeFlagPhotoConfirmationDialog();
        if (activePhoto && isAlbumEntry(activePhoto.photo)) {
            dispatch(flagContent({
                category: ModerationCategory.photos,
                caseId: activeCase.id,
                caseName: activeCase.name,
                itemId: activePhoto.photo.id,
            }));
        }
        closePopper();
    };

    handleFullScreenPhoto = () => {
        const { dispatch, photoList, activePhoto } = this.props;
        if (activePhoto && isAlbumEntry(activePhoto.photo) && photoList) {
            const reversed = [...photoList].reverse();
            dispatch(openPhotoSwipeDialog('GATHER_PHOTO', activePhoto.photo.public_id, reversed));
        }
    };

    clickAwayListener = (e: MouseEvent | TouchEvent) => {
        const { closePopper, isGuestPopperOpen, skipParentPopperOpenCheck } = this.props;
        const { isPhotoDeleteDialogOpen } = this.state;

        if (isPhotoDeleteDialogOpen) {
            return;
        }

        const target = e.target as HTMLElement;

        const clickAwayElementId = target && target.classList[0];

        if ((clickAwayElementId && clickAwayElementId.indexOf('MuiButton') !== -1)
            || (isGuestPopperOpen && !skipParentPopperOpenCheck)) {
            return;
        }
        closePopper();
    };

    openGuestPopper = (
        event: React.MouseEvent<HTMLElement>,
        user: GuestListUser
    ) => {
        event.persist();
        const { dispatch, zIndex } = this.props;

        const target = event.currentTarget;

        dispatch(openGuestPopper({
            zIndex: zIndex + 1,
            getTarget: () => target,
            activeGuest: user
        }));
    };

    renderPopperHeader = () => {
        const { classes } = this.props;

        return (
            <Grid item xs={12} className={classes.popperHeader}>
                <Button
                    color="primary"
                    className={classes.rotateButton}
                    onClick={() => this.handleRotatePhoto('left')}
                >
                    <RotateLeftIcon color="primary" />&nbsp;
                    Rotate Left
                </Button>
                <Button
                    color="primary"
                    className={classes.rotateButton}
                    onClick={() => this.handleRotatePhoto('right')}
                >
                    Rotate Right&nbsp;
                    <RotateRightIcon color="primary" />
                </Button>
            </Grid>
        );
    };

    renderRemoveButton = () => {
        const { classes } = this.props;

        return (
            <Grid item xs={12} className={classes.buttonContainer}>
                <Button color="primary" onClick={this.handleRemovePhoto} >
                    <RemoveCircleOutlineIcon color="primary" />&nbsp;
                    Remove photo from album
                </Button>
            </Grid>
        );
    };

    renderScreenMode = () => {
        const { classes } = this.props;

        return (
            <Grid item xs={12} className={classes.buttonContainer}>
                <Button color="primary" onClick={this.handleFullScreenPhoto}>
                    <FullscreenIcon color="primary" />&nbsp;
                    View photo in full-screen mode
                </Button>
            </Grid>
        );
    };

    renderDownloadButton = () => {
        const { classes } = this.props;

        return (
            <Grid item xs={12} className={classes.buttonContainer}>
                <Button color="primary" onClick={e => this.handleDownloadPhoto()}>
                    <CloudDownloadIcon color="primary" />&nbsp;
                    Download This Photo
                </Button>
            </Grid>
        );
    };

    renderDeleteButton = () => {
        const { classes } = this.props;

        return (
            <Grid item xs={12} className={classes.buttonContainer}>
                <Button color="primary" onClick={(e) => this.openDeletePhotoConfirmationDialog()}>
                    <DeleteIcon color="primary" />&nbsp;
                    Delete photo permanently
                </Button>
            </Grid>
        );
    };

    renderFlagButton = () => {
        const { classes, activeCase } = this.props;

        return (
            <Grid item xs={12} className={classes.buttonContainer}>
                <Tooltip placement="top" title={flagTitle(activeCase.funeral_home.name)}>
                    <Button color="primary" onClick={e => this.openFlagPhotoConfirmationDialog()}>
                        <FlagIcon color="primary" />&nbsp;
                        Flag this photo as inappropriate
                    </Button>
                </Tooltip>
            </Grid>
        );
    };

    renderFlagConfirmationDialog = () => {
        const { zIndex, activeCase } = this.props;
        const { isFlagPhotoDialogOpen } = this.state;

        const subHeader = `Flagging as inappropriate will alert someone at ${activeCase.funeral_home.name} 
        to review. If blocked, this will be removed.`;

        return (
            <ConfirmationDialog
                header={'Are you sure you want to flag this?'}
                subHeader={subHeader}
                confirmationButtonText={'Flag'}
                cancelButtonText={'Nevermind'}
                onClose={this.closeFlagPhotoConfirmationDialog}
                open={isFlagPhotoDialogOpen}
                onConfirm={this.handleFlagPhoto}
                zIndex={zIndex + 5}
            />
        );
    };

    renderConfirmationDialog = () => {
        const { zIndex } = this.props;
        const { isPhotoDeleteDialogOpen } = this.state;

        return (
            <ConfirmationDialog
                header={'Are you sure?'}
                subHeader={'Deleting this photo will remove it permanently from the system.'}
                confirmationButtonText={'Delete Photo'}
                cancelButtonText={'Nevermind'}
                onClose={this.closeDeletePhotoConfirmationDialog}
                open={isPhotoDeleteDialogOpen}
                onConfirm={this.handleDeletePhoto}
                zIndex={zIndex + 5}
            />
        );
    };

    renderPopperFooter = () => {
        const { classes, contributor, timeSinceUploaded } = this.props;

        const ifOnRememberPage = isRememberPage();

        const avatar = (
            <Avatar className={classes.avatarClass}>
                <UserAvatar
                    user={contributor}
                    className={classes.userAvatar}
                    size={52}
                />
            </Avatar>
        );

        if (!contributor) {
            return null;
        }

        return (
            <>
                <Grid item xs={12} className={classes.footerContainer}>
                    {ifOnRememberPage &&
                        <IconButton onClick={e => this.openGuestPopper(e, contributor)} size="large">
                            {avatar}
                        </IconButton> ||
                        avatar
                    }

                    <div className={classes.leftSide}>
                        <Typography className={classes.smallText}>
                            {!!timeSinceUploaded && <>Uploaded {timeSinceUploaded}</>}
                        </Typography>
                        <Typography className={classes.largeText}>
                            {joinNameParts(contributor)}
                        </Typography>
                        <Typography className={classes.smallText}>
                            {contributor.relationship}
                        </Typography>
                    </div>
                </Grid>
            </>
        );
    };

    renderContent = () => {
        const {
            classes,
            hideRemoveAlbum,
            canFlagPhoto,
            canEditPhoto,
            hideUploaderDetails,
            hideFlagInappropriateButton
        } = this.props;

        return (
            <Grid item xs={12} className={classes.popperContent}>
                {canEditPhoto &&
                    <>
                        {this.renderPopperHeader()}
                        <Divider className={classes.divider} />
                    </>
                }
                {!hideRemoveAlbum && canEditPhoto &&
                    <>
                        {this.renderRemoveButton()}
                        <Divider className={classes.divider} />
                    </>
                }
                {this.renderScreenMode()}
                <Divider className={classes.divider} />
                {this.renderDownloadButton()}
                {canFlagPhoto && !hideFlagInappropriateButton &&
                    <>
                        <Divider className={classes.divider} />
                        {this.renderFlagButton()}
                    </>
                }
                {canEditPhoto &&
                    <>
                        <Divider className={classes.divider} />
                        {this.renderDeleteButton()}
                    </>
                }
                {!hideUploaderDetails && this.renderPopperFooter()}
            </Grid>
        );
    };

    render() {
        const { popperEl, zIndex, classes, hideUploaderDetails, isOpenPhotoPopper, contributor } = this.props;

        return (
            <>
                {isOpenPhotoPopper &&
                    <GPopper
                        anchorEle={popperEl}
                        className={classNames(classes.popper,
                            hideUploaderDetails || !contributor && classes.uploaderDetailsHidden)}
                        arrowClass={classes.arrow}
                        paperClass={classes.popoverPaper}
                        zIndex={zIndex}
                        closePopper={this.clickAwayListener}
                        placement='bottom'
                    >
                        {this.renderContent()}
                    </GPopper>
                }

                {this.renderConfirmationDialog()}
                {this.renderFlagConfirmationDialog()}
            </>
        );
    }
}

export default withState(mapStateToProps)(withStyles(styles)(PhotoPopper));
