import {
    S3FileUploadPresignUrlResponse,
    GatherEventWithPlayback,
    FileAsset,
    UploadedAssetInsertRequest,
    UserProfileUXRecord,
    UploadedAssetRecord,
    UploadedAssetUpdateRequest,
    UploadedAssetStatusType,
    UploadedAssetRecordWithSignedUrl,
    GatherCasePublic
} from '../shared/types';
import { getFromAPI, postToAPI, patchAPI, deleteFromAPI } from '.';
// import { registerAppError } from './errors';
import * as mime from 'mime-types';
import { generateHash } from '../shared/docs/utils';
import { registerAppError } from './errors';
import { S3Response } from 'react-s3-uploader';
import { PlaybackStreamAsset } from '../shared/types/live_stream';
import { streamableEventsLoaded } from './LiveStream.action';
import { AppDispatch } from '../store';

export function getVideoUploadSignedS3Url(
    caseUuid: string,
    eventId: number,
    newFile: File,
    userData: UserProfileUXRecord,
) {
    return async (dispatch: AppDispatch): Promise<UploadedAssetRecordWithSignedUrl | null> => {
        let suffix: string | null = null;
        const derivedSuffix: string | false = mime.extension(newFile.type);
        if (typeof derivedSuffix === 'string') {
            suffix = derivedSuffix;
        }
        const nowMs = Date.now();
        const uniqueHash = generateHash(`Gather ${nowMs} ${newFile.name}`);

        const newAsset: FileAsset = {
            name: newFile.name,
            type: newFile.type,
            uniqueIdHash: uniqueHash,
            suffix: suffix || 'unknown',
        };
        const URLEndpoint = `api/case/${caseUuid}/event/${eventId}/video/uploadurl`;
        const response = await getFromAPI<S3FileUploadPresignUrlResponse>(
            URLEndpoint + `/${newAsset.uniqueIdHash}/${newAsset.suffix}`,
            dispatch,
        ); //  we have what we came for.  but we need to add this to the database. 
        if (!response) {
            return null;
        }
        const urlparts = response.presignedurl.split('\?');
        const insertRequest: UploadedAssetInsertRequest = {
            event_id: eventId,
            unique_id: uniqueHash,
            path: response.path,
            url: urlparts[0],
            uploaded_by: userData.id,
            location: 's3',
        };
        const newVideoAsset = await postToAPI<UploadedAssetRecord>(
            `api/case/${caseUuid}/event/${eventId}/video/`,
            { video: insertRequest },
            dispatch,
        );
        if (!newVideoAsset) {
            dispatch(registerAppError('Unable to create new uploaded asset'));
            return null;
        }
        return {
            ...newVideoAsset,
            signedUrl: response.presignedurl,
        };
    };
}

export function finalizeVideoUpload(
    caseUuid: string,
    eventId: number,
    fileIdHash: string,
    s3Result: S3Response,
) {
    return async (dispatch: AppDispatch): Promise<UploadedAssetRecord | null> => {
        // make calls to update a status on the database and kick off mux sync

        const updateVideoRequest: UploadedAssetUpdateRequest = {
            asset_status: UploadedAssetStatusType.uploaded,
            url: s3Result.publicUrl

        };
        const newVideoAsset = await patchAPI<UploadedAssetRecord>(
            `api/case/${caseUuid}/event/${eventId}/video/${fileIdHash}/`,
            {
                video: updateVideoRequest,
            },
            dispatch,
        );
        if (!newVideoAsset) {
            dispatch(registerAppError('Unable to create new uploaded asset'));
            return null;
        }
        return newVideoAsset;
    };
}

export const ADD_BACK_STREAM_ASSET = 'ADD_BACK_STREAM_ASSET';

interface AddBackStreamAsset {
    type: typeof ADD_BACK_STREAM_ASSET;
    asset: PlaybackStreamAsset;
    eventId: number;
}

function addBackStreamAsset(asset: PlaybackStreamAsset, eventId: number): AddBackStreamAsset {
    return {
        type: ADD_BACK_STREAM_ASSET,
        asset,
        eventId,
    };
}

export const REMOVE_STREAM_ASSET = 'REMOVE_STREAM_ASSET';

interface RemoveStreamAsset {
    type: typeof REMOVE_STREAM_ASSET;
    assetId: number;
    eventId: number;
}

function removeStreamAsset(assetId: number, eventId: number): RemoveStreamAsset {
    return {
        type: REMOVE_STREAM_ASSET,
        assetId,
        eventId,
    };
}

export function deleteVideoAsset(
    gatherCase: GatherCasePublic,
    event: GatherEventWithPlayback,
    asset: PlaybackStreamAsset,
) {
    return async (dispatch: AppDispatch): Promise<GatherEventWithPlayback[] | null> => {

        dispatch(removeStreamAsset(asset.asset_id, event.id));
        const updatedEvents = await deleteFromAPI<GatherEventWithPlayback[]>(
            `api/case/${gatherCase.uuid}/event/${event.id}/video/${asset.asset_id}`,
            dispatch,
        );
        if (!updatedEvents) {
            dispatch(addBackStreamAsset(asset, event.id));
            dispatch(registerAppError('Unable to remove asset'));
            return null;
        }
        dispatch(streamableEventsLoaded(updatedEvents));
        return updatedEvents;
    };
}

// ----> Update Video Asset Ranks <----
export const UPDATING_VIDEO_ASSET_RANKS = 'UPDATING_VIDEO_ASSET_RANKS';

interface UpdatingVideoAssetsRanks {
    type: typeof UPDATING_VIDEO_ASSET_RANKS;
    eventId: number;
    optimisticAssets: PlaybackStreamAsset[];
}

function updatingVideoAssetsRanks(eventId: number, optimisticAssets: PlaybackStreamAsset[]): UpdatingVideoAssetsRanks {
    return {
        type: UPDATING_VIDEO_ASSET_RANKS,
        eventId,
        optimisticAssets,
    };
}

export const UPDATING_VIDEO_ASSET_RANKS_FAILED = 'UPDATING_VIDEO_ASSET_RANKS_FAILED';

interface UpdatingVideoAssetsRanksFailed {
    type: typeof UPDATING_VIDEO_ASSET_RANKS_FAILED;
    eventId: number;
    rollbackAssets: PlaybackStreamAsset[];
}

function updatingVideoAssetsRanksFailed(
    eventId: number,
    rollbackAssets: PlaybackStreamAsset[],
): UpdatingVideoAssetsRanksFailed {
    return {
        type: UPDATING_VIDEO_ASSET_RANKS_FAILED,
        eventId,
        rollbackAssets,
    };
}

export function updateVideoAssetsRanks(
    assets: PlaybackStreamAsset[],
    existingAssets: PlaybackStreamAsset[],
    eventId: number,
    caseUuid: string,
) {
    return async (dispatch: AppDispatch): Promise<GatherEventWithPlayback[] | null> => {
        const assetIds = assets.map((asset) => asset.asset_id);
        const route = `api/case/${caseUuid}/event/${eventId}/video/rank`;
        dispatch(updatingVideoAssetsRanks(eventId, assets));
        const updatedEvents = await postToAPI<GatherEventWithPlayback[]>(route, { assetIds }, dispatch);
        if (updatedEvents) {
            dispatch(streamableEventsLoaded(updatedEvents));
            return updatedEvents;
        }
        dispatch(updatingVideoAssetsRanksFailed(eventId, existingAssets));
        dispatch(registerAppError('Unable to update video asset order.'));
        return null;
    };
}

export type VideoAction =
    | AddBackStreamAsset
    | RemoveStreamAsset
    | UpdatingVideoAssetsRanks
    | UpdatingVideoAssetsRanksFailed
    ;
