import * as t from 'io-ts';
import { getValidator } from './utils';

export enum ThemeType {
    Child = 'child',
    Adult = 'adult'
}

export interface ThemeRecord {
    id: number;
    rank: number;
    name: string;
    description: string | null;
    thumbnail: string;
    type: ThemeType;
    primary_color: string;
    secondary_color: string;
    primary_background_image: string;
    secondary_background_image: string;
    themed_hero_image_mobile: string | null;
    background_pattern: string | null;
    top_accent_image: string;
    top_accent_image_reversed: string | null;
    top_graphic: string;
    top_mobile_graphic: string;
    bottom_left_graphic: string;
    bottom_right_graphic: string;
    bottom_mobile_graphic: string;
    created_by: number;
    updated_by: number;
    created_time: Date;
    updated_time: Date;
    is_hidden: boolean;
}

export interface ThemeUX extends ThemeRecord {}

const ThemeCreateRequestType = t.type({
    name: t.string,
    description: t.union([t.string, t.null]),
    color: t.string,
    backgroundImage: t.union([t.string, t.null]),
    glyphImage: t.union([t.string, t.null]),
});
export interface ThemeCreateRequest extends t.TypeOf<typeof ThemeCreateRequestType> {}

export class ThemeCreateRequest {
    public static fromRequest = getValidator<ThemeCreateRequest>(ThemeCreateRequestType);
}
