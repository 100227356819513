import * as t from 'io-ts';
import { PhotoTransformationsType, GatherEventPublic, MemoryAuthor, getValidator } from '.';
import { splitFullName } from '../utils';

export enum FlowerSaleType {
    flower = 'flower',
    tree = 'tree',
}
export interface FlowerSalesProduct {
    price: number;
    dimension: string;
    name: string;
    large: string;
    description: string;
    small: string;
    code: string;
    saleType: FlowerSaleType;
}

export interface LoadFlowerSalesProductsResponse {
    total: number;
    products: FlowerSalesProduct[];
}

export interface LoadFlowerSalesOrderPageResponse {
    informant: PossibleRecipient | null;
    events: GatherEventPublic[];
    product: FlowerSalesProduct;
}

export interface FlowerSalesOrderDraft {
    taxTotal: number;
    subTotal: number;
    grandTotal: number;
    deliveryTotal: number;
}

export interface FlowerSalesOrderComplete extends FlowerSalesOrderDraft {
    flowerSaleId?: number;
    orderNo: number;
    productPrice: number;
    productName: string;
    productDescription: string;
    productPhotoLarge: string;
    productPhotoThumbnail: string;
    productCode: string;
    deliveryDate: string;
    cardMessage: string;
    specialInstructions: string;
    customerEmail: string;
}

// This is a bit of a kludge, but it's the easiest way to get the UX object
//  without having to make another API call - this will only show to the user
//  who placed the order, so it's not a big deal if it's not 100% accurate
//  and it will be replaced by the real UX object when the page is refreshed
export const flowerSalesUXFromOrder = (
    orderComplete: FlowerSalesOrderComplete,
    orderRequest: FlowerSalesOrderRequest,
): FlowerSalesUX => {
    const name = splitFullName(orderRequest.customerName);
    return {
        id: orderComplete.flowerSaleId || 0,
        order_time: new Date(),
        product_photo_thumbnail: orderComplete.productPhotoThumbnail,
        card_message: orderRequest.cardMessage,
        show_message: orderRequest.showMessage,
        fname: '',
        displayName: orderRequest.displayName || '',
        time_since: 'Now',
        author: {
            fname: name.fname,
            entity_id: 0,
            case_entity_id: 0,
            created_time: new Date(),
            user_id: orderRequest.createdBy || 0,
            relationship: null,
            photo: null,
            photo_transformations: null,
            is_pending_decision: false,
            mname: name.mname,
            lname: name.lname,
            isFHorGOM: false,
        },
        quantity: 1,
        sale_type: FlowerSaleType.flower,
    };
};

export interface FlowerSalesUX {
    id: number;
    order_time: Date;
    product_photo_thumbnail: string | null;
    card_message: string | null;
    show_message: boolean;
    fname: string;
    time_since: string;
    author: MemoryAuthor | null;
    displayName: string;
    sale_type: FlowerSaleType;
    quantity: number;
    project_name?: string;
}

export interface AuthorizeNetInfo {
    loginId: string;
    clientKey: string;
}

export interface FlowerSalesOrderDraftResponse {
    orderDraft: FlowerSalesOrderDraft;
    authorizeNetInfo: AuthorizeNetInfo;
}

export enum FlowerSalesOrderRecipient {
    event = 'event',
    funeral_home = 'funeral_home',
    informant = 'informant',
    other = 'other',
}

const FlowerSalesOrderRecipientDefinition = t.union([
    t.literal(FlowerSalesOrderRecipient.event),
    t.literal(FlowerSalesOrderRecipient.funeral_home),
    t.literal(FlowerSalesOrderRecipient.informant),
    t.literal(FlowerSalesOrderRecipient.other),
]);

// ---> FlowerSalesOrderRequest <---
const OtherRecipientRequestType = t.type({
    name: t.string,
    address1: t.string,
    address2: t.union([t.string, t.null]),
    city: t.string,
    state: t.string,
    zipcode: t.string,
    phone: t.union([t.string, t.null]),
});

export interface OtherRecipientRequest extends t.TypeOf<typeof OtherRecipientRequestType> {

}

const flowerSalesOrderRequestRequired = {
    customerName: t.string,
    displayName: t.union([t.string, t.undefined]),
    customerEmail: t.string,
    customerPhone: t.string,
    customerAddress1: t.string,
    customerAddress2: t.union([t.string, t.null]),
    customerCity: t.string,
    customerState: t.string,
    customerZipcode: t.string,
    productPrice: t.number,
    productCode: t.string,
    cardMessage: t.string,
    showMessage: t.boolean,
    specialInstructions: t.union([t.string, t.null]),
    recipient: FlowerSalesOrderRecipientDefinition,
    eventId: t.union([t.number, t.null]),
    otherRecipient: t.union([OtherRecipientRequestType, t.null]),
    deliveryDate: t.string,
    ccinfoToken: t.string,
    orderTotal: t.number,
};

const flowerSalesOrderRequestOptional = t.partial({
    createdBy: t.number,
});

const FlowerSalesOrderRequestType = t.intersection([
    flowerSalesOrderRequestOptional, t.type(flowerSalesOrderRequestRequired)
]);

export interface FlowerSalesOrderRequest extends t.TypeOf<typeof FlowerSalesOrderRequestType> {
    recipient: FlowerSalesOrderRecipient;
    otherRecipient: OtherRecipientRequest | null;
}

export class FlowerSalesOrderRequest {
    public static fromRequest = getValidator<FlowerSalesOrderRequest>(FlowerSalesOrderRequestType);
}

const FlowerSalesOrderUpdateRequestType = t.partial({
    show_message: t.boolean,
    created_by: t.number,
});

export interface FlowerSalesOrderUpdateRequest extends t.TypeOf<typeof FlowerSalesOrderUpdateRequestType> { }

export class FlowerSalesOrderUpdateRequest {
    public static fromRequest = getValidator<FlowerSalesOrderUpdateRequest>(FlowerSalesOrderUpdateRequestType);
}

export enum FlowerSalesProductCategory {
    table_arrangements = 'table_arrangements',
    baskets = 'baskets',
    sprays = 'sprays',
    plants = 'plants',
    inside_casket = 'inside_casket',
    wreaths = 'wreaths',
    hearts = 'hearts',
    trees = 'trees',
    crosses = 'crosses',
    casket_sprays = 'casket_sprays',
    urn_arrangements = 'urn_arrangements',
}

export const FlowerSalesProductCategoryDisplay = {
    [FlowerSalesProductCategory.table_arrangements]: 'Table Arrangements',
    [FlowerSalesProductCategory.baskets]: 'Baskets',
    [FlowerSalesProductCategory.sprays]: 'Sprays',
    [FlowerSalesProductCategory.plants]: 'Plants',
    [FlowerSalesProductCategory.inside_casket]: 'Inside Casket',
    [FlowerSalesProductCategory.wreaths]: 'Wreaths',
    [FlowerSalesProductCategory.hearts]: 'Hearts',
    [FlowerSalesProductCategory.crosses]: 'Crosses',
    [FlowerSalesProductCategory.casket_sprays]: 'Casket Sprays',
    [FlowerSalesProductCategory.urn_arrangements]: 'Urn Arrangements',
    [FlowerSalesProductCategory.trees]: 'Memorial Trees',
};

export const FlowerSalesProductCategoryDefinition = t.union([
    t.literal(FlowerSalesProductCategory.table_arrangements),
    t.literal(FlowerSalesProductCategory.baskets),
    t.literal(FlowerSalesProductCategory.sprays),
    t.literal(FlowerSalesProductCategory.plants),
    t.literal(FlowerSalesProductCategory.inside_casket),
    t.literal(FlowerSalesProductCategory.wreaths),
    t.literal(FlowerSalesProductCategory.hearts),
    t.literal(FlowerSalesProductCategory.crosses),
    t.literal(FlowerSalesProductCategory.casket_sprays),
    t.literal(FlowerSalesProductCategory.urn_arrangements),
    t.literal(FlowerSalesProductCategory.trees),
]);

export function isFlowerSalesProductCategory(c: string): c is FlowerSalesProductCategory {
    return Boolean(FlowerSalesProductCategory[c]);
}

export interface PossibleRecipient {
    user_id?: number;
    fname: string;
    lname: string;
    relationship: string;
    photo?: string | null;
    photo_transformations?: PhotoTransformationsType | null;
    zipcode: string;
    phone: string | null;
}

export const getForestPreposition = (projectName: string | undefined): string => {
    if (!projectName) {
        return 'in';
    }
    if (projectName.match(/^the /i)) {
        return 'in';
    } else if (projectName.match(/^(a )|(an )/i)) {
        return 'in';
    } else if (projectName.match(/^(support)|(help)/i)) {
        return 'to';
    }
    return 'in the';
};
