import classNames from 'classnames';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import ClearIcon from '@mui/icons-material/Clear';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import { convertHexToRGBA } from '../../../../services';
import { GatherCasePublic, } from '../../../../shared/types';
import UserAvatar from '../../../common/UserAvatar';
import { CASE_DETAILS_TEXT_SHADOW } from '../utils';
import { SlideTransition } from '../../../common/Transitions';
import { useGSelector } from '../../../../types';
import { RefCallback } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) => ({
    dialogPaper: {
        width: '100%',
        maxWidth: '100%',
        borderRadius: 6,
        '@media (min-width: 768px)': {
            maxWidth: 720,
            height: 'auto',
            maxHeight: 'calc(100vh - 120px)',
            overflow: 'visible'
        }
    },
    dialogTitle: {
        boxShadow: 'inset 0px -4px 6px 0px rgba(0, 0, 0, 0.2)',
        background: convertHexToRGBA(theme.palette.primary.main, 0.04),
        borderBottom: `1px solid ${theme.palette.secondary.main}`,
        padding: '12px 0',
        position: 'relative',
        borderRadius: '6px 6px 0 0',
        '@media (min-width: 768px)': {
            padding: '0 0 12px'
        }
    },
    dialogContent: {
        overflowY: 'unset',
        padding: 0,
        '@media (min-width: 768px)': {
            overflowY: 'auto',
            height: 515
        }
    },
    clearIcon: {
        position: 'absolute',
        top: 8,
        right: 8,
        cursor: 'pointer',
        fontSize: 32
    },
    userAvatarContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 76,
        height: 76,
        margin: 'auto',
        borderRadius: '50%',
        boxShadow: '0 0 8px 4px rgb(0, 0, 0, 0.2)',
        background: `${theme.palette.common.white} !important`,
        '@media (min-width: 600px)': {
            width: 96,
            height: 96,
        },
        '@media (min-width: 768px)': {
            position: 'absolute',
            left: '50%',
            transform: 'translate(-50%, -50%)'
        }
    },
    userAvatar: {
        width: 72,
        height: 72,
        fontSize: 40,
        fontWeight: 300,
        '@media (min-width: 600px)': {
            width: 92,
            height: 92,
        }
    },
    title: {
        fontSize: 20,
        fontWeight: 300,
        lineHeight: 1,
        marginTop: 4,
        textShadow: CASE_DETAILS_TEXT_SHADOW,
        '@media (min-width: 420px)': {
            fontSize: 24
        },
        '@media (min-width: 500px)': {
            fontSize: 28
        },
        '@media (min-width: 600px)': {
            fontSize: 32
        },
        '@media (min-width: 768px)': {
            marginTop: 52
        }
    },
    subTitle: {
        fontSize: 12,
        lineHeight: 1.125,
        textShadow: CASE_DETAILS_TEXT_SHADOW,
        '@media (min-width: 420px)': {
            fontSize: 14
        },
        '@media (min-width: 600px)': {
            fontSize: 16
        }
    },
    textUppercase: {
        textTransform: 'uppercase'
    },
    cursorAuto: {
        cursor: 'auto'
    }
}), { name: 'RememberPageDialog' });

interface Props {
    children: JSX.Element;
    zIndex: number;
    isOpen: boolean;
    publicCase: GatherCasePublic;
    title: string;
    subtitle: string;
    closeDialog: () => void;
    isCasePhotoSaving?: boolean;
    dialogPaperClass?: string;
    titleClass?: string;
    subTitleClass?: string;
    userAvatarClass?: string;
    dialogContentClass?: string;
    scroll?: 'body' | 'paper';
    onAvatarClick?: () => void;
    titleRef?: RefCallback<HTMLDivElement>;
}

const RememberPageDialog = (props: Props) => {
    const {
        isOpen,
        publicCase,
        zIndex,
        isCasePhotoSaving,
        children,
        title,
        subtitle,
        dialogPaperClass,
        titleClass,
        userAvatarClass,
        dialogContentClass,
        scroll,
        subTitleClass,
        titleRef,
        onAvatarClick,
        closeDialog,
    } = props;

    const classes = useStyles();

    const themeBackground = useGSelector(({ whiteLabel }) => whiteLabel.rememberTheme?.primary_background_image);

    const spinnerSize = window.innerWidth <= 768 ? 36 : 48;

    return (
        <Dialog
            open={isOpen}
            fullScreen
            onClose={e => closeDialog()}
            transitionDuration={300}
            TransitionComponent={SlideTransition}
            classes={{ paper: classNames(classes.dialogPaper, dialogPaperClass) }}
            style={{ zIndex }}
            scroll={scroll}
        >
            <DialogTitle
                className={classes.dialogTitle}
                ref={titleRef}
                style={{
                    background: themeBackground && `url(${themeBackground}) 0% 0%/50%` || undefined,
                }}>
                <IconButton
                    className={classNames(
                        classes.userAvatarContainer,
                        !onAvatarClick && classes.cursorAuto,
                        userAvatarClass
                    )}
                    onClick={e => onAvatarClick && onAvatarClick()}
                    disableRipple={!onAvatarClick}
                    disableTouchRipple={!onAvatarClick}
                    size="large">
                    <UserAvatar
                        user={publicCase}
                        size={92}
                        className={classNames(classes.userAvatar, classes.textUppercase)}
                        isLoading={isCasePhotoSaving}
                        spinnerSize={spinnerSize}
                    />
                </IconButton>

                <Typography color="primary" align="center" className={classNames(classes.title, titleClass)}>
                    {title}
                </Typography>
                <Typography
                    color="secondary"
                    align="center"
                    className={classNames(classes.subTitle, subTitleClass)}
                >
                    {subtitle}
                </Typography>
                <ClearIcon
                    color="secondary"
                    className={classes.clearIcon}
                    onClick={e => closeDialog()}
                />
            </DialogTitle>

            <DialogContent className={classNames(classes.dialogContent, dialogContentClass)}>
                {children}
            </DialogContent>
        </Dialog>
    );
};

export default RememberPageDialog;
