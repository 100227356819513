import { Button, Dialog, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import { SlideTransition } from "../common/Transitions";
import { Theme } from '@mui/material/styles';
import classNames from "classnames";
import Clear from '@mui/icons-material/Clear';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { values } from "lodash";
import {
    ProductCategory,
    ProductCategoryDisplayLookup,
    ProductCategoryEnum,
    ProductSummary,
    ProductUX
} from "../../shared/types";
import GlobalCatalogProductContents from "./GlobalCatalogProductContents";
import makeStyles from "@mui/styles/makeStyles";
import { GStyles } from "../../styles/GStyles";
import useFullScreen from "../common/hooks/useFullScreen";

const useStyles = makeStyles((theme: Theme) => ({
    dialogHeader: {
        zIndex: 1,
        padding: 16,
        borderBottom: '1px solid rgba(0,0,0,0.21)'
    },
    dialogPaper: {},
    clearIcon: {
        position: 'absolute',
        top: 12,
        right: 10,
        fontSize: 28,
        color: '#fff',
        '&:hover': {
            cursor: 'pointer',
        },
        '@media (min-width: 400px)': {
            fontSize: 34,
        }
    },
    heading: {
        color: '#fff',
        fontSize: 16,
        '@media (min-width: 400px)': {
            fontSize: 20
        }
    },
}), { name: 'AllProductCategoriesInterimDialog' });

interface Props {
    selectedCategory: ProductCategory | null;
    isDialogOpen: boolean;
    zIndex: number;
    availableProducts: ProductSummary[];
    selectedProducts: ProductUX[];
    closeDialog: () => void;
    onCreateNewProductClick: (productName?: string) => void;
    onLoadProducts: (offset: number, searchCategory: string) => Promise<void>;
    onProductAdd: (id: number) => Promise<void>;
    onSelectedCategoryChange: (c: ProductCategory) => void;
}

const AllProductCategoriesInterimDialog = (props: Props) => {
    const {
        selectedCategory,
        isDialogOpen,
        closeDialog,
        zIndex,
        availableProducts,
        selectedProducts,
        onCreateNewProductClick,
        onLoadProducts,
        onProductAdd,
        onSelectedCategoryChange
    } = props;

    const classes = useStyles();
    const fullScreen = useFullScreen();

    const renderCategoryOptions = () => {
        return (
            <Grid container sx={{ padding: '0px 30px 20px' }}>
                <Grid item pt='15px'>
                    <Typography
                        color='secondary'
                        fontSize={16}
                    >
                        You must choose a category in order to view the global catalog items:
                    </Typography>
                </Grid>
                <Grid item width={1} mt={1.5} sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px'
                }}>
                    {values(ProductCategoryEnum).map(c => {
                        return <Button
                            key={c}
                            variant="outlined"
                            fullWidth
                            sx={{
                                justifyContent: 'space-between', color: "#000",
                            }}
                            onClick={() => onSelectedCategoryChange(c)}
                        >
                            {ProductCategoryDisplayLookup[c]}
                            <ArrowForwardIcon />
                        </Button>;
                    })}
                </Grid>
            </Grid>
        );
    };

    const renderCategoryProductContents = () => {
        if (!selectedCategory) {
            return;
        }
        return (
            <GlobalCatalogProductContents
                category={selectedCategory}
                availableProducts={availableProducts}
                selectedProducts={selectedProducts}
                onCreateNewProductClick={onCreateNewProductClick}
                onLoadProducts={onLoadProducts}
                onProductAdd={onProductAdd}
            />
        );
    };

    return (
        <Dialog
            fullScreen={selectedCategory !== null && fullScreen}
            open={isDialogOpen}
            onClose={closeDialog}
            TransitionComponent={SlideTransition}
            transitionDuration={300}
            style={{ zIndex }}
            sx={{
                '& .MuiDialog-paper': {
                    width: '100%',
                    maxWidth: selectedCategory !== null ? 2000 : 400
                }
            }}
        >
            <DialogTitle
                id="alert-dialog-slide-title"
                className={classNames(
                    classes.dialogHeader,
                    GStyles.backgroundPrimary
                )}
            >
                <Clear
                    className={classNames(classes.clearIcon)}
                    onClick={closeDialog}
                />
                <Typography
                    component="p"
                    className={classNames(classes.heading)}
                    align="left"
                >
                    Catalog Products
                </Typography>
            </DialogTitle>
            <DialogContent sx={{ paddingX: '0px' }}>
                {selectedCategory === null ? renderCategoryOptions() : renderCategoryProductContents()}
            </DialogContent>
        </Dialog >
    );
};

export default AllProductCategoriesInterimDialog;