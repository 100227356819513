import FilterResults, { SortType, Filter, ActiveFilters, FilterCounts } from './FilterResults';
import { Theme } from '@mui/material/styles';
import { StyleRulesCallback } from '@mui/styles';
import withGStyles, { WithGStyles } from '../../../../styles/WithGStyles';
import GPopper from '../../../common/GPopper';

interface Props {
    haveActiveFilters: boolean;
    sortType: SortType;
    filters: Filter[];
    activeFilters: ActiveFilters;
    filterCounts: FilterCounts;
    searchText: string;
    popperAnchorEle: HTMLElement | null;
    clickAwayListener: (event: MouseEvent | TouchEvent) => void;
    onSortTypeChange: (value: SortType) => void;
    onFilterClick: (name: string, option: string) => void;
    onSearchChange: (searchText: string) => void;
    resetFilters: () => void;
    zIndex: number;
}

const styles: StyleRulesCallback<Theme, Props> = theme => ({
    root: {},
    popoverPaper: {
        borderRadius: 4,
        WebkitBorderRadius: 4,
        MozBorderRadius: 4,
        width: 280,
        boxShadow: theme.shadows[10],
    },
    popperContent: {
        textAlign: 'center',
        overflowY: 'auto',
        padding: 8,
        borderRadius: 4,
        WebkitBorderRadius: 4,
        MozBorderRadius: 4,
    },
    arrow: {
        border: '20px solid transparent',
    },
    popper: {
        '&[data-popper-placement*="bottom"]': {
            marginTop: '15px !important'
        },
        '&[data-popper-placement*="top"]': {
            marginBottom: '15px !important'
        },
        '&[data-popper-placement*="bottom"] $arrow': {
            borderBottom: '20px solid #fff',
            top: '-20px',
        },
        '&[data-popper-placement*="top"] $arrow': {
            borderTop: '20px solid #fff',
            bottom: '-20px',
        },
    }
});

type StyledProps = Props & WithGStyles<'root' | 'popper' | 'popoverPaper' | 'arrow' | 'popperContent'>;

const FilterResultsPopper = (props: StyledProps) => {

    const {
        classes,
        sortType,
        filters,
        activeFilters,
        filterCounts,
        searchText,
        popperAnchorEle,
        clickAwayListener,
        onSortTypeChange,
        onFilterClick,
        onSearchChange,
        resetFilters,
        haveActiveFilters,
        zIndex
    } = props;

    return (
        <GPopper
            anchorEle={popperAnchorEle}
            className={classes.popper}
            popperContentClass={classes.popperContent}
            arrowClass={classes.arrow}
            paperClass={classes.popoverPaper}
            zIndex={zIndex}
            closePopper={clickAwayListener}
            interactive
        >
            <FilterResults
                sortType={sortType}
                filters={filters}
                activeFilters={activeFilters}
                filterCounts={filterCounts}
                searchText={searchText}
                onSortTypeChange={onSortTypeChange}
                onFilterClick={onFilterClick}
                onSearchChange={onSearchChange}
                haveActiveFilters={haveActiveFilters}
                resetFilters={resetFilters}
            />
        </GPopper>
    );
};

export default withGStyles(styles)(FilterResultsPopper);
