import * as t from 'io-ts';
import { getValidator } from './utils';

export const MAX_CASE_LABEL_LENGTH = 26;

export interface CaseLabelRecord {
    id: number;
    name: string;
    color: string;
    funeral_home_id: number;
    created_time: Date;
    created_by: number;
    updated_time: Date;
    updated_by: number;
    deleted_time: Date | null;
    deleted_by: number | null;
}

export interface CaseLabelUX {
    id: number;
    name: string;
    color: string;
    funeralHomeId: number;
}

export const isCaseLabelUX = (obj: unknown): obj is CaseLabelUX => {
    const label = obj as CaseLabelUX;
    return Boolean(
        typeof label.id === 'number' &&
        typeof label.name === 'string' &&
        typeof label.color === 'string' &&
        typeof label.funeralHomeId === 'number'
    );
};

export interface CaseLabelCaseRecord {
    case_label_id: number;
    gather_case_id: number;
    created_time: Date;
    created_by: number;
    deleted_time: Date | null;
    deleted_by: number | null;
}

const gatherCaseCreateLabelRequestDefinition = {
    name: t.string,
    color: t.string,
};
const GatherCaseLabelCreateRequestType = t.type(gatherCaseCreateLabelRequestDefinition);

export interface GatherCaseLabelCreateRequest extends t.TypeOf<typeof GatherCaseLabelCreateRequestType> {}

export class GatherCaseLabelCreateRequest {
    public static fromRequest = getValidator<GatherCaseLabelCreateRequest>(GatherCaseLabelCreateRequestType);
}

const gatherCaseUpdateLabelRequestDefinition = {
    name: t.string,
    color: t.string,
};
const GatherCaseLabelUpdateRequestType = t.partial(gatherCaseUpdateLabelRequestDefinition);

export interface GatherCaseLabelUpdateRequest extends t.TypeOf<typeof GatherCaseLabelUpdateRequestType> {}

export class GatherCaseLabelUpdateRequest {
    public static fromRequest = getValidator<GatherCaseLabelUpdateRequest>(GatherCaseLabelUpdateRequestType);
}

export function mapCaseLabelToCaseLabelUX(caseLabel: CaseLabelRecord): CaseLabelUX {
    return {
        id: caseLabel.id,
        name: caseLabel.name,
        color: caseLabel.color,
        funeralHomeId: caseLabel.funeral_home_id,
    };
}
